import React, { memo } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

const Dropzone = ({ children, ...restProps }) => {
  const { getRootProps, getInputProps } = useDropzone({ ...restProps });
  return (
    <Grid {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </Grid>
  );
};

Dropzone.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(Dropzone);
