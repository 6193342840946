import React, { memo, useState } from 'react';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import CircleTableChairs from './CircleTableChairs';
import RectangleTableChairs from './RectangleTableChairs';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import Icon from '../Icon/Icon';
import { useTheme } from '@mui/material';
import useModal from '@utils/hooks/useModal/useModal';
import TableReservationDetailsModal from './TableReservationDetailsModal';
import WalkInModal from './WalkInModal';
import mova from 'mova';
import { getReservationDuration } from "utils/date";
import { useSelector } from "react-redux";
import { placeSelectors } from "state/ducks/place";
import StyledBaseTable from "./tables/StyledBaseTable";
import StyledLoungeTable from "./tables/StyledLoungeTable";
import { reservationSelectors } from "../../../state/ducks/reservation";
import { sortBy } from "../../../utils/lo/lo";

const t = mova.ns('components.Reservations');

const StyledTableLabel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -16,
  right: -16,
  padding: theme.spacing(0, 0.5),
  borderRadius: '16px',
  backgroundColor: 'white',
  border: `2px solid ${theme.palette.primary.dark}`,
  ...theme.typography.h4,
  zIndex: 100
}));

const StyledMultipleReservationsLabel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -16,
  left: -24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 0.5),
  borderRadius: '16px',
  backgroundColor: 'white',
  border: `2px solid ${theme.palette.primary.dark}`,
  ...theme.typography.h4,
  zIndex: 100,
}));

const StyledKeepTableLabel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: -16,
  right: -16,
  width: 20,
  height: 20,
  borderRadius: '16px',
  backgroundColor: 'white',
  border: `2px solid #FFBE3F`,
  ...theme.typography.h4,
}));

const tableComponents = {
  RECT: StyledBaseTable,
  ELLIPSE: StyledBaseTable,
  LOUNGE: StyledLoungeTable,
}

const getReservationFromEvent = (e) => {
  try {
    return JSON.parse(e.dataTransfer.getData('reservation'));
  } catch (_) {
    return null;
  }
};

const HallTable = ({ table, activeElement, offsetY = 0, assignReservation, date, fullView, showPast }) => {
  const [detailsModalOpen, showDetails, closeDetailsModal] = useModal(false);
  const [walkInModalOpen, newWalkIn, closeWalkInModal] = useModal(false);
  const [dragCounters, setDragCounters] = useState(0);
  const manage = !assignReservation;
  const theme = useTheme();
  const chairs =
    table.type === 'ELLIPSE' ? <CircleTableChairs table={table} /> : <RectangleTableChairs table={table} />;
  const activePlace = useSelector(placeSelectors.getActivePlace());

  const toggleDropContainerClass = (e, enable) => {
    if (!assignReservation) {
      return;
    }

    if (enable) {
      setDragCounters(v => v + 1);
    } else {
      setDragCounters(v => v - 1);
    }
  };

  const itemDropped = e => {
    setDragCounters(0);
    const reservation = getReservationFromEvent(e);
    if (reservation && assignReservation) {
      assignReservation({ reservation, table });
    }
  };

  const walkIn = () => {
    closeDetailsModal();
    newWalkIn();
  };

  const reservations = useSelector(reservationSelectors.getHallReservations());
  const tableReservations = sortBy(
    reservations
      .filter(r => r.spots?.find(s => s.id === table.id))
      .filter(r => ['ACCEPTED', 'INPROGRESS', 'FINISHED'].includes(r.status))
      .filter(r => moment(r.from).format('L') === moment(date).format('L'))
      .filter(r => showPast || r.status !== 'FINISHED'),
    'from'
  );
  const inProgressReservations = tableReservations.filter(r => r.status === 'INPROGRESS');
  const nearestReservation = tableReservations[0] || null;
  const minutesSinceStart = moment().diff(moment(nearestReservation?.from), 'minutes');
  const durationString = getReservationDuration(minutesSinceStart, t);
  const TableComponent = tableComponents[table.type];

  const dragStarted = (e) => {
    const reservation = tableReservations.find(r => r.status !== 'FINISHED');
    if (reservation) {
      e.dataTransfer.setData('reservation', JSON.stringify(reservation));
    }
  };

  return (
    <>
      <TableComponent
        table={table}
        id={table.id}
        className='hall-table'
        data-x={table.x}
        data-y={table.y - offsetY}
        selected={activeElement?.id === table.id || dragCounters > 0 || (tableReservations.length > 0 && !manage)}
        inProgress={inProgressReservations.length > 0}
        offsetY={offsetY}
        onDragEnter={e => toggleDropContainerClass(e, true)}
        onDragLeave={e => toggleDropContainerClass(e, false)}
        onDrop={itemDropped}
        onDragOver={e => e.preventDefault()}
        onClick={manage || !table.active ? null : showDetails}
      >
        <Box
          className='table-content'
          textAlign='center'
          sx={{ pointerEvents: manage ? 'none' : 'auto', whiteSpace: 'nowrap', width: '100%' }}
          draggable={!manage && tableReservations.length > 0}
          onDragStart={dragStarted}
        >
          <StyledTableLabel>{table.label}</StyledTableLabel>
          {tableReservations.length > 1 && (
            <StyledMultipleReservationsLabel>
              <Icon type='multiple' /> {tableReservations.length}
            </StyledMultipleReservationsLabel>
          )}
          {nearestReservation?.variables?.keepTable && (
            <StyledKeepTableLabel>
              <Icon type='star' color='#FFBE3F' size={12} filled />
            </StyledKeepTableLabel>
          )}
          {!fullView && (
            <Typography variant='h4' sx={{ overflow: 'hidden' }}>
              {!!nearestReservation && !manage &&
                (nearestReservation.user?.firstName || nearestReservation.variables?.user?.firstName || t('unknownUser'))
              }
            </Typography>
          )}
          {(!fullView || !nearestReservation) && (
            <Typography variant='h4' sx={{ overflow: 'hidden' }}>
              {!!nearestReservation && !manage && nearestReservation.status === 'INPROGRESS' && durationString}
              {!!nearestReservation && !manage && nearestReservation.status !== 'INPROGRESS' &&
                moment(nearestReservation.from).format('HH:mm')}
              {(!nearestReservation || manage) && !activePlace.hideTableCapacity && table.type !== 'LOUNGE' && (
                <Box display='flex' alignItems='center' justifyContent='center'>
                  <Box>{table.minSeats > 0 && table.minSeats + '-'}{table.seats}</Box>
                  <Icon type='user' color={theme.palette.primary.main} size={14} />
                </Box>
              )}
            </Typography>
          )}
          {fullView && nearestReservation && (
            <Box
              display='flex'
              flexDirection='column'
              gap={0.25}
              position='absolute'
              left={`calc(50% - 60px)`}
              width={120}
              top={6}
              zIndex={1}
              p={0.5}
              backgroundColor='white'
              maxHeight={107}
              sx={{ borderRadius: '8px', boxShadow: theme.shadows[1], overflowY: 'auto' }}
            >
              {tableReservations.map(r => (
                <Typography key={r.id} sx={{ display: 'flex', justifyContent: 'space-between', gap: 0.5, fontSize: 12 }}>
                  <Typography component='span' noWrap sx={{ fontSize: 12, fontWeight: 'bold' }}>
                    {r.user?.firstName || r.variables?.user?.firstName || t('unknownUser')}
                  </Typography>
                  <Typography component='span' sx={{ fontSize: 12 }}>
                    {moment(r.from).format('HH:mm')}-{moment(r.to).format('HH:mm')}
                  </Typography>
                </Typography>
              ))}
            </Box>
          )}
          {nearestReservation && table.variables?.width >= 2 && !manage && !fullView && table.type !== 'LOUNGE' && (
            <Box display='flex' alignItems='center' justifyContent='center'>
              <Typography variant='h4' sx={{ overflow: 'hidden' }}>{nearestReservation.seats}</Typography>
              <Icon type='user' color={theme.palette.primary.main} size={14} />
            </Box>
          )}
        </Box>
        {table.type !== 'LOUNGE' && chairs}
      </TableComponent>
      <TableReservationDetailsModal
        open={detailsModalOpen}
        onClose={closeDetailsModal}
        table={table}
        walkIn={walkIn}
        date={date}
        showPast={showPast}
      />
      <WalkInModal open={walkInModalOpen} onClose={closeWalkInModal} table={table} />
    </>
  );
};

export default memo(HallTable);
