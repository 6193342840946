import React, { memo } from 'react';
import Modal from '@components/Modal/Modal';
import mova from 'mova';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@components/Button/Button';

const t = mova.ns('components.Halls');

const AssignReservationModal = ({ open, onClose, confirmAssign, addTable, assignData }) => {
  const isWaitList = assignData?.reservation?.status === 'WAITLIST';

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('assigningReservation')}
      actions={
        <Box display='flex' justifyContent='flex-end' sx={{ width: '100%' }} gap={2}>
          <Button variant='text' color='primary' size='large' onClick={onClose}>
            {t('cancel')}
          </Button>
          {!isWaitList && (
            <Button variant='outlined' size='large' color='primary' onClick={addTable}>
              {t('link')}
            </Button>
          )}
          <Button size='large' onClick={confirmAssign}>
            {isWaitList ? t('accept') : t('reassign')}
          </Button>
        </Box>
      }
    >
      <Typography>
        {assignData?.reservation?.status === 'ACCEPTED' ? t('reassignText') : t('assignText')}
      </Typography>
    </Modal>
  );
};

export default memo(AssignReservationModal);
