import moment from 'moment';

const today = [moment().startOf('day'), moment().endOf('day')];
const thisWeek = [moment().startOf('week'), moment().endOf('week')];
const thisMonth = [moment().startOf('month'), moment().endOf('month')];
const thisYear = [moment().startOf('year'), moment().endOf('year')];

export default {
  today,
  thisWeek,
  thisMonth,
  thisYear,
};
