import React, { memo, useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import Modal from '@components/Modal/Modal';
import ModalActions from '@components/Modal/ModalActions';
import mova from 'mova';
import FormikTextInput from '@form/FormikTextInput';
import * as Yup from 'yup';
import { Typography } from '@mui/material';

const t = mova.ns('components.Reservations');

const FinishReservationModal = (
  { open, onClose, reservation, activePlace, confirmFinishReservation, confirmUpdateBill, updating }
) => {
  const formRef = useRef();

  useEffect(() => {
    if (open) {
      formRef.current?.resetForm();
    }
  }, [open]);

  const schema = Yup.object().shape({
    billAmount: activePlace.donates?.length > 0 ?
      Yup.number(() => t('errors.number')).required(() => t('errors.required')) : Yup.number(() => t('errors.number')),
  });

  const onSubmit = values => {
    if (updating) {
      confirmUpdateBill({ billAmount: Number(values.billAmount) });
    } else {
      confirmFinishReservation({ billAmount: Number(values.billAmount) });
    }
    onClose();
  };

  const showBill = (activePlace.donates?.length > 0 || activePlace.billType === 'enterprise') && reservation.user;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={updating ? t('updatingBill') : t('finishingReservation')}
      actions={
        <ModalActions onClose={onClose} submitForm={() => onSubmit(formRef.current.values)} submitTitle={t('accept')} />
      }
    >
      <Formik
        innerRef={formRef}
        onSubmit={onSubmit}
        initialValues={{
          billAmount: updating ? reservation.billAmount : '',
        }}
        validationSchema={schema}
        enableReinitialize
      >
        <Form>
          {showBill && (
            <>
              <Typography>{t('indicateBillAmount')}:</Typography>
              <FormikTextInput sx={{ my: 1 }} fullWidth name='billAmount' placeholder={t('billAmount')} />
            </>
          )}
          {!showBill && <Typography>{t('confirmFinishReservation')}</Typography>}
        </Form>
      </Formik>
    </Modal>
  );
};

export default memo(FinishReservationModal);
