import React, { memo } from 'react';
import styled from '@emotion/styled';
import mova from 'mova';
import Box from '@mui/material/Box';

const t = mova.ns('auth.Login');

const StyledDivider = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '1px',
  backgroundColor: theme.palette.typography.extraLight,
  textAlign: 'center'
}));

const StyledText = styled('span')(({theme}) => ({
  position: 'absolute',
  top: '-12px',
  left: 'calc(50% - 22px)',
  backgroundColor: '#f6f6f6',
  padding: theme.spacing(0, 1)
}));

const Divider = () => {
  return (
    <Box my={4}>
      <StyledDivider>
        <StyledText>{t('or')}</StyledText>
      </StyledDivider>
    </Box>
  );
};

export default memo(Divider);
