import React, { memo } from 'react';
import { useField } from 'formik';
import TextInput from './TextInput';

const FormikTextInput = ({ name, ...rest }) => {
  const [field, state] = useField(name);

  return (
    <TextInput
      {...field}
      {...rest}
      error={!!state.error && state.touched}
      helperText={!!state.error && state.touched && state.error}
    />
  );
};

export default memo(FormikTextInput);
