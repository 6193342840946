import React from 'react';
import theme from '../../theme';

export default function doubleChevronRight({ color = theme.palette.primary.main }) {
  return (
    <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.2323 2.4122C4.91213 2.08222 4.9201 1.55517 5.25009 1.23501C5.58007 0.914845 6.10712 0.922809 6.42728 1.2528L10.7936 5.75313C10.9503 5.91457 11.0349 6.13252 11.0283 6.35735C11.0216 6.58219 10.9243 6.79478 10.7585 6.94671L6.39212 10.9464C6.05308 11.2569 5.52648 11.2339 5.21591 10.8948C4.90535 10.5558 4.92843 10.0292 5.26746 9.71862L9.00206 6.29765L5.2323 2.4122Z'
        fill={color}
      />
      <path
        d='M2.23503 2.41223C1.91486 2.08225 1.9228 1.5552 2.25278 1.23503C2.58275 0.914854 3.1098 0.922799 3.42998 1.25277L7.79632 5.75278C7.95296 5.91421 8.0376 6.13215 8.03099 6.35699C8.02437 6.58183 7.92706 6.79442 7.7612 6.94636L3.39486 10.9464C3.05583 11.2569 2.52923 11.2339 2.21865 10.8949C1.90807 10.5558 1.93113 10.0292 2.27015 9.71865L6.00477 6.29737L2.23503 2.41223Z'
        fill={color}
      />
    </svg>
  );
}
