import { get } from 'utils/lo/lo';

export default function (thunk, statePath, overrides = {}) {
  return {
    [thunk.pending]:
      overrides.pending ||
      (state => {
        get(state, statePath).isLoading = true;
      }),
    [thunk.fulfilled]:
      overrides.fulfilled ||
      ((state, action) => {
        get(state, statePath).isLoading = false;
        get(state, statePath).data = action.payload;
        get(state, statePath).error = null;
      }),
    [thunk.rejected]:
      overrides.rejected ||
      ((state, action) => {
        get(state, statePath).isLoading = false;
        get(state, statePath).error = action.error.message;
      }),
  };
}
