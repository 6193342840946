import React from 'react';
import theme from '../../theme';

export default function chevronUp({ color = theme.palette.common.white }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M21 19H3V17H21V19ZM21 15H3V13H21V15ZM21 11H3V9H21V11ZM21 7H3V5H21V7Z' fill={color} />
    </svg>
  );
}
