import React, { memo } from 'react';
import { useField } from 'formik';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

const FormikAutocomplete = ({ name, label, type = 'text', inputProps, textFieldProps = {}, ...rest }) => {
  const [field, state, { setValue }] = useField(name);

  return (
    <Autocomplete
      name={field.name}
      value={field.value}
      onChange={(_, newValue) => setValue(newValue?.label || '')}
      {...rest}
      renderInput={(params) =>
        <TextField
          {...params}
          {...field}
          type={type}
          label={label}
          inputProps={{
            ...params.inputProps,
            ...inputProps,
            autoComplete: 'off',
            form: {
              autocomplete: 'off'
            }
          }}
          autoComplete='off'
          error={state.touched && !!state.error}
          helperText={!!state.error && state.touched && state.error}
          {...textFieldProps}
        />
      }
    />
  );
};

export default memo(FormikAutocomplete);
