import React, { memo } from 'react';
import styled from '@emotion/styled';
import MaterialLink from '@mui/material/Link';
import { Link } from 'react-router-dom';

const StyledLink = styled(MaterialLink)(({theme}) => ({
  color: theme.palette.typography.primary,
  '&:visited': {
    color: theme.palette.typography.primary,
  }
}));

const RouterLink = (props) => {
  return (
    <StyledLink component={Link} {...props} />
  );
};

export default memo(RouterLink);
