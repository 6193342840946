import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import mova from 'mova';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import AdminCard from './components/AdminCard';
import AdminTable from './components/AdminTable';
import AdminTableRow from './components/AdminTableRow';
import PlusButton from '@components/PlusButton/PlusButton';
import SearchInput from '@components/SearchInput/SearchInput';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Button from '@components/Button/Button';
import Icon from '@components/Icon/Icon';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import { toastrActions } from 'state/ducks/toastr';
import useModal from '@hooks/useModal/useModal';
import AddEditAdminModal from './components/AddEditAdminModal';
import ConfirmModal from '@components/ConfirmModal/ConfirmModal';

const t = mova.ns('components.Administration');

const Administration = () => {
  const location = useLocation();
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('sm'), { noSsr: true });
  const urlParams = queryString.parse(location.search);
  const [searchActive, setSearchActive] = useState(!mobileView || !!urlParams.q);
  const [addEditModalOpen, addEditAdmin, closeAddEditModal, managerToEdit] = useModal();
  const [deleteModalOpen, onDeleteAdmin, closeDeleteModal, adminToDelete] = useModal();

  const activePlace = useSelector(placeSelectors.getActivePlace());
  const managers = useSelector(placeSelectors.getPlaceManagers());
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(placeThunks.fetchManagers({ placeId: activePlace.id }));
  }, [activePlace.id, dispatch]);

  const search = urlParams.q?.toLowerCase() || '';
  const filteredManagers = useMemo(
    () =>
      managers.filter(
        m =>
          m.user.displayedName?.toLowerCase()?.includes(search) ||
          m.user.email?.toLowerCase()?.includes(search) ||
          m.user.phone?.toLowerCase()?.includes(search),
      ),
    [managers, search],
  );

  const onEditManager = useCallback(manager => addEditAdmin(manager), [addEditAdmin]);

  const confirmDeleteAdmin = useCallback(async () => {
    await dispatch(placeThunks.deleteManager({ placeId: activePlace.id, userId: adminToDelete }));
    await dispatch(toastrActions.showToastr({ message: t('messages.deleted') }));
    closeDeleteModal();
  }, [activePlace, dispatch, closeDeleteModal, adminToDelete]);

  const onAddAdmin = useCallback(() => {
    addEditAdmin(null);
  }, [addEditAdmin]);

  const adminCards = filteredManagers.map(c => (
    <AdminCard admin={c} key={`${c.userId}${c.user.displayedName}`} onEditManager={onEditManager} />
  ));

  const adminTableRows = filteredManagers.map(c => (
    <AdminTableRow
      onEditManager={onEditManager}
      onDeleteManager={onDeleteAdmin}
      admin={c}
      key={`${c.userId}${c.user.displayedName}`}
    />
  ));

  return (
    <Box>
      <Box mb={4} display='flex' justifyContent='space-between'>
        <SearchInput
          small={mobileView && !searchActive}
          onActivate={() => setSearchActive(true)}
          onDeactivate={() => setSearchActive(false)}
          fullWidth={mobileView && searchActive}
        />
        {!mobileView && (
          <Button onClick={onAddAdmin} startIcon={<Icon type='plus' color='white' />}>
            {t('addAdmin')}
          </Button>
        )}
      </Box>
      <Box>
        <Typography variant='h4' mb={2}>
          {t('title')} ({managers.length})
        </Typography>
        {mobileView && adminCards}
        {!mobileView && managers.length > 0 && <AdminTable>{adminTableRows}</AdminTable>}
        {mobileView && (
          <Box mt={4} display='flex' justifyContent='center'>
            <PlusButton onClick={addEditAdmin} />
          </Box>
        )}
      </Box>
      {addEditModalOpen && (
        <AddEditAdminModal
          editManager={managerToEdit}
          managers={managers}
          placeId={activePlace.id}
          onClose={closeAddEditModal}
        />
      )}
      <ConfirmModal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
        confirm={confirmDeleteAdmin}
        title={t('deletingAdmin')}
        content={<Typography>{t('confirmDeleteAdmin')}</Typography>}
      />
    </Box>
  );
};

export default memo(Administration);
