import { v1 } from '../utils/apiClient';
import localStorage from '../utils/localStorage';
import mova from 'mova';

const t = mova.ns('errors');

const apiService = {
  get: (path, headers) => {
    return makeRequest(path, 'GET', null, headers);
  },
  post: (path, body, headers) => {
    return makeRequest(path, 'POST', body, headers);
  },
  put: (path, body, headers) => {
    return makeRequest(path, 'PUT', body, headers);
  },
  delete: (path, headers) => {
    return makeRequest(path, 'DELETE', null, headers);
  },
};

export default apiService;

const defaultHeaders = {
  'Cache-Control': 'no-cache, no-store, must-revalidate, proxy-revalidate',
  Pragma: 'no-cache',
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'x-admin': 'true',
};

const makeRequest = (path, method = 'GET', body, headers = {}) => {
  if (!path) {
    throw new Error('Path not specified');
  }

  const savedToken = localStorage.get(localStorage.Keys.TOKEN);
  if (savedToken) {
    headers.Authorization = `Bearer ${savedToken}`;
  }

  return new Promise((resolve, reject) => {
    v1({
      url: path,
      method: method.toLowerCase(),
      data: body,
      headers: { ...defaultHeaders, ...headers },
    }).then(
      res => resolve(_getData(res)),
      err => reject(getMessageFromError(err)),
    );
  });
};

const _getData = response => {
  const data = response.data || {};
  return data && data.result !== undefined ? data.result : data;
};

function getMessageFromError(error) {
  const errors = error?.response?.data?.errors || error?.response?.data?.error;
  if (!errors) {
    return 'There are some problems with API server...';
  }
  if (Array.isArray(errors)) {
    return errors.reduce((accum, err) => {
      accum[err.field] = t ? t(err.message) || err.message : err.message; // eslint-disable-line no-param-reassign
      return accum;
    }, {});
  }
  try {
    return t ? t(error.response.data.error) || error.response.data.error : error.response.data.error;
  } catch (e) {
    return t ? t(error.message) || error.message : error.message;
  }
}
