import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Loader = ({ fullScreen }) => {
  return (
    <div className={`egret-loader ${fullScreen ? 'egret-loader--page' : ''}`}>
      <img
        src='/assets/images/logo-circle.svg'
        alt=''
      />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

Loader.propTypes = {
  fullScreen: PropTypes.bool
};

Loader.defaultProps = {
  fullScreen: false
};

export default memo(Loader);
