import React, { memo } from 'react';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Card from '@components/Card/Card';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Icon from '@components/Icon/Icon';
import mova from 'mova';
import Button from '@components/Button/Button';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from '@form/FormikTextInput';
import { billingSelectors, billingThunks } from '@state/ducks/billing';
import { placeSelectors } from '@state/ducks/place';
import Loader from '@components/Loader/Loader';

const t = mova.ns('components.Payment');

const schema = Yup.object().shape({
  amount: Yup.number(() => t('errors.number')).min(1, () => t('errors.minNumber', { min: 1 })),
});

const NewPayment = () => {
  const dispatch = useDispatch();

  const activePlace = useSelector(placeSelectors.getActivePlace());
  const newBill = useSelector(billingSelectors.getNewBill());
  const newBillLoading = useSelector(billingSelectors.getNewBillLoading());

  const theme = useTheme();
  const tariffPerMonth = 900;

  const formikContext = useFormik({
    initialValues: {
      amount: 0,
    },
    validationSchema: schema,
    onSubmit: async ({ amount }) => {
      dispatch(
        billingThunks.addBilling({
          placeId: activePlace.id,
          amount,
        }),
      );
    },
  });

  const setAmount = months => {
    formikContext.setFieldValue('amount', months * tariffPerMonth);
  };

  return (
    <>
      <Box mb={2} display='flex' alignItems='center'>
        <IconButton component={Link} to='/payment' sx={{ textDecoration: 'none' }}>
          <Icon type='arrow' revert color={theme.palette.primary.main} />
        </IconButton>
        <Typography variant='h4' sx={{ ml: 1 }}>
          {t('makingPayment')}
        </Typography>
      </Box>
      <Card>
        <Typography variant='h4' sx={{ mb: 2 }}>
          {t('selectAmount')}
        </Typography>
        <FormikProvider value={formikContext}>
          <Form>
            <FormikTextInput type='number' sx={{ mt: 1 }} name='amount' inputProps={{ step: 0.01 }} />
            <Box my={2} display='flex' alignItems='center' flexWrap='wrap' gap={2}>
              <Typography>{t('orSelect')}:</Typography>
              <Chip label={t('months.1')} variant='outlined' onClick={() => setAmount(1)} />
              <Chip label={t('months.2')} variant='outlined' onClick={() => setAmount(2)} />
              <Chip label={t('months.3')} variant='outlined' onClick={() => setAmount(3)} />
              <Chip label={t('months.6')} variant='outlined' onClick={() => setAmount(6)} />
              <Chip label={t('months.9')} variant='outlined' onClick={() => setAmount(9)} />
              <Chip label={t('months.12')} variant='outlined' onClick={() => setAmount(12)} />
            </Box>
            <Box>
              {!newBillLoading && newBill && formikContext.values.amount === newBill.amount ? (
                <Button
                  onClick={() => {
                    window.open(newBill.data.pageUrl, '_blank', 'noopener,noreferrer');
                  }}
                >
                  {t('pay')}
                </Button>
              ) : (
                <Button type='submit'>{t('proceed')}</Button>
              )}
            </Box>
          </Form>
        </FormikProvider>
      </Card>
      {newBillLoading && <Loader />}
    </>
  );
};

export default memo(NewPayment);
