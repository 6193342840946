import localStorage from 'utils/localStorage';

const getPlace = () => state => state.place.details.data;
const getPlaceLoading = () => state => state.place.details.isLoading;
const getPlaceError = () => state => state.place.details.error;

const getPlaces = () => state => state.place.list.data;
const getPlacesLoading = () => state => state.place.list.isLoading;
const getPlacesError = () => state => state.place.list.error;

const getAllPlaces = () => state => state.place.fullList.data.rows;
const getAllPlacesCount = () => state => state.place.fullList.data.count;
const getAllPlacesPage = () => state => state.place.fullList.data.page;
const getAllPlacesLoading = () => state => state.place.fullList.isLoading;
const getallPlacesError = () => state => state.place.fullList.error;

const getActivePlaceStorage = () => localStorage.get(localStorage.Keys.ACTIVE_PLACE);
const getActivePlace = () => state => state.place.activePlace;

const getPlaceContacts = () => state => state.place.contacts.data;
const getPlaceOpenHours = () => state => state.place.openHours.data;
const getPlacePhotos = () => state => state.place.photos.data;
const isPlaceSettingsLoading = () => state =>
  state.place.photos.isLoading || state.place.openHours.isLoading || state.place.contacts.isLoading;

const getPlaceManagers = () => state => state.place.managers.data;

const getPlaceStats = () => state => state.place.placeStats.data;
const getPlaceStatsLoading = () => state => state.place.placeStats.isLoading;

const getPlaceClient = () => state => state.place.client.data;
const getPlaceClientLoading = () => state => state.place.client.isLoading;

const getPlaceStatsVisits = () => state => state.place.statsVisits.data;
const getPlaceStatsVisitsLoading = () => state => state.place.statsVisits.isLoading;

const getPlaceReviews = type => state => state.place.reviews.data[type].rows;
const getPlaceReviewsCount = type => state => state.place.reviews.data[type].count;
const getPlaceReviewsPage = type => state => state.place.reviews.data[type].page;
const getPlaceReviewsLoading = () => state => state.place.reviews.isLoading;
const getPlaceReviewStats = () => state => state.place.reviewStats.data;
const getPlaceReviewGoogleStats = () => state => state.place.reviewGoogleStats.data;
const getPlaceReviewGoogleStatsLoading = () => state => state.place.reviewGoogleStats.isLoading;

const getPlaceDonations = () => state => state.place.donations.data;

const getClientReservationHistory = () => state => state.place.clientHistory.data.rows;
const getClientReservationHistoryCount = () => state => state.place.clientHistory.data.count;

const getCustomTags = () => state => state.place.customTags.data || {};

const getDeposits = () => state => state.place.deposits.data;

const getCertificates = () => state => state.place.certificates.data;

const getPaidServices = () => state => state.place.paidServices.data;

export default {
  getPlace,
  getPlaceLoading,
  getPlaceError,
  getPlaces,
  getPlacesLoading,
  getPlacesError,

  getAllPlaces,
  getAllPlacesCount,
  getAllPlacesPage,
  getAllPlacesLoading,
  getallPlacesError,

  getActivePlaceStorage,
  getActivePlace,

  getPlaceContacts,
  getPlaceOpenHours,
  getPlacePhotos,

  getPlaceManagers,
  isPlaceSettingsLoading,

  getPlaceStats,
  getPlaceStatsLoading,

  getPlaceClient,
  getPlaceClientLoading,

  getPlaceReviews,
  getPlaceReviewsCount,
  getPlaceReviewsPage,
  getPlaceReviewsLoading,
  getPlaceReviewStats,
  getPlaceReviewGoogleStats,
  getPlaceReviewGoogleStatsLoading,

  getPlaceStatsVisits,
  getPlaceStatsVisitsLoading,

  getPlaceDonations,

  getClientReservationHistory,
  getClientReservationHistoryCount,

  getCustomTags,

  getDeposits,
  getPaidServices,
  getCertificates,
};
