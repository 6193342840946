const getChannels = () => state => state.channel.list.data;
const getChannelsLoading = () => state => state.channel.list.isLoading;
const getChannelsError = () => state => state.channel.list.error;

const getChannel = (id) => state => state.channel.list.data.find(c => c.id === +id);

const getChannelStats = () => state => state.channel.channelStats.data;
const getChannelStatsLoading = () => state => state.channel.channelStats.isLoading;

export default {
  getChannels,
  getChannelsLoading,
  getChannelsError,

  getChannel,

  getChannelStats,
  getChannelStatsLoading
};
