import React from 'react';
import theme from '../../theme';

export default function grid({ color = theme.palette.common.white }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M19 19H13V13H19V19ZM11 19H5V13H11V19ZM19 11H13V5H19V11ZM11 11H5V5H11V11Z' fill={color} />
    </svg>
  );
}
