import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { billingSelectors, billingThunks } from "state/ducks/billing";
import { placeSelectors } from "../../../../state/ducks/place";
import Card from "../../../components/Card/Card";
import Typography from "@mui/material/Typography";
import { Table, TableBody } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "@emotion/styled";
import TableCell from "@mui/material/TableCell";
import mova from 'mova';

const t = mova.ns('components.PaymentStatistics');

const StyledTableCell = styled(TableCell)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

const PaymentStatisticsDeposits = () => {
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const placeDeposits = useSelector(billingSelectors.getPlaceDeposits());
  const { from, to } = queryString.parse(location.search);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activePlace.id && from && to) {
      dispatch(billingThunks.fetchPlaceDeposits({ from, to, placeId: activePlace.id }));
    }
  }, [activePlace.id, from, to, dispatch]);

  return Object.entries(placeDeposits).map(([day, bills]) => {
    let total = 0;
    return (
      <Card sx={{ p: 2 }} key={day}>
        <Typography sx={{ mb: 2 }} variant='h3'>{day}</Typography>
        <Table sx={{ mt: 2 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('deposits.name')}</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('deposits.phone')}</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('deposits.hall')}</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('deposits.spot')}</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('deposits.billId')}</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('deposits.amount')}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bills.map(({ id, reservation, amount }) => {
              total = total + amount;
              return (
                <TableRow key={id}>
                  <StyledTableCell>{reservation.user?.displayedName}</StyledTableCell>
                  <StyledTableCell>{reservation.user?.phone}</StyledTableCell>
                  <StyledTableCell>{reservation.hall?.name}</StyledTableCell>
                  <StyledTableCell>{reservation.spots.map(s => s.label).join(', ')}</StyledTableCell>
                  <StyledTableCell>{id}</StyledTableCell>
                  <StyledTableCell>{amount} {t('uah')}</StyledTableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <StyledTableCell colSpan={5} sx={{ fontWeight: 'bold' }}>{t('deposits.total')}</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }}>{total} {t('uah')}</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    )
  });
};

export default memo(PaymentStatisticsDeposits);
