import React from 'react';
import * as Yup from 'yup';
import mova from 'mova';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Card from '@components/Card/Card';
import Button from '@components/Button/Button';
import Icon from '@components/Icon/Icon';
import FormikTextInput from '@form/FormikTextInput';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import { toastrActions } from 'state/ducks/toastr';

const t = mova.ns('components.Integrations');
const tSettings = mova.ns('components.Settings');

const portmoneSchema = Yup.object().shape({
  portmonePayeeId: Yup.string(),
});

const PortmoneIntegrations = () => {
  const dispatch = useDispatch();

  const activePlace = useSelector(placeSelectors.getActivePlace());
  const connected = activePlace.connections?.portmonePayeeId;
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const connectPortmone = async data => {
    const result = await dispatch(
      placeThunks.updatePlaceConnections({
        id: activePlace.id,
        connections: {
          portmonePayeeId: data.portmonePayeeId,
        },
      }),
    );
    if (result?.payload?.connections) {
      await dispatch(toastrActions.showToastr({ message: tSettings('messages.saved') }));
    }
  };

  return (
    <Card sx={{ mt: 4 }}>
      <Formik
        initialValues={{
          portmonePayeeId: activePlace.connections?.portmonePayeeId || '',
        }}
        onSubmit={connectPortmone}
        validationSchema={portmoneSchema}
      >
        <Form>
          <Typography variant='h3' sx={{ mb: 2 }}>
            {t('portmoneService')}
          </Typography>
          <Box
            display='flex'
            alignItems={mobileView ? 'flex-start' : 'center'}
            justifyContent='space-between'
            flexDirection={mobileView ? 'column' : 'row'}
          >
            <Typography sx={{ flexGrow: 1, mr: 2, flexShrink: 0, mb: mobileView ? 1 : 0 }} noWrap>
              {t('portmoneKey')}
            </Typography>
            <FormikTextInput sx={{ flexGrow: 3 }} name='portmonePayeeId' fullWidth placeholder={t('portmoneKey')} />
          </Box>
          {connected && (
            <Box display='flex' alignItems='center' mt={1}>
              <Icon type='done' sx={{ mr: 1 }} />
              <Typography variant='h4' color='success.main'>
                {t('connected')}
              </Typography>
            </Box>
          )}
          <Box display='flex' justifyContent='center' mt={2} gap={2}>
            <Button type='submit'>{t('connect')}</Button>
          </Box>
        </Form>
      </Formik>
    </Card>
  );
};

export default PortmoneIntegrations;
