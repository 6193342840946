import React, { memo } from 'react';
import { default as SlickSlider } from 'react-slick';
import classes from './Slider.module.scss';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Slider = ({ children, margins, ...rest }) => {
  const settings = {
    dots: false,
    infinite: false,
    variableWidth: true,
    swipeToSlide: true,
  };

  return (
    <SlickSlider {...settings} {...rest} className={clsx(classes.slider, margins && classes.margins)}>
      {children}
    </SlickSlider>
  );
};

Slider.propTypes = {
  margins: PropTypes.bool // adds 16px margins between slider items
};

export default memo(Slider);
