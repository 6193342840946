import React from 'react';

export default function ru({ size = 24 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 9.375H24V14.625H0V9.375Z'
        fill='#1B75BB'
      />
      <path
        d='M24 3.75H3.75C1.26487 3.75 0 3.75 0 3.75V9.375H24V7.875C24 5.59688 24 3.75 24 3.75Z'
        fill='#E6E7E8'
      />
      <path
        d='M0 16.125C0 18.4031 0 20.25 0 20.25H20.25C22.7351 20.25 24 20.25 24 20.25V14.625H0V16.125Z'
        fill='#EC1C24'
      />
    </svg>
  );
}
