import React from 'react';
import theme from '../../theme';

export default function chevronlLeft({ color = theme.palette.typography.primary }) {
  return (
    <svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.0992 10.2759L1.72858 5.99445L6.0992 1.71302'
        stroke={color}
        strokeWidth='1.665'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
