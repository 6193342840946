import React, { memo, useCallback } from 'react';
import Logo from '../Logo/Logo';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Icon from '../Icon/Icon';
import SidebarMenu from './components/SidebarMenu';
import LanguageSelector from './components/LanguageSelector';
import Box from '@mui/material/Box';
import Select from '@form/Select';
import { useDispatch, useSelector } from 'react-redux';
import { placeActions, placeSelectors } from 'state/ducks/place';
import { Link, useLocation } from 'react-router-dom';
import jwtService from 'services/jwtService';
import { userSelectors } from '@state/ducks/user';
import Div100vh from 'react-div-100vh';
import Loader from '../Loader/Loader';

const SidebarRoot = styled(Div100vh)(({ theme }) => ({
  width: theme.spacing(30),
  flexGrow: 0,
  flexShrink: 0,
}));

const SidebarFixed = styled(Div100vh)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: theme.spacing(30),
  padding: theme.spacing(8, 2, 0),
  textAlign: 'center',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  overflowY: 'auto',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(12),
  margin: theme.spacing(3, 'auto'),
}));

const StyledIcon = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(0, 1),
}));

const Sidebar = ({ close }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const places = useSelector(placeSelectors.getPlaces());
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const location = useLocation();
  const role = useSelector(userSelectors.getUserRole());

  const handleChangeActivePlace = useCallback(
    value => {
      dispatch(placeActions.setActivePlace(places.find(p => p.id === value)));
    },
    [places, dispatch],
  );

  const showLogo = useMediaQuery(theme => theme.breakpoints.up('md'), { noSsr: true });

  const logout = () => {
    jwtService.logout();
  };

  if (!activePlace) {
    return <Loader />
  }

  return (
    <SidebarRoot>
      <SidebarFixed>
        {showLogo && <Logo />}
        <StyledAvatar src={activePlace?.avatarUrl || ''} />
        <Select
          type='red'
          onChange={handleChangeActivePlace}
          value={activePlace.id}
          items={places.map(place => ({ label: place.name, value: place.id }))}
        />
        <Box my={2}>
          {['owner', 'super'].includes(role) && (
            <Link to='/settings?path=description'>
              <StyledIcon>
                <Icon
                  type='cogwheel'
                  color={location.pathname === '/settings' ? theme.palette.common.white : theme.palette.typography.blurred}
                />
              </StyledIcon>
            </Link>
          )}
          <Link to='/profile'>
            <StyledIcon>
              <Icon
                type='user'
                color={location.pathname === '/profile' ? theme.palette.common.white : theme.palette.typography.blurred}
              />
            </StyledIcon>
          </Link>
          <StyledIcon onClick={logout}>
            <Icon type='logout' color={theme.palette.typography.blurred} />
          </StyledIcon>
        </Box>
        <SidebarMenu close={close} />
        <LanguageSelector />
      </SidebarFixed>
    </SidebarRoot>
  );
};

export default memo(Sidebar);
