import React from 'react';
import theme from '../../theme';

export default function chevronDown({ color = theme.palette.typography.primary }) {
  return (
    <svg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.3799 1.90758L6.2533 7.14097L1.12671 1.90758'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
