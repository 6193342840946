import React, { memo } from 'react';
import Icon from '@components/Icon/Icon';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';

const StyledIconWrapper = styled(Box)(({ top, left, right, bottom }) => ({
  position: 'absolute',
  top,
  left,
  right,
  bottom,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}));

const StyledIcon = styled(Icon)(({ rotate }) => ({
  transform: rotate ? `rotate(${rotate})` : 'none',
}));

const Couch = ({ rotate, iconProps, ...props }) => {
  return (
    <StyledIconWrapper {...props}>
      <StyledIcon type='couch' rotate={rotate} {...iconProps} />
    </StyledIconWrapper>
  );
};

export default memo(Couch);
