import React from 'react';
import theme from '../../theme';

export default function heart({ color = theme.palette.common.white }) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.768 2C9.91467 2 9.104 2.34133 8.50667 2.93867L7.99467 3.45067L7.52533 2.98133C6.288 1.70133 4.24 1.70133 3.00267 2.93867L2.96 2.98133C1.68 4.26133 1.68 6.30933 2.96 7.58933L7.99467 12.6667L13.0293 7.58933C14.3093 6.30933 14.3093 4.26133 13.0293 2.98133C12.432 2.34133 11.6213 2 10.768 2Z'
        fill={color}
      />
    </svg>
  );
}
