import React from 'react';
import styled from '@emotion/styled';

const StyledLayout = styled('div')(({ theme }) => ({
  maxWidth: theme.spacing(65),
  margin: '0 auto',
  padding: theme.spacing(2),
}));

const AuthLayout = ({ children }) => {
  return (
    <StyledLayout>
      {children}
    </StyledLayout>
  );
};

export default AuthLayout;
