import React from 'react';
import theme from '../../theme';

export default function check({ color = theme.palette.primary.main, size = 24 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.55012 17.5754C9.41679 17.5754 9.29179 17.5544 9.17512 17.5124C9.05846 17.4711 8.95012 17.4004 8.85012 17.3004L4.55012 13.0004C4.36679 12.8171 4.27912 12.5794 4.28712 12.2874C4.29579 11.9961 4.39179 11.7587 4.57512 11.5754C4.75846 11.3921 4.99179 11.3004 5.27512 11.3004C5.55846 11.3004 5.79179 11.3921 5.97512 11.5754L9.55012 15.1504L18.0251 6.67539C18.2085 6.49206 18.4461 6.40039 18.7381 6.40039C19.0295 6.40039 19.2668 6.49206 19.4501 6.67539C19.6335 6.85872 19.7251 7.09606 19.7251 7.38739C19.7251 7.67939 19.6335 7.91706 19.4501 8.10039L10.2501 17.3004C10.1501 17.4004 10.0418 17.4711 9.92512 17.5124C9.80846 17.5544 9.68346 17.5754 9.55012 17.5754V17.5754Z'
        fill={color}
      />
    </svg>
  );
}
