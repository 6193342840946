import { createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
import api from 'services/apiService';

const fetchClients = createAsyncThunk('client/fetchClients', async ({ placeId, ...query }) => {
  return api.get(`/places/${placeId}/clients?${queryString.stringify(query)}`);
});

const fetchUsers = createAsyncThunk('client/fetchUsers', async data => {
  return api.get(`/search/users?${queryString.stringify(data)}`);
});

const addClient = createAsyncThunk('client/addClient', async data => {
  return api.put(`/users`, data);
});
const updateClient = createAsyncThunk('client/updateClient', async ({ id, ...data }) => {
  return api.put(`/users/${id}`, data);
});

const updateClientNote = createAsyncThunk('client/updateClientNote', async data => {
  return api.put('/users/note/update', data);
});

const fetchTopVisiors = createAsyncThunk('client/fetchTopVisiors', async ({ placeId, ...query }) => {
  return api.get(`/places/${placeId}/clients?${queryString.stringify(query)}`);
});
const fetchTopUserBills = createAsyncThunk('client/fetchTopUserBills', async ({ placeId, ...query }) => {
  return api.get(`/places/${placeId}/clients?${queryString.stringify(query)}`);
});

export default {
  fetchClients,
  addClient,
  updateClient,
  updateClientNote,

  fetchUsers,

  fetchTopVisiors,
  fetchTopUserBills,
};
