import React, { memo } from 'react';
import Datepicker from '@components/Datepicker/Datepicker';
import { useField } from 'formik';
import mova from 'mova';

const t = mova.ns('types.place');

const FormikTimepicker = ({ name, fullWidth, handleChange, datepickerProps, ...rest }) => {
  const [field, , { setValue }] = useField(name);

  const onChange = (val) => {
    setValue(val);
    handleChange && handleChange(val);
  };

  const defaultProps = {
    ...field,
    selected: field.value,
    onChange,
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 15,
    timeCaption: t('time'),
    dateFormat: 'HH:mm',
    fullWidth
  };

  return <Datepicker {...rest} datepickerProps={{ ...defaultProps, ...datepickerProps }} icon='clock' />;
};

export default memo(FormikTimepicker);
