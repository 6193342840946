import React, { memo, useMemo } from 'react';
import Chair from './Chair';
import Couch from './Couch';

const oppositeDirections = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
};

const sideRotate = {
  top: '0deg',
  bottom: '180deg',
  left: '270deg',
  right: '90deg',
};

const alternateProp = {
  top: 'left',
  bottom: 'left',
  left: 'top',
  right: 'top',
};

const RectangleTableChairs = ({ table }) => {
  const tableLength = table.variables?.length || 2;
  const tableWidth = table.variables?.width || 2;
  const maxChairs = (tableLength * 2 + tableWidth * 2 - 2) * 2;

  let positions = useMemo(() => {
    const w = tableLength * 2;
    const h = tableWidth * 2;
    const n = table.seats;

    const positions = {
      top: {
        number: 0,
      },
      left: {
        number: 0,
      },
      right: {
        number: 0,
      },
      bottom: {
        number: 0,
      },
    };
    let previousPosition;
    const biggerSide = w >= h ? 'top' : 'left';
    const lesserSide = w < h ? 'top' : 'left';

    if (w >= h) {
      positions.top.number++;
      previousPosition = 'top';
    } else {
      positions.left.number++;
      previousPosition = 'left';
    }

    const conditionArray = [maxChairs, ...[...Array(maxChairs).keys()].slice(1)];
    for (let i = 1; i < conditionArray[n % maxChairs]; i++) {
      if (i % 2 > 0) {
        positions[oppositeDirections[previousPosition]].number++;
      } else {
        if (w >= h ? (w - 1 - positions.top.number) / (h - 1 - positions.left.number) >= 2 : (h - 1 - positions.left.number) / (w - 1 - positions.top.number) >= 2) {
          positions[biggerSide].number++;
          previousPosition = biggerSide;
        } else {
          positions[lesserSide].number++;
          previousPosition = lesserSide;
        }
      }
    }

    if (n > maxChairs) {
      const multiplier = Math.floor(n / maxChairs);
      positions.top.number += (w - 1) * multiplier;
      positions.bottom.number += (w - 1) * multiplier;
      positions.left.number += (h - 1) * multiplier;
      positions.right.number += (h - 1) * multiplier;
    }

    return positions;
  }, [table, tableLength, tableWidth, maxChairs]);

  let chairs = [];
  const smallChairs = table.seats > maxChairs;

  if (table.variables?.manualChairs) {
    positions = {
      top: {
        number: table.variables?.chairsTop || 0,
        couch: table.variables?.chairsTopCouch || false,
      },
      right: {
        number: table.variables?.chairsRight || 0,
        couch: table.variables?.chairsRightCouch || false,
      },
      bottom: {
        number: table.variables?.chairsBottom || 0,
        couch: table.variables?.chairsBottomCouch || false,
      },
      left: {
        number: table.variables?.chairsLeft || 0,
        couch: table.variables?.chairsLeftCouch || false,
      },
    };
  }

  Object.entries(positions).forEach(position => {
    if (position[1].couch) {
      const props = {
        top: position[0] === 'bottom' ? undefined : position[0] === 'top' ? -16 : `calc(50% - 6px)`,
        bottom: position[0] === 'bottom' ? -16 : undefined,
        left: position[0] === 'left' ? -10 - tableWidth * 16 : ['top', 'bottom'].includes(position[0]) ? 0 : undefined,
        right: position[0] === 'right' ? -10 - tableWidth * 16 : undefined,
        rotate: sideRotate[position[0]],
        iconProps: {
          width: ['top', 'bottom'].includes(position[0]) ? tableLength * 32 : tableWidth * 32,
          height: 11.5,
        }
      };
      chairs.push(<Couch key={`${table.id}-chairs-${chairs.length + 1}`} {...props} table={table} />);
    } else {
      for (let i = 1; i <= position[1].number; i++) {
        const props = {
          [position[0]]: smallChairs ? -12 : -16,
          [alternateProp[position[0]]]: `calc(${100 / (position[1].number + 1) * i}% - ${smallChairs ? 5 : 7}px)`,
          rotate: sideRotate[position[0]],
          small: smallChairs,
        };
        chairs.push(<Chair key={`${table.id}-chairs-${chairs.length + 1}`} {...props} />);
      }
    }
  });
  return chairs;
};

export default memo(RectangleTableChairs);
