import React, { useState } from 'react';
import mova from 'mova';
import { Box, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import HallsArea from '@components/Halls/HallsArea';
import { useDispatch, useSelector } from 'react-redux';
import { hallSelectors } from 'state/ducks/hall';
import { placeSelectors } from 'state/ducks/place';
import { reservationActions, reservationThunks } from 'state/ducks/reservation';
import HallsSelect from '@components/Halls/HallsSelect';
import useModal from '@utils/hooks/useModal/useModal';
import Button from '@components/Button/Button';
import Icon from '@components/Icon/Icon';
import AssignReservationModal from './components/AssignReservationModal';
import Datepicker from '../../components/Datepicker/Datepicker';
import SearchInput from '../../components/SearchInput/SearchInput';
import IconButton from "@mui/material/IconButton";
import localStorage from 'utils/localStorage';
import ButtonGroup from "@mui/material/ButtonGroup";
import moment from "moment";
import { nearest15Minutes } from "utils/date";
import HallReservations from "../reservations/HallReservations";

const t = mova.ns('components.Halls');

const Halls = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('md'), { noSsr: true });
  const [date, setDate] = useState(new Date());
  const [showPast, setShowPast] = useState(false);
  const [assignModalOpen, assignReservation, closeAssignModal, assignData] = useModal(false);
  const [reservationsHidden, setReservationsHidden] = useState(localStorage.get(localStorage.Keys.RESERVATIONS_HIDDEN));
  const dispatch = useDispatch();
  const activeHall = useSelector(hallSelectors.getActiveHall());
  const activePlace = useSelector(placeSelectors.getActivePlace());

  const datepickerProps = {
    onChange: setDate,
    selected: date,
  };

  const addReservation = () => dispatch(reservationActions.openReservationDrawer({ date: date.getTime() }));

  const confirmAssignReservation = () => {
    const { reservation, table } = assignData;
    closeAssignModal();

    const body = {
      ...reservation,
      hallId: table.hallId,
      spots: [table],
      statusFrom: reservation.status,
      statusTo: ['WAITLIST', 'INPROGRESS'].includes(reservation.status) ? 'INPROGRESS' : 'ACCEPTED',
    };

    if (reservation.status === 'WAITLIST') {
      body.from = moment().toDate();
      body.to = activePlace.reservationAllDay
        ? moment()
          .set('hour', activePlace.openHours[moment().isoWeekday() - 1].closedH)
          .set('minute', activePlace.openHours[moment().isoWeekday() - 1].closedM)
          .toDate()
        : nearest15Minutes(moment())
          .add(activePlace?.reservationDuration || 120, 'minutes')
          .toDate();
    }

    dispatch(
      reservationThunks.updateReservation(body),
    );
  };

  const addTable = () => {
    const { reservation, table } = assignData;
    closeAssignModal();

    dispatch(
      reservationThunks.updateReservation({
        ...reservation,
        hallId: table.hallId,
        spots: [...reservation.spots, table],
        statusFrom: reservation.status,
        statusTo: 'ACCEPTED',
      }),
    );
  };

  const toggleHideReservations = () => {
    localStorage.set(localStorage.Keys.RESERVATIONS_HIDDEN, !reservationsHidden)
    setReservationsHidden(!reservationsHidden);
  }

  const addDays = (days) => setDate(moment(date).add(days, 'days').toDate());

  return (
    <Box>
      <Box mb={2}>
        <Typography variant='h4'>{t('title')}</Typography>
      </Box>
      <Box display='flex' flexDirection={mobileView ? 'column' : 'row'} gap={mobileView ? 0 : 4}>
        <Box flexGrow={1} order={mobileView ? 2 : 1} minWidth={0}>
          <Box mb={2} display='flex' justifyContent='space-between' flexWrap='wrap' gap={2}>
            <Box display='flex' gap={2} flexWrap='wrap'>
              <HallsSelect type='white' />
              <ButtonGroup>
                <Button variant='outlined' color='primary' sx={{ backgroundColor: 'white' }} onClick={() => addDays(-1)}>
                  <Icon type='chevronLeft' />
                </Button>
                <Datepicker datepickerProps={datepickerProps} showToday inputProps={{ style: { borderRadius: 'unset' } }} />
                <Button variant='outlined' color='primary' sx={{ backgroundColor: 'white' }} onClick={() => addDays(1)}>
                  <Icon type='chevronRight' />
                </Button>
              </ButtonGroup>
              <SearchInput />
            </Box>
            <Box display='flex' alignItems='center' gap={1}>
              <Button onClick={addReservation} startIcon={<Icon type='plus' />}>
                {t('createReservation')}
              </Button>
              <Tooltip title={t('hideReservations')} placement='top'>
                <IconButton onClick={toggleHideReservations}>
                  <Icon type={reservationsHidden ? 'eye' : 'eyeOff'} color='black' />
                </IconButton>
              </Tooltip>
              {activePlace.billTypeExtra?.includes('highload') && (
                <Tooltip title={t('showPast')} placement='top'>
                  <IconButton onClick={() => setShowPast(!showPast)}>
                    <Icon type='history' color={showPast ? theme.palette.secondary.main : theme.palette.primary.main} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
          <Box>
            {activeHall &&
              <HallsArea hall={activeHall} assignReservation={assignReservation} date={date} showPast={showPast} />
            }
          </Box>
        </Box>
        <HallReservations
          flexBasis={mobileView ? '100%' : 320}
          flexGrow={0}
          flexShrink={0}
          minWidth={0}
          order={mobileView ? 1 : 2}
          mb={2}
          draggable
          horizontal={mobileView}
          date={date}
          hidden={reservationsHidden}
          showPast={showPast}
          showFilters
        />
      </Box>
      <AssignReservationModal
        open={assignModalOpen}
        onClose={closeAssignModal}
        confirmAssign={confirmAssignReservation}
        addTable={addTable}
        assignData={assignData}
        title={t('assigningReservation')}
        content={
          <Typography>
            {assignData?.reservation?.status === 'ACCEPTED' ? t('reassignText') : t('assignText')}
          </Typography>
        }
      />
    </Box>
  );
};

export default Halls;
