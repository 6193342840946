import React, { memo, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import mova from 'mova';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import Loader from '@components/Loader/Loader';
import Card from '@components/Card/Card';
import currency from '@filters/currency';
import IconButton from '@mui/material/IconButton';
import Icon from '@components/Icon/Icon';
import date from '@filters/date';
import { placeSelectors } from '@state/ducks/place';
import { billingSelectors, billingThunks } from '@state/ducks/billing';

const t = mova.ns('components.Payment');

const InvoiceDetails = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const activePlace = useSelector(placeSelectors.getActivePlace());
  const bill = useSelector(billingSelectors.getBilling());
  const billLoading = useSelector(billingSelectors.getBillingLoading());
  const { invoiceId } = useParams();

  useEffect(() => {
    dispatch(billingThunks.fetchBilling({ placeId: activePlace.id, id: invoiceId }));
  }, [activePlace.id, invoiceId, dispatch]);

  if (!bill && billLoading) {
    return <Loader />;
  }

  return (
    <>
      <Box mb={2} display='flex' alignItems='center'>
        <IconButton component={Link} to='/payment' sx={{ textDecoration: 'none' }}>
          <Icon type='arrow' revert color={theme.palette.primary.main} />
        </IconButton>
        <Typography variant='h4' sx={{ ml: 1 }}>
          {t('invoiceDetails')}
        </Typography>
      </Box>
      <Card>
        <Box mb={1}>
          <Typography component='span'>{t('invoiceBillType')}:</Typography>&nbsp;
          <Typography component='span' variant='h3'>
            {activePlace.billType}
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography component='span'>{t('billingPeriod')}:</Typography>&nbsp;
          <Typography component='span' variant='h3'>
            {date(moment(bill.data.period).subtract(1, 'months'))}
          </Typography>
          <Typography component='span'>&nbsp;-&nbsp;</Typography>
          <Typography component='span' variant='h3'>
            {date(bill.data.period)}
          </Typography>
        </Box>
        <Table sx={{ mt: 2 }}>
          <TableBody>
            <TableRow>
              <TableCell>{t('tariffPayment')}</TableCell>
              <TableCell>{currency(bill.data.billPrice, undefined, 2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('smsTotal')}</TableCell>
              <TableCell>{currency(bill.data.sms, undefined, 2)}</TableCell>
            </TableRow>
            {/*}<TableRow>
              <TableCell>{t('reservationsInTariff')}</TableCell>
              <TableCell>{bill.data.reservations.all}</TableCell>
            </TableRow>*/}
            <TableRow>
              <TableCell>{t('reservationsUsed')}</TableCell>
              <TableCell>{bill.data.reservations.all}</TableCell>
            </TableRow>
            {/*<TableRow>
              <TableCell>{t('reservationsOverTariff')}</TableCell>
              <TableCell>{currency(mockInvoice.reservationsOverTariff, undefined, 2)}</TableCell>
            </TableRow>*/}
            {bill.data.adjustment && (
              <TableRow>
                <TableCell>{bill.data.comment}</TableCell>
                <TableCell>{currency(bill.data.adjustment, undefined, 2)}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>{t('commission')}</TableCell>
              <TableCell>1.3%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('total')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{currency(bill.amount, undefined, 2)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    </>
  );
};

export default memo(InvoiceDetails);
