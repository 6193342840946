import * as reducers from './ducks';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

const rootReducer = combineReducers(reducers);

export default configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
});
