import React, { memo } from 'react';
import MenuList from '@mui/material/MenuList';
import queryString from 'query-string';
import Button from '../Button/Button';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

const SwitchMenu = ({ items, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const currentPath = queryString.parse(location.search).path;

  const goToPath = (path) => {
    const urlParams = queryString.parse(location.search);
    urlParams.path = path;
    history.push(`${location.pathname}?${queryString.stringify(urlParams)}`);
  };

  const getItemColor = i => {
    if (i.active !== undefined) {
      return i.active ? 'secondary' : 'info';
    } else {
      return currentPath === i.path ? 'secondary' : 'info';
    }
  };

  const menuItems = items.map((i, index) =>
    <Box mb={2} key={index}>
      <Button
        color={getItemColor(i)}
        onClick={() => i.onClick ? i.onClick() : goToPath(i.path)}
        fullWidth
        size='large'
      >
        {i.label}
      </Button>
    </Box>
  );

  return (
    <MenuList {...rest}>
      {menuItems}
    </MenuList>
  );
};

export default memo(SwitchMenu);
