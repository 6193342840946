import React, { useState } from 'react';
import Button from '@components/Button/Button';
import * as IconTypes from '../../theme/icons';
import Modal from '@components/Modal/Modal';

const Sandbox = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <h3>Icons</h3>
      <div style={{ backgroundColor: 'lightgrey' }}>
        {Object.keys(IconTypes).map(type => <span key={type}>{type} {IconTypes[type]({})}|</span>)}
      </div>

      <h3>Buttons</h3>
      <div>
        <Button>Default</Button>
        <Button color='primary' variant='outlined'>Outlined Primary</Button>
        <Button color='secondary' variant='contained'>Secondary</Button>
        <Button color='primary' variant='contained'>Contained Primary</Button>
      </div>
      <div>
        <Button onClick={() => setModalOpen(true)}>Open Modal</Button>
        <Modal
          onClose={() => setModalOpen(false)}
          open={modalOpen}
          title='Sample modal title'
          actions={<Button onClick={() => setModalOpen(false)}>Close modal</Button>}
          maxWidth='sm'
          fullWidth
        >
          <div>This is modal content</div>
        </Modal>
      </div>
    </div>
  );
};

export default Sandbox;
