import React, { memo } from 'react';
import Avatar from '@components/Avatar/Avatar';
import IconButton from '@mui/material/IconButton';
import Icon from '@components/Icon/Icon';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import moment from 'moment';
import mova from 'mova';
import BorderCollapseTableRow from '@components/BorderCollapseTableRow/BorderCollapseTableRow';
import Link from '@mui/material/Link';

const t = mova.ns('components.Administration');

const AdminTableRow = ({ admin, onEditManager, onDeleteManager }) => {
  const { permissions, user } = admin || {};
  const { displayedName, phone, email, lastVisitedAt, avatarUrl, id } = user || {};
  return (
    <BorderCollapseTableRow>
      <td>
        <Box p={2}>
          <Avatar src={avatarUrl} size={30} />
        </Box>
      </td>
      <td>
        <Typography variant='h3'>{displayedName}</Typography>
      </td>
      <td>
        <Typography noWrap title={phone}>
          <Link color='primary.main' href={`tel:${phone}`}>{phone}</Link>
        </Typography>
      </td>
      <td>
        <Typography noWrap title={email}>
          <Link color='primary.main' href={`mailto:${email}`}>{email}</Link>
        </Typography>
      </td>
      <td>
        <Typography noWrap title={t(`fields.roles.${permissions[0]}`)}>
          {t(`fields.roles.${permissions[0]}`)}
        </Typography>
      </td>
      <td>{moment(lastVisitedAt).format('D MMM')}</td>
      <td>
        <IconButton onClick={() => onEditManager(admin)}>
          <Icon type='edit' />
        </IconButton>
        <IconButton onClick={() => onDeleteManager(id)}>
          <Icon type='remove' />
        </IconButton>
      </td>
    </BorderCollapseTableRow>
  );
};

export default memo(AdminTableRow);
