import React, { memo } from 'react';
import { useField } from 'formik';
import Typography from '@mui/material/Typography';
import { RadioGroup } from '@mui/material';

const FormikRadioGroup = ({ name, children, ...rest }) => {
  const [field, state] = useField(name);

  return (
    <>
      <RadioGroup {...field} {...rest} name={name}>
        {children}
      </RadioGroup>
      {!!state.error && state.touched && <Typography color='error' variant='caption'>{state.error}</Typography>}
    </>
  );
};

export default memo(FormikRadioGroup);
