import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@components/Modal/Modal';
import mova from 'mova';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from '@form/FormikTextInput';
import ModalActions from '@components/Modal/ModalActions';
import { toastrActions } from 'state/ducks/toastr';
import store from '@state/store';
import { PHONE_REGEX } from "utils/utils";
import FormikClientPhoneInput from "../../../form/FormikClientPhoneInput";
import { useSelector } from "react-redux";
import { placeSelectors } from "state/ducks/place";
import { certificateThunks } from "state/ducks/certificate";
import { MenuItem } from "@mui/material";
import FormikSelect from "../../../form/FormikSelect";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icon from "../../../components/Icon/Icon";
import Select from "../../../form/Select";
import { CATEGORY_CERTIFICATE, CATEGORY_SUBSCRIPTION, getCertificateIcon, getCertificateType } from "utils/certificate";

const t = mova.ns('components.Certificates');

const schema = Yup.object().shape({
  data: Yup.object().shape({
    user: Yup.object().shape({
      firstName: Yup.string().required(() => t('errors.required')),
      lastName: Yup.string(),
      phone: Yup.string().required(() => t('errors.required')).matches(PHONE_REGEX, () => t('errors.phone')),
    })
  }),
  certificateId: Yup.number().required(() => t('errors.required')).nullable(true),
});

const AddCertificateModal = ({ onClose, submitForm }) => {
  const [type, setType] = useState(CATEGORY_CERTIFICATE);
  const certificates = useSelector(placeSelectors.getCertificates())
    .filter(c => c.active)
    .filter(c => c.category === type);

  const getCertificateName = (id) => certificates.find(c => c.id === id)?.name

  return (
    <Modal
      open
      onClose={onClose}
      title={t('addNewCertificate')}
      actions={<ModalActions onClose={onClose} submitForm={submitForm} />}
    >
      <Form>
        <Box display='flex' flexDirection='column' gap={2}>
          <Select label={t('fields.category')} value={type} onChange={setType}>
            <MenuItem value={CATEGORY_CERTIFICATE}>{t('fields.categoryCertificate')}</MenuItem>
            <MenuItem value={CATEGORY_SUBSCRIPTION}>{t('fields.categorySubscription')}</MenuItem>
          </Select>
          <FormikSelect
            name='certificateId'
            placeholder={t('fields.categoryCertificate')}
            label={t('fields.categoryCertificate')}
            fullWidth
            renderValue={getCertificateName}
          >
            {certificates.map(c => (
              <MenuItem key={c.id} value={c.id} sx={{ p: 1, display: 'flex', gap: 2 }}>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  width={48}
                  height={48}
                  sx={{ borderRadius: '50%', backgroundColor: 'primary.main' }}
                >
                  <Icon type={getCertificateIcon(c)} color='white' />
                </Box>
                <Box>
                  <Typography variant='body2' color='typography.info'>{c.name}</Typography>
                  <Typography>{getCertificateType(c, t)}</Typography>
                </Box>
              </MenuItem>
            ))}
          </FormikSelect>
          <FormikClientPhoneInput
            sx={{ '.MuiOutlinedInput-root': { borderRadius: '8px', height: 40, paddingTop: 0 } }}
            name='data.user.phone'
            label={t('fields.phone')}
          />
          <FormikTextInput
            fullWidth
            name='data.user.firstName'
            label={t('fields.clientName')}
            placeholder={t('fields.clientName')}
          />
          <FormikTextInput
            fullWidth
            name='data.user.lastName'
            label={t('fields.clientLastName')}
            placeholder={t('fields.clientLastName')}
          />
        </Box>
      </Form>
    </Modal>
  );
};

AddCertificateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  placeId: PropTypes.number.isRequired,
};

export default memo(
  withFormik({
    mapPropsToValues: () => ({
      data: {
        user: {
          firstName: '',
          lastName: '',
          phone: '',
        }
      },
      certificateId: '',
    }),
    validationSchema: schema,
    handleSubmit: async (data, helpers) => {
      await store.dispatch(certificateThunks.addCertificate({ data, placeId: helpers.props.placeId }));
      store.dispatch(toastrActions.showToastr({ message: t('messages.added') }));
      helpers.props.reloadCertificates();
      helpers.props.onClose();
    },
    enableReinitialize: true,
  })(AddCertificateModal),
);
