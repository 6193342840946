import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'state/utils/loadDataExtraReducer';
import loadDataInitialState from 'state/utils/loadDataInitialState';

const certificateSlice = createSlice({
  name: 'client',
  initialState: {
    list: loadDataInitialState({ rows: [], count: 0 }),
  },
  extraReducers: {
    ...loadDataExtraReducer(thunks.fetchCertificates, 'list'),
  },
});

export default certificateSlice.reducer;
