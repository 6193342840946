import React, { memo } from 'react';
import mova from 'mova';
import Typography from "@mui/material/Typography";
import { CATEGORY_CERTIFICATE, CATEGORY_SUBSCRIPTION, TYPE_AMOUNT, TYPE_VISITS } from "utils/certificate";
import Icon from "../../../components/Icon/Icon";
import Box from "@mui/material/Box";

const t = mova.ns('components.Certificates');

const CertificateTypeLabel = ({ certificate, variant = 'body2' }) => {
  return (
    <Box display='flex' gap={1} alignItems='center'>
      {certificate.category === CATEGORY_SUBSCRIPTION && (
        <>
          <Icon type='calendar' />
          <Typography variant={variant}>{certificate.data.duration} {t('days')}</Typography>
        </>
      )}
      {certificate.category === CATEGORY_CERTIFICATE && certificate.data.type === TYPE_VISITS && (
        <>
          <Icon type='pedestrian' />
          <Typography variant={variant}>{certificate.visited}/{certificate.data.visits}</Typography>
        </>
      )}
      {certificate.category === CATEGORY_CERTIFICATE && certificate.data.type === TYPE_AMOUNT && (
        <>
          <Icon type='dollar' />
          <Typography variant={variant}>{certificate.data.amount} {t('uah')}</Typography>
        </>
      )}
    </Box>
  );
};

export default memo(CertificateTypeLabel);
