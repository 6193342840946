import React, { memo, useCallback, useEffect } from 'react';
import { useField } from 'formik';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { userSelectors, userThunks } from "../../state/ducks/user";
import { debounce as debounceFunc } from "../../utils/utils";
import FormikAutocomplete from "./FormikAutocomplete";

const FormikClientPhoneInput = ({ name = 'phone', ...rest }) => {
  const [field, state, { setValue }] = useField(name); // eslint-disable-line no-unused-vars
  const searchUsers = useSelector(userSelectors.getSearchUsers());
  const dispatch = useDispatch();

  useEffect(() => {
    if (field.value.length >= 9) {
      debounceFunc(q => dispatch(userThunks.searchUsers({ q })), 1000)(field.value);
    }
  }, [field.value, dispatch]); // eslint-disable-line

  const normalizedOptions = useCallback(() => {
    return searchUsers.map(user => ({ label: user.phone, user }));
  }, [searchUsers]);

  const phoneFocused = () => {
    if (!field.value) {
      // toDO: Insert different default phone prefix based on place country
      setValue('+380');
    }
  };

  return (
    <FormikAutocomplete
      fullWidth
      name={name}
      inputProps={{
        placeholder: '+380'
      }}
      options={normalizedOptions()}
      renderOption={(props, option) => (
        <Box component='li' {...props}>
          <Typography sx={{ mr: 2 }}>{option.label}</Typography>
          <Typography color='typography.blurred'>{option.user.displayedName}</Typography>
        </Box>
      )}
      freeSolo
      type='tel'
      onFocus={phoneFocused}
      textFieldProps={{ sx: { '.MuiInputLabel-root': { top: '-7px' } }}}
      {...rest}
    />
  );
};

export default memo(FormikClientPhoneInput);
