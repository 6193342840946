import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/apiService';

const getSyrveOrganisations = createAsyncThunk('integration/getSyrveOrganisations', async data => {
  return api.post(`/places/${data.placeId}/integrations/syrve/organisations`, data);
});

const getSyrveTerminals = createAsyncThunk('integration/getSyrveTerminals', async data => {
  return api.post(`/places/${data.placeId}/integrations/syrve/terminals`, data);
});

const syncSyrveMenu = createAsyncThunk('integration/syncSyrveMenu', async (placeId) => {
  return api.get(`/places/${placeId}/integrations/syrve/syncMenu`);
});

export default {
  getSyrveOrganisations,
  getSyrveTerminals,
  syncSyrveMenu,
};
