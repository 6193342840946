import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Modal from '@components/Modal/Modal';
import mova from 'mova';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from '@form/FormikTextInput';
import FormikSelect from '@form/FormikSelect';
import ModalActions from '@components/Modal/ModalActions';
import { placeThunks } from 'state/ducks/place';
import { toastrActions } from 'state/ducks/toastr';
import store from '@state/store';
import { PHONE_REGEX } from "utils/utils";

const t = mova.ns('components.Administration');

const schema = Yup.object().shape({
  firstName: Yup.string().required(() => t('errors.required')),
  lastName: Yup.string(),
  phone: Yup.string()
    .required(() => t('errors.required'))
    .matches(PHONE_REGEX, () => t('errors.phone')),
  email: Yup.string().email(() => t('errors.email')),
  role: Yup.string().required(() => t('errors.required')),
});

const roles = ['admin', 'analyst', 'owner'];

const AddEditAdminModal = ({ editManager, onClose, submitForm }) => {
  const roleItems = roles.map(r => ({ label: t(`fields.roles.${r}`), value: r }));
  return (
    <Modal
      open
      onClose={onClose}
      title={editManager ? t('editingAdmin') : t('addNewAdmin')}
      actions={<ModalActions onClose={onClose} submitForm={submitForm} />}
    >
      <Form>
        <FormikTextInput
          sx={{ my: 1 }}
          fullWidth
          name='firstName'
          placeholder={t('fields.firstName')}
        />
        <FormikTextInput
          sx={{ my: 1 }}
          fullWidth
          name='lastName'
          placeholder={t('fields.lastName')}
        />
        <FormikTextInput
          disabled={!!editManager}
          sx={{ my: 1 }}
          fullWidth
          name='phone'
          placeholder={t('fields.phone')}
        />
        <FormikTextInput
          disabled={!!editManager}
          sx={{ my: 1 }}
          fullWidth
          name='email'
          placeholder={t('fields.email')}
        />
        <FormikSelect sx={{ my: 1 }} name='role' fullWidth placeholder={t('fields.role')} items={roleItems} />
      </Form>
    </Modal>
  );
};

AddEditAdminModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default memo(
  withFormik({
    mapPropsToValues: props => ({
      firstName: props.editManager?.user?.firstName || '',
      lastName: props.editManager?.user?.lastName || '',
      phone: props.editManager?.user?.phone || '',
      email: props.editManager?.user?.email || '',
      role: (props.editManager?.permissions && props.editManager?.permissions[0]) || 'admin',
    }),
    validationSchema: schema,
    handleSubmit: async (data, helpers) => {
      const isEditMode = !!helpers.props.editManager;
      const updatedData = {
        ...data,
        email: data.email || null,
        placeId: helpers.props.placeId,
        permissions: [data.role],
      };

      if (isEditMode) {
        updatedData.userId = helpers.props.editManager.userId;
      }

      const result = await store.dispatch(
        helpers.props.editManager ? placeThunks.updateManager(updatedData) : placeThunks.addManager(updatedData),
      );
      const isExist = result?.payload?.length === helpers.props.managers.length;
      if (!isEditMode) {
        store.dispatch(
          toastrActions.showToastr({
            message: isExist
              ? t('messages.exist', { displayedName: result?.payload?.displayedName })
              : t('messages.added'),
          }),
        );
      } else {
        store.dispatch(
          toastrActions.showToastr({
            message: t('messages.edited'),
          }),
        );
      }
      helpers.props.onClose();
    },
  })(AddEditAdminModal),
);
