import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@form/Select';
import { hallActions, hallSelectors } from 'state/ducks/hall';

const HallsSelect = ({ onSelect, ...rest }) => {
  const dispatch = useDispatch();
  const activeHall = useSelector(hallSelectors.getActiveHall());
  const halls = useSelector(hallSelectors.getHalls());

  const handleOnChange = useCallback(
    e => {
      const { value } = e.target;
      dispatch(hallActions.setActiveHall(halls.find(p => p.id === value)));
      onSelect && onSelect();
    },
    [halls, dispatch, onSelect],
  );

  return (
    <Select
      key={activeHall?.id || 0}
      {...rest}
      onChange={handleOnChange}
      value={activeHall?.id || ''}
      items={halls}
      labelPath='name'
      valuePath='id'
      sendEvent
    />
  );
};

export default memo(HallsSelect);
