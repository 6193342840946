import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'state/utils/loadDataExtraReducer';
import loadDataInitialState from 'state/utils/loadDataInitialState';

const hallSlice = createSlice({
  name: 'hall',
  initialState: {
    list: loadDataInitialState([]),
    activeHall: null,
  },
  reducers: {
    setActiveHall(state, action) {
      state.activeHall = action.payload?.id;
    },
  },
  extraReducers: {
    ...loadDataExtraReducer(thunks.fetchHalls, 'list'),
    ...loadDataExtraReducer(thunks.addHall, 'list'),
    ...loadDataExtraReducer(thunks.deleteHall, 'list'),
    ...loadDataExtraReducer(thunks.updateHall, 'list'),
    ...loadDataExtraReducer(thunks.addSpot, 'list'),
    ...loadDataExtraReducer(thunks.updateSpot, 'list'),
    ...loadDataExtraReducer(thunks.deleteSpot, 'list'),
  },
});

export const { setHalls, setActiveHall } = hallSlice.actions;

export default hallSlice.reducer;
