import React, { memo } from 'react';
import styled from '@emotion/styled';
import TableRow from '@mui/material/TableRow';

const StyledRow = styled(TableRow)(({ theme }) => ({
  height: theme.spacing(8),
  background: 'white',
  border: '10px solid transparent',
  'td:first-of-type': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  'td:last-child': {
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}));

const BorderCollapseTableRow = ({ children, ...rest }) => {
  return (
    <StyledRow {...rest}>
      {children}
    </StyledRow>
  );
};

export default memo(BorderCollapseTableRow);
