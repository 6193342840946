import React from 'react';
import theme from '../../theme';

export default function home({ color = theme.palette.common.white }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.22222 21.9998H12H19.7778C20.3671 21.9998 22 22 22 22C22 22 22 20.5302 22 19.9998V10.9998C22.0008 10.8682 21.9728 10.7377 21.9175 10.6159C21.8622 10.4941 21.7807 10.3833 21.6778 10.2898L12.7889 2.28982C12.5807 2.10357 12.2991 1.99902 12.0055 1.99902C11.712 1.99902 11.4304 2.10357 11.2222 2.28982L2.33333 10.2898C2.22835 10.3824 2.14482 10.4929 2.08761 10.6148C2.03039 10.7366 2.00061 10.8675 2 10.9998V19.9998C2 20.5302 2 21.9998 2 21.9998C2 21.9998 3.63285 21.9998 4.22222 21.9998ZM9.77777 21.9998V14.9998H14.2222V21.9998H9.77777ZM4.22222 11.4098L12 4.40982L19.7778 11.4098V19.9998H16.4444V14.9998C16.4444 14.4694 16.2103 13.9607 15.7936 13.5856C15.3768 13.2105 14.8116 12.9998 14.2222 12.9998H9.77777C9.1884 12.9998 8.62317 13.2105 8.20642 13.5856C7.78968 13.9607 7.55555 14.4694 7.55555 14.9998V19.9998H4.22222V11.4098Z'
        fill={color}
      />
    </svg>
  );
}
