import React from 'react';
import theme from '../../theme';

export default function star({ color = theme.palette.common.white, filled = false, size = 24 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d={
          filled
            ? 'M21.8733 8.36877L15.8654 7.45365L13.1802 1.76529C12.6991 0.751358 11.305 0.738469 10.8198 1.76529L8.13459 7.45365L2.12675 8.36877C1.04937 8.53204 0.617588 9.91975 1.3989 10.7146L5.74543 15.1398L4.7174 21.391C4.53235 22.5209 5.67141 23.3673 6.62543 22.8389L12 19.8873L17.3746 22.8389C18.3286 23.363 19.4677 22.5209 19.2826 21.391L18.2546 15.1398L22.6011 10.7146C23.3824 9.91975 22.9506 8.53204 21.8733 8.36877Z'
            : 'M21.8733 8.36877L15.8654 7.45365L13.1802 1.76529C12.6991 0.751357 11.305 0.738468 10.8198 1.76529L8.13459 7.45365L2.12675 8.36877C1.04937 8.53204 0.61759 9.91975 1.3989 10.7146L5.74543 15.1398L4.7174 21.391C4.53235 22.5209 5.67141 23.3673 6.62543 22.8389L12 19.8873L17.3746 22.8389C18.3286 23.363 19.4677 22.5209 19.2826 21.391L18.2546 15.1398L22.6011 10.7146C23.3824 9.91975 22.9506 8.53204 21.8733 8.36877V8.36877ZM16.1368 14.418L17.1114 20.3642L12 17.5587L6.88861 20.3642L7.86318 14.418L3.72637 10.2076L9.44225 9.33975L12 3.92635L14.5578 9.33975L20.2736 10.2076L16.1368 14.418V14.418Z'
        }
        fill={color}
      />
    </svg>
  );
}
