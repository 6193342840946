import React, { memo, useState } from 'react';
import mova from 'mova';
import { userThunks } from 'state/ducks/user';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from '@form/FormikTextInput';
import store from '@state/store';
import Box from '@mui/material/Box';
import Button from '@components/Button/Button';
import FormikCheckbox from '@form/FormikCheckbox';
import SocialLoginButton from './components/SocialLoginButton';
import Divider from './components/Divider';
import Typography from '@mui/material/Typography';
import Icon from '@components/Icon/Icon';
import RouterLink from '@components/RouterLink/RouterLink';
import history from 'history.js';
import config from 'config';
import { useSelector } from 'react-redux';
import { userActions, userSelectors } from '@state/ducks/user';
import { Redirect } from 'react-router-dom';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

const t = mova.ns('auth.Login');

const schema = Yup.object().shape({
  phone: Yup.string().required(() => t('errors.required')),
  password: Yup.string(),
  rememberMe: Yup.boolean(),
});

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleOAuthFacebook = () => {
    window.location = `${config.apiRoot}/auth/admin/facebook`;
  };

  // const handleOAuthGoogle = () => {
  //   window.location = `${config.apiRoot}/auth/admin/google`;
  // };

  const user = useSelector(userSelectors.getCurrentUser());
  if (user) {
    return <Redirect to='/' />;
  }

  return (
    <Form>
      <Typography mt={10} mb={3} variant='h0' component='h1' align='center'>
        {t('title')}
      </Typography>
      {/*<SocialLoginButton sx={{ my: 1 }} fullWidth provider='google' onClick={handleOAuthGoogle} />*/}
      <SocialLoginButton sx={{ my: 1 }} fullWidth provider='facebook' onClick={handleOAuthFacebook} />
      <Divider />
      <FormikTextInput sx={{ my: 1 }} fullWidth name='phone' placeholder={t('fields.phone')} />
      <FormikTextInput
        sx={{ my: 1 }}
        fullWidth
        name='password'
        placeholder={t('fields.password')}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton edge='end' onClick={() => setShowPassword(!showPassword)}>
                <Icon type={showPassword ? 'eyeOff' : 'eye'} />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Box my={2} display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap'>
        <FormikCheckbox name='rememberMe' label={t('rememberMe')} />
        <Typography variant='body2' align='right'>
          <RouterLink to='/forgot'>{t('forgotPassword')}</RouterLink>
        </Typography>
      </Box>
      <Button fullWidth type='submit' variant='contained' color='primary' endIcon={<Icon type='arrow' />}>
        {t('submit')}
      </Button>
      {/*<Typography mt={2} variant='body2' align='center' color='typography.info'>*/}
      {/*{t('dontHaveAccount')}&nbsp;<RouterLink to='/register'>{t('createAccount')}</RouterLink>*/}
      {/*</Typography>*/}
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: () => ({
      phone: '',
      password: '',
      rememberMe: false,
    }),
    validationSchema: schema,
    handleSubmit: (credentials, helpers) => {
      store.dispatch(
        userThunks.loginUser({
          credentials,
          callback: async response => {
            await store.dispatch(userActions.setUserData(response));
            helpers.setSubmitting(false);
            history.push('/');
          },
        }),
      );
    },
  })(Login),
);
