import React from 'react';
import theme from '../../theme';

export default function search({ color = theme.palette.primary.main }) {
  return (
    <svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.677 16.6071L9.96204 10.8911C7.41971 12.6986 3.91648 12.2565 1.90291 9.87407C-0.11065 7.49165 0.0372005 3.96373 2.24304 1.75814C4.4483 -0.448412 7.97657 -0.596902 10.3594 1.41656C12.7423 3.43002 13.1847 6.93359 11.377 9.47614L17.092 15.1921L15.678 16.6061L15.677 16.6071ZM6.48504 2.00013C4.58874 1.9997 2.95273 3.33082 2.56752 5.18758C2.1823 7.04434 3.15375 8.91641 4.89372 9.67038C6.63369 10.4243 8.66394 9.85297 9.75528 8.30219C10.8466 6.75141 10.6991 4.64746 9.40204 3.26414L10.007 3.86414L9.32504 3.18414L9.31304 3.17214C8.56483 2.41932 7.54644 1.99727 6.48504 2.00013Z'
        fill={color}
      />
    </svg>
  );
}
