import React, { memo } from 'react';
import Icon from '../Icon/Icon';
import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  borderRadius: '50%',
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
  '&:active': {
    backgroundColor: theme.palette.secondary.dark,
  }
}));

const PlusButton = (props) => {
  return (
    <StyledIconButton color='secondary' {...props}>
      <Icon type='plus' />
    </StyledIconButton>
  );
};

PlusButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default memo(PlusButton);
