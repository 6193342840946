import React from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';

import Header from '@components/Header/Header';
import Sidebar from '@components/Sidebar/Sidebar';
import UserModal from '@components/UserModal/UserModal';
import AppDataContainer from "../containers/AppDataContainer";
import AddReservationDrawer from "../components/AddReservationDrawer/AddReservationDrawer";
import { useSelector } from "react-redux";
import { reservationSelectors } from "state/ducks/reservation";
import { placeSelectors } from "../../state/ducks/place";
// import OverduePaymentPopup from "../components/OverduePaymentPopup/OverduePaymentPopup";

const StyledLayout = styled(Box)(({ theme }) => ({
  width: '100%',
  minWidth: 0,
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2, 4),
  },
  [theme.breakpoints.up('lg')]: {
    width: `calc(100vw - ${theme.spacing(30)})`,
  },
}));

const Layout = ({ children }) => {
  const showSidebar = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const drawer = useSelector(reservationSelectors.getReservationDrawer());
  const place = useSelector(placeSelectors.getActivePlace());

  return (
    <>
      <Header />
      <AppDataContainer>
        <Box sx={{ display: 'flex' }}>
          {showSidebar && <Sidebar />}
          <StyledLayout>{children}</StyledLayout>
        </Box>
      </AppDataContainer>
      <UserModal />
      {drawer.open && (
        <AddReservationDrawer reservation={drawer.reservation} table={drawer.table} date={drawer.date} place={place} />
      )}
      <Box id='custom-portal-root' />
      {/*<OverduePaymentPopup />*/}
    </>
  );
};

export default Layout;
