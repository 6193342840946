import React from 'react';
import theme from '../../theme';

export default function chart({ color = theme.palette.typography.secondary }) {
  return (
    <svg width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 8.414C11.1875 8.60155 11.4418 8.70694 11.707 8.707H12.293C12.5582 8.70694 12.8125 8.60155 13 8.414L17.707 3.707L20 6V0H14L16.293 2.293L12 6.586L8.99997 3.586C8.81248 3.39845 8.55816 3.29306 8.29297 3.293H7.70697C7.44177 3.29306 7.18746 3.39845 6.99997 3.586L0.292969 10.293L1.70697 11.707L7.99997 5.414L11 8.414Z'
        fill={color}
      />
    </svg>
  );
}
