import React from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const StyledStatisticsItem = styled(Box)(({ theme, red }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: theme.spacing(2),
  backgroundColor: red ? theme.palette.secondary.main : theme.palette.common.white,
  color: red ? theme.palette.common.white : theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadow
}));

const StatisticsItem = ({ red = false, label, value, ...rest }) => {
  return (
    <StyledStatisticsItem red={red} {...rest}>
      <Typography variant='body2'>{label}</Typography>
      <Typography sx={{ fontSize: 36, fontWeight: 'bold' }}>{value}</Typography>
    </StyledStatisticsItem>
  );
};

StatisticsItem.propTypes = {
  red: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
};

export default StatisticsItem;
