import React, { memo } from 'react';
import { Tooltip, useTheme } from '@mui/material';
import Card from '@components/Card/Card';
import Box from '@mui/material/Box';
import Icon from '@components/Icon/Icon';
import Typography from "@mui/material/Typography";
import mova from 'mova';
import CertificateStatusLabel from "./CertificateStatusLabel";
import CertificateTypeLabel from "./CertificateTypeLabel";

const t = mova.ns('components.Certificates');

const getClientName = (billing) => `${billing?.data?.user.firstName || ''} ${billing?.data?.user.lastName || ''}`

const CertificateCard = ({ billing, view }) => {
  const theme = useTheme();

  const { certificate } = billing;

  return (
    <Card onClick={() => view(billing)} sx={{ cursor: 'pointer' }}>
      <Box display='flex' alignItems='center' gap={1}>
        <Typography noWrap variant='body2'>{certificate.name}</Typography>
        {billing.data.comment && (
          <Tooltip title={billing.data.comment}>
            <Icon type='comment' color={theme.palette.primary.main} />
          </Tooltip>
        )}
      </Box>
      <Box>
        <Typography color='typography.info' variant='caption'>{t('fields.id')}: {billing.data.uuid}</Typography>
      </Box>
      <Box mt={0.5} display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant='caption'>{getClientName(billing)}</Typography>
        <CertificateTypeLabel certificate={certificate} variant='caption' />
      </Box>
      <Box mt={0.5} display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant='caption'>{billing.data?.user?.phone}</Typography>
        <CertificateStatusLabel billing={billing} variant='caption' />
      </Box>
    </Card>
  );
};

export default memo(CertificateCard);
