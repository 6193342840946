import React from 'react';
import { ToastContainer } from 'react-toastify';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import history from 'history.js';

import Auth from 'views/auth/Auth';
import i18n from './i18n';
import * as serviceWorker from './serviceWorker';
import theme from './theme/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import store from './state/store';
import './index.css';
import AppRoutes from './routes/AppRoutes';
import * as Sentry from '@sentry/react';

import 'react-toastify/dist/ReactToastify.css';

const RootHtml = () => (
  <ReduxProvider store={store}>
    <Auth>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <CssBaseline />
          <AppRoutes />
          <ToastContainer />
        </Router>
      </ThemeProvider>
    </Auth>
  </ReduxProvider>
);

(function main() {
  i18n.init();

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [],
    environment: window.location.hostname,
  });

  render(<RootHtml />, document.getElementById('root'));
})();

serviceWorker.register();
