import React from 'react';

export default function facebook({ color = 'white' }) {
  return (
    <svg width='10' height='15' viewBox='0 0 11 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.89478 0.817444C4.8761 0.817444 3.23962 2.45392 3.23962 4.4726V7.89099H1.19891C0.646667 7.89099 0.198914 8.33868 0.198914 8.89099V10.9999C0.198914 11.5522 0.646667 11.9999 1.19891 11.9999H3.23962V20.0001C3.23962 20.5523 3.68732 21.0001 4.23962 21.0001H6.23901C6.79126 21.0001 7.23901 20.5523 7.23901 20.0001V12H9.57373C10.0964 12 10.5308 11.5975 10.5708 11.0764L10.7325 8.96747C10.777 8.38672 10.3178 7.89099 9.73541 7.89099H7.23901V5.25385C7.23901 4.7016 7.68671 4.25385 8.23901 4.25385H9.76318C10.3155 4.25385 10.7632 3.80615 10.7632 3.25385V2.50006V1.81744C10.7632 1.26514 10.3155 0.817444 9.76318 0.817444H6.89478Z'
        fill={color}
      />
    </svg>
  );
}


