import React, { memo, useState } from 'react';
import { Box, Checkbox, Collapse, Radio, RadioGroup, Typography, useTheme } from "@mui/material";
import mova from "mova";
import { useSelector } from "react-redux";
import { placeSelectors } from "state/ducks/place";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Icon from "../../Icon/Icon";
import Grid from "@mui/material/Grid";
import TextInput from "../../../form/TextInput";
import { clone } from "utils/utils";

const t = mova.ns('components.DepositManagement')

const getItemPrice = (from, type) => type[moment(from).isoWeekday()];

const getTypeDefaultCount = (values, depositId) => values.depositOrder.find(val => val.depositId === depositId)?.count || 1;

const getTypeDefaultPrice = (values, deposits, depositId, type) =>
  values.depositOrder.find(val => val.depositId === depositId)?.price || getItemPrice(values.from, type);

const selectedDepositType = (values, depositId) =>
  values.depositOrder.find(deposit => deposit.depositId === depositId)?.depositTypeId || '';

const getTotalAmount = (values) => values.depositOrder.reduce((acc, val) => acc + val.count * val.price, 0);

const DepositManagement = ({ values, setFieldValue, editDisabled = false }) => {
  const theme = useTheme();
  const [enabled, setEnabled] = useState(values.depositOrder.length > 0);
  const [expanded, setExpanded] = useState(values.depositOrder.map(item => item.depositId));
  const deposits = useSelector(placeSelectors.getDeposits());

  const toggleExpandItem = (item) => {
    if (editDisabled) {
      return;
    }

    if (expanded.includes(item.id)) {
      setExpanded(expanded.filter(e => e !== item.id));
      setFieldValue('depositOrder', values.depositOrder.filter(val => val.depositId !== item.id))
    } else {
      setExpanded([...expanded, item.id]);
      setFieldValue(
        'depositOrder',
        [...values.depositOrder,
          {
            depositId: item.id,
            depositTypeId: item.types[0].id,
            count: 1,
            price: getItemPrice(values.from, item.types[0])
          }
        ]
      )
    }
    setFieldValue('depositUpdated', true);
  }

  const typeUpdated = (deposit, newType) => {
    const index = values.depositOrder.findIndex(d => d.depositId === deposit.id);
    const updatedOrder = clone(values.depositOrder);
    updatedOrder[index].depositTypeId = +newType;
    updatedOrder[index].count = document.getElementById(`deposits-${newType}-count`).value;
    updatedOrder[index].price = document.getElementById(`deposits-${newType}-price`).value;
    setFieldValue('depositOrder', updatedOrder);
    setFieldValue('depositUpdated', true);
  }

  const priceUpdated = (deposit, type, newPrice) => {
    if (!newPrice) {
      return;
    }

    const index = values.depositOrder.findIndex(d => d.depositId === deposit.id);
    if (values.depositOrder[index].depositTypeId === type.id) {
      const updatedOrder = clone(values.depositOrder);
      updatedOrder[index].price = +newPrice;
      setFieldValue('depositOrder', updatedOrder);
      setFieldValue('depositUpdated', true);
    }
  }

  const countUpdated = (deposit, type, newCount) => {
    if (!newCount) {
      return;
    }

    const index = values.depositOrder.findIndex(d => d.depositId === deposit.id);
    if (values.depositOrder[index].depositTypeId === type.id) {
      const updatedOrder = clone(values.depositOrder)
      updatedOrder[index].count = +newCount;
      setFieldValue('depositOrder', updatedOrder);
      setFieldValue('depositUpdated', true);
    }
  }

  const toggleDepositsEnabled = () => {
    if (enabled) {
      setFieldValue('depositOrder', []);
      setExpanded([]);
      setFieldValue('depositUpdated', true);
    }
    setEnabled(!enabled);
  }

  return (
    <Box>
      <FormControlLabel
        control={<Switch color='secondary' checked={enabled} onChange={toggleDepositsEnabled} />}
        label={<Typography variant='body1'>{t('switch')}</Typography>}
        disabled={editDisabled}
      />
      {enabled && (
        <Box>
          <Box mt={1} display='flex' flexDirection='column' gap={0.5}>
            {deposits.map(deposit => (
              <Box key={deposit.id}>
                <Box
                  p={1}
                  sx={{ borderRadius: '4px', cursor: 'pointer' }}
                  backgroundColor='typography.background'
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  gap={1}
                  onClick={() => toggleExpandItem(deposit)}
                >
                  <Box display='flex' alignItems='center' gap={1}>
                    <Checkbox
                      checked={expanded.includes(deposit.id)}
                      sx={{ p: 0, pointerEvents: 'none' }}
                      size='small'
                      color='secondary'
                    />
                    <Typography color='typography.info' variant='body2'>{deposit.label}</Typography>
                  </Box>
                  <Icon
                    type={expanded.includes(deposit.id) ? 'chevronUp' : 'chevronDown'}
                    color={theme.palette.typography.info}
                  />
                </Box>
                <Collapse in={expanded.includes(deposit.id)} sx={{ mt: 1 }}>
                  <RadioGroup
                    component={Box}
                    sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                    value={selectedDepositType(values, deposit.id)}
                    onChange={(e, newType) => typeUpdated(deposit, newType)}
                  >
                    {deposit.types.map(type => (
                      <Grid container spacing={1} key={type.id}>
                        <Grid item xs={6}>
                          <FormControlLabel
                            sx={{ maxWidth: '100%' }}
                            value={type.id}
                            control={<Radio sx={{ pl: 2 }} size='small' />}
                            label={<Typography variant='body2' noWrap>{type.label}</Typography>}
                            disabled={editDisabled}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextInput
                            id={`deposits-${type.id}-count`}
                            sx={{ '.MuiOutlinedInput-root': { height: 'unset !important' } }}
                            type='number'
                            placeholder={t('quantity')}
                            inputProps={{ min: 1, style: { fontSize: 14, padding: theme.spacing() } }}
                            defaultValue={getTypeDefaultCount(values, deposit.id)}
                            onChange={(e) => countUpdated(deposit, type, e.target.value)}
                            disabled={selectedDepositType(values, deposit.id) !== type.id || editDisabled}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextInput
                            id={`deposits-${type.id}-price`}
                            sx={{ '.MuiOutlinedInput-root': { height: 'unset !important' } }}
                            type='number'
                            placeholder={t('price')}
                            inputProps={{ min: 1, style: { fontSize: 14, padding: theme.spacing() } }}
                            defaultValue={getTypeDefaultPrice(values, deposits, deposit.id, type)}
                            onChange={(e) => priceUpdated(deposit, type, e.target.value)}
                            disabled={selectedDepositType(values, deposit.id) !== type.id || editDisabled}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </RadioGroup>
                </Collapse>
              </Box>
            ))}
          </Box>
          <Box mt={0.5} display='flex' alignItems='center' justifyContent='space-between'>
            <Typography variant='body1'>{t('total')}</Typography>
            <Typography variant='h3'>{getTotalAmount(values)} {t('uah')}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default memo(DepositManagement);
