import React, { memo } from 'react';
import Card from '@components/Card/Card';
import Box from '@mui/material/Box';
import mova from 'mova';
import Button from '@components/Button/Button';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import FormikCheckbox from '@form/FormikCheckbox';
import FormikTimepicker from '@form/FormikTimepicker';
import FormikTextInput from '@form/FormikTextInput';
import { clone } from '@utils/utils';
import moment from 'moment';
import FormikSelect from "../../../../form/FormikSelect";
import { MenuItem, useTheme } from "@mui/material";

const t = mova.ns('components.ReservationSettings');
const eventsT = mova.ns('types.reservation.events');

const parseBusyHoursDates = obj => {
  if (!obj) {
    return obj;
  }

  const result = clone(obj);

  Object.keys(obj).forEach(key => {
    if (result[key].from) {
      result[key].from = moment(result[key].from).toDate();
    }

    if (result[key].to) {
      result[key].to = moment(result[key].to).toDate();
    }
  });

  return result;
};

const defaultTime = {
  monday: {
    from: null,
    to: null,
    apply: false,
  },
  tuesday: {
    from: null,
    to: null,
    apply: false,
  },
  wednesday: {
    from: null,
    to: null,
    apply: false,
  },
  thursday: {
    from: null,
    to: null,
    apply: false,
  },
  friday: {
    from: null,
    to: null,
    apply: false,
  },
  saturday: {
    from: null,
    to: null,
    apply: false,
  },
  sunday: {
    from: null,
    to: null,
    apply: false,
  },
};

const ReservationSettings = ({ place, showToastr }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const customTags = useSelector(placeSelectors.getCustomTags());
  const syrveConnected = activePlace.connections?.syrve?.apiKey;
  const showMenuSetting = activePlace.billTypeExtra?.includes('menu');
  const showCertificateSetting = ['enterprise', 'pro'].includes(activePlace.billType);

  const updateSettings = async values => {
    if (values.highlightBefore === '') {
      values.highlightBefore = null;
    }

    await dispatch(
      placeThunks.updatePlace({
        id: place.id,
        uri: place.uri,
        ...values,
        maxGuests: values?.maxGuests || null,
      }),
    );
    await showToastr(t('success'));
  };

  const updateAdditionalMessage = async values => {
    const { message, ...time } = values;
    await dispatch(
      placeThunks.updatePlaceBusyHours({
        id: place.id,
        uri: place.uri,
        busyHours: { message, time },
      }),
    );
    await showToastr(t('success'));
  };

  const updateCustomTags = async values => {
    await dispatch(placeThunks.saveCustomTags({ placeId: activePlace.id, values }));
    await showToastr(t('success'));
  };

  const highlightReservationsChanged = (e, setFieldValue) => {
    if (e.target.value) {
      setFieldValue('highlightBefore', '');
    }
  };

  return (
    <Box>
      <Card>
        <Formik
          initialValues={{
            ourManagment: !!place.ourManagment,
            autoSeating: place.autoSeating || false,
            tgAdminNotification: place.tgAdminNotification || false,
            smsConfirmation: place.smsConfirmation || false,
            smsFeedback: place.smsFeedback || false,
            newReservationMessage: place.newReservationMessage || false,
            reservationCancelMessage: place.reservationCancelMessage || false,
            showHalls: place.showHalls || false,
            showSpots: place.showSpots || false,
            spotsMandatory: place.spotsMandatory || (place.showSpots && place.onlyDepositReservations),
            reservationDuration: place.reservationDuration || 120,
            reservationAllDay: place.reservationAllDay || false,
            highlightReservations: !!place.highlightBefore,
            highlightBefore: place.highlightBefore || '',
            hideTableCapacity: !!place.hideTableCapacity,
            disableSameTimeReservation: !!place.disableSameTimeReservation,
            showAutomaticReservationButton: !!place.showAutomaticReservationButton,
            syrveDelay: place.syrveDelay || 0,
            depositsActive: !!place.depositsActive,
            menuActive: !!place.menuActive && showMenuSetting,
            clientMenuActive: !!place.clientMenuActive && showMenuSetting,
            showCertificates: !!place.showCertificates && showCertificateSetting,
            depositsPaymentTime: place.depositsPaymentTime || 30,
            onlyDepositReservations: !!place.onlyDepositReservations,
            lastTimeReservation: place.lastTimeReservation || 60,
            customDeclineMsg: place.customDeclineMsg || '',
            maxGuests: place.maxGuests || '',
          }}
          onSubmit={updateSettings}
        >
          {formikContext => (
            <Form>
              <Typography variant='h3' sx={{ mb: 2 }}>{t('title')}</Typography>
              <Grid container alignItems='center'>
                <Grid item xs={12}>
                  <Box display='flex' alignItems='center' flexWrap='wrap' gap={2}>
                    <Typography>{t('maxGuests')}</Typography>
                    <FormikTextInput
                      sx={{ minWidth: 100 }}
                      type='number'
                      name='maxGuests'
                      placeholder={t('maxGuestsLabel')}
                      label={t('maxGuestsLabel')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormikCheckbox name='ourManagment' label={t('ourManagement')} />
                </Grid>
                <Grid item xs={12}>
                  <FormikCheckbox name='tgAdminNotification' label={t('tgAdminNotification')} />
                </Grid>
                <Grid item xs={12}>
                  <FormikCheckbox name='autoSeating' label={t('autoSeating')} />
                </Grid>
                <Grid item xs={12}>
                  <FormikCheckbox name='smsConfirmation' label={t('smsConfirmation')} />
                </Grid>
                <Grid item xs={12}>
                  <FormikCheckbox name='smsFeedback' label={t('smsFeedback')} />
                </Grid>
                <Grid item xs={12}>
                  <FormikCheckbox name='newReservationMessage' label={t('newReservationMessage')} />
                </Grid>
                <Grid item xs={12}>
                  <FormikCheckbox name='reservationCancelMessage' label={t('reservationCancelMessage')} />
                </Grid>
                <Grid item xs={12}>
                  <FormikCheckbox name='showHalls' label={t('showHalls')} disabled={formikContext.values.showSpots} />
                </Grid>
                {['enterprise'].includes(activePlace.billType) && (
                  <Grid item xs={12} display='flex' gap={1} flexWrap='wrap'>
                    <FormikCheckbox name='showSpots' label={t('showSpots')} disabled={formikContext.values.ourManagment} />
                    <FormikCheckbox
                      name='spotsMandatory'
                      label={t('spotsMandatory')}
                      disabled={(formikContext.values.showSpots && formikContext.values.onlyDepositReservations) || formikContext.values.ourManagment}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Box display='flex' alignItems='center' flexWrap='wrap' gap={2}>
                    <FormikTextInput
                      sx={{ minWidth: 300 }}
                      type='number'
                      name='reservationDuration'
                      placeholder={t('reservationDuration')}
                      label={t('reservationDuration')}
                      disabled={formikContext.values.reservationAllDay}
                    />
                    <FormikCheckbox name='reservationAllDay' label={t('reservationAllDay')} />
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Box display='flex' alignItems='center' flexWrap='wrap' gap={2}>
                    <Typography>{t('lastTimeReservation1')}</Typography>
                    <FormikTextInput
                      sx={{ width: 80 }}
                      type='number'
                      name='lastTimeReservation'
                      placeholder={t('minutes')}
                      label={t('minutes')}
                    />
                    <Typography>{t('lastTimeReservation2')}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Box display='flex' alignItems='center' flexWrap='wrap' gap={2}>
                    <FormikCheckbox
                      name='highlightReservations'
                      label={t('highlightReservations')}
                      onChange={e => highlightReservationsChanged(e, formikContext.setFieldValue)}
                    />
                    <FormikTextInput
                      type='number'
                      name='highlightBefore'
                      placeholder={t('minutesBefore')}
                      label={t('minutesBefore')}
                      disabled={!formikContext.values.highlightReservations}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormikCheckbox name='hideTableCapacity' label={t('hideTableCapacity')} />
                </Grid>
                <Grid item xs={12}>
                  <FormikCheckbox name='disableSameTimeReservation' label={t('disableSameTimeReservation')} />
                </Grid>
                <Grid item xs={12}>
                  <FormikCheckbox name='showAutomaticReservationButton' label={t('showAutomaticReservationButton')} />
                </Grid>
                {syrveConnected && (
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Box display='flex' alignItems='center' flexWrap='wrap' gap={1}>
                      <Typography>{t('syrveDelay')}</Typography>
                      <FormikSelect name='syrveDelay' sx={{ width: 75 }}>
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </FormikSelect>
                      <Typography>{t('minutes')}</Typography>
                    </Box>
                  </Grid>
                )}
                {['enterprise', 'pro'].includes(activePlace.billType) && (
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Box display='flex' alignItems='center' flexWrap='wrap' gap={2}>
                      <FormikCheckbox name='depositsActive' label={t('depositsActive')} />
                      <FormikTextInput
                        sx={{ minWidth: 300 }}
                        inputProps={{ min: 15 }}
                        type='number'
                        name='depositsPaymentTime'
                        placeholder={t('depositsPaymentTime')}
                        label={t('depositsPaymentTime')}
                        disabled={!formikContext.values.depositsActive}
                      />
                    </Box>
                  </Grid>
                )}
                {['enterprise', 'pro'].includes(activePlace.billType) && (
                  <Grid item xs={12}>
                    <Box display='flex' alignItems='center' flexWrap='wrap' gap={2}>
                      <FormikCheckbox
                        disabled={!formikContext.values.depositsActive}
                        name='onlyDepositReservations'
                        label={t('onlyDepositReservations')}
                      />
                    </Box>
                  </Grid>
                )}
                {showMenuSetting && (
                  <Grid item xs={12} display='flex' gap={1} flexWrap='wrap'>
                    <FormikCheckbox name='menuActive' label={t('menuActive')} />
                    <FormikCheckbox
                      disabled={!formikContext.values.menuActive}
                      name='clientMenuActive'
                      label={t('clientMenuActive')}
                    />
                  </Grid>
                )}
                {showCertificateSetting && (
                  <Grid item xs={12}>
                    <FormikCheckbox name='showCertificates' label={t('certificatesActive')} />
                  </Grid>
                )}
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <FormikTextInput
                    multiline
                    fullWidth
                    inputProps={{ max: 10 }}
                    name='customDeclineMsg'
                    placeholder={t('customDeclineMsg')}
                    label={t('customDeclineMsg')}
                  />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Button type='submit' size='large' sx={{ mt: 2 }}>
                    {t('save')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Card>
      {activePlace.billTypeExtra?.includes('highload') && (
        <Card sx={{ mt: 2 }}>
          <Formik
            initialValues={{
              adminSelection: place.adminSelection || false,
              showAllReservations: place.showAllReservations || false,
              showReservationConfirmed: place.showReservationConfirmed || false,
              showWaitList: place.showWaitList || false,
              showHallFilter: place.showHallFilter || false,
              reservationMissed: place.reservationMissed || false,
              reservationMissedTime: place.reservationMissedTime || 30,
            }}
            onSubmit={updateSettings}
          >
            {formikContext => (
              <Form>
                <Typography variant='h3' sx={{ mb: 2 }}>{t('highloadTitle')}</Typography>
                <Grid container alignItems='center'>
                  <Grid item xs={12}>
                    <FormikCheckbox name='adminSelection' label={t('adminSelection')} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikCheckbox name='showAllReservations' label={t('showAllReservations')} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikCheckbox name='showReservationConfirmed' label={t('showReservationConfirmed')} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikCheckbox name='showWaitList' label={t('showWaitList')} />
                  </Grid>
                  <Grid item xs={12} display='flex' gap={1}>
                    <FormikCheckbox name='reservationMissed' label={t('reservationMissed')} />
                    <FormikTextInput
                      sx={{ minWidth: 100 }}
                      type='number'
                      name='reservationMissedTime'
                      placeholder={t('reservationMissedTime')}
                      label={t('reservationMissedTime')}
                      disabled={!formikContext.values.reservationMissed}
                    />
                  </Grid>
                  <Grid item xs={12} display='flex' gap={1}>
                    <FormikCheckbox name='showHallFilter' label={t('showHallFilter')} />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Button type='submit' size='large' sx={{ mt: 2 }}>
                      {t('save')}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Card>
      )}
      <Card sx={{ mt: 2 }}>
        <Formik
          initialValues={{
            message: place?.busyHours?.message || {
              ru: t('message.ruDefault'),
              ua: t('message.uaDefault'),
              en: t('message.enDefault'),
            },
            ...defaultTime,
            ...(parseBusyHoursDates(place?.busyHours?.time) || {}),
          }}
          onSubmit={updateAdditionalMessage}
        >
          <Form>
            <Typography variant='h3' sx={{ mb: 2 }}>
              {t('title2')}
            </Typography>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={4}>
                <FormikTextInput fullWidth name='message.ru' placeholder={t('message.ruPlaceholder')} multiline />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikTextInput fullWidth name='message.ua' placeholder={t('message.uaPlaceholder')} multiline />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormikTextInput fullWidth name='message.en' placeholder={t('message.enPlaceholder')} multiline />
              </Grid>
            </Grid>
            <Grid alignItems='center' container spacing={2} maxWidth={700}>
              <Grid item xs={12} sm={3}>
                <Typography>{t('hourFields.mon')}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='monday.from' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='monday.to' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikCheckbox name='monday.apply' label={t('apply')} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography>{t('hourFields.tue')}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='tuesday.from' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='tuesday.to' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikCheckbox name='tuesday.apply' label={t('apply')} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography>{t('hourFields.wed')}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='wednesday.from' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='wednesday.to' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikCheckbox name='wednesday.apply' label={t('apply')} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography>{t('hourFields.thu')}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='thursday.from' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='thursday.to' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikCheckbox name='thursday.apply' label={t('apply')} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography>{t('hourFields.fri')}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='friday.from' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='friday.to' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikCheckbox name='friday.apply' label={t('apply')} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography>{t('hourFields.sat')}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='saturday.from' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='saturday.to' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikCheckbox name='saturday.apply' label={t('apply')} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography>{t('hourFields.sun')}</Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='sunday.from' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikTimepicker name='sunday.to' />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormikCheckbox name='sunday.apply' label={t('apply')} />
              </Grid>
            </Grid>
            <Box display='flex' justifyContent='center' mt={2}>
              <Button type='submit' size='large'>
                {t('save')}
              </Button>
            </Box>
          </Form>
        </Formik>
      </Card>
      <Card sx={{ mt: 2 }}>
        <Formik
          initialValues={{
            id: customTags?.id,
            other: customTags?.other || '',
            business: customTags?.business || '',
            date: customTags?.date || '',
            family: customTags?.family || '',
            friends: customTags?.friends || '',
            birthday: customTags?.birthday || '',
            football: customTags?.football || '',
          }}
          onSubmit={updateCustomTags}
        >
          <Form>
            <Typography variant='h3' sx={{ mb: 2 }}>
              {t('customTagsTitle')}
            </Typography>
            <Box display='flex' flexDirection='column' gap={2}>
              {
                ['other', 'business', 'date', 'family', 'friends', 'birthday', 'football'].map(
                  (e, index) => (
                    <Box key={e} display='flex' gap={2} alignItems='center'>
                      <Box
                        width={16}
                        height={16}
                        sx={{ backgroundColor: theme.palette.reservations[index] }}
                        flexShrink={0}
                      />
                      <FormikTextInput placeholder={eventsT(e)} fullWidth name={e} />
                    </Box>
                  ))
              }
            </Box>
            <Box display='flex' justifyContent='center' mt={2}>
              <Button type='submit' size='large'>
                {t('save')}
              </Button>
            </Box>
          </Form>
        </Formik>
      </Card>
    </Box>
  );
};

export default memo(ReservationSettings);
