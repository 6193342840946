import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const options = {
  autoClose: 3000,
  hideProgressBar: false,
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
};

const toastrSlice = createSlice({
  name: 'toastr',
  initialState: {
    message: '',
    variant: '',
  },
  reducers: {
    showToastr: {
      reducer: (state, action) => {
        const { variant, message } = action.payload;
        const toastFunc = toast[variant] || toast.success;
        toastFunc(message, options);
      },
      prepare: payload => ({ payload }),
    },
  },
});

export const { showToastr } = toastrSlice.actions;

export default toastrSlice.reducer;
