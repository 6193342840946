import React from 'react';
import theme from '../../theme';

export default function chair({ color = theme.palette.primary.main, width = 14 }) {
  return (
    <svg
      width={width}
      viewBox='0 0 40 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='path-1-inside-1_501_2594' fill='white'>
        <path d='M35.2031 32.7337L35.3195 15.4L4.98552 15.1962L4.8691 32.53L35.2031 32.7337Z' />
        <path
          d='M30.9718 17.5375C35.7584 17.5697 39.6647 13.7155 39.6969 8.92889C39.729 4.14232 35.8748 0.235975 31.0882 0.203827L9.42107 0.0583051C4.6345 0.0261572 0.728148 3.88038 0.696 8.66695C0.663853 13.4535 4.51807 17.3599 9.30465 17.392L30.9718 17.5375Z' />
      </mask>
      <path d='M35.2031 32.7337L35.3195 15.4L4.98552 15.1962L4.8691 32.53L35.2031 32.7337Z' fill='white' />
      <path
        d='M30.9718 17.5375C35.7584 17.5697 39.6647 13.7155 39.6969 8.92889C39.729 4.14232 35.8748 0.235975 31.0882 0.203827L9.42107 0.0583051C4.6345 0.0261572 0.728148 3.88038 0.696 8.66695C0.663853 13.4535 4.51807 17.3599 9.30465 17.392L30.9718 17.5375Z'
        fill={color} />
      <path
        d='M35.2031 32.7337L35.1886 34.9004L37.3553 34.915L37.3698 32.7482L35.2031 32.7337ZM35.3195 15.4L37.4862 15.4145L37.5008 13.2478L35.3341 13.2333L35.3195 15.4ZM4.98552 15.1962L5.00007 13.0295L2.83336 13.015L2.81881 15.1817L4.98552 15.1962ZM4.8691 32.53L2.70239 32.5154L2.68784 34.6821L4.85455 34.6967L4.8691 32.53ZM30.9718 17.5375L30.9863 15.3708L30.9863 15.3708L30.9718 17.5375ZM31.0882 0.203827L31.1028 -1.96289L31.0882 0.203827ZM9.42107 0.0583051L9.40652 2.22502L9.42107 0.0583051ZM9.30465 17.392L9.2901 19.5587L9.2901 19.5587L9.30465 17.392ZM37.3698 32.7482L37.4862 15.4145L33.1528 15.3854L33.0364 32.7191L37.3698 32.7482ZM35.3341 13.2333L5.00007 13.0295L4.97097 17.363L35.305 17.5667L35.3341 13.2333ZM2.81881 15.1817L2.70239 32.5154L7.03582 32.5445L7.15223 15.2108L2.81881 15.1817ZM4.85455 34.6967L35.1886 34.9004L35.2177 30.567L4.88365 30.3632L4.85455 34.6967ZM30.9572 19.7043C36.9405 19.7444 41.8234 14.9267 41.8636 8.94345L37.5301 8.91434C37.506 12.5043 34.5763 15.3949 30.9863 15.3708L30.9572 19.7043ZM41.8636 8.94345C41.9038 2.96023 37.086 -1.9227 31.1028 -1.96289L31.0737 2.37054C34.6636 2.39465 37.5543 5.32441 37.5301 8.91434L41.8636 8.94345ZM31.1028 -1.96289L9.43563 -2.10841L9.40652 2.22502L31.0737 2.37054L31.1028 -1.96289ZM9.43563 -2.10841C3.45241 -2.14859 -1.43053 2.66918 -1.47071 8.6524L2.86271 8.68151C2.88683 5.09158 5.81659 2.20091 9.40652 2.22502L9.43563 -2.10841ZM-1.47071 8.6524C-1.5109 14.6356 3.30688 19.5186 9.2901 19.5587L9.3192 15.2253C5.72927 15.2012 2.8386 12.2714 2.86271 8.68151L-1.47071 8.6524ZM9.2901 19.5587L30.9572 19.7043L30.9863 15.3708L9.3192 15.2253L9.2901 19.5587Z'
        fill={color} mask='url(#path-1-inside-1_501_2594)' />
    </svg>
  );
}
