import React, { memo } from 'react';
import mova from 'mova';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import store from 'state/store';
import { placeThunks } from "state/ducks/place";
import Modal from "../../../../../components/Modal/Modal";
import ModalActions from "../../../../../components/Modal/ModalActions";
import FormikTextInput from "../../../../../form/FormikTextInput";
import Button from "../../../../../components/Button/Button";
import FormikCheckbox from "../../../../../form/FormikCheckbox";
import FormikSelect from "../../../../../form/FormikSelect";
import { MenuItem } from "@mui/material";
import FormikDatepicker from "../../../../../form/FormikDatepicker";
import {
  ACTIVATION_PURCHASE, ACTIVATION_VISIT,
  CATEGORY_CERTIFICATE,
  CATEGORY_SUBSCRIPTION,
  EXPIRATION_DATE,
  EXPIRATION_DAYS,
  TYPE_AMOUNT,
  TYPE_VISITS
} from "utils/certificate";

const t = mova.ns('components.CertificateSettings');

const CertificateModal = ({ onClose, certificate, values, handleSubmit, setFieldValue }) => {
  return (
    <Modal
      open
      onClose={onClose}
      title={certificate ? t('editingCertificate') : t('addingCertificate')}
      actions={<ModalActions onClose={onClose} submitForm={handleSubmit} />}
    >
      <Box display='flex' flexDirection='column' gap={2}>
        <Box>
          <FormikCheckbox name='active' label={t('fields.active')} />
        </Box>
        <FormikTextInput fullWidth name='name' placeholder={t('fields.name')} label={t('fields.name')} />
        <FormikSelect fullWidth name='category' placeholder={t('fields.category')} label={t('fields.category')}>
          <MenuItem value={CATEGORY_CERTIFICATE}>{t('fields.categoryCertificate')}</MenuItem>
          <MenuItem value={CATEGORY_SUBSCRIPTION}>{t('fields.categorySubscription')}</MenuItem>
        </FormikSelect>
        {values.category === CATEGORY_CERTIFICATE && (
          <FormikSelect fullWidth name='data.type' placeholder={t('fields.type')} label={t('fields.type')}>
            <MenuItem value={TYPE_VISITS}>{t('fields.typeVisits')}</MenuItem>
            <MenuItem value={TYPE_AMOUNT}>{t('fields.typeAmount')}</MenuItem>
          </FormikSelect>
        )}
        {values.category === CATEGORY_CERTIFICATE && values.data?.type === TYPE_VISITS && (
          <FormikTextInput
            fullWidth
            name='data.visits'
            placeholder={t('fields.visits')}
            label={t('fields.visits')}
            type='number'
          />
        )}
        {values.category === CATEGORY_CERTIFICATE && values.data?.type === TYPE_AMOUNT && (
          <FormikTextInput
            fullWidth
            name='data.amount'
            placeholder={t('fields.amount')}
            label={t('fields.amount')}
            type='number'
          />
        )}
        {values.category === CATEGORY_CERTIFICATE && (
          <FormikSelect fullWidth name='data.expiration' placeholder={t('fields.expiration')} label={t('fields.expiration')}>
            <MenuItem value={EXPIRATION_DATE}>{t('fields.expirationDate')}</MenuItem>
            <MenuItem value={EXPIRATION_DAYS}>{t('fields.expirationDays')}</MenuItem>
          </FormikSelect>
        )}
        {values.category === CATEGORY_CERTIFICATE && values.data?.expiration === EXPIRATION_DATE && (
          <FormikDatepicker
            fullWidth
            placeholder={t('fields.tillDate')}
            name='data.expirationDate'
            datepickerProps={{ withPortal: true, portalId: 'custom-portal-root' }}
          />
        )}
        {values.category === CATEGORY_CERTIFICATE && values.data?.expiration === EXPIRATION_DAYS && (
          <FormikTextInput
            fullWidth
            name='data.validDays'
            placeholder={t('fields.expirationDays')}
            label={t('fields.expirationDays')}
            type='number'
          />
        )}
        {values.category === CATEGORY_SUBSCRIPTION && (
          <FormikSelect fullWidth name='data.activation' placeholder={t('fields.activation')} label={t('fields.activation')}>
            <MenuItem value={ACTIVATION_PURCHASE}>{t('fields.activationPurchase')}</MenuItem>
            <MenuItem value={ACTIVATION_VISIT}>{t('fields.activationVisit')}</MenuItem>
          </FormikSelect>
        )}
        {values.category === CATEGORY_SUBSCRIPTION && values.data?.activation === ACTIVATION_VISIT && (
          <FormikTextInput
            name='data.activationVisitMaxDays'
            label={t('fields.activationVisitMaxDays')}
            placeholder={t('fields.activationVisitMaxDays')}
            type='number'
          />
        )}
        {values.category === CATEGORY_SUBSCRIPTION && (
          <FormikTextInput
            fullWidth
            name='data.duration'
            placeholder={t('fields.duration')}
            label={t('fields.duration')}
            type='number'
          />
        )}
        <FormikTextInput fullWidth name='price' placeholder={t('fields.price')} label={t('fields.price')} type='number' />
        <FormikTextInput
          multiline
          fullWidth
          name='description'
          placeholder={t('fields.description')}
          label={t('fields.description')}
        />
        {certificate?.imageUrl && (
          <Box component='img'
            width='100%'
            src={certificate.imageUrl}
            alt={certificate.name}
            sx={{ objectFit: 'cover', borderRadius: '8px' }} />
        )}
        <Box display='flex' alignItems='center' gap={2}>
          <input
            accept='image/*'
            id='new-photo-input'
            type='file'
            style={{ display: 'none' }}
            onChange={event => {
              setFieldValue('newImage', event.currentTarget.files[0]);
            }}
          />
          <label htmlFor='new-photo-input' style={{ flexShrink: 0 }}>
            <Button component='span' color='primary'>{t('uploadPhoto')}</Button>
          </label>
          <Typography noWrap>{values.newImage?.name}</Typography>
        </Box>
      </Box>
    </Modal>
  );
};

const schema = Yup.object().shape({
  active: Yup.boolean(),
  category: Yup.string().required(() => t('errors.required')),
  name: Yup.string().required(() => t('errors.required')),
  description: Yup.string(),
  price: Yup.number().required(() => t('errors.required')),
  image: Yup.string(),
  data: Yup.object().shape({
    type: Yup.string().test('required', () => t('errors.required'), function () {
      const formData = this.options.context;
      return formData.category !== CATEGORY_CERTIFICATE || !!formData.data.type;
    }),
    visits: Yup.number().test('required', () => t('errors.required'), function () {
      const formData = this.options.context;
      return formData.category !== CATEGORY_CERTIFICATE || formData.data.type !== TYPE_VISITS || !!formData.data.visits;
    }),
    amount: Yup.number().test('required', () => t('errors.required'), function () {
      const formData = this.options.context;
      return formData.category !== CATEGORY_CERTIFICATE || formData.data.type !== TYPE_AMOUNT || !!formData.data.amount;
    }),
    expiration: Yup.string().test('required', () => t('errors.required'), function () {
      const formData = this.options.context;
      return formData.category !== CATEGORY_CERTIFICATE || !!formData.data.expiration;
    }),
    expirationDate: Yup.date().test('required', () => t('errors.required'), function () {
      const formData = this.options.context;
      return formData.category !== CATEGORY_CERTIFICATE || formData.data.expiration !== EXPIRATION_DATE || !!formData.data.expirationDate;
    }),
    validDays: Yup.number().test('required', () => t('errors.required'), function () {
      const formData = this.options.context;
      return formData.category !== CATEGORY_CERTIFICATE || formData.data.expiration !== EXPIRATION_DAYS || !!formData.data.validDays;
    }),
    activation: Yup.string().test('required', () => t('errors.required'), function () {
      const formData = this.options.context;
      return formData.category !== CATEGORY_SUBSCRIPTION || !!formData.data.activation;
    }),
    activationVisitMaxDays: Yup.number(),
    duration: Yup.number().test('required', () => t('errors.required'), function () {
      const formData = this.options.context;
      return formData.category !== CATEGORY_SUBSCRIPTION || !!formData.data.duration;
    }),
  }),
});

export default memo(
  withFormik({
    mapPropsToValues: ({ certificate = {} }) => ({
      ...certificate,
      active: !!certificate?.active,
      category: certificate?.category || CATEGORY_CERTIFICATE,
      name: certificate?.name || '',
      description: certificate?.description || '',
      price: certificate?.price || '',
      image: certificate?.image || '',
      newImage: null,
      data: {
        type: certificate?.data?.type || '',
        visits: certificate?.data?.visits || '',
        amount: certificate?.data?.amount || '',
        expiration: certificate?.data?.expiration || '',
        expirationDate: certificate?.data?.expirationDate || '',
        validDays: certificate?.data?.validDays || '',
        activation: certificate?.data?.activation || '',
        activationVisitMaxDays: certificate?.data?.activationVisitMaxDays || '',
        duration: certificate?.data?.duration || '',
      },
    }),
    validationSchema: schema,
    handleSubmit: async (data, helpers) => {
      const formData = new FormData();

      for (let key in data) {
        if (typeof data[key] === 'object' && data[key] !== null && !data[key]?.size) {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      }

      await store.dispatch(
        placeThunks.saveCertificate({
          placeId: helpers.props.place.id,
          certificate: data,
        }),
      );
      helpers.props.onClose();
    },
    enableReinitialize: true,
  })(CertificateModal),
);
