import React from 'react';
import theme from '../../theme';

export default function flag({ size = 24, color = theme.palette.primary.main }) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.2605 0H3.98867C3.38867 0 2.89648 0.4875 2.89648 1.09219V24L11.6246 15.2719L20.3527 24V1.09219C20.3527 0.4875 19.8652 0 19.2605 0Z'
        fill={color}
      />
    </svg>
  );
}


