import React from 'react';
import theme from '../../theme';

export default function edit({ color = theme.palette.typography.primary, size = 19 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.41999 18.579C1.13948 18.5785 0.872062 18.4602 0.682993 18.253C0.490439 18.0475 0.394758 17.7695 0.419993 17.489L0.664993 14.795L11.983 3.481L15.52 7.017L4.20499 18.33L1.51099 18.575C1.47999 18.578 1.44899 18.579 1.41999 18.579ZM16.226 6.31L12.69 2.774L14.811 0.652997C14.9986 0.46522 15.2531 0.359711 15.5185 0.359711C15.7839 0.359711 16.0384 0.46522 16.226 0.652997L18.347 2.774C18.5348 2.96157 18.6403 3.21609 18.6403 3.4815C18.6403 3.74691 18.5348 4.00143 18.347 4.189L16.227 6.309L16.226 6.31Z'
        fill={color}
      />
    </svg>
  );
}
