/* eslint-disable quote-props */
import axios from 'axios';
import localStorage from 'utils/localStorage';

export function createApiClient(clientConfig = {}) {
  const client = axios.create({
    timeout: 60000,
    ...clientConfig,
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate, proxy-revalidate',
      Pragma: 'no-cache',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-admin': 'true',
      ...clientConfig.headers,
    },

    validateStatus: status => {
      if (clientConfig.validateStatus) {
        const outerResult = clientConfig.validateStatus(status);

        // If custom validateStatus returns a value - stop standard flow
        if (outerResult) {
          return outerResult;
        }
      }

      return status >= 200 && status < 300;
    },
  });

  client.setToken = function setToken(token) {
    const headers = client.defaults.headers.common;
    const savedToken = localStorage.get(localStorage.Keys.TOKEN);
    if (!token && !savedToken) {
      delete headers.Authorization;
      return;
    }
    headers.Authorization = `Bearer ${token || savedToken}`;
  };
  client.setToken(clientConfig.token);

  return client;
}
