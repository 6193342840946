import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Box from "@mui/material/Box";

const Page404 = () => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
      Sorry, this page does not exist
      <Link to='/'>Go to Home page</Link>
    </Box>
  );
};

export default memo(Page404);
