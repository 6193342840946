import React from 'react';
import theme from '../../theme';

export default function link({ color = theme.palette.typography.primary }) {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.46492 17.535C3.44276 17.535 1.6197 16.3169 0.845724 14.4487C0.0717475 12.5805 0.499254 10.4301 1.92892 8.99999L4.05092 6.87799L5.46492 8.29199L3.34392 10.413C2.58598 11.1709 2.28997 12.2756 2.5674 13.311C2.84482 14.3464 3.65354 15.1551 4.6889 15.4325C5.72426 15.7099 6.82898 15.4139 7.58692 14.656L9.70792 12.535L11.1219 13.95L9.00092 16.071C8.06499 17.0115 6.79178 17.5387 5.46492 17.535ZM6.17192 13.242L4.75792 11.828L11.8289 4.75699L13.2429 6.17099L6.17292 13.241L6.17192 13.242ZM13.9509 11.121L12.5359 9.70699L14.6569 7.58599C15.4252 6.83032 15.7291 5.72065 15.4532 4.67897C15.1772 3.63729 14.3637 2.82364 13.3221 2.54743C12.2805 2.27123 11.1708 2.57491 10.4149 3.34299L8.29292 5.46399L6.87892 4.04999L9.00092 1.92799C10.956 -0.0100441 14.1099 -0.00313771 16.0565 1.94344C18.003 3.89002 18.01 7.04391 16.0719 8.99899L13.9509 11.12V11.121Z'
        fill={color}
      />
    </svg>
  );
}
