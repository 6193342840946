import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'state/utils/loadDataExtraReducer';
import loadDataInitialState from 'state/utils/loadDataInitialState';

const dishSlice = createSlice({
  name: 'dish',
  initialState: {
    dishes: loadDataInitialState([]),
    menus: loadDataInitialState([]),
  },
  extraReducers: {
    ...loadDataExtraReducer(thunks.fetchMenus, 'menus'),
    ...loadDataExtraReducer(thunks.addMenu, 'menus'),
    ...loadDataExtraReducer(thunks.updateMenu, 'menus'),
    ...loadDataExtraReducer(thunks.deleteMenu, 'menus'),

    ...loadDataExtraReducer(thunks.fetchDishes, 'dishes'),
    ...loadDataExtraReducer(thunks.addDish, 'dishes'),
    ...loadDataExtraReducer(thunks.deleteDish, 'dishes'),
    ...loadDataExtraReducer(thunks.updateDish, 'dishes'),
  },
});

export default dishSlice.reducer;
