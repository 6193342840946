import React, { memo } from 'react';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import mova from 'mova';
import Icon from '@components/Icon/Icon';
import Box from '@mui/material/Box';

const t = mova.ns('auth.Social');

const StyledButton = styled(Button)(({ theme, provider }) => ({
  padding: theme.spacing(1, 2),
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadiusSmall,
  backgroundColor: provider === 'google' ? '#D7503F' : '#1D6FC5',
  textTransform: 'none',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: provider === 'google' ? '#c3493a' : '#1c60b1',
  }
}));

const SocialLoginButton = ({ provider, ...rest }) => {
  return (
    <StyledButton provider={provider} {...rest}>
      <Box component='span' mr={1}><Icon type={provider} /></Box> {t('signIn')} {t('providers.' + provider)}
    </StyledButton>
  );
};

export default memo(SocialLoginButton);
