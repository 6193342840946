import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useMediaQuery, useTheme } from '@mui/material';
import { userActions, userSelectors } from '@state/ducks/user';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import Card from '../Card/Card';
import IconButton from '@mui/material/IconButton';
import Icon from '../Icon/Icon';
import Box from '@mui/material/Box';
import Avatar from '../Avatar/Avatar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import mova from 'mova';
import { Form, Formik } from 'formik';
import FormikTextInput from '@form/FormikTextInput';
import Button from '../Button/Button';
import Chip from '@mui/material/Chip';
import PeriodsSelector from '../PeriodsSelector/PeriodsSelector';
import periodUtils from '@utils/periodUtils';
import StatisticsItem from '../StatisticsItem/StatisticsItem';
import { userThunks } from 'state/ducks/user';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import Link from '@mui/material/Link';
import ReservationCard from '../ReservationCard/ReservationCard';
import Loader from '../Loader/Loader';
import AddClientModal from '@pages/clients/components/AddClientModal';
import useModal from '@utils/hooks/useModal/useModal';

const t = mova.ns('components.UserModal');

const UserModal = () => {
  const [showAllHistory, setShowAllHistory] = useState(false);
  const [editModalOpen, editUser, closeEditModal, editedUser] = useModal();
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const theme = useTheme();
  const selectedUser = useSelector(userSelectors.getUserInModal());
  const activePlace = useSelector(placeSelectors.getActivePlace());

  const userDetails = useSelector(placeSelectors.getPlaceClient());
  const reservationHistory = useSelector(placeSelectors.getClientReservationHistory());
  const reservationHistoryCount = useSelector(placeSelectors.getClientReservationHistoryCount());

  const [dateParams, setDateParams] = useState({
    from: periodUtils.thisMonth[0].format('DD-MM-YYYY'),
    to: periodUtils.thisMonth[1].format('DD-MM-YYYY'),
  });
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (selectedUser) {
      dispatch(
        placeThunks.fetchPlaceClient({
          placeId: activePlace.id,
          clientId: selectedUser.id,
          from: dateParams.from,
          to: dateParams.to,
        }),
      );
    }
  }, [selectedUser, activePlace, dispatch, dateParams.from, dateParams.to]);

  useEffect(() => {
    if (selectedUser) {
      dispatch(placeThunks.fetchClientHistory({ placeId: activePlace.id, clientId: selectedUser.id, all: showAllHistory }));
    }
  }, [selectedUser, activePlace, showAllHistory, dispatch]);

  const saveComment = data => {
    dispatch(
      userThunks.updateNote({
        ...data,
        placeId: activePlace.id,
        userId: userDetails.id,
      }),
    );
  };

  const closeUserModal = useCallback(() => {
    setShowAllHistory(false);
    dispatch(userActions.setUserInModal(null));
  }, [dispatch]);

  const previousReservations = useMemo(
    () => reservationHistory.map(r => <ReservationCard key={r.id} reservation={r} />),
    [reservationHistory]
  );

  if (!selectedUser || !userDetails) {
    return null;
  }

  const cusineTags = (userDetails.cusines || []).map(c => (
    <Chip key={c.id} color='primary' size='small' label={c.tr || c.name} sx={{ mr: 1 }} />
  ));

  return (
    <Modal open onClose={closeUserModal} background={theme.palette.typography.background} maxWidth='md' fullWidth>
      {userDetails?.id !== selectedUser?.id && <Loader />}
      {userDetails?.id === selectedUser?.id && (
        <>
          <Card sx={{ position: 'relative' }}>
            <Box display='flex' mb={2}>
              <Avatar size={48} src={userDetails.avatarUrl} sx={{ mr: 2 }} />
              <Box>
                <Typography variant='h2' color='primary' mb={0.5} mr={2} display='flex' alignItems='center'>
                  {userDetails.lang && (
                    <Typography mr={1} variant='h4' component='div' color='typography.info'>
                      {userDetails.lang.toUpperCase()}
                    </Typography>
                  )}
                  {userDetails.displayedName}
                  <Link ml={1} href={`tel:${userDetails.phone}`}>
                    <IconButton>
                      <Icon type='phone' />
                    </IconButton>
                  </Link>
                </Typography>
                <Typography variant='body2' color='typography.info'>
                  {userDetails.phone}
                </Typography>
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={5} sm={4}>
                <Typography variant='body2'>{t('birthday')}</Typography>
              </Grid>
              <Grid item xs={7} sm={8}>
                <Typography variant='h4'>
                  {userDetails.birthday ? moment(userDetails.birthday).format('DD MMM YYYY') : '-'}
                </Typography>
              </Grid>
              <Grid item xs={5} sm={4}>
                <Typography variant='body2'>{t('gender')}</Typography>
              </Grid>
              <Grid item xs={7} sm={8}>
                <Typography variant='h4'>{userDetails.gender}</Typography>
              </Grid>
              {/* TODO: status <Grid item xs={5} sm={4}>
            <Typography variant='body2'>{t('familyStatus')}</Typography>
          </Grid>
          <Grid item xs={7} sm={8}>
            <Typography variant='h4'>{mockUserDetails.familyStatus}</Typography>
          </Grid>*/}
            </Grid>
            {!userDetails.isAuthorized && (
              <IconButton sx={{ position: 'absolute', top: 16, right: 16 }} onClick={() => editUser(userDetails)}>
                <Icon type='edit' />
              </IconButton>
            )}
          </Card>
          <Card sx={{ mt: 2 }}>
            <Formik
              key={`${userDetails.id}${userDetails?.note?.note}`}
              initialValues={{
                note: userDetails?.note?.note || '',
              }}
              onSubmit={saveComment}
            >
              <Form>
                <Typography variant='h4' mb={2}>
                  {t('comment')}
                </Typography>
                <FormikTextInput name='note' multiline fullWidth />
                <Button type='submit' sx={{ mt: 2 }}>
                  {t('save')}
                </Button>
              </Form>
            </Formik>
          </Card>
          {(userDetails.dishes || userDetails.ingridients || userDetails.allergy || userDetails.cusines.length > 0) && (
            <Card sx={{ mt: 2 }}>
              <Typography variant='h4' mb={2}>
                {t('preferences')}
              </Typography>
              <Grid container spacing={2}>
                {userDetails.cusines.length > 0 && (
                  <>
                    <Grid item width={160}>
                      <Typography variant='body2'>{t('cuisines')}</Typography>
                    </Grid>
                    <Grid item xs={12} xssm={8} mb={2}>
                      <Typography variant='h4'>{cusineTags}</Typography>
                    </Grid>
                  </>
                )}
                {userDetails.dishes && (
                  <>
                    <Grid item width={160}>
                      <Typography variant='body2'>{t('favorite')}</Typography>
                    </Grid>
                    <Grid item xs={12} xssm={8} mb={2}>
                      <Typography variant='h4'>{userDetails.dishes}</Typography>
                    </Grid>
                  </>
                )}
                {userDetails.ingridients && (
                  <>
                    <Grid item width={160}>
                      <Typography variant='body2'>{t('dontLike')}</Typography>
                    </Grid>
                    <Grid item xs={12} xssm={8} mb={2}>
                      <Typography variant='h4'>{userDetails.ingridients}</Typography>
                    </Grid>
                  </>
                )}
                {userDetails.allergy && (
                  <>
                    <Grid item width={160}>
                      <Typography variant='body2'>{t('allergies')}</Typography>
                    </Grid>
                    <Grid item xs={12} xssm={8}>
                      <Typography variant='h4'>{userDetails.allergy}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Card>
          )}
          <Card sx={{ mt: 2 }}>
            <Typography variant='h4' mb={2}>{t('statistics')}</Typography>
            <PeriodsSelector setDateParams={setDateParams} sx={{ mb: 4 }} />
            {mobileView && (
              <Box my={2} display='flex'>
                <Typography
                  variant='h4'
                  color={showAll ? 'typography.light' : 'secondary'}
                  onClick={() => setShowAll(false)}
                  sx={{ cursor: 'pointer' }}
                  mr={2}
                >
                  {t('ourPlace')}
                </Typography>
                <Typography
                  variant='h4'
                  color={showAll ? 'secondary' : 'typography.light'}
                  onClick={() => setShowAll(true)}
                  sx={{ cursor: 'pointer' }}
                >
                  {t('allPlaces')}
                </Typography>
              </Box>
            )}
            {!mobileView && (
              <Typography variant='h4' mb={2} color='secondary'>
                {t('ourPlace')}
              </Typography>
            )}
            {(!mobileView || !showAll) && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StatisticsItem red label={t('visits')} value={userDetails.visits} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StatisticsItem label={t('people')} value={userDetails.avgSeats} />
                </Grid>
                {/*<Grid item xs={12} sm={4} backgroundColor='white' color={theme.palette.primary.main}>*/}
                {/* toDO: For other countries put correct currency here, take from config */}
                {/*<StatisticsItem label={t('averageBill')} value={currency(userDetails.avgBillAmount)} />*/}
                {/*</Grid>*/}
              </Grid>
            )}
            {!mobileView && (
              <Typography variant='h4' mb={2} mt={4} color='secondary'>
                {t('allPlaces')}
              </Typography>
            )}
            {(!mobileView || showAll) && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StatisticsItem red label={t('visits')} value={userDetails.visitsAll} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StatisticsItem label={t('people')} value={userDetails.avgSeatsAll} />
                </Grid>
                {/* toDO: Activate when we have information about average bill */}
                {/*<Grid item xs={12} sm={4} backgroundColor='white' color={theme.palette.primary.main}>*/}
                {/*<StatisticsItem label={t('averageBill')} value={currency(userDetails.avgBillAmountAll)} />*/}
                {/*</Grid>*/}
              </Grid>
            )}
          </Card>
          <Card sx={{ mt: 2 }}>
            <Typography variant='h4' mb={2}>{t('reservationHistory')} ({reservationHistoryCount})</Typography>
            <Box display='flex' flexWrap='wrap' gap={2}>
              {previousReservations}
            </Box>
            {
              !showAllHistory && reservationHistoryCount > 4 &&
              <Button sx={{ mt: 2 }} onClick={() => setShowAllHistory(true)}>{t('showAll')}</Button>
            }
          </Card>
        </>
      )}
      <AddClientModal
        placeId={activePlace.id}
        open={editModalOpen}
        onClose={closeEditModal}
        client={editedUser}
        reloadClients={() => dispatch(placeThunks.fetchPlaceClient({
            placeId: activePlace.id,
            clientId: selectedUser.id,
            from: dateParams.from,
            to: dateParams.to,
          }),
        )}
      />
    </Modal>
  );
};

export default memo(UserModal);
