import React from 'react';
import theme from '../../theme';

export default function finish({ color = theme.palette.primary.main, size = 24 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 21V14C5 13.45 5.196 12.979 5.588 12.587C5.98 12.195 6.45067 11.9993 7 12H8V10H7C6.45 10 5.979 9.804 5.587 9.412C5.195 9.02 4.99934 8.54934 5 8V5C5 4.45 5.196 3.979 5.588 3.587C5.98 3.195 6.45067 2.99934 7 3H17C17.55 3 18.021 3.196 18.413 3.588C18.805 3.98 19.0007 4.45067 19 5V8C19 8.55 18.804 9.021 18.412 9.413C18.02 9.805 17.5493 10.0007 17 10H16V12H17C17.55 12 18.021 12.196 18.413 12.588C18.805 12.98 19.0007 13.4507 19 14V21H17V18H7V21H5ZM10 12H14V10H10V12Z'
        fill={color}
      />
    </svg>
  );
}
