import React from 'react';
import { useSelector } from 'react-redux';
import { userSelectors } from '@state/ducks/user';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ children, condition = true, ...rest }) => {
  const user = useSelector(userSelectors.getCurrentUser());

  return (
    <Route
      {...rest}
      render={() => {
        if (!user) {
          return <Redirect to='/login' />;
        } else {
          if (condition) {
            return children;
          } else {
            return <Redirect to='/' />;
          }
        }
      }}
    />
  );
};

export default ProtectedRoute;
