import React, { forwardRef, memo } from 'react';
import * as IconTypes from '../../../theme/icons';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const Icon = forwardRef(({ type, color, filled = false, clickable = true, revert, cursor = 'pointer', size, width, height, ...rest }, ref) => {
  const iconJsx = IconTypes[type];

  if (!iconJsx) {
    return null;
  }

  return (
    <Box
      component='span'
      display='inline-flex'
      alignItems='center'
      {...rest}
      style={{ cursor: clickable ? cursor : 'default' }}
      ref={ref}
    >
      {iconJsx({ type, color, filled, revert, size, width, height })}
    </Box>
  );
});

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  filled: PropTypes.bool,
  clickable: PropTypes.bool,
  revert: PropTypes.bool,
  cursor: PropTypes.string
};

export default memo(Icon);
