import React from 'react';

export default function hot({ size = 24 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M11.9162 4.16907C11.9684 4.31876 12.0834 4.70245 11.9001 5.68572C11.6626 6.95486 11.2186 7.86114 10.8962 8.31821C10.5861 8.87248 9.59472 10.4507 7.38118 11.3358C6.87112 11.5401 4.41181 12.5315 2.1425 11.6221C1.84026 11.4991 0.609483 10.9883 0.168833 9.93029C-0.013682 9.49194 -0.0921906 8.88636 0.161306 8.77743C0.45995 8.64852 0.861763 9.36237 1.92194 9.70532C2.05424 9.74798 3.22482 10.1108 4.39312 9.64778C5.45592 9.22695 5.86396 8.38144 6.02539 8.04689C6.39042 7.29018 6.36484 6.56512 6.33729 5.80278C6.30697 4.92692 6.12169 4.27417 6.58956 3.5716C6.67768 3.4394 6.76132 3.31632 6.92571 3.189C7.48599 2.75579 8.27992 2.81813 9.39358 2.91921C10.4609 3.01707 11.1192 3.08435 11.554 3.5302C11.7693 3.75051 11.8499 3.97861 11.9162 4.16907Z' fill='#D94375' />
      <path d='M6.94891 3.14219C7.14492 2.99477 7.45561 2.80313 7.96477 2.69689C8.07171 2.67474 8.20133 2.65072 8.35232 2.63228C8.48206 2.61639 8.88987 2.27838 8.9497 2.11869C9.00427 1.97338 9.04268 1.82342 9.09014 1.66335C9.1486 1.46828 9.23243 1.13627 9.20037 0.964901C9.13258 0.606646 8.91817 0.23023 8.18825 0.483148C8.09754 0.514011 7.91076 0.663513 7.80373 0.537934C7.58707 0.286319 8.20771 -0.0682553 8.72412 0.0114183C9.2999 0.100645 9.66576 0.591997 9.73002 0.702389C9.8894 0.970489 9.88582 1.22295 9.87901 1.71353C9.87666 1.91172 9.87675 2.05945 9.87757 2.21722C9.87972 2.44908 10.0875 2.70363 10.1893 2.72742C10.8254 2.87185 11.1839 3.12637 11.3627 3.28124C11.6308 3.51355 11.9157 3.89202 11.8214 3.97765C11.7644 4.02975 11.5734 3.96821 11.5134 3.94862C11.3181 3.88496 11.2905 3.80535 11.1484 3.73934C10.9229 3.63433 10.6214 3.66501 10.4232 3.74361C10.1359 3.85784 10.1562 4.05417 9.97495 4.06593C9.76152 4.08059 9.71618 3.8051 9.28556 3.65181C9.06445 3.57235 8.87498 3.57366 8.82267 3.57481C8.66402 3.57919 8.50849 3.61219 8.36685 3.67153C8.12989 3.77077 8.13292 3.86182 7.9398 3.90978C7.77734 3.95008 7.55951 3.93919 7.50803 3.85762C7.46095 3.78223 7.59307 3.71182 7.56589 3.55977C7.56276 3.54211 7.53767 3.4161 7.4227 3.35847C7.3225 3.30885 7.20295 3.33374 6.99511 3.37775C6.80447 3.41804 6.73258 3.45807 6.71069 3.4353C6.67535 3.39711 6.82515 3.23524 6.94891 3.14219Z' fill='#9ED84D' />
      <path d='M7.47304 7.31238C7.42169 7.32045 7.36617 7.31924 7.31332 7.30317C7.23578 7.28114 7.17186 7.23445 7.13557 7.17335C7.09929 7.11226 7.0936 7.04174 7.11976 6.97727C7.25506 6.64532 7.17997 6.17859 7.12057 5.80425C7.08643 5.58748 7.05673 5.40031 7.06225 5.24321C7.08187 4.72336 7.44044 4.48161 7.48149 4.45641C7.51352 4.43582 7.55012 4.42069 7.58922 4.41187C7.62831 4.40306 7.66912 4.40073 7.70933 4.40503C7.74954 4.40933 7.78835 4.42017 7.82354 4.43693C7.85874 4.45369 7.88964 4.47604 7.91447 4.50271C7.9393 4.52937 7.95758 4.55984 7.96826 4.59235C7.97894 4.62487 7.98182 4.65881 7.97673 4.69222C7.97164 4.72564 7.95868 4.75788 7.93859 4.78711C7.9185 4.81633 7.89167 4.84197 7.85965 4.86256C7.8457 4.87272 7.68868 4.99233 7.67899 5.26038C7.67518 5.37635 7.70271 5.55278 7.73211 5.739C7.79974 6.16658 7.88469 6.69944 7.70413 7.14411C7.66715 7.23262 7.57775 7.29479 7.47304 7.31238ZM6.8616 8.85614C6.80682 8.86641 6.74967 8.86398 6.69637 8.84912C6.64308 8.83425 6.59568 8.80753 6.55934 8.77185C6.44796 8.66352 6.46434 8.50202 6.59329 8.40989C6.74529 8.30155 6.84278 8.02059 6.86822 7.86835C6.87378 7.83502 6.88719 7.80295 6.90768 7.77396C6.92817 7.74498 6.95533 7.71964 6.98761 7.6994C7.0199 7.67915 7.05668 7.6644 7.09585 7.65599C7.13502 7.64757 7.17582 7.64566 7.21591 7.65035C7.25608 7.65493 7.29478 7.66606 7.3298 7.68308C7.36482 7.7001 7.39547 7.72269 7.42 7.74956C7.44452 7.77642 7.46245 7.80704 7.47274 7.83965C7.48303 7.87225 7.48549 7.90622 7.47997 7.93959C7.47077 7.99966 7.37312 8.53235 6.99366 8.80104C6.95466 8.82885 6.90891 8.84691 6.8616 8.85614Z' fill='white' />
    </svg>
  );
}
