import React from 'react';

export default function google({ color = 'white' }) {
  return (
    <svg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.17356 4.4136V6.4842H9.04876C8.78116 7.8 7.66096 8.556 6.17356 8.556C4.44109 8.53215 3.04916 7.12093 3.04916 5.3883C3.04916 3.65566 4.44109 2.24444 6.17356 2.2206C6.89369 2.21973 7.59165 2.46964 8.14756 2.9274L9.70756 1.3674C7.91812 -0.205762 5.30477 -0.408102 3.2945 0.870863C1.28423 2.14983 0.360179 4.60272 1.02691 6.89017C1.69365 9.17761 3.79093 10.7499 6.17356 10.7484C8.85376 10.7484 11.291 8.799 11.291 5.388C11.2868 5.05958 11.2466 4.73261 11.171 4.413L6.17356 4.4136Z'
        fill={color} />
    </svg>
  );
}
