import mova from 'mova';
import localStorage from 'utils/localStorage';
import en from './en.i18n';
import ru from './ru.i18n';
import ua from './ua.i18n';
import es from './es.i18n';

const lang = localStorage.get(localStorage.Keys.LANG) || 'ua';

export default {
  init() {
    mova.setLanguage(
      {
        en,
        ru,
        ua,
        es,
      }[lang],
    );
  },
};
