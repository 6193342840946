import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/apiService';
import { setActiveHall } from './slice';
import mova from 'mova';
import { toastrActions } from '../toastr';
import { hallSelectors } from './index';
import createDebouncedAsyncThunk from 'state/utils/createDebouncedAsyncThunk';
import moment from "moment";

const t = mova.ns('actions.hall');

const fetchHalls = createDebouncedAsyncThunk('hall/fetchHalls', async ({ placeId, showPast, date }, { dispatch, getState }) => {
  const params = showPast ? `?isPast=true&date=${moment(date).format('DD-MM-YYYY')}` : ''

  const halls = await api.get(`/places/${placeId}/halls${params}`);

  const activeHall = halls.find(h => h.id === getState().hall.activeHall);
  if (!activeHall) {
    if (halls.length > 0) {
      dispatch(setActiveHall(halls[0]));
    } else {
      dispatch(setActiveHall(null));
    }
  }

  return halls;
});

const addHall = createAsyncThunk('hall/addHall', async (data, { dispatch }) => {
  const res = await api.post(`/places/${data.placeId}/halls`, data);
  dispatch(toastrActions.showToastr({ message: t('hallAdded') }));
  dispatch(setActiveHall(res[res.length - 1]));
  return res;
});

const updateHall = createAsyncThunk('hall/updateHall', async ({ data, showNotification }, { dispatch }) => {
  const res = await api.put(`/places/${data.placeId}/halls/${data.id}`, data);
  if (showNotification) {
    dispatch(toastrActions.showToastr({ message: t('saved') }));
  }
  return res;
});

// toDO: We do not allow deleting halls anymore. Remove this method later if not needed
const deleteHall = createAsyncThunk('hall/deleteHall', async (data, { dispatch, getState }) => {
  const newActiveHall = hallSelectors
    .getHalls()(getState())
    .find(h => h.id !== data.id);
  dispatch(setActiveHall(newActiveHall));
  return api.delete(`/places/${data.placeId}/halls/${data.id}`);
});

const addSpot = createAsyncThunk('hall/addSpot', async data => {
  return api.post(`/places/${data.placeId}/halls/${data.hallId}/spots`, data);
});

const updateSpot = createAsyncThunk('hall/updateSpot', async (data, { dispatch }) => {
  const res = api.put(`/places/${data.placeId}/halls/${data.hallId}/spots/${data.id}`, data);
  dispatch(toastrActions.showToastr({ message: t('saved') }));
  return res;
});

const deleteSpot = createAsyncThunk('hall/deleteSpot', async (data, { dispatch }) => {
  const res = await api.delete(`/places/${data.placeId}/halls/${data.hallId}/spots/${data.id}`);
  dispatch(toastrActions.showToastr({ message: t('spotDeleted') }));
  return res;
});

const addPhoto = createAsyncThunk('hall/addPhoto', ({ data, callback }) => {
  return api
    .post(`/places/${data.placeId}/halls/${data.hallId}/photos`, data.formData, {
      'Content-Type': 'multipart/form-data',
    })
    .then(res => {
      callback && callback(res);
      return res;
    });
});

export default {
  fetchHalls,
  addHall,
  updateHall,
  deleteHall,
  updateSpot,
  addSpot,
  deleteSpot,
  addPhoto,
};
