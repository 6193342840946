import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'state/utils/loadDataExtraReducer';
import loadDataInitialState from 'state/utils/loadDataInitialState';

const billingSlice = createSlice({
  name: 'billing',
  initialState: {
    details: loadDataInitialState(null),
    list: loadDataInitialState([]),
    stats: loadDataInitialState(null),
    newBill: loadDataInitialState(null, false),
    listInvoices: loadDataInitialState([]),
    placeDeposits: loadDataInitialState({}),
    placeCertificates: loadDataInitialState({}),
  },
  extraReducers: {
    ...loadDataExtraReducer(thunks.fetchBilling, 'details'),
    ...loadDataExtraReducer(thunks.fetchBillings, 'list'),
    ...loadDataExtraReducer(thunks.fetchBillingsStats, 'stats'),
    ...loadDataExtraReducer(thunks.addBilling, 'newBill'),
    ...loadDataExtraReducer(thunks.fetchPendingInvoices, 'listInvoices'),
    ...loadDataExtraReducer(thunks.updatePendingInvoice, 'listInvoices'),
    ...loadDataExtraReducer(thunks.updatePendingInvoicesStatus, 'listInvoices'),
    ...loadDataExtraReducer(thunks.fetchPlaceDeposits, 'placeDeposits'),
    ...loadDataExtraReducer(thunks.fetchPlaceCertificates, 'placeCertificates'),
  },
});

export const { setBillings, setActiveBilling } = billingSlice.actions;

export default billingSlice.reducer;
