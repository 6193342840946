import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { userSelectors, userThunks } from 'state/ducks/user';

import {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
} from './push-notifications';
// import all the function created to manage the push notifications

const pushNotificationSupported = isPushNotificationSupported();
// first thing to do: check if the push notifications are supported by the browser

function usePrevious(value) {
  const ref = useRef(null);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current === null ? value : ref.current;
}

export default function usePushNotifications() {
  const dispatch = useDispatch();

  const notification = useSelector(userSelectors.getNotification());
  const notificationLoading = useSelector(userSelectors.getNotificationLoading());
  const notificationError = useSelector(userSelectors.getNotificationError());

  const [userConsent, setSuserConsent] = useState(window.Notification && window.Notification.permission);
  // to manage the user consent:
  // Notification.permission is a JavaScript native function that return the current state of the permission
  // We initialize the userConsent with that value
  const [userSubscription, setUserSubscription] = useState(null);
  // to manage the push server subscription
  const [error, setError] = useState(null);
  // to manage errors
  const [loading, setLoading] = useState(true);
  // to manage async actions

  const isSubscribed = !!(notification?.id && userSubscription);
  useEffect(() => {
    if (pushNotificationSupported) {
      setLoading(true);
      setError(false);
      registerServiceWorker().then(() => {
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (userSubscription && userSubscription.toJSON) {
      const json = userSubscription.toJSON();
      dispatch(userThunks.getNotification({ auth: json?.keys?.auth }));
    }
  }, [dispatch, userSubscription]);

  const prevNotificationLoading = usePrevious(notificationLoading);
  useEffect(() => {
    if (
      !notificationError &&
      userSubscription &&
      prevNotificationLoading === true &&
      !isSubscribed &&
      userSubscription.toJSON
    ) {
      const json = userSubscription.toJSON();
      dispatch(userThunks.addNotification(json));
    }
  }, [notification, notificationLoading, notificationError, isSubscribed, dispatch, prevNotificationLoading]); // eslint-disable-line
  // if the push notifications are supported, registers the service worker
  // this effect runs only the first render

  useEffect(() => {
    setLoading(true);
    setError(false);
    const getExixtingSubscription = async () => {
      try {
        const existingSubscription = await getUserSubscription();
        // await existingSubscription.unsubscribe();
        setUserSubscription(existingSubscription);
      } catch (e) {
        console.log('$$$ [e]', e); // eslint-disable-line no-console
      }
      setLoading(false);
    };
    getExixtingSubscription();
  }, []);
  // Retrieve if there is any push notification subscription for the registered service worker
  // this use effect runs only in the first render

  /**
   * define a click handler that creates a push notification subscription.
   * Once the subscription is created, it uses the setUserSubscription hook
   */
  const susbribeToPushNotification = () => {
    if (!loading || error) {
      setLoading(true);
      setError(false);
    }
    createNotificationSubscription()
      .then(subscrition => {
        setUserSubscription(subscrition);
        setLoading(false);
      })
      .catch(err => {
        /* eslint-disable */
        console.error(
          "Couldn't create the notification subscription",
          err,
          'name:',
          err.name,
          'message:',
          err.message,
          'code:',
          err.code,
        );
        /* eslint-enable */
        setError(err);
        setLoading(false);
      });
  };

  /**
   * define a click handler that asks the user permission,
   * it uses the setSuserConsent state, to set the consent of the user
   * If the user denies the consent, an error is created with the setError hook
   */
  const enableNotifications = async () => {
    setLoading(true);
    setError(false);
    if (!isSubscribed) {
      askUserPermission().then(consent => {
        setSuserConsent(consent);
        if (consent !== 'granted') {
          setError({
            name: 'Consent denied',
            message: 'You denied the consent to receive notifications',
            code: 0,
          });
        } else {
          susbribeToPushNotification();
        }
        setLoading(false);
      });
    } else {
      if (userSubscription && userSubscription.toJSON) {
        const json = userSubscription.toJSON();
        dispatch(userThunks.deleteNotification({ auth: json?.keys?.auth }));
      }

      setUserSubscription(null);
      await userSubscription.unsubscribe();
      setLoading(false);
    }
  };
  //

  /**
   * returns all the stuff needed by a Component
   */
  return {
    enableNotifications,
    userConsent,
    pushNotificationSupported,
    userSubscription,
    notification,
    error,
    loading: loading || notificationLoading,
    isSubscribed,
  };
}
