import React, { memo, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import Card from '@components/Card/Card';
import mova from 'mova';
import { useSelector, useDispatch } from 'react-redux';
import { placeSelectors } from '@state/ducks/place';
import { billingSelectors, billingThunks } from '@state/ducks/billing';
import Button from '@components/Button/Button';
import currency from '@filters/currency';
import { Link } from 'react-router-dom';
import routes from '@routes';
import moment from 'moment';
import date from '@filters/date';
import Loader from '@components/Loader/Loader';

const t = mova.ns('components.Payment');

const billTypes = {
  starter: 'Free',
  pro: 'Starter',
  enterprise: 'Entree'
}

const Payment = () => {
  const dispatch = useDispatch();

  const activePlace = useSelector(placeSelectors.getActivePlace());
  const billingsStats = useSelector(billingSelectors.getBillingsStats());
  const billingsStatsLoading = useSelector(billingSelectors.getBillingsStatsLoading());
  const billings = useSelector(billingSelectors.getBillings());
  const invoices = useSelector(billingSelectors.getPendingInvoices());

  useEffect(() => {
    dispatch(billingThunks.fetchBillingsStats({ placeId: activePlace.id }));
    dispatch(billingThunks.fetchBillings({ placeId: activePlace.id }));
    dispatch(
      billingThunks.fetchPendingInvoices({
        placeId: activePlace.id,
        type: ['DONE', 'INPROCESS'],
      }),
    );
  }, [activePlace.id, dispatch]);

  if (billingsStatsLoading) {
    return <Loader />;
  }

  // const reservationsOverTariff = billingsStats.all - billingsStats.tariff.count;
  // const commission = (billingsStats.amount / 100) * billingsStats.monoComission;

  const historyItems = billings.map(h => (
    <TableRow key={h.id}>
      <TableCell>{t(h.status === 'DONE' ? 'DONE_PAYMENT' : h.status)}</TableCell>
      <TableCell>
        <Typography component={h.type === 'invoice' ? Link : 'span'} to={`/payment/invoice/${h.id}`}>
          {date(h.createdAt)}
        </Typography>
      </TableCell>
      <TableCell>
        {h.status === 'invoice' ? '-' : ''}
        {currency(h.amount, undefined, 2)}
      </TableCell>
    </TableRow>
  ));

  const historyInvoices = invoices.map(h => (
    <TableRow key={h.id}>
      <TableCell>
        <Link to={`/payment/invoice/${h.id}`}>{h.id}</Link>
      </TableCell>
      <TableCell>{t(h.status)}</TableCell>
      <TableCell>
        <Typography component={h.type === 'invoice' ? Link : 'span'} to={`/payment/invoice/${h.id}`}>
          {date(h.createdAt)}
        </Typography>
      </TableCell>
      <TableCell>
        {h.status === 'invoice' ? '-' : ''}
        {currency(h.amount, undefined, 2)}
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <Box mb={2} display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4'>{t('title')}</Typography>
        <Button component={Link} to={routes.newPayment.path}>
          {t('pay')}
        </Button>
      </Box>
      <Card>
        <Typography sx={{ mb: 2 }} variant='h4'>
          {t('currentBalance')}
        </Typography>
        <Typography sx={{ mb: 2 }}>{t('balanceDetails')}</Typography>
        <Typography variant='h1' sx={{ fontSize: '28px' }}>
          {currency(activePlace.account, undefined, 2)}
        </Typography>
      </Card>
      <Card sx={{ mt: 4 }}>
        <Box mb={1}>
          <Typography component='span'>{t('currentBillType')}:</Typography>&nbsp;
          <Typography component='span' variant='h3'>
            {billTypes[activePlace.billType]}
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography component='span'>{t('billingPeriod')}:</Typography>&nbsp;
          <Typography component='span' variant='h3'>
            {date(moment(activePlace.billEnd).subtract(1, 'months'))}
          </Typography>
          <Typography component='span'>&nbsp;-&nbsp;</Typography>
          <Typography component='span' variant='h3'>
            {date(activePlace.billEnd)}
          </Typography>
        </Box>
        <Table sx={{ mt: 2 }}>
          <TableBody>
            <TableRow>
              <TableCell>{t('tariffPayment')}</TableCell>
              <TableCell>{currency(billingsStats?.data?.billPrice, undefined, 0)}</TableCell>
            </TableRow>
            {Object.entries(billingsStats?.tariffExtra).filter(([key]) => key !== 'highload').map(([key, val]) => (
              <TableRow key={key}>
                <TableCell>{t(key)}</TableCell>
                <TableCell>{currency(val.amount * billingsStats.usd, undefined, 0)}</TableCell>
              </TableRow>
            ))}
            {/* <TableRow>
              <TableCell>{t('reservationsInTariff')}</TableCell>
              <TableCell>{billingsStats.tariff.count}</TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell>{t('reservationsUsed')}</TableCell>
              <TableCell>{billingsStats.all}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('reservationsOverTariff')}</TableCell>
              <TableCell>{reservationsOverTariff > 0 ? currency(reservationsOverTariff, undefined, 2) : 0}</TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell>{t('smsTotal')}</TableCell>
              <TableCell>{currency(billingsStats?.data?.sms, undefined, 2)}</TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell>{t('commission', { commission: billingsStats.monoComission })}</TableCell>
              <TableCell>{currency(commission, undefined, 2)}</TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('total')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                {currency(billingsStats?.amount, undefined, 2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      {historyInvoices.length > 0 && (
        <Card sx={{ mt: 4 }}>
          <Typography variant='h4'>{t('invoicesHistory')}</Typography>
          <Table sx={{ mt: 2 }}>
            <TableBody>{historyInvoices}</TableBody>
          </Table>
        </Card>
      )}
      {historyItems.length > 0 && (
        <Card sx={{ mt: 4 }}>
          <Typography variant='h4'>{t('paymentHistory')}</Typography>
          <Table sx={{ mt: 2 }}>
            <TableBody>{historyItems}</TableBody>
          </Table>
        </Card>
      )}
    </>
  );
};

export default memo(Payment);
