import React, { memo } from 'react';
import { Button as MaterialButton } from '@mui/material';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const StyledButton = styled(MaterialButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSmall,
  textTransform: 'none',
  fontWeight: 'bold',
  lineHeight: theme.spacing(3),
}));

const Button = ({ onClick, color = 'secondary', variant = 'contained', type = 'button', ...rest }) => {
  return (
    <StyledButton onClick={onClick} color={color} variant={variant} {...rest} type={type} />
  );
};

Button.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  onClick: function(props, propName) {
    if (props['type'] !== 'submit' && !props['component'] && props[propName] === undefined) {
      return new Error('Button - required onClick prop is not provided');
    }
  },
};

export default memo(Button);
