import React, { memo } from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)(({ theme }) => ({
  flexGrow: 1,
  textDecoration: 'none',
  color: theme.palette.common.white
}));

const Logo = () => {
  return (
    <StyledLink to='/'>
      <Typography variant='h1' sx={{ fontSize: 20, fontWeight: 'bold' }} align='center'>reservble</Typography>
    </StyledLink>
  );
};

export default memo(Logo);
