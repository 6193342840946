import React, { memo } from 'react';
import Chair from './Chair';

const toRadians = (angle) => {
  return angle * (Math.PI / 180);
};

const CircleTableChairs = ({ table }) => {
  const tableLength = table.variables?.length || 2;
  const maxChairs = (tableLength * 4 - 2) * 2;
  const smallChairs = table.seats > maxChairs;
  const angleChange = 360 / table.seats;

  return [...Array(table.seats).keys()].map(index => {
    const props = {
      top: -Math.sin(toRadians(90 + angleChange * index)) * (tableLength * 16 + 8) + tableLength * 16 + 'px',
      left: Math.cos(toRadians(90 + angleChange * index)) * (tableLength * 16 + 8) + tableLength * 16 + 'px',
      rotate: `${-angleChange * index}deg`,
      small: smallChairs,
    };
    return <Chair key={`${table.id}-chairs-${index + 1}`} adjustPosition {...props} />;
  });
};

export default memo(CircleTableChairs);
