import classMethodDecorator from './_classMethodDecorator';

export default classMethodDecorator((target, key, descriptor) => ({
  configurable: true,
  get() {
    const value = descriptor.value.bind(this);
    Object.defineProperty(this, key, { ...descriptor, value });
    return value;
  },

  set(value) {
    if (process.env.NODE_ENV !== 'test') {
      throw new Error('Unable to set new value to decorated method');
    }
    Object.defineProperty(this, key, { ...descriptor, value });
  },
}));
