import React, { memo } from 'react';
import { useTheme } from '@mui/material';
import Card from '@components/Card/Card';
import Box from '@mui/material/Box';
import Avatar from '@components/Avatar/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Icon from '@components/Icon/Icon';
import mova from 'mova';

const t = mova.ns('components.Administration');

const AdminCard = ({ admin, onEditManager }) => {
  const theme = useTheme();

  const { displayedName, phone, avatarUrl } = admin?.user || {};

  return (
    <Card sx={{ mb: 2 }}>
      <Box display='flex' alignItems='center'>
        <Box mr={2} flexGrow={0}>
          <Avatar src={avatarUrl} size={44} />
        </Box>
        <Box flexGrow={1} mr={2}>
          <Typography variant='h4'>{displayedName}</Typography>
          <Typography variant='caption' color='typography.info'>{phone}</Typography>
          <Typography variant='body2'>{t(`fields.roles.${admin?.permissions[0]}`)}</Typography>
        </Box>
        <Box>
          <IconButton onClick={() => onEditManager(admin)}>
            <Icon type='edit' color={theme.palette.primary.main} size={14} />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};

export default memo(AdminCard);
