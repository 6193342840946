import React, { memo } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Slider from '../Slider/Slider';

const StyledItem = styled('div')(({ theme, active }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(0, 1),
  color: active ? theme.palette.error.main : theme.palette.typography.light,
  backgroundColor: active ? theme.palette.secondary.background : 'transparent',
  cursor: 'pointer',
  outline: 'none',
  borderRadius: theme.shape.borderRadiusSmall,
  textAlign: 'center'
}));

const ScrollableMenu = ({ items }) => {
  const history = useHistory();
  const location = useLocation();
  const currentPath = queryString.parse(location.search).path;

  const goToPath = (path) => {
    const urlParams = queryString.parse(location.search);
    urlParams.path = path;
    history.push(`${location.pathname}?${queryString.stringify(urlParams)}`);
  };

  const sliderItems = items.map((i, index) =>
    <StyledItem
      active={i.active !== undefined ? i.active : currentPath === i.path}
      key={index}
      onClick={() => i.onClick ? i.onClick() : goToPath(i.path)}
    >
      <Typography variant='h4'>
        {i.label}
      </Typography>
    </StyledItem>
  );

  return (
    <Slider>{sliderItems}</Slider>
  );
};

ScrollableMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string,
    onClick: PropTypes.func,
    active: PropTypes.bool
  })).isRequired
};

export default memo(ScrollableMenu);
