import React, { memo, useEffect } from 'react';
import mova from 'mova';
import { userThunks } from 'state/ducks/user';
import { useSelector } from 'react-redux';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from '@form/FormikTextInput';
import store from '@state/store';
import Box from '@mui/material/Box';
import Button from '@components/Button/Button';
import Typography from '@mui/material/Typography';
import RouterLink from '@components/RouterLink/RouterLink';
import history from 'history.js';

const t = mova.ns('auth.Forgot');

const schema = Yup.object().shape({
  email: Yup.string().required(() => t('errors.required')),
});

const ForgotPassword = () => {
  // const isLoading = useSelector(({ user }) => user.recovery?.isLoading);
  // const error = useSelector(({ user }) => user.recovery?.error);
  const recoveryResult = useSelector(({ user }) => user.recovery?.data);

  useEffect(() => {
    if (recoveryResult?.succes) {
      history.push('/login');
    }
  }, [recoveryResult]);

  return (
    <Form>
      <Typography mt={10} mb={3} variant='h0' component='h1' align='center'>
        {t('title')}
      </Typography>
      <Box my={2}>
        <FormikTextInput fullWidth name='email' placeholder={t('fields.email')} />
      </Box>
      <Button fullWidth type='submit' variant='contained' color='primary'>
        {t('buttonSend')}
      </Button>
      <Typography mt={2} variant='body2' align='center'>
        <RouterLink to='/login'>{t('goBackLogin')}</RouterLink>
      </Typography>
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: () => ({
      email: '',
    }),
    validationSchema: schema,
    handleSubmit: (data, helpers) => {
      store.dispatch(
        userThunks.updateRecovery(data, () => {
          helpers.setSubmitting(false);
        }),
      );
    },
  })(ForgotPassword),
);
