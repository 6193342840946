import React from 'react';
import theme from '../../theme';

export default function commentFilled({ color = theme.palette.common.white }) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.1912 7.61937C14.1912 10.3551 11.4626 12.5718 8.09594 12.5718C7.21261 12.5718 6.37452 12.4194 5.61737 12.1456C5.33404 12.3527 4.87213 12.636 4.32452 12.8741C3.75309 13.1218 3.06499 13.3337 2.38166 13.3337C2.2269 13.3337 2.0888 13.2408 2.02928 13.0979C1.96975 12.9551 2.00309 12.7932 2.11023 12.6837L2.11737 12.6765C2.12452 12.6694 2.13404 12.6598 2.14833 12.6432C2.17452 12.6146 2.21499 12.5694 2.26499 12.5075C2.36261 12.3884 2.49356 12.2122 2.6269 11.9932C2.86499 11.5979 3.09118 11.0789 3.13642 10.4956C2.42213 9.68604 2.00071 8.69318 2.00071 7.61937C2.00071 4.88366 4.72928 2.66699 8.09594 2.66699C11.4626 2.66699 14.1912 4.88366 14.1912 7.61937Z'
        fill={color}
      />
    </svg>
  );
}
