import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import baseTableStyles from "./baseTableStyles";

export default styled(Box)(({ theme, selected, inProgress, table, offsetY }) => ({
  ...baseTableStyles(theme, table, inProgress, selected, offsetY),
  width: table.variables?.length * 32 || 64,
  height: table.variables?.width * 32 || 64,
  borderRadius: table.type === 'ELLIPSE' ? '50%' : 0,
}));
