import React, { memo } from 'react';
import mova from 'mova';
import { Box, FormControlLabel, MenuItem, Radio } from '@mui/material';
import Card from '@components/Card/Card';
import Button from '@components/Button/Button';
import Icon from '@components/Icon/Icon';
import { useSelector } from 'react-redux';
import { hallSelectors } from 'state/ducks/hall';
import useModal from '@hooks/useModal/useModal';
import AddEditHallModal from './AddEditHallModal';
import { placeSelectors } from 'state/ducks/place';
import ConfirmModal from '@components/ConfirmModal/ConfirmModal';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from '@form/FormikTextInput';
import Grid from '@mui/material/Grid';
import FormikRadioGroup from '@form/FormikRadioGroup';
import HallsSelect from '@components/Halls/HallsSelect';
import FormikCheckbox from '@form/FormikCheckbox';
import FormikSelect from '@form/FormikSelect';

const t = mova.ns('components.Halls');

const spotSchema = Yup.object().shape({
  label: Yup.string().required(() => t('errors.required')),
  seats: Yup.number(() => t('errors.number')).required(() => t('errors.required')),
  minSeats: Yup.number(() => t('errors.number')).required(() => t('errors.required')),
  variables: Yup.object().shape({
    border: Yup.string(),
    length: Yup.number(() => t('errors.number')).required(() => t('errors.required')),
    width: Yup.number(() => t('errors.number')).required(() => t('errors.required')),
    manualChairs: Yup.boolean(),
    chairsTop: Yup.number(() => t('errors.number')),
    chairsTopCouch: Yup.boolean(),
    chairsBottom: Yup.number(() => t('errors.number')),
    chairsBottomCouch: Yup.boolean(),
    chairsLeft: Yup.number(() => t('errors.number')),
    chairsLeftCouch: Yup.boolean(),
    chairsRight: Yup.number(() => t('errors.number')),
    chairsRightCouch: Yup.boolean(),
    angle: Yup.number(() => t('errors.number')).nullable(true),
  }),
  priority: Yup.number(() => t('errors.number')).required(() => t('errors.required')),
  active: Yup.boolean(),
  depositId: Yup.number().nullable(true),
});

const HallsSidebar = ({ activeElement, setActiveElement, handleSave, handleAddSpot, handleDeleteSpot }) => {
  const [addHallModalOpen, openAddHallModal, closeAddHallModal, editedHall] = useModal();
  const [deleteSpotModalOpen, deleteSpot, closeDeleteSpotModal] = useModal();
  const halls = useSelector(hallSelectors.getHalls());
  const activeHall = useSelector(hallSelectors.getActiveHall());
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const deposits = useSelector(placeSelectors.getDeposits());

  const confirmDeleteSpot = () => {
    closeDeleteSpotModal();
    handleDeleteSpot();
  };

  const borderOptions = [
    { id: 'solid', name: t('spotsForm.fields.borderType.solid') },
    { id: 'dashed', name: t('spotsForm.fields.borderType.dashed') },
    { id: 'dotted', name: t('spotsForm.fields.borderType.dotted') },
  ];

  return (
    <>
      <Box mb={2}>
        <Button startIcon={<Icon type='plus' />} fullWidth size='large' onClick={() => openAddHallModal(null)}>
          {t('addNewHall')}
        </Button>
      </Box>
      {halls.length > 0 && (
        <Card sx={{ my: 2 }}>
          <HallsSelect onSelect={() => setActiveElement(null)} fullWidth />
        </Card>
      )}
      {halls.length > 0 && (
        <Button
          sx={{ mb: 2 }}
          startIcon={<Icon type='edit' color='white' />}
          fullWidth
          size='large'
          onClick={() => openAddHallModal(activeHall)}
        >
          {t('editHall')}
        </Button>
      )}
      {halls.length > 0 && (
        <Button startIcon={<Icon type='plus' />} color='primary' fullWidth size='large' onClick={handleAddSpot}>
          {t('spotsForm.submit')}
        </Button>
      )}
      {activeElement && (
        <>
          <Card sx={{ mt: 2 }}>
            <Formik
              initialValues={{
                ...activeElement,
                variables: {
                  ...(activeElement.variables || {}),
                  border: activeElement.variables?.border || 'solid',
                  length: activeElement.variables?.length || 2,
                  width: activeElement.variables?.width || 2,
                  manualChairs: activeElement.variables?.manualChairs || false,
                  chairsTop: activeElement.variables?.chairsTop || 0,
                  chairsTopCouch: activeElement.variables?.chairsBottomCouch || false,
                  chairsBottom: activeElement.variables?.chairsBottom || 0,
                  chairsBottomCouch: activeElement.variables?.chairsBottomCouch || false,
                  chairsLeft: activeElement.variables?.chairsLeft || 0,
                  chairsLeftCouch: activeElement.variables?.chairsLeftCouch || false,
                  chairsRight: activeElement.variables?.chairsRight || 0,
                  chairsRightCouch: activeElement.variables?.chairsRightCouch || false,
                  angle: activeElement.variables?.angle || 0,
                  seats: activeElement.variables?.seats || 1,
                },
              }}
              onSubmit={handleSave}
              validationSchema={spotSchema}
              enableReinitialize={true}
            >
              {(formikProps) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xssm={6} md={12}>
                      <FormikTextInput
                        fullWidth
                        sx={{ my: 1 }}
                        label={t('spotsForm.fields.label')}
                        name='label'
                      />
                    </Grid>
                    <Grid item xs={12} xssm={6} md={12}>
                      <FormikTextInput
                        fullWidth
                        sx={{ my: 1 }}
                        label={t('spotsForm.fields.seats')}
                        name='seats'
                      />
                    </Grid>
                    <Grid item xs={12} xssm={6} md={12}>
                      <FormikTextInput
                        fullWidth
                        sx={{ my: 1 }}
                        label={t('spotsForm.fields.minSeats')}
                        name='minSeats'
                      />
                    </Grid>
                    {activePlace?.depositsActive && deposits.length > 0 && (
                      <Grid item xs={12} xssm={6} md={12}>
                        <FormikSelect
                          fullWidth
                          name='depositId'
                          placeholder={t('spotsForm.fields.depositCategory')}
                          label={t('spotsForm.fields.depositCategory')}
                          labelPath='label'
                          valuePath='id'
                        >
                          {deposits.map((d) => <MenuItem key={d.id} value={d.id}>{d.label}</MenuItem>)}
                        </FormikSelect>
                      </Grid>
                    )}
                    <Grid item xs={12} xssm={6} md={12}>
                      <FormikRadioGroup name='type'>
                        <FormControlLabel value='RECT' control={<Radio />} label={t('spotsForm.types.RECT')} />
                        <FormControlLabel value='ELLIPSE' control={<Radio />} label={t('spotsForm.types.ELLIPSE')} />
                        <FormControlLabel value='LOUNGE' control={<Radio />} label={t('spotsForm.types.LOUNGE')} />
                      </FormikRadioGroup>
                    </Grid>
                    <Grid item xs={12} xssm={6} md={12}>
                      <FormikTextInput
                        fullWidth
                        sx={{ my: 1 }}
                        type='number'
                        label={t(formikProps.values.type === 'LOUNGE' ? 'spotsForm.fields.width' : 'spotsForm.fields.length')}
                        name='variables.length'
                        inputProps={{
                          min: 1,
                          step: 0.5,
                        }}
                      />
                    </Grid>
                    {formikProps.values.type === 'RECT' && (
                      <Grid item xs={12} xssm={6} md={12}>
                        <FormikTextInput
                          fullWidth
                          sx={{ my: 1 }}
                          type='number'
                          label={t('spotsForm.fields.width')}
                          name='variables.width'
                          inputProps={{
                            min: 1,
                            step: 0.5,
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} xssm={6} md={12}>
                      <FormikTextInput
                        fullWidth
                        sx={{ my: 1 }}
                        type='number'
                        label={t('spotsForm.fields.priority')}
                        name='priority'
                        inputProps={{
                          min: 1,
                        }}
                      />
                    </Grid>
                    {formikProps.values.type === 'LOUNGE' && (
                      <Grid item xs={12} xssm={6} md={12}>
                        <FormikTextInput
                          fullWidth
                          sx={{ my: 1 }}
                          type='number'
                          label={t('spotsForm.fields.angle')}
                          name='variables.angle'
                          inputProps={{
                            min: 0,
                            max: 360,
                            step: 45,
                          }}
                        />
                      </Grid>
                    )}
                    {formikProps.values.type === 'LOUNGE' && (
                      <Grid item xs={12} xssm={6} md={12}>
                        <FormikSelect
                          fullWidth
                          name='variables.seats'
                          items={[
                            { id: 1, name: t('spotsForm.fields.oneSeat') },
                            { id: 2, name: t('spotsForm.fields.twoSeat') },
                          ]}
                          label={t('spotsForm.fields.seats')}
                          labelPath='name'
                          valuePath='id'
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} xssm={6} md={12}>
                      <FormikSelect
                        fullWidth
                        name='variables.border'
                        items={borderOptions}
                        label={t('spotsForm.fields.border')}
                        labelPath='name'
                        valuePath='id'
                      />
                    </Grid>
                    {formikProps.values.type === 'RECT' && (
                      <Grid item xs={12} xssm={6} md={12}>
                        <FormikCheckbox name='variables.manualChairs' label={t('spotsForm.manualChairs')} />
                      </Grid>
                    )}
                    {formikProps.values.variables.manualChairs && formikProps.values.type === 'RECT' && (
                      <Grid item xs={12} xssm={6} md={12}>
                        <Box display='flex' gap={2} mb={2}>
                          <FormikTextInput
                            sx={{ width: 80 }}
                            type='number'
                            min={0}
                            name='variables.chairsTop'
                            label={t('spotsForm.top')}
                            disabled={formikProps.values.variables.chairsTopCouch}
                          />
                          <FormikCheckbox sx={{ flexShrink: 0 }} name='variables.chairsTopCouch'
                            label={t('spotsForm.couch')} />
                        </Box>
                        <Box display='flex' gap={2} mb={2}>
                          <FormikTextInput
                            sx={{ width: 80 }}
                            type='number'
                            min={0}
                            name='variables.chairsBottom'
                            label={t('spotsForm.bottom')}
                            disabled={formikProps.values.variables.chairsBottomCouch}
                          />
                          <FormikCheckbox name='variables.chairsBottomCouch' label={t('spotsForm.couch')} />
                        </Box>
                        <Box display='flex' gap={2} mb={2}>
                          <FormikTextInput
                            sx={{ width: 80 }}
                            type='number'
                            min={0}
                            name='variables.chairsLeft'
                            label={t('spotsForm.left')}
                            disabled={formikProps.values.variables.chairsLeftCouch}
                          />
                          <FormikCheckbox name='variables.chairsLeftCouch' label={t('spotsForm.couch')} />
                        </Box>
                        <Box display='flex' gap={2}>
                          <FormikTextInput
                            sx={{ width: 80 }}
                            type='number'
                            min={0}
                            name='variables.chairsRight'
                            label={t('spotsForm.right')}
                            disabled={formikProps.values.variables.chairsRightCouch}
                          />
                          <FormikCheckbox name='variables.chairsRightCouch' label={t('spotsForm.couch')} />
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12} xssm={6} md={12}>
                      <FormikCheckbox name='active' label={t('spotsForm.active')} />
                    </Grid>
                  </Grid>
                  <Button sx={{ mt: 1, mb: 2 }} type='submit' fullWidth>{t('save')}</Button>
                  <Button
                    fullWidth
                    startIcon={<Icon type='remove' />}
                    onClick={deleteSpot}
                    variant='outlined'
                    color='primary'
                  >
                    {t('spotsForm.delete')}
                  </Button>
                </Form>
              )}
            </Formik>
          </Card>
        </>
      )}
      <AddEditHallModal open={addHallModalOpen} onClose={closeAddHallModal} placeId={activePlace?.id} hall={editedHall} />
      <ConfirmModal
        open={deleteSpotModalOpen}
        onClose={closeDeleteSpotModal}
        confirm={confirmDeleteSpot}
        title={t('deletingSpot')}
        content={<Typography>{t('confirmDeleteSpot')}</Typography>}
      />
    </>
  );
};

export default memo(HallsSidebar);
