import React, { memo, useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import Modal from '@components/Modal/Modal';
import ModalActions from '@components/Modal/ModalActions';
import mova from 'mova';
import FormikTextInput from '@form/FormikTextInput';
import * as Yup from 'yup';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { reservationThunks } from 'state/ducks/reservation';
import { placeSelectors } from 'state/ducks/place';
import FormikDatepicker from '@form/FormikDatepicker';
import FormikTimepicker from '@form/FormikTimepicker';
import moment from 'moment';
import { nearest15Minutes } from "../../../utils/date";

const t = mova.ns('components.Halls');

const schema = Yup.object().shape({
  seats: Yup.number(() => t('errors.number')),
});

const WalkInModal = ({ open, onClose, table }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const place = useSelector(placeSelectors.getActivePlace());

  useEffect(() => {
    if (open) {
      formRef.current?.resetForm();
    }
  }, [open]);

  const onSubmit = values => {
    const { hallId, id } = table;
    const { seats } = values;
    dispatch(
      reservationThunks.addReservation({
        from: new Date(),
        to: values.to,
        placeId: place.id,
        status: 'INPROGRESS',
        hallId,
        spots: [{ id }],
        seats,
      }),
    );
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('newWalkIn')}
      actions={
        <ModalActions onClose={onClose} submitForm={() => onSubmit(formRef.current.values)} submitTitle={t('accept')} />
      }
    >
      <Formik
        innerRef={formRef}
        onSubmit={onSubmit}
        initialValues={{
          seats: 2,
          to: place?.reservationAllDay ?
            moment().set('hour', place.openHours[moment().isoWeekday() - 1].closedH).set('minute', place.openHours[moment().isoWeekday() - 1].closedM).toDate() :
            nearest15Minutes()
              .add(place?.reservationDuration || 120, 'minutes')
              .toDate(),
        }}
        validationSchema={schema}
        enableReinitialize
      >
        <Form>
          <Typography>{t('indicateSeats')}:</Typography>
          <FormikTextInput type='number' sx={{ my: 1 }} fullWidth name='seats' placeholder={t('seats')} />
          <Typography>{t('indicateFinish')}:</Typography>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <FormikDatepicker
                sx={{ my: 1 }}
                fullWidth
                name='to'
                datepickerProps={{ withPortal: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikTimepicker
                sx={{ my: 1 }}
                fullWidth
                name='to'
                datepickerProps={{ withPortal: true }}
              />
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  );
};

export default memo(WalkInModal);
