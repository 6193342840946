import React from 'react';
import theme from '../../theme';

export default function arrow({ color = theme.palette.common.white, revert = false }) {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      transform={revert ? 'scale(-1,1)' : ''}
    >
      <path
        d='M1.20431 9.13081H12.9105L7.79626 14.2451C7.38754 14.6538 7.38754 15.3245 7.79626 15.7332C8.20498 16.142 8.86522 16.142 9.27394 15.7332L16.1803 8.82689C16.589 8.41817 16.589 7.75793 16.1803 7.34921L9.28442 0.43238C9.08862 0.236139 8.8228 0.125854 8.54558 0.125854C8.26836 0.125854 8.00254 0.236139 7.80674 0.43238C7.39802 0.841102 7.39802 1.50134 7.80674 1.91007L12.9105 7.0348H1.20431C0.627913 7.0348 0.156311 7.50641 0.156311 8.08281C0.156311 8.65921 0.627913 9.13081 1.20431 9.13081Z'
        fill={color}
      />
    </svg>
  );
}
