import React, { memo, useState } from 'react';
import mova from 'mova';
import * as Yup from 'yup';
import { Form, withFormik } from 'formik';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@components/Card/Card';
import Button from '@components/Button/Button';
import FormikTimepicker from '@form/FormikTimepicker';
import { deNormalizeOpenHours, normalizeOpenHours } from 'utils/date';
import store from '@state/store';
import { placeThunks } from 'state/ducks/place';
import Grid from '@mui/material/Grid';
import FormikCheckbox from '@form/FormikCheckbox';
import Datepicker from "@components/Datepicker/Datepicker";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import Icon from "../../../../components/Icon/Icon";
import { useDispatch } from "react-redux";

const t = mova.ns('components.Settings');
const hoursT = mova.ns('components.Hours');

const schema = Yup.object().shape({
  monday: Yup.object().shape({
    from: Yup.date(),
    to: Yup.date(),
    inactive: Yup.boolean(),
  }),
  tuesday: Yup.object().shape({
    from: Yup.date(),
    to: Yup.date(),
    inactive: Yup.boolean(),
  }),
  wednesday: Yup.object().shape({
    from: Yup.date(),
    to: Yup.date(),
    inactive: Yup.boolean(),
  }),
  thursday: Yup.object().shape({
    from: Yup.date(),
    to: Yup.date(),
    inactive: Yup.boolean(),
  }),
  friday: Yup.object().shape({
    from: Yup.date(),
    to: Yup.date(),
    inactive: Yup.boolean(),
  }),
  saturday: Yup.object().shape({
    from: Yup.date(),
    to: Yup.date(),
    inactive: Yup.boolean(),
  }),
  sunday: Yup.object().shape({
    from: Yup.date(),
    to: Yup.date(),
    inactive: Yup.boolean(),
  }),
});

const Hours = ({ values, place, showToastr }) => {
  const [closedDay, setClosedDay] = useState(new Date());
  const [closedDays, setClosedDays] = useState(place.closedDays?.days || []);
  const dispatch = useDispatch();

  const datepickerProps = {
    onChange: setClosedDay,
    selected: closedDay,
  };

  const addClosedDay = () => {
    setClosedDays([...closedDays, closedDay]);
  }

  const removeClosedDay = (date) => {
    setClosedDays(closedDays.filter(d => d !== date));
  }

  const saveClosedDays = () => {
    dispatch(placeThunks.saveClosedDays({
      placeId: place.id,
      days: {
        days: closedDays,
      }
    }))
      .then(() => {
        showToastr(t('messages.saved'));
      });
  }

  return (
    <Box>
      <Card>
        <Form>
          <Grid alignItems='center' container spacing={2} maxWidth={700}>
            <Grid item xs={12} sm={3}>
              <Typography>{t('hourFields.mon')}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.monday.closed} name='monday.from' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.monday.closed} name='monday.to' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikCheckbox name='monday.closed' label={t('inactive')} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>{t('hourFields.tue')}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.tuesday.closed} name='tuesday.from' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.tuesday.closed} name='tuesday.to' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikCheckbox name='tuesday.closed' label={t('inactive')} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>{t('hourFields.wed')}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.wednesday.closed} name='wednesday.from' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.wednesday.closed} name='wednesday.to' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikCheckbox name='wednesday.closed' label={t('inactive')} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>{t('hourFields.thu')}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.thursday.closed} name='thursday.from' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.thursday.closed} name='thursday.to' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikCheckbox name='thursday.closed' label={t('inactive')} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>{t('hourFields.fri')}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.friday.closed} name='friday.from' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.friday.closed} name='friday.to' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikCheckbox name='friday.closed' label={t('inactive')} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>{t('hourFields.sat')}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.saturday.closed} name='saturday.from' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.saturday.closed} name='saturday.to' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikCheckbox name='saturday.closed' label={t('inactive')} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>{t('hourFields.sun')}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.sunday.closed} name='sunday.from' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikTimepicker disabled={values.sunday.closed} name='sunday.to' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormikCheckbox name='sunday.closed' label={t('inactive')} />
            </Grid>
          </Grid>
          <Box display='flex' justifyContent='center' mt={2}>
            <Button type='submit' size='large'>
              {t('save')}
            </Button>
          </Box>
        </Form>
      </Card>
      <Typography sx={{ my: 2 }} variant='h4'>{hoursT('notWorkingDays')}</Typography>
      <Card>
        <Typography>{hoursT('selectDate')}:</Typography>
        <Box my={2} display='flex' gap={2}>
          <Datepicker datepickerProps={datepickerProps} />
          <Button onClick={addClosedDay}>{hoursT('addDate')}</Button>
        </Box>
        {closedDays.length > 0 && (
          <>
            <Typography>{hoursT('dateList')}:</Typography>
            <Box mt={2}>
              {closedDays.map((date, index) => (
                <Box mb={1} display='flex' key={index} gap={2} alignItems='center'>
                  <Typography>{moment(date).format('DD.MM.yyyy')}</Typography>
                  <IconButton onClick={() => removeClosedDay(date)}>
                    <Icon type='remove' />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </>
        )}
        <Box display='flex' justifyContent='center' mt={2}>
          <Button size='large' onClick={saveClosedDays}>
            {t('save')}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ openHours, place }) => ({
      placeId: place.id,
      openHours,
      ...normalizeOpenHours(openHours),
    }),
    validationSchema: schema,
    handleSubmit: async (data, helpers) => {
      await store.dispatch(
        placeThunks.updateOpenHour({
          ...data,
          openHours: deNormalizeOpenHours(data),
        }),
      );
      helpers.props.showToastr(t('messages.saved'));
      helpers.setSubmitting(false);
    },
  })(Hours),
);
