import React from 'react';
import theme from '../../theme';

export default function table({ color = theme.palette.primary.main }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_605_3278)'>
        <path
          d='M12 7.07812C9.28608 7.07812 7.07812 9.28608 7.07812 12C7.07812 14.7139 9.28608 16.9219 12 16.9219C14.7139 16.9219 16.9219 14.7139 16.9219 12C16.9219 9.28608 14.7139 7.07812 12 7.07812ZM12 15.5156C10.0615 15.5156 8.48438 13.9385 8.48438 12C8.48438 10.0615 10.0615 8.48438 12 8.48438C13.9385 8.48438 15.5156 10.0615 15.5156 12C15.5156 13.9385 13.9385 15.5156 12 15.5156Z'
          fill={color}
        />
        <path
          d='M21.8906 9.1875H18.375V14.8125H21.8906C23.0537 14.8125 24 13.8662 24 12.7031V11.2969C24 10.1338 23.0537 9.1875 21.8906 9.1875ZM22.5938 12.7031C22.5938 13.0908 22.2783 13.4062 21.8906 13.4062H19.7812V10.5938H21.8906C22.2783 10.5938 22.5938 10.9092 22.5938 11.2969V12.7031Z'
          fill={color}
        />
        <path
          d='M2.10938 9.1875C0.946266 9.1875 0 10.1338 0 11.2969V12.7031C0 13.8662 0.946266 14.8125 2.10938 14.8125H5.625V9.1875H2.10938ZM4.21875 13.4062H2.10938C1.72167 13.4062 1.40625 13.0908 1.40625 12.7031V11.2969C1.40625 10.9092 1.72167 10.5938 2.10938 10.5938H4.21875V13.4062Z'
          fill={color}
        />
        <path
          d='M9.1875 18.375V21.8906C9.1875 23.0537 10.1338 24 11.2969 24H12.7031C13.8662 24 14.8125 23.0537 14.8125 21.8906V18.375H9.1875ZM13.4062 21.8906C13.4062 22.2783 13.0908 22.5938 12.7031 22.5938H11.2969C10.9092 22.5938 10.5938 22.2783 10.5938 21.8906V19.7812H13.4062V21.8906Z'
          fill={color}
        />
        <path
          d='M12.7031 0H11.2969C10.1338 0 9.1875 0.946266 9.1875 2.10938V5.625H14.8125V2.10938C14.8125 0.946266 13.8662 0 12.7031 0ZM13.4062 4.21875H10.5938V2.10938C10.5938 1.72167 10.9092 1.40625 11.2969 1.40625H12.7031C13.0908 1.40625 13.4062 1.72167 13.4062 2.10938V4.21875Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_605_3278'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>

  );
}
