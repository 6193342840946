import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataInitialState from 'state/utils/loadDataInitialState';
import jwtService from 'services/jwtService';
import loadDataExtraReducer from 'state/utils/loadDataExtraReducer';

const initialState = (userLoading = true) => ({
  profile: loadDataInitialState(null, userLoading),
  role: null,
  password: loadDataInitialState(null),
  recovery: loadDataInitialState(null),
  notification: loadDataInitialState(null),
  notices: loadDataInitialState([]),
  userInModal: null,
  search: loadDataInitialState([]),
});

const userSlice = createSlice({
  name: 'userSlice',
  initialState: initialState(),
  reducers: {
    setUserData: (state, action) => {
      let user = action.payload?.user;
      if (user) {
        user = jwtService.signInWithPhoneAndPassword(action);
      }
      state.role = user.role === 13 ? 'super' : 'user';
      state.profile.data = user;
    },
    logOutUser: () => initialState(false),
    setUserInModal: (state, action) => {
      state.userInModal = action.payload;
    },
  },
  extraReducers: {
    ...loadDataExtraReducer(thunks.getProfile, 'profile'),
    // ...loadDataExtraReducer(thunks.loginUser, 'profile'),
    ...loadDataExtraReducer(thunks.updateProfile, 'profile'),
    ...loadDataExtraReducer(thunks.updateProfileAvatar, 'profile'),
    ...loadDataExtraReducer(thunks.updateLang, 'profile', { pending: () => {} }),

    ...loadDataExtraReducer(thunks.updatePassword, 'password'),
    ...loadDataExtraReducer(thunks.updateRecovery, 'recovery'),

    ...loadDataExtraReducer(thunks.getNotification, 'notification'),
    ...loadDataExtraReducer(thunks.addNotification, 'notification'),
    ...loadDataExtraReducer(thunks.deleteNotification, 'notification'),

    ...loadDataExtraReducer(thunks.getNotices, 'notices'),
    ...loadDataExtraReducer(thunks.deleteNotices, 'notices'),

    ...loadDataExtraReducer(thunks.searchUsers, 'search'),
  },
});

export const { setUserData, logOutUser, setUserInModal } = userSlice.actions;
export default userSlice.reducer;
