import React, { memo, useCallback, useEffect } from 'react';
import mova from 'mova';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, withFormik } from 'formik';
import Typography from '@mui/material/Typography';
import FormikTextInput from '@form/FormikTextInput';
import Box from '@mui/material/Box';
import Card from '@components/Card/Card';
import Button from '@components/Button/Button';
import FormikMultiSelect from '@form/FormikMultiSelect';
import { dictionarySelectors } from 'state/ducks/dictionary';
import store from '@state/store';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import Grid from '@mui/material/Grid';
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import Icon from '@components/Icon/Icon';
import FormikCheckbox from '@form/FormikCheckbox';

const t = mova.ns('components.Settings');

const StyledGridItem = styled(Grid)(({ theme }) => ({
  position: 'relative',
  flexBasis: '33.333333%',
  maxWidth: '33.333333%',
  [theme.breakpoints.up('xssm')]: {
    flexBasis: '25%',
    maxWidth: '25%',
  },
  [theme.breakpoints.up('sm')]: {
    flexBasis: '16.666666%',
    maxWidth: '16.666666%',
  },
  [theme.breakpoints.up('smmd')]: {
    flexBasis: '14%',
    maxWidth: '14%',
  },
  [theme.breakpoints.up('md')]: {
    flexBasis: '16.666666%',
    maxWidth: '16.666666%',
  },
  [theme.breakpoints.up('xl')]: {
    flexBasis: '12.5%',
    maxWidth: '12.5%',
  },
}));

const StyledImageWrapper = styled('div')(({ theme, src }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadiusSmall,
  backgroundColor: theme.palette.typography.extraLight,
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  width: '100%',
  minHeight: theme.spacing(10),
}));

const StyledLabel = styled('label')(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSmall,
  width: '100%',
  cursor: 'pointer',
}));

const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: '60px',
  right: '0px',
}));

const schema = Yup.object().shape({
  uri: Yup.string()
    .test(
      'is-custom-uri',
      () => t('errors.url'),
      value => /^[a-z-0-9]+$/.test(value),
    )
    .required(() => t('errors.required')),
  cusines: Yup.array().required(() => t('errors.required')),
  mood: Yup.array().of(Yup.object()).required(() => t('errors.required')),
  payments: Yup.array().of(Yup.object()).required(() => t('errors.required')),
});

const Characteristics = () => {
  const cusines = useSelector(dictionarySelectors.getCusines());
  const moods = useSelector(dictionarySelectors.getMoods());
  const payments = useSelector(dictionarySelectors.getPayments());
  const paidServices = useSelector(placeSelectors.getPaidServices());
  const placeId = useSelector(placeSelectors.getActivePlace()).id;
  const dispatch = useDispatch();

  const handlePhotoUploadChange = useCallback(
    e => {
      const { files } = e.target;
      const formData = new FormData();
      for (let i = 0; i < files.length; i += 1) {
        formData.append('images', files[i]);
      }
      dispatch(placeThunks.addPaidServicesPhoto({ placeId, formData }));
    },
    [dispatch, placeId],
  );

  const handleDelete = (photo) => {
    dispatch(placeThunks.deletePaidServicesPhoto({ photo, placeId }));
  };

  useEffect(() => {
    dispatch(placeThunks.fetchPaidServices(placeId));
  }, [dispatch, placeId]);

  const galleryItems = (paidServices.images || []).map((image, index) => (
    <StyledGridItem item key={image}>
      <StyledImageWrapper src={paidServices.imagesUrl[index]} />
      <StyledIconButton onClick={() => handleDelete(image)}>
        <Icon color='white' type='remove' />
      </StyledIconButton>
    </StyledGridItem>
  ));

  const submitPaidServices = (values) => {
    dispatch(placeThunks.savePaidServices({ placeId, ...values }));
  }

  return (
    <Box>
      <Card>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography>{t('fields.link')}</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormikTextInput name='uri' fullWidth placeholder={t('fields.link')} disabled />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>{t('fields.cusines')}</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormikMultiSelect
                options={cusines}
                name='cusines'
                fullWidth
                placeholder={t('fields.cusines')}
                labelPath='tr'
                valuePath='id'
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>{t('fields.moods')}</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormikMultiSelect
                options={moods}
                name='mood'
                fullWidth
                placeholder={t('fields.moods')}
                labelPath='name'
                valuePath='id'
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography>{t('fields.payments')}</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <FormikMultiSelect
                options={payments}
                name='payments'
                fullWidth
                placeholder={t('fields.payments')}
                labelPath='name'
                valuePath='id'
              />
            </Grid>
          </Grid>
          <Box display='flex' justifyContent='center' mt={2}>
            <Button type='submit' size='large'>
              {t('save')}
            </Button>
          </Box>
        </Form>
      </Card>
      <Card sx={{ mt: 2 }}>
        <Typography sx={{ mb: 1 }} variant='h4'>{t('paidServices.title')}</Typography>
        <Formik
          initialValues={{
            id: paidServices.id,
            active: paidServices.active,
            label: paidServices.label || '',
          }}
          enableReinitialize={true}
          onSubmit={submitPaidServices}
        >
          <Box component={Form}>
            <Box display='flex' alignItems='center' gap={2}>
              <FormikCheckbox sx={{ my: 1 }} name='active' label={t('paidServices.active')} />
              <FormikTextInput name='label' placeholder={t('paidServices.label')} />
            </Box>
            <Box display='flex' justifyContent='center' mt={2}>
              <Button type='submit' size='large'>
                {t('save')}
              </Button>
            </Box>
          </Box>
        </Formik>
        <input
          accept='image/*'
          style={{ display: 'none' }}
          id='button-file'
          type='file'
          onChange={handlePhotoUploadChange}
          multiple
        />
        <Grid mt={1} container spacing={2}>
          {galleryItems}
          <StyledGridItem item>
            <StyledLabel htmlFor='button-file'>
              <StyledImageWrapper>
                <Icon type='chevronUp' />
              </StyledImageWrapper>
            </StyledLabel>
          </StyledGridItem>
        </Grid>
      </Card>
    </Box>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ place }) => ({
      id: place.id,
      uri: place.uri,
      cusines: place.cusines.map(c => ({ value: c.id, label: c.tr })),
      mood: place.mood.map(c => ({ value: c.id, label: c.name })),
      payments: place.payments.map(c => ({ value: c.id, label: c.name })),
    }),
    validationSchema: schema,
    handleSubmit: async (data, helpers) => {
      await store.dispatch(
        placeThunks.updatePlace({
          ...data,
          cusines: data.cusines.map(c => c.value),
          mood: data.mood.map(m => m.value),
          payments: data.payments.map(p => p.value),
        }),
      );
      helpers.props.showToastr(t('messages.saved'));
      helpers.setSubmitting(false);
    },
  })(Characteristics),
);
