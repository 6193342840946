import placeSelectors from 'state/ducks/place/selectors';

const getCurrentUser = () => state => state.user.profile.data;
const getSignInError = () => state => state.user.profile.error;
const isUserLoading = () => state => state.user.profile.isLoading;
const getUserRole = () => state => {
  if (state.user.role === 'super') {
    return state.user.role;
  }
  const activePlace = placeSelectors.getActivePlace()(state) || placeSelectors.getActivePlaceStorage();
  if (!state.user.profile.data?.permissions || !activePlace) {
    return null;
  }
  const permissions = state.user.profile.data.permissions[activePlace.id];
  return permissions && permissions[0];
};

const getNotices = () => state => state.user.notices.data;

const getNotification = () => state => state.user.notification.data;
const getNotificationLoading = () => state => state.user.notification.isLoading;
const getNotificationError = () => state => state.user.notification.error;

const getUserInModal = () => state => state.user.userInModal;

const getSearchUsers = () => state => state.user.search.data;

export default {
  getCurrentUser,
  getSignInError,
  isUserLoading,
  getUserRole,
  getNotices,

  getNotification,
  getNotificationLoading,
  getNotificationError,

  getUserInModal,

  getSearchUsers,
};
