import React, { memo } from 'react';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 36,
  height: 36,
  color: 'white',
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  }
}));

const SelectQuantity = ({ selected, setSelected }) => {
  if (!selected) {
    return <StyledIconButton onClick={() => setSelected(1)}>+</StyledIconButton>
  }

  return (
    <Box display='flex' alignItems='center' gap={1}>
      <StyledIconButton onClick={() => setSelected(selected - 1)}>-</StyledIconButton>
      <Typography variant='h4' sx={{ width: 20 }} align='center'>{selected}</Typography>
      <StyledIconButton onClick={() => setSelected(selected + 1)}>+</StyledIconButton>
    </Box>
  );
};

export default memo(SelectQuantity);
