import config from 'config';
import { createApiClient } from './apiClient';

export const v1 = createApiClient({
  baseURL: config.apiRoot,
});

export default {
  v1,
};
