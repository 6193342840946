import React from 'react';
import theme from '../../theme';

export default function rotate({ color = theme.palette.primary.main, revert = false }) {
  return (
    <svg
      viewBox='0 0 492 492'
      width='14'
      height='14'
      style={{enableBackground: 'new 0 0 492 492'}}
      transform={revert ? 'rotate(-180)' : 'scale(-1,1) rotate(-180)'}
    >
      <path
        d='M484.004,292.48c-5.063-5.086-11.821-8.025-18.947-8.068H330.468c-14.824,0-26.676,12.3-26.676,27.12v22.78
				c0,7.156,2.7,13.9,7.788,18.992c5.088,5.092,11.784,7.896,18.94,7.896l39.052,0.008c-32.06,34.332-76.68,53.864-123.672,53.864
				c-93.22,0-169.072-75.844-169.072-169.072s75.84-169.072,169.064-169.072c69.796,0,133.336,43.82,158.108,109.04
				c5.632,14.816,20.068,24.776,35.916,24.776c4.66,0,9.248-0.848,13.632-2.52c19.8-7.516,29.784-29.748,22.26-49.544
				C439.772,63.84,347.376,0.112,245.888,0.112C110.308,0.112,0,110.416,0,246s110.236,245.888,245.816,245.888
				c62.584,0,123.272-24.632,169.364-67.872v21.788c0,14.824,12.208,26.812,27.032,26.812h22.78
				c14.824,0,27.008-11.988,27.008-26.812v-134.18C492,304.444,489.116,297.564,484.004,292.48z'
        fill={color}
      />
    </svg>
  );
}
