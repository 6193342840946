import React from 'react';

export default function vegetarian({ size = 24 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M10.0004 0.704951L9.9404 0.0749512L9.3404 0.274951C8.3121 0.632223 7.21403 0.74186 6.1354 0.594951C5.00197 0.419057 3.84186 0.57536 2.7954 1.04495C2.24064 1.28117 1.75377 1.65241 1.37913 2.12486C1.00449 2.5973 0.753979 3.15596 0.650396 3.74995C0.516086 4.5173 0.583277 5.30636 0.845396 6.03995C1.01417 5.659 1.2114 5.29129 1.4354 4.93995C1.9435 4.19237 2.64754 3.59904 3.4704 3.22495C4.26312 2.86301 5.12959 2.69177 6.0004 2.72495C5.17141 2.8517 4.3779 3.14969 3.6704 3.59995C3.32913 3.81438 3.01661 4.07145 2.7404 4.36495C2.47623 4.65831 2.24331 4.97837 2.0454 5.31995C1.67366 6.03396 1.40915 6.79884 1.2604 7.58995C1.10005 8.38362 1.01302 9.19035 1.0004 9.99995H2.0004C2.0474 9.27387 2.14598 8.55205 2.2954 7.83995C2.98911 8.21455 3.76707 8.4056 4.5554 8.39495C5.29063 8.39445 6.01831 8.24653 6.6954 7.95995C10.5004 6.33495 10.0004 0.929951 10.0004 0.704951Z' fill='url(#paint0_linear_5_828)' />
      <defs>
        <linearGradient id='paint0_linear_5_828' x1='7.23919' y1='0.019951' x2='2.48928' y2='9.89637' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#9ED84D' />
          <stop offset='1' stopColor='#65AC0B' />
        </linearGradient>
      </defs>
    </svg>
  );
}
