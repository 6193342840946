import React from 'react';
import theme from '../../theme';

export default function couch({ color = theme.palette.primary.main, width = 14, height = null }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 34 33' fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='none'
    >
      <g clipPath='url(#clip0_526_2921)'>
        <path d='M31.3128 16.3933L31.2099 31.7271L2.87583 31.5368L2.97882 16.203L31.3128 16.3933Z' fill='#EBDDC3'
              stroke={color} strokeWidth='2' />
        <path d='M33.6975 17.5373V8.92868V0.203613L0.695312 0.0581055L0.696584 8.66674L0.697769 17.3918L33.6975 17.5373Z'
              fill={color} />
        <mask id='mask0_526_2921' style={{ maskType: 'luminance', maskUnits: 'userSpaceOnUse' }} x='0' y='0' width={width}
              height={height}>
          <path d='M32.2031 32.7333L32.3195 15.3996L1.98556 15.1958L1.86914 32.5296L32.2031 32.7333Z' fill={color} />
          <path d='M33.6962 17.3918V8.92869V0.0581055H0.694545L0.695233 8.66675L0.578125 17.3918H33.6962Z' fill={color} />
        </mask>
        <g mask='url(#mask0_526_2921)'>
          <path
            d='M32.2014 32.7335L32.1869 34.9002L34.3536 34.9148L34.3681 32.748L32.2014 32.7335ZM32.3178 15.3998L34.4845 15.4143L34.4991 13.2476L32.3324 13.2331L32.3178 15.3998ZM1.98382 15.196L1.99837 13.0293L-0.168336 13.0148L-0.182887 15.1815L1.98382 15.196ZM1.8674 32.5298L-0.299306 32.5152L-0.313857 34.6819L1.85285 34.6965L1.8674 32.5298ZM34.3681 32.748L34.4845 15.4143L30.1511 15.3852L30.0347 32.7189L34.3681 32.748ZM32.3324 13.2331L1.99837 13.0293L1.96927 17.3628L32.3033 17.5665L32.3324 13.2331ZM-0.182887 15.1815L-0.299306 32.5152L4.03412 32.5443L4.15053 15.2106L-0.182887 15.1815ZM1.85285 34.6965L32.1869 34.9002L32.216 30.5668L1.88195 30.363L1.85285 34.6965ZM27.9555 19.7041C33.9388 19.7442 38.8217 14.9265 38.8619 8.94322L34.5284 8.91411C34.5043 12.5041 31.5746 15.3947 27.9846 15.3706L27.9555 19.7041ZM38.8619 8.94322C38.9021 2.96 34.0843 -1.92293 28.1011 -1.96312L28.072 2.37031C31.6619 2.39442 34.5526 5.32418 34.5284 8.91411L38.8619 8.94322ZM28.1011 -1.96312L6.43393 -2.10864L6.40482 2.22479L28.072 2.37031L28.1011 -1.96312ZM6.43393 -2.10864C0.450713 -2.14882 -4.43223 2.66895 -4.47241 8.65217L-0.138987 8.68128C-0.114867 5.09135 2.81489 2.20068 6.40482 2.22479L6.43393 -2.10864ZM-4.47241 8.65217C-4.5126 14.6354 0.305183 19.5184 6.2884 19.5585L6.3175 15.2251C2.72757 15.201 -0.163097 12.2712 -0.138987 8.68128L-4.47241 8.65217ZM6.2884 19.5585L27.9555 19.7041L27.9846 15.3706L6.3175 15.2251L6.2884 19.5585Z'
            fill={color} />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_526_2921'>
          <rect width='34' height='33' fill='white' />
        </clipPath>
      </defs>
      ;
    </svg>

  )
    ;
}
