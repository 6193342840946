import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import mova from 'mova';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { reservationSelectors, reservationThunks } from 'state/ducks/reservation';
import { placeSelectors } from 'state/ducks/place';
import ReservationCard from '@components/ReservationCard/ReservationCard';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Icon from '@components/Icon/Icon';
import { Checkbox, useMediaQuery, useTheme } from '@mui/material';
import Pagination from '@components/Pagination/Pagination';
import Button from '@components/Button/Button';
import styled from '@emotion/styled';
import ButtonGroup from '@mui/material/ButtonGroup';
import Slider from '@components/Slider/Slider';
import './Reservations.scss';
import moment from 'moment';
import FormControlLabel from "@mui/material/FormControlLabel";
import { hallSelectors } from "../../../state/ducks/hall";

const t = mova.ns('components.Reservations');

const StyledWarning = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
}));

const reservationsTotal = (reservations, status) => reservations.filter(r => r.status === status).length;

const HallReservations = ({ width, maxWidth, horizontal, draggable, date, hidden, showPast, showFilters, ...rest }) => {
  const [showOnlyUnconfirmed, setShowOnlyUnconfirmed] = useState(false);
  const [onlyThisHall, setOnlyThisHall] = useState(false);
  const [page, setPage] = useState(0);

  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const reservations = useSelector(reservationSelectors.getHallReservations());
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const activeHall = useSelector(hallSelectors.getActiveHall());
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('md'));
  const dragGhostImage = useRef();

  const filter = queryString.parse(location.search).filter || 'ACCEPTED';
  const q = queryString.parse(location.search).q;
  const todayReservations = reservations
    .filter(r =>
      r.status === 'WAITLIST' || r.status === 'REQUESTED' || moment(r.from).format('L') === moment(date).format('L')
    );

  const filteredReservations = todayReservations
    .filter(r => r.status === filter)
    .filter(r => !q || r.variables.user?.displayedName.toLowerCase().includes(q.toLowerCase()) || r.user?.displayedName.toLowerCase().includes(q.toLowerCase()) || r.user?.phone.toLowerCase().includes(q.toLowerCase()))
    .filter(r => filter !== 'ACCEPTED' || !showOnlyUnconfirmed || !r.confirmed)
    .filter(r => !['ACCEPTED', 'INPROGRESS'].includes(filter) || !onlyThisHall || r.hallId === activeHall?.id)
    .sort((r1, r2) => filter === 'WAITLIST'
      ? moment(r1.createdAt).diff(moment(r2.createdAt))
      : moment(r1.from).diff(moment(r2.from))
    );

  useEffect(() => {
    setShowOnlyUnconfirmed(false);
    setOnlyThisHall(false);
    resetPagination();
  }, [activePlace?.id]);

  useEffect(() => {
    resetPagination();
  }, [q, onlyThisHall]);

  const loadReservations = () => {
    resetPagination();
    dispatch(reservationThunks.fetchHallReservations({ placeId: activePlace.id, date, showPast }));
  }

  useEffect(() => {
    if (date) {
      loadReservations();
    }
  }, [dispatch, activePlace, date, showPast]); // eslint-disable-line

  useEffect(() => {
    const intervalId = setInterval(loadReservations, 300000);

    return () => {
      clearInterval(intervalId);
    }
  }, [activePlace, date, showPast]); // eslint-disable-line

  const resetPagination = () => {
    setPage(0);
  }

  const handleFilterChange = (filter) => {
    resetPagination();
    const urlParams = queryString.parse(location.search);
    urlParams.filter = filter;
    history.push(`${location.pathname}?${queryString.stringify(urlParams)}`);
  };

  const CardsWrapper = horizontal ? Slider : Grid;

  const dragStarted = useCallback((e, reservation) => {
    const dragImage = document.createElement('div');
    dragImage.classList.add('reservations__drag-image');
    dragImage.textContent = moment(reservation.from).format('HH:mm');

    document.body.appendChild(dragImage);
    e.dataTransfer.setDragImage(dragImage, 0, 0);
    e.dataTransfer.setData('reservation', JSON.stringify(reservation));
    dragGhostImage.current = dragImage;
  }, []);

  const dragStopped = useCallback(() => {
    if (dragGhostImage.current) {
      document.body.removeChild(dragGhostImage.current);
      dragGhostImage.current = null;
    }
  }, [dragGhostImage]);

  const reservationCards = filteredReservations
    .slice(page * 12, page * 12 + 12)
    .map(r => (
      <Grid item key={r.id} sx={{ mr: horizontal ? 2 : 0 }}>
        <ReservationCard
          reservation={r}
          draggable={draggable}
          onDragStart={e => dragStarted(e, r)}
          onDragEnd={dragStopped}
          smallView
        />
      </Grid>
    ));

  const seeAllButton = (
    <Box
      component={Link}
      to={`/reservations?filter=${filter}`}
      display='flex'
      alignItems='center'
      sx={{ textDecoration: 'none' }}
    >
      <Typography color='primary' variant='h4'>
        {t('seeAll')}
      </Typography>
      <Icon sx={{ ml: 1 }} type='arrow' color={theme.palette.primary.main} />
    </Box>
  );

  const confirmationEnabled = showFilters && activePlace.billTypeExtra?.includes('highload') && activePlace.showReservationConfirmed && filter === 'ACCEPTED';
  const hallFilterEnabled = showFilters && activePlace.billTypeExtra?.includes('highload') && activePlace.showHallFilter && ['ACCEPTED', 'INPROGRESS'].includes(filter);
  const showWaitList = activePlace.billTypeExtra?.includes('highload') && activePlace.showWaitList;

  if (hidden) {
    return null;
  }

  return (
    <Box width={width} maxWidth={maxWidth} {...rest}>
      <Box mb={2} display='flex' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4'>{t('title')}</Typography>
        {seeAllButton}
      </Box>
      <Box
        mb={2}
        display='flex'
        justifyContent='flex-start'
        flexDirection='column'
      >
        <ButtonGroup variant='outlined' fullWidth={mobileView && !horizontal}>
          {showWaitList && (
            <Button
              sx={{ whiteSpace: 'nowrap', flexShrink: 10 }}
              onClick={() => handleFilterChange('WAITLIST')}
              variant={filter === 'WAITLIST' ? 'contained' : 'outlined'}
              color={filter === 'WAITLIST' ? 'secondary' : 'primary'}
            >
              <Icon type='clock'
                color={filter === 'WAITLIST' ? undefined : theme.palette.primary.main} />&nbsp;({reservationsTotal(todayReservations, 'WAITLIST')})
            </Button>
          )}
          <Button
            sx={{ whiteSpace: 'nowrap', flexShrink: 9 }}
            onClick={() => handleFilterChange('REQUESTED')}
            variant={filter === 'REQUESTED' ? 'contained' : 'outlined'}
            color={filter === 'REQUESTED' ? 'secondary' : 'primary'}
          >
            {`${t('filter.requested')} (${reservationsTotal(todayReservations, 'REQUESTED')})`}
          </Button>
          <Button
            sx={{ whiteSpace: 'nowrap', flexShrink: 8 }}
            onClick={() => handleFilterChange('ACCEPTED')}
            variant={filter === 'ACCEPTED' ? 'contained' : 'outlined'}
            color={filter === 'ACCEPTED' ? 'secondary' : 'primary'}
          >
            {`${t('filter.accepted')} (${reservationsTotal(todayReservations, 'ACCEPTED')})`}
          </Button>
          <Button
            sx={{ whiteSpace: 'nowrap', flexShrink: 10 }}
            onClick={() => handleFilterChange('INPROGRESS')}
            variant={filter === 'INPROGRESS' ? 'contained' : 'outlined'}
            color={filter === 'INPROGRESS' ? 'secondary' : 'primary'}
          >
            <Icon type='reservationChair'
              color={filter === 'INPROGRESS' ? 'white' : undefined} />&nbsp;({reservationsTotal(todayReservations, 'INPROGRESS')})
          </Button>
        </ButtonGroup>
      </Box>
      {activePlace.disabledReservation && (
        <StyledWarning display='flex' alignItems='center' mb={2}>
          <Icon type='warning' color={theme.palette.secondary.main} mr={2} />
          <Typography variant='body1'>{t('dayClosedWarning')}</Typography>
        </StyledWarning>
      )}
      {confirmationEnabled && (
        <FormControlLabel
          sx={{ mb: 1 }}
          label={t('showOnlyUnconfirmed')}
          control={
            <Checkbox
              onChange={() => setShowOnlyUnconfirmed(!showOnlyUnconfirmed)}
              color='error'
              checked={showOnlyUnconfirmed}
            />
          }
        />
      )}
      {hallFilterEnabled && (
        <FormControlLabel
          sx={{ mb: 1 }}
          label={t('onlyThisHall')}
          control={
            <Checkbox
              onChange={() => setOnlyThisHall(!onlyThisHall)}
              color='error'
              checked={onlyThisHall}
            />
          }
        />
      )}
      <CardsWrapper container spacing={2}>
        {reservationCards}
      </CardsWrapper>
      <Pagination
        mt={4}
        page={page}
        perPage={12}
        items={filteredReservations.length}
        justifyContent='center'
        onChangePage={setPage}
      />
    </Box>
  );
};

export default memo(HallReservations);
