import React, { Fragment, PureComponent } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import { Button, Grid, IconButton, Slider, Typography } from '@mui/material';

import { bind } from 'utils/decorators';
import Icon from '@components/Icon/Icon';
import Avatar from '@components/Avatar/Avatar';
import Dropzone from '@components/Dropzone/Dropzone';

const styleColor = [255, 255, 255, 0];
const defaultState = {
  showAvatarEditor: false,
  imageFile: null,
  scale: 1,
  rotate: 0,
};
export const isImageType = type => type && type.startsWith('image/');

class AvatarEditor extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    image: PropTypes.string,
    initials: PropTypes.string,
  };

  static defaultProps = {
    WrapperProps: {},
    AvatarProps: {},
    label: null,
    name: null,
    disabled: null,
    image: null,
    initials: null,
  };

  state = defaultState;
  editorRef = React.createRef();

  @bind
  handleDrop(acceptFiles, rejectFiles) {
    const files = [...acceptFiles, ...rejectFiles].filter(file => isImageType(file.type));
    const imageFile = files[0];
    if (imageFile) {
      this.setState({
        showAvatarEditor: true,
        imageFile,
      });
    }
  }

  @bind
  cancelUpload() {
    this.setState(defaultState);
  }

  @bind
  handleUpload() {
    if (this.editorRef.current) {
      const { name, onChange } = this.props;
      const canvas = this.editorRef.current.getImage();
      const context = canvas.getContext('2d');
      context.globalCompositeOperation = 'destination-over';
      context.fillStyle = '#fff';
      context.fillRect(0, 0, canvas.width, canvas.height);
      if (canvas) {
        canvas.toBlob(
          blob => {
            const image = new File([blob], `${name || 'profile'}.jpg`, {
              type: 'image/jpeg',
            });
            this.setState(defaultState, () => {
              if (onChange) {
                onChange({ target: { value: image, name } });
              }
            });
          },
          'image/jpeg',
          1,
        );
      }
    }
  }

  @bind
  handleScaleChange(event, value) {
    this.setState({ scale: value > 4 ? 4 : value });
  }

  @bind
  handleRotateLeft() {
    this.setState(({ rotate }) => ({
      rotate: parseFloat(rotate - 90),
    }));
  }

  @bind
  handleRotateRight() {
    this.setState(({ rotate }) => ({
      rotate: parseFloat(rotate + 90),
    }));
  }

  @bind
  dropZoneClick(event) {
    const { disabled } = this.props;
    if (disabled) {
      event.stopPropagation();
    }
  }

  render() {
    const { image, initials, label, disabled, AvatarProps, WrapperProps } = this.props;
    const { showAvatarEditor, imageFile, scale, rotate } = this.state;
    const classes = {};
    return (
      <Grid container direction='column' alignItems='center' {...WrapperProps} sx={{ cursor: 'pointer' }}>
        {
          !showAvatarEditor ? (
            <Dropzone accept='image/*' onDrop={this.handleDrop} filesLimit={1}>
              <Grid
                className={!disabled ? classes.avatarWrapper : ''}
                onClick={this.dropZoneClick}
                item
                container
                justify='space-between'
                alignItems='center'
              >
                <Avatar initials={initials} src={image} sx={{ mx: 'auto' }} size={96} {...AvatarProps} />
                {label && <Typography className={classes.label}>{label}</Typography>}
              </Grid>
            </Dropzone>
          ) : (
            <Fragment>
              <ReactAvatarEditor
                image={imageFile}
                scale={scale}
                rotate={rotate}
                ref={this.editorRef}
                border={1}
                borderRadius={5}
                color={styleColor}
              />
              <Grid container direction='column' className={classes.wrapper}>
                <Grid item container alignItems='center'>
                  <Typography>Zoom:</Typography>
                  <Slider
                    max={4}
                    min={1}
                    step={0.01}
                    value={scale}
                    onChange={this.handleScaleChange}
                    className={classes.slider}
                  />
                </Grid>
                <Grid item container justify='space-between' alignItems='center'>
                  <Typography>Rotate:</Typography>
                  <IconButton onClick={this.handleRotateLeft}>
                    <Icon color='white' type='left' />
                  </IconButton>
                  <IconButton onClick={this.handleRotateRight}>
                    <Icon color='white' type='right' />
                  </IconButton>
                </Grid>
                <Grid container justifyContent='center' alignItems='center'>
                  <Button variant='text' onClick={this.cancelUpload}>
                    Cancel
                  </Button>
                  <Button variant='contained' color='primary' onClick={this.handleUpload}>
                    Crop and Upload
                  </Button>
                </Grid>
              </Grid>
            </Fragment>
          )
        }
      </Grid>
    );
  }
}

export default AvatarEditor;
