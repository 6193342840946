import React from 'react';
import theme from '../../theme';

export default function leaf({ color = theme.palette.common.white }) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.9725 2.80093L13.8962 2L13.1334 2.25426C11.8261 2.70847 10.4301 2.84785 9.05884 2.66108C7.61789 2.43747 6.14302 2.63618 4.81264 3.23318C4.10737 3.53349 3.48841 4.00545 3.01213 4.60607C2.53584 5.2067 2.21736 5.91693 2.08567 6.67208C1.91492 7.64762 2.00034 8.65077 2.33358 9.58339C2.54815 9.09908 2.79888 8.63161 3.08365 8.18495C3.72961 7.23454 4.62467 6.48023 5.67078 6.00464C6.67858 5.54451 7.78014 5.32681 8.88721 5.36898C7.8333 5.53012 6.8245 5.90897 5.92504 6.48139C5.49119 6.75399 5.09388 7.08081 4.74272 7.45394C4.40688 7.8269 4.11076 8.23379 3.85916 8.66805C3.38656 9.57577 3.05029 10.5482 2.86117 11.5539C2.65733 12.5629 2.54668 13.5885 2.53063 14.6178H3.80195C3.8617 13.6947 3.98703 12.7771 4.17699 11.8718C5.05892 12.348 6.04795 12.5909 7.05016 12.5773C7.98488 12.5767 8.90998 12.3887 9.77077 12.0243C14.6081 9.95843 13.9725 3.08697 13.9725 2.80093Z'
        fill={color}
      />
    </svg>
  );
}
