import DOMPurify from 'dompurify';

export const linkify = (inputText) => {
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  const commentWithLink = DOMPurify
    .sanitize(inputText)
    .replace(urlRegex, function (url) {
      let hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
    });
  return commentWithLink;
}
