import React, { memo, useEffect } from 'react';
import { Form, withFormik } from 'formik';
import Modal from '@components/Modal/Modal';
import ModalActions from '@components/Modal/ModalActions';
import mova from 'mova';
import * as Yup from 'yup';
import { Box, Chip, MenuItem, Typography, useTheme } from '@mui/material';
import FormikSelect from '@form/FormikSelect';
import { useDispatch, useSelector } from 'react-redux';
import { hallSelectors } from 'state/ducks/hall';
import Button from '../../Button/Button';
import { reservationThunks } from '@state/ducks/reservation';
import { toastrActions } from '@state/ducks/toastr';
import { getReservationColor, sortSpots } from "utils/utils";
import { placeSelectors } from "../../../../state/ducks/place";
import DepositManagement from "./DepositManagement";

const t = mova.ns('components.Reservations');
const formT = mova.ns('components.Halls.reservationForm');

const schema = required =>
  Yup.object().shape({
    hallId: required
      ? Yup.number(() => t('errors.number')).required(() => t('errors.required'))
      : Yup.number(() => t('errors.number')),
    spots: required ? Yup.array().min(1, () => t('errors.required')) : Yup.array(),
    event: Yup.string(),
  });

const AcceptReservationModal = (
  { open, onClose, activePlace, values, resetForm, handleSubmit, reservation, setFieldValue }
) => {
  const theme = useTheme();
  const halls = useSelector(hallSelectors.getHalls());
  const allSpots = halls.map(h => h.spots).reduce((arr, val) => [...arr, ...val], []);
  const spots = (halls.find(h => h.id === values.hallId)?.spots || [])
    .filter(s => s.active)
    .slice()
    .sort(sortSpots);
  const managingReservations = ['enterprise', 'pro'].includes(activePlace.billType);
  const dispatch = useDispatch();
  const customTags = useSelector(placeSelectors.getCustomTags());
  const deposits = useSelector(placeSelectors.getDeposits());

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open, resetForm]);

  const pickFreeSpot = () => {
    dispatch(
      reservationThunks.fetchFreeSpot({ from: reservation.from, to: reservation.to, seats: reservation.seats }),
    ).then(res => {
      const spot = res.payload;
      if (spot?.id) {
        setFieldValue('hallId', spot.hallId);
        setFieldValue('spots', [spot.id]);
      } else {
        dispatch(toastrActions.showToastr({ variant: 'error', message: t('spotNotFound') }));
      }
    });
  };

  const eventOptions = ['other', 'business', 'date', 'family', 'friends', 'birthday', 'football'].map((event, index) => (
    <MenuItem
      key={event}
      value={event}
      sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
    >
      <Box sx={{ backgroundColor: theme.palette.reservations[index] + ' !important', height: 40, width: 40 }} />
      {customTags[event] || formT(`fields.reservation.events.${event}`)}
    </MenuItem>
  ));

  const removeSpot = id => {
    setFieldValue(
      'spots',
      values.spots.filter(s => s !== id),
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('acceptingReservation')}
      actions={<ModalActions onClose={onClose} submitForm={handleSubmit} submitTitle={t('accept')} />}
    >
      <Form>
        {activePlace.depositsActive && deposits.length > 0 &&
          <DepositManagement values={values} setFieldValue={setFieldValue} />
        }
        {managingReservations && (
          <Box display='flex' flexDirection='column' gap={2}>
            <Typography>{t('indicateHallAndTable')}:</Typography>
            <FormikSelect
              fullWidth
              name='hallId'
              items={halls}
              label={t('hall')}
              labelPath='name'
              valuePath='id'
            />
            <FormikSelect
              multiple
              items={spots}
              name='spots'
              fullWidth
              label={t('table')}
              labelPath='label'
              valuePath='id'
              disabled={!values.hallId}
              renderValue={selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map(val => (
                    <Chip
                      size='small'
                      onDelete={() => removeSpot(val)}
                      key={val}
                      label={allSpots.find(s => s.id === val)?.label}
                      onMouseDown={e => {
                        e.stopPropagation();
                      }}
                    />
                  ))}
                </Box>
              )}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
            />
            {activePlace?.showAutomaticReservationButton && (
              <Box>
                <Button onClick={pickFreeSpot} color='primary'>{t('pickSpot')}</Button>
              </Box>
            )}
            <FormikSelect
              fullWidth
              name='event'
              label={formT('fields.reservation.event')}
              renderValue={(selected) => (
                <Box display='flex' alignItems='center' gap={1}>
                  <Box backgroundColor={getReservationColor(selected)} width={24} height={24} />
                  <Typography>{customTags[selected] || formT(`fields.reservation.events.${selected}`)}</Typography>
                </Box>
              )}
            >
              {eventOptions}
            </FormikSelect>
          </Box>
        )}
        {!managingReservations && <Typography>{t('confirmAcceptReservation')}</Typography>}
      </Form>
    </Modal>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ reservation }) => ({
      hallId: reservation.hallId || '',
      spots: (reservation.spots || []).map(s => s.id) || [],
      event: reservation.event || 'other',
      from: reservation.from,
      depositOrder: reservation.bill?.data?.order || [],
      depositUpdated: false,
    }),
    validationSchema: props => schema(['enterprise', 'pro'].includes(props.activePlace.billType)),
    handleSubmit: async (data, helpers) => {
      helpers.props.confirm({
        ...data,
        depositOrder: data.depositUpdated ? data.depositOrder : undefined,
        spots: data.spots.map(s => ({ id: s })),
      });
      helpers.props.onClose();
    },
    enableReinitialize: true,
  })(AcceptReservationModal),
);
