import config from './currencyConfig';

export default function (amount, currencySymbol = 'UAH', decimalPlaces = 0) {
  if (isNaN(amount)) return amount;
  if (typeof amount === 'undefined' || amount === null) return '-';
  if (typeof amount === 'string') amount = parseFloat(amount);

  let value, res, resIndex, processStartIdx;

  // preallocate the resulting string array and account for separator members
  let sign = '';
  let separatorCnt = 0;
  const separator = config[currencySymbol].separator;
  const symbol = config[currencySymbol].symbol;

  amount = decimalPlaces > 0 ? amount.toFixed(decimalPlaces) : Math.round(amount);
  value = '' + amount;

  if (value[0] === '-') {
    value = value.substring(1);
    sign = '-';
  }

  if (decimalPlaces === 0) {
    res = new Array(Math.floor((value.length - 1) / 3) + value.length);
    resIndex = res.length - 1;
    processStartIdx = value.length - 1;
  } else {
    const dotIdx = value.lastIndexOf('.');
    let extension = 0;

    if (dotIdx >= 0) {
      extension = value.length - dotIdx;
    }

    res = new Array(Math.floor((value.length - 1) / 3) + value.length + extension);
    resIndex = res.length - 1;

    for (let i = value.length - 1; i > value.length - extension; i--) {
      res[resIndex--] = value[i];
    }

    if (dotIdx >= 0) {
      res[resIndex--] = config[currencySymbol].decSeparator;
    }
    processStartIdx = value.length - 1 - extension;
  }

  for (let i = processStartIdx; i >= 0; i--) {
    if (separatorCnt === 3) {
      res[resIndex--] = separator;
      separatorCnt = 0;
    }
    res[resIndex--] = value[i];
    separatorCnt++;
  }

  return config[currencySymbol].prefixed ? sign + symbol + res.join('') : sign + res.join('') + symbol;
}
