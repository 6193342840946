const getMenus = () => state => state.dish.menus.data;
const getMenusLoading = () => state => state.dish.menus.isLoading;
const getMenusError = () => state => state.dish.menus.error;

const getDishes = () => state => state.dish.dishes.data;
const getDishesLoading = () => state => state.dish.dishes.isLoading;
const getDishesError = () => state => state.dish.dishes.error;

export default {
  getDishes,
  getDishesLoading,
  getDishesError,

  getMenus,
  getMenusLoading,
  getMenusError,
};
