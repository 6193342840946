import React, { memo } from 'react';
import ReservationCard from '../../ReservationCard/ReservationCard';
import Box from '@mui/material/Box';
import Modal from '../../Modal/Modal';
import Typography from '@mui/material/Typography';
import mova from 'mova';
import ModalActions from '../../Modal/ModalActions';

const t = mova.ns('components.ConflictingReservationsModal');

const ConflictingReservationsModal = (
  {
    onClose, conflicts = [], confirmReservation = () => {
  }, isSubmitting
  }
) => {
  const cards = conflicts.map(r => <ReservationCard key={r.id} reservation={r} width='100%' />);
  return (
    <Modal
      open
      onClose={onClose}
      title={t('title')}
      sx={{ zIndex: 1200 }}
      actions={
        <ModalActions onClose={onClose}
          submitForm={confirmReservation}
          submitTitle={t('confirm')}
          submitProps={{ disabled: isSubmitting }}
        />
      }
    >
      <Typography>
        {t('description')}
      </Typography>
      <Box display='flex' flexDirection='column' mt={2} gap={2}>
        {cards}
      </Box>
    </Modal>
  );
};

export default memo(ConflictingReservationsModal);
