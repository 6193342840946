import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import mova from 'mova';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { placeSelectors } from 'state/ducks/place';
import PlusButton from '@components/PlusButton/PlusButton';
import SearchInput from '@components/SearchInput/SearchInput';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Button from '@components/Button/Button';
import Icon from '@components/Icon/Icon';
import useModal from '@hooks/useModal/useModal';
import Pagination from '@components/Pagination/Pagination';
import Loader from '@components/Loader/Loader';
import Datepicker from "../../components/Datepicker/Datepicker";
import { certificateSelectors, certificateThunks } from "../../../state/ducks/certificate";
import Select from "../../form/Select";
import CertificateCard from "./components/CertificateCard";
import CertificatesTable from "./components/CertificatesTable";
import CertificateTableRow from "./components/CertificatesTableRow";
import AddCertificateModal from "./components/AddCertificateModal";
import CertificateInfoModal from "./components/CertificateInfoModal";
import {
  CATEGORY_CERTIFICATE,
  CATEGORY_SUBSCRIPTION,
  CERTIFICATE_STATUS_ACTIVE,
  CERTIFICATE_STATUS_DRAFT,
  CERTIFICATE_STATUS_EXPIRED,
  CERTIFICATE_STATUS_INACTIVE,
  CERTIFICATE_STATUS_USED
} from "utils/certificate";

const t = mova.ns('components.Certificates');

const Certificates = () => {
  const [addModalOpen, addCertificate, closeAddModal] = useModal();
  const [certificateModalOpen, viewCertificate, closeCertificateModal, viewedBilling] = useModal();
  const [date, setDate] = useState(null);
  const [type, setType] = useState('ALL');
  const [status, setStatus] = useState('ALL');

  const location = useLocation();
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('smmd'), { noSsr: true });
  const urlParams = queryString.parse(location.search);
  const [searchActive, setSearchActive] = useState(!mobileView || !!urlParams.q);
  const [activePage, setActivePage] = useState(0);

  const dispatch = useDispatch();
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const certificates = useSelector(certificateSelectors.getCertificates());
  const count = useSelector(certificateSelectors.getCertificatesCount());
  const loading = useSelector(certificateSelectors.getCertificatesLoading());

  const datepickerProps = {
    onChange: setDate,
    selected: date,
  };

  const reloadCertificates = useCallback(
    (extraData = {}) => {
      const data = {
        placeId: activePlace.id,
        page: activePage + 1,
        type: type === 'ALL' ? undefined : type,
        status: status === 'ALL' ? undefined : status,
        ...extraData,
      };
      const urlParams = queryString.parse(location.search);
      if (urlParams.q) {
        data.q = urlParams.q;
      }
      dispatch(certificateThunks.fetchCertificates(data));
    },
    [activePlace, activePage, location.search, type, status, dispatch],
  );

  useEffect(() => {
    setActivePage(0);
    reloadCertificates({
      page: 1,
    });
  }, [location.search, activePlace, type, status]); // eslint-disable-line

  const onChangePage = useCallback(
    page => {
      setActivePage(page);
      reloadCertificates({ page: page + 1 });
    },
    [reloadCertificates],
  );

  const filterOptions = useMemo(() => [
    { value: 'ALL', label: t('fields.type') },
    { value: CATEGORY_CERTIFICATE, label: t('filter.certificates') },
    { value: CATEGORY_SUBSCRIPTION, label: t('filter.subscriptions') },
  ], []);

  const statusOptions = useMemo(() => [
    { value: 'ALL', label: t('fields.status') },
    { value: CERTIFICATE_STATUS_DRAFT, label: t('fields.statuses.DRAFT') },
    { value: CERTIFICATE_STATUS_INACTIVE, label: t('fields.statuses.INACTIVE') },
    { value: CERTIFICATE_STATUS_ACTIVE, label: t('fields.statuses.ACTIVE') },
    { value: CERTIFICATE_STATUS_USED, label: t('fields.statuses.USED') },
    { value: CERTIFICATE_STATUS_EXPIRED, label: t('fields.statuses.EXPIRED') },
  ], []);

  const certificateCards = certificates.map(b => <CertificateCard billing={b} key={b.id} view={viewCertificate} />);
  const tableRows = certificates.map(b => <CertificateTableRow billing={b} key={b.id} view={viewCertificate} />);

  return (
    <Box>
      <Box
        mb={2}
        display='flex'
        justifyContent={mobileView ? 'space-between' : 'flex-start'}
        flexDirection={mobileView && searchActive ? 'column' : 'row'}
        gap={2}
      >
        <Select type='white' items={filterOptions} value={type} onChange={setType} fullWidth={mobileView} />
        <Select type='white' items={statusOptions} value={status} onChange={setStatus} fullWidth={mobileView} />
        <SearchInput
          small={mobileView && !searchActive}
          onActivate={() => setSearchActive(true)}
          onDeactivate={() => setSearchActive(false)}
          fullWidth={mobileView && searchActive}
        />
        <Datepicker datepickerProps={datepickerProps} allowClear fullWidth={mobileView} />
        {!mobileView && (
          <Button onClick={addCertificate} sx={{ mb: 2, marginLeft: 'auto' }} startIcon={<Icon type='plus' color='white' />}>
            {t('addCertificate')}
          </Button>
        )}
      </Box>
      {loading && certificates.length === 0 && <Loader />}
      {(!loading || certificates.length > 0) && (
        <Box>
          <Typography variant='h4' mb={2}>{t('title')} ({count})</Typography>
          {mobileView && <Box display='flex' flexDirection='column' gap={2}>{certificateCards}</Box>}
          {!mobileView && certificates.length > 0 && <CertificatesTable>{tableRows}</CertificatesTable>}
          <Pagination
            mt={4}
            page={activePage}
            perPage={10}
            items={count}
            justifyContent='center'
            onChangePage={onChangePage}
          />
          {mobileView && (
            <Box mt={4} display='flex' justifyContent='center'>
              <PlusButton onClick={addCertificate} />
            </Box>
          )}
        </Box>
      )}
      {addModalOpen &&
        <AddCertificateModal reloadCertificates={reloadCertificates} onClose={closeAddModal} placeId={activePlace.id} />
      }
      {certificateModalOpen && (
        <CertificateInfoModal
          billing={viewedBilling}
          onClose={closeCertificateModal}
          reloadCertificates={reloadCertificates}
          placeId={activePlace.id}
        />
      )}
    </Box>
  );
};

export default memo(Certificates);
