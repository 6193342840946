import mova from 'mova';

import localStorage from 'utils/localStorage';
import { v1 } from 'utils/apiClient';
import EventEmitter from 'utils/event/EventEmitter';

const t = mova.ns('service.jwt');

class JwtService extends EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    v1.interceptors.response.use(
      response => response,
      err =>
        new Promise(() => {
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', t('invalidToken'));
            this.setSession(null);
          }
          if (err.response.status === 504) {
            this.emit('onNoAccessToken');
            // this.setSession(null);
          }
          throw err;
        }),
    );
  };

  handleAuthentication = () => {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      this.emit('onAutoLogout');
      return;
    }

    if (this.isAuthTokenValid(accessToken)) {
      this.setSession(accessToken);
      this.emit('onAutoLogin', true);
      this.emit('connectSocket', accessToken);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', t('accessExpired'));
    }
  };

  createUser = (data, createUser) =>
    createUser(data).then(response => {
      this.setSession(response.payload.accessToken);
      this.emit('connectSocket', response.payload.accessToken);
      return response.payload.user;
    });

  signInWithPhoneAndPassword = response => {
    if (response.payload.user) {
      this.setSession(response.payload.accessToken);
      this.emit('connectSocket', response.payload.accessToken);
      return response.payload.user;
    }
    return response.payload.user;
  };

  signInWithToken = getProfile =>
    getProfile().then(response => {
      this.setSession(response.payload.accessToken);
      return response;
    });

  updateUserData = (user, updateProfile) => updateProfile(user);

  setSession = accessToken => {
    if (accessToken) {
      localStorage.set(localStorage.Keys.TOKEN, accessToken);
    } else {
      localStorage.delete(localStorage.Keys.TOKEN);
    }
  };

  logout = () => {
    this.setSession(null);
    this.emit('onLogOut');
  };

  isAuthTokenValid = accessToken => {
    if (!accessToken) {
      return false;
    }
    return true;
  };

  getAccessToken = () => localStorage.get(localStorage.Keys.TOKEN);
}

const instance = new JwtService();

export default instance;
