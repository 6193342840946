import React from 'react';
import theme from '../../theme';

export default function multiple({ color = theme.palette.text.primary }) {
  return (
    <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Reservation 1' clipPath='url(#clip0_2387_806)'>
        <g id='surface1731'>
          <path
            d='M13.8544 3.32983L3.03495 3.33678C2.62176 3.33678 2.23981 3.52081 1.98634 3.84719C1.85092 4.01733 1.76759 4.21178 1.72592 4.4097L0.239813 10.4896C0.139118 10.8403 0.184257 11.2083 0.368285 11.5243C0.54884 11.8403 0.847452 12.0625 1.23981 12.1597L3.59051 12.6111C3.7294 12.6389 3.8787 12.6701 4.03495 12.6701L14.8544 12.6666C15.2676 12.6666 15.653 12.4791 15.9065 12.1528C16.16 11.8264 16.2502 11.4132 16.1634 11.0729L15.1495 4.34025C15.0002 3.7465 14.469 3.32983 13.8544 3.32983ZM13.8509 4.66664L14.8544 11.3333L4.18773 11.3298L4.08704 11.2465L3.03495 4.67011L13.8509 4.66664ZM6.18773 7.33331V8.66664H11.5211V7.33331H6.18773ZM2.18773 8.07636L2.65301 11.0486L1.53148 10.8333L2.18773 8.07636Z'
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2387_806'>
          <rect width='16' height='16' fill={color} transform='translate(0.187744)' />
        </clipPath>
      </defs>
    </svg>
  );
}
