import React, { memo } from 'react';
import mova from 'mova';
import Modal from '../Modal/Modal';
import ReservationCard from '../ReservationCard/ReservationCard';
import Box from '@mui/material/Box';
import Button from '../Button/Button';
import { styled } from "@mui/material";
import { reservationActions, reservationSelectors } from "state/ducks/reservation";
import { useDispatch, useSelector } from "react-redux";
import { sortBy } from "../../../utils/lo/lo";
import moment from "moment/moment";

const t = mova.ns('components.Halls');

const StyledModal = styled(Modal)(() => ({
  zIndex: 1100,
}));

const TableReservationDetailsModal = ({ open, onClose, table, walkIn, date, showPast }) => {
  const dispatch = useDispatch();
  const reservations = useSelector(reservationSelectors.getHallReservations());
  const tableReservations = sortBy(
    reservations
      .filter(r => r.spots?.find(s => s.id === table?.id))
      .filter(r => ['ACCEPTED', 'INPROGRESS', 'FINISHED'].includes(r.status))
      .filter(r => moment(r.from).format('L') === moment(date).format('L'))
      .filter(r => showPast || r.status !== 'FINISHED'),
    'from'
  );

  const cards = tableReservations.map(r =>
    <ReservationCard key={r.id} reservation={r} onEdit={onClose} width='100%' />
  );

  const addReservation = () => {
    dispatch(reservationActions.openReservationDrawer({ table, date: date?.getTime() }));
    onClose();
  }

  return (
    <StyledModal open={open} onClose={onClose} title={`${t('tableReservations')} ${table?.label}`}>
      <Box display='flex' gap={2}>
        <Button sx={{ flexBasis: '50%' }} fullWidth onClick={walkIn} color='primary' variant='outlined'>
          {t('newWalkIn')}
        </Button>
        <Button onClick={addReservation} sx={{ flexBasis: '50%' }}>{t('createReservation')}</Button>
      </Box>
      <Box display='flex' flexDirection='column' mt={cards.length > 0 ? 2 : 0} gap={2}>
        {cards}
      </Box>
    </StyledModal>
  );
};

export default memo(TableReservationDetailsModal);
