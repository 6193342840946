import React, { memo } from 'react';
import Typography from '@mui/material/Typography';
import mova from 'mova';
import BorderCollapseTable from '@components/BorderCollapseTable/BorderCollapseTable';
import TableRow from '@mui/material/TableRow';

const t = mova.ns('components.Certificates');

const CertificatesTable = ({ children }) => {
  return (
    <BorderCollapseTable>
      <thead>
        <TableRow sx={{ textAlign: 'left' }}>
          <th width='20%'><Typography color='typography.info' variant='caption'>{t('fields.name')}</Typography></th>
          <th width='15%'><Typography color='typography.info' variant='caption'>{t('fields.id')}</Typography></th>
          <th width='20%'><Typography color='typography.info' variant='caption'>{t('fields.clientName')}</Typography></th>
          <th width='20%'><Typography color='typography.info' variant='caption'>{t('fields.phone')}</Typography></th>
          <th width='10%'><Typography color='typography.info' variant='caption'>{t('fields.type')}</Typography></th>
          <th width='10%'><Typography color='typography.info' variant='caption'>{t('fields.status')}</Typography></th>
        </TableRow>
      </thead>
      <tbody>
        {children}
      </tbody>
    </BorderCollapseTable>
  );
};

export default memo(CertificatesTable);
