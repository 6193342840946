import { userSelectors } from "../user";
import { placeSelectors } from "../place";

const isSuperAdmin = () => state => userSelectors.getUserRole()(state) === "super";
const showMenu = () => state => {
  const activePlace = placeSelectors.getActivePlace()(state);
  return activePlace.billTypeExtra?.includes('menu') && activePlace.menuActive;
}
const showCertificates = () => state => {
  const activePlace = placeSelectors.getActivePlace()(state);
  return ['enterprise', 'pro'].includes(activePlace.billType) && activePlace.showCertificates;
}

export default {
  isSuperAdmin,
  showMenu,
  showCertificates,
};
