import React, { memo, useCallback } from 'react';
import Card from '@components/Card/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import styled from '@emotion/styled';
import { placeThunks, placeSelectors } from 'state/ducks/place';
import { useSelector, useDispatch } from 'react-redux';
import Icon from '@components/Icon/Icon';
import IconButton from '@mui/material/IconButton';
import AvatarEditor from 'views/components/AvatarEditor/AvatarEditor';

const StyledGridItem = styled(Grid)(({ theme }) => ({
  flexBasis: '33.333333%',
  maxWidth: '33.333333%',
  [theme.breakpoints.up('xssm')]: {
    flexBasis: '25%',
    maxWidth: '25%',
  },
  [theme.breakpoints.up('sm')]: {
    flexBasis: '16.666666%',
    maxWidth: '16.666666%',
  },
  [theme.breakpoints.up('smmd')]: {
    flexBasis: '14%',
    maxWidth: '14%',
  },
  [theme.breakpoints.up('md')]: {
    flexBasis: '16.666666%',
    maxWidth: '16.666666%',
  },
  [theme.breakpoints.up('xl')]: {
    flexBasis: '12.5%',
    maxWidth: '12.5%',
  },
}));

const StyledImageWrapper = styled('div')(({ theme, src }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadiusSmall,
  backgroundColor: theme.palette.typography.extraLight,
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  width: '100%',
  minHeight: theme.spacing(10),
}));

const StyledLabel = styled('label')(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSmall,
  width: '100%',
  cursor: 'pointer',
}));

const StyledIconButton = styled(IconButton)(() => ({
  position: 'relative',
  top: '-35px',
  left: '25px',
}));

const Gallery = ({ place }) => {
  const dispatch = useDispatch();
  const { id: placeId } = place;

  const photos = useSelector(placeSelectors.getPlacePhotos());

  const handleUploadChange = useCallback(
    e => {
      const { files } = e.target;
      const formData = new FormData();
      for (let i = 0; i < files.length; i += 1) {
        formData.append('photos', files[i]);
      }
      dispatch(placeThunks.addPhoto({ placeId, formData }));
    },
    [dispatch, placeId],
  );

  const onSubmitAvatar = useCallback(
    async e => {
      const {
        target: { value },
      } = e;
      const fd = new FormData();
      fd.append('avatar', value);
      await dispatch(placeThunks.updateAvatar({ placeId, file: fd }));
      await dispatch(placeThunks.fetchPlaces());
    },
    [dispatch, placeId],
  );

  const handleDelete = photo => {
    return () => {
      dispatch(placeThunks.deletePhoto({ ...photo, placeId }));
    };
  };

  const galleryItems = [
    ...photos.map(image => (
      <StyledGridItem item key={image.id}>
        <StyledImageWrapper src={image.url} />
        <StyledIconButton onClick={handleDelete(image)}>
          <Icon color='white' type='remove' />
        </StyledIconButton>
      </StyledGridItem>
    )),
    <StyledGridItem item key={photos.length + 999}>
      <StyledLabel htmlFor='button-file'>
        <StyledImageWrapper>
          <Icon type='chevronUp' />
        </StyledImageWrapper>
      </StyledLabel>
    </StyledGridItem>,
  ];

  return (
    <Card sx={{ textAlign: 'center' }}>
      <Typography mb={2} variant='h4'>
        Avatar
      </Typography>
      <AvatarEditor image={place.avatarUrl} initials={place.name} onChange={onSubmitAvatar} />
      <input
        accept='image/*'
        style={{ display: 'none' }}
        id='button-file'
        type='file'
        onChange={handleUploadChange}
        multiple
      />
      <Grid container spacing={2} mt={4}>
        {galleryItems}
      </Grid>
    </Card>
  );
};

export default memo(Gallery);
