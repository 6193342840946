const getHalls = () => state => state.hall.list.data;
const getHallsLoading = () => state => state.hall.list.isLoading;
const getHallsError = () => state => state.hall.list.error;

const getActiveHall = () => state => state.hall.list.data.find(h => h.id === state.hall.activeHall);

export default {
  getHalls,
  getHallsLoading,
  getHallsError,

  getActiveHall,
};
