import React, { memo } from 'react';
import mova from 'mova';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import {
  CERTIFICATE_STATUS_ACTIVE,
  CERTIFICATE_STATUS_DRAFT,
  CERTIFICATE_STATUS_EXPIRED,
  CERTIFICATE_STATUS_INACTIVE,
  CERTIFICATE_STATUS_USED
} from "utils/certificate";

const t = mova.ns('components.Certificates');

const STATUS_COLORS = (status, theme) => ({
  [CERTIFICATE_STATUS_DRAFT]: theme.palette.typography.blurred,
  [CERTIFICATE_STATUS_INACTIVE]: theme.palette.typography.primary,
  [CERTIFICATE_STATUS_ACTIVE]: theme.palette.green.primary,
  [CERTIFICATE_STATUS_USED]: theme.palette.error.main,
  [CERTIFICATE_STATUS_EXPIRED]: theme.palette.error.main
});

const CertificateStatusLabel = ({ billing, variant = 'body2' }) => {
  const theme = useTheme();

  if (!billing?.data?.status) {
    return null;
  }

  return (
    <Box display='flex' gap={1} alignItems='center'>
      <Typography sx={{ color: STATUS_COLORS(billing.data.status, theme) }} variant={variant}>
        {t(`fields.statuses.${billing.data.status}`)}
      </Typography>
    </Box>
  );
};

export default memo(CertificateStatusLabel);
