const getClients = () => state => state.client.list.data?.rows;
const getClientsCount = () => state => state.client.list.data?.count;
const getClientsLoading = () => state => state.client.list.isLoading;
const getClientsError = () => state => state.client.list.error;

const getPlaceTopVisitors = () => state => state.client.topVisitors.data?.rows;
const getPlaceTopVisitorsLoading = () => state => state.client.topVisitors.isLoading;
const getPlaceTopBills = () => state => state.client.topUserBills.data?.rows;
const getPlaceTopBillsLoading = () => state => state.client.topUserBills.isLoading;

export default {
  getClients,
  getClientsCount,
  getClientsLoading,
  getClientsError,

  getPlaceTopVisitors,
  getPlaceTopVisitorsLoading,
  getPlaceTopBills,
  getPlaceTopBillsLoading,
};
