import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'state/utils/loadDataExtraReducer';
import loadDataInitialState from 'state/utils/loadDataInitialState';

const integrationSlice = createSlice({
  name: 'integration',
  initialState: {
    syrve: {
      organisations: loadDataInitialState([]),
      terminals: loadDataInitialState([]),
    },
  },
  extraReducers: {
    ...loadDataExtraReducer(thunks.getSyrveOrganisations, 'syrve.organisations'),
    ...loadDataExtraReducer(thunks.getSyrveTerminals, 'syrve.terminals'),
  },
});

export default integrationSlice.reducer;
