import React, { memo } from 'react';
import mova from 'mova';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CertificateModal from './components/CertificateModal';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import useModal from "utils/hooks/useModal/useModal";
import Button from "../../../../components/Button/Button";
import Icon from "../../../../components/Icon/Icon";
import ConfirmModal from "../../../../components/ConfirmModal/ConfirmModal";
import CertificateSettingsCard from "./components/CertificateSettingsCard";

const t = mova.ns('components.CertificateSettings');

const CertificateSettings = ({ place, showToastr }) => {
  const dispatch = useDispatch();
  const certificates = useSelector(placeSelectors.getCertificates());
  const [editingCertificate, addEditCertificate, closeCertificateModal, editedCertificate] = useModal();
  const [deletingCertificate, deleteCertificate, closeDeleteCertificateModal, deletedCertificate] = useModal();

  const certificateCards = certificates
    .map(certificate => (
      <Grid key={certificate.id} item xs={12} sm={12} md={12} lg={6}>
        <CertificateSettingsCard
          certificate={certificate}
          editCertificate={addEditCertificate}
          deleteCertificate={deleteCertificate}
        />
      </Grid>
    ));

  const confirmDeleteCertificate = () => {
    const response = dispatch(placeThunks.deleteCertificate({ placeId: place.id, certificateId: deletedCertificate.id }));
    if (!response.error) {
      closeDeleteCertificateModal();
    }
  };

  return (
    <Box>
      <Button sx={{ mb: 2 }} startIcon={<Icon type='plus' />} onClick={addEditCertificate}>
        {t('addCertificate')}
      </Button>
      <Grid container flexGrow={1} minWidth={0} spacing={2}>
        {certificateCards}
      </Grid>
      {editingCertificate && (
        <CertificateModal
          onClose={closeCertificateModal}
          certificate={editedCertificate}
          place={place}
          showToastr={showToastr}
        />
      )}
      <ConfirmModal
        open={deletingCertificate}
        onClose={closeDeleteCertificateModal}
        confirm={confirmDeleteCertificate}
        title={t('deletingCertificate')}
        content={t('confirmDeleteCertificate')}
      />
    </Box>
  );
};

export default memo(CertificateSettings);
