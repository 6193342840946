import React from 'react';
import theme from '../../theme';

export default function walkIn({ color = theme.palette.primary.main, size = 24 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_608_3338)'>
        <path
          d='M15.3622 13.8753C14.7994 14.4379 14.2052 14.9241 13.6041 15.3267C13.2831 15.5417 12.8543 15.4976 12.5811 15.2244L8.64557 11.2889C8.37238 11.0155 8.32825 10.5869 8.54322 10.2657C8.94568 9.66456 9.43183 9.07038 9.99469 8.5077C12.6479 5.85432 16.0004 4.9051 17.4828 6.38734C18.9648 7.86939 18.0156 11.2219 15.3622 13.8753Z'
          fill={color}
        />
        <path
          d='M6.26812 13.001C6.44628 12.8227 6.64166 12.6712 6.84912 12.5465C7.16772 12.3552 7.57604 12.4072 7.83898 12.6701L11.1999 16.031C11.4626 16.2938 11.5148 16.7023 11.3233 17.0209C11.1986 17.2284 11.0472 17.4235 10.869 17.6019C9.64715 18.8236 7.62676 18.784 6.3562 17.5135C5.08581 16.2431 5.04626 14.2227 6.26812 13.001Z'
          fill={color}
        />
        <path
          d='M1 12C1 5.92478 5.92478 1 12 1C18.0752 1 23 5.92478 23 12C23 18.0752 18.0752 23 12 23C5.92478 23 1 18.0752 1 12Z'
          stroke={color} strokeWidth='2'
        />
      </g>
      <defs>
        <clipPath id='clip0_608_3338'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
