import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Modal from '@components/Modal/Modal';
import mova from 'mova';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from '@form/FormikTextInput';
import ModalActions from '@components/Modal/ModalActions';
import { hallThunks } from 'state/ducks/hall';
import store from '@state/store';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import Icon from '@components/Icon/Icon';
import { useDispatch } from 'react-redux';
import FormikCheckbox from "@form/FormikCheckbox";

const t = mova.ns('components.Halls.hallForm');

const StyledImage = styled('img')(({ theme, selected }) => ({
  width: '100%',
  border: `3px solid ${selected ? theme.palette.primary.main : 'transparent'}`,
}));

const StyledUploadWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadiusSmall,
  backgroundColor: theme.palette.typography.extraLight,
  width: '100%',
  minHeight: theme.spacing(11),
}));

const schema = Yup.object().shape({
  name: Yup.string().required(() => t('errors.required')),
  active: Yup.boolean().required(() => t('errors.required')),
  variables: Yup.object().shape({
    length: Yup.number(() => t('errors.number')).required(() => t('errors.required')),
    width: Yup.number(() => t('errors.number')).required(() => t('errors.required')),
  }),
  order: Yup.number().required(() => t('errors.required')).min(1, () => t('errors.minNumber', { min: 1 }))
});

const AddEditHallModal = ({ open, onClose, submitForm, hall, values, setFieldValue }) => {
  const dispatch = useDispatch();
  const handleUploadChange = (e) => {
    const { files } = e.target;
    const formData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      formData.append('photos', files[i]);
    }
    dispatch(hallThunks.addPhoto({
      data: { placeId: hall?.placeId, hallId: hall?.id, formData },
      callback: (res) => {
        setFieldValue('variables.bgCustom', res.filename);
      }
    }));
    setFieldValue('variables.bg', null);
  };

  const setDefaultBg = (value) => {
    setFieldValue('variables.bg', value);
    setFieldValue('variables.bgCustom', null);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={hall ? t('editHall') : t('addNewHall')}
      actions={<ModalActions onClose={onClose} submitForm={submitForm} />}
    >
      <Form>
        <FormikTextInput sx={{ my: 1 }} fullWidth name='name' placeholder={t('fields.name')} />
        <FormikCheckbox sx={{ mb: 1 }} name='active' label={t('fields.active')} />
        <FormikTextInput
          type='number'
          sx={{ my: 1 }}
          fullWidth
          name='variables.length'
          placeholder={t('fields.length')}
          label={t('fields.length')}
        />
        <FormikTextInput
          type='number'
          sx={{ my: 1 }}
          fullWidth
          name='variables.width'
          placeholder={t('fields.width')}
          label={t('fields.width')}
        />
        <FormikTextInput
          type='number'
          sx={{ my: 1 }}
          inputProps={{ min: 1 }}
          fullWidth
          name='order'
          placeholder={t('fields.order')}
          label={t('fields.order')}
        />
        <Box my={2}>
          <Typography variant='h4'>{t('pattern')}</Typography>
        </Box>
        <Grid container spacing={2} sx={{ textAlign: 'center' }}>
          <Grid item xs={3} onClick={() => setDefaultBg(null)}>
            <StyledImage
              src={'/assets/images/patterns/white.jpg'}
              selected={!values.variables?.bg && !values.variables?.bgCustom}
              alt={t('patterns.none')}
            />
            <Typography variant='caption'>{t('patterns.none')}</Typography>
          </Grid>
          <Grid item xs={3} alignItems='center' onClick={() => setDefaultBg('asphalt')}>
            <StyledImage
              src={'/assets/images/patterns/asphalt.jpg'}
              selected={values.variables?.bg === 'asphalt'}
              alt={t('patterns.asphalt')}
            />
            <Typography variant='caption'>{t('patterns.asphalt')}</Typography>
          </Grid>
          <Grid item xs={3} alignItems='center' onClick={() => setDefaultBg('stone')}>
            <StyledImage
              src={'/assets/images/patterns/stone.jpg'}
              selected={values.variables?.bg === 'stone'}
              alt={t('patterns.stone')}
            />
            <Typography variant='caption'>{t('patterns.stone')}</Typography>
          </Grid>
          <Grid item xs={3} alignItems='center' onClick={() => setDefaultBg('stone2')}>
            <StyledImage
              src={'/assets/images/patterns/stone2.jpg'}
              selected={values.variables?.bg === 'stone2'}
              alt={t('patterns.stone')}
            />
            <Typography variant='caption'>{t('patterns.stone')}</Typography>
          </Grid>
          <Grid item xs={3} alignItems='center' onClick={() => setDefaultBg('tiles')}>
            <StyledImage
              src={'/assets/images/patterns/tiles.jpg'}
              selected={values.variables?.bg === 'tiles'}
              alt={t('patterns.tiles')}
            />
            <Typography variant='caption'>{t('patterns.tiles')}</Typography>
          </Grid>
          <Grid item xs={3} alignItems='center' onClick={() => setDefaultBg('tiles2')}>
            <StyledImage
              src={'/assets/images/patterns/tiles2.jpg'}
              selected={values.variables?.bg === 'tiles2'}
              alt={t('patterns.tiles')}
            />
            <Typography variant='caption'>{t('patterns.tiles')}</Typography>
          </Grid>
          <Grid item xs={3} alignItems='center' onClick={() => setDefaultBg('wood')}>
            <StyledImage
              src={'/assets/images/patterns/wood.jpg'}
              selected={values.variables?.bg === 'wood'}
              alt={t('patterns.wood')}
            />
            <Typography variant='caption'>{t('patterns.wood')}</Typography>
          </Grid>
          <Grid item xs={3} alignItems='center' onClick={() => setDefaultBg('wood2')}>
            <StyledImage
              src={'/assets/images/patterns/wood2.jpg'}
              selected={values.variables?.bg === 'wood2'}
              alt={t('patterns.wood')}
            />
            <Typography variant='caption'>{t('patterns.wood')}</Typography>
          </Grid>
          <Grid item xs={3} alignItems='center' onClick={() => setDefaultBg('wood3')}>
            <StyledImage
              src={'/assets/images/patterns/wood3.jpg'}
              selected={values.variables?.bg === 'wood3'}
              alt={t('patterns.wood')} />
            <Typography variant='caption'>{t('patterns.wood')}</Typography>
          </Grid>
          <Grid item xs={3} alignItems='center' onClick={() => setDefaultBg('wood4')}>
            <StyledImage
              src={'/assets/images/patterns/wood4.jpg'}
              selected={values.variables?.bg === 'wood4'}
              alt={t('patterns.wood')}
            />
            <Typography variant='caption'>{t('patterns.wood')}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Box component='label' htmlFor='button-file' sx={{ cursor: 'pointer' }}>
              {values.variables?.bgCustom && (
                <StyledImage
                  src={`/public/uploads/places/photos/${values.variables?.bgCustom}`}
                  selected={values.variables?.bgCustom}
                  alt={t('patterns.wood')}
                />
              )}
              {!values.variables?.bgCustom && (
                <StyledUploadWrapper
                  src={values.variables?.bgCustom}
                  selected={values.variables?.bgCustom}
                  alt={t('patterns.wood')}
                >
                  <Icon type='chevronUp' />
                </StyledUploadWrapper>
              )}
              <Typography variant='caption'>{t('bgCustom')}</Typography>
            </Box>
          </Grid>,
        </Grid>
        <input
          accept='image/*'
          style={{ display: 'none' }}
          id='button-file'
          type='file'
          onChange={handleUploadChange}
        />
      </Form>
    </Modal>
  );
};

AddEditHallModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(
  withFormik({
    mapPropsToValues: ({ hall = {} }) => ({
      ...hall,
      name: hall?.name || '',
      active: hall?.active || false,
      variables: {
        length: hall?.variables?.length || 16,
        width: hall?.variables?.width || 16,
        bg: hall?.variables?.bg || null,
        bgCustom: hall?.variables?.bgCustom || null,
      },
      order: hall?.order || 1,
    }),
    validationSchema: schema,
    handleSubmit: async (data, helpers) => {
      const updatedData = {
        ...data,
        placeId: helpers.props.placeId,
      };
      store.dispatch(helpers.props.hall ? hallThunks.updateHall({ data: updatedData }) : hallThunks.addHall(updatedData));
      helpers.props.onClose();
    },
    enableReinitialize: true,
  })(AddEditHallModal),
);
