import moment from 'moment';

import memoize from 'memoize-one';
import { set } from 'lodash';

const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const normalizeOpenHours = memoize(openHours =>
  (openHours || []).reduce(
    (accum, openHour) =>
      set(accum, daysOfWeek[openHour.dayOfWeek - 1], {
        from: moment(`${openHour.openH}:${openHour.openM}`, 'HH:mm').toDate(),
        to: moment(`${openHour.closedH}:${openHour.closedM}`, 'HH:mm').toDate(),
        closed: Boolean(openHour.closed),
      }),
    {},
  ),
);

const deNormalizeOpenHours = values => {
  const { openHours } = values;
  return daysOfWeek.map((d, i) => ({
    ...openHours.find(o => o.dayOfWeek === String(i + 1)),
    openH: moment(values[d].from).format('HH'),
    openM: moment(values[d].from).format('mm'),
    closedH: moment(values[d].to).format('HH'),
    closedM: moment(values[d].to).format('mm'),
    closed: values[d].closed,
  }));
};

const formatDate = (date, format = 'DD-MM-YYYY') => moment(date).format(format);

const hasDifferentYear = (...values) => {
  const currentYear = moment().format('YYYY');
  return values.some(v => moment(v).format('YYYY') !== currentYear);
};

const nearest15Minutes = (date) => {
  return moment(Math.ceil(moment(date || moment()) / moment.duration(15, 'minutes')) * moment.duration(15, 'minutes'));
}

const getReservationDuration = (minutesSinceStart, t) => {
  const duration = moment.duration(minutesSinceStart, 'minutes');
  return duration.hours() > 0
    ? `${duration.hours()}${t('hours')} ${duration.minutes()}${t('minutes')}`
    : `${duration.minutes()}${t('minutes')}`;
}

export { normalizeOpenHours, deNormalizeOpenHours, formatDate, moment, hasDifferentYear, nearest15Minutes, getReservationDuration };
