import React from 'react';
import theme from '../../theme';

export default function statistics({ color = theme.palette.common.white }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.14282 15.857H5.56982V9.43H8.14182V15.858L8.14282 15.857ZM13.2858 15.857H10.7138V3H13.2858V15.857ZM18.4278 15.857H15.8568V6.857H18.4278V15.857Z'
        fill={color}
      />
      <path fillRule='evenodd' clipRule='evenodd' d='M21 20.7139H3V18.7139H21V20.7139Z' fill={color}/>
    </svg>
  );
}
