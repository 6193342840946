import React, { memo, useEffect, useState } from 'react';
import mova from 'mova';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import periodUtils from '@utils/periodUtils';
import Datepicker from '@components/Datepicker/Datepicker';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { Button as MaterialButton, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import ButtonGroup from '@mui/material/ButtonGroup';
import Icon from '@components/Icon/Icon';
import { formatDate, hasDifferentYear } from '@utils/date';

const t = mova.ns('components.PeriodsSelector');

const formatPeriod = (start, end, unit) => {
  if (end === null) {
    return moment(start).format('DD MMM');
  } else if (moment(end).diff(moment(start), 'days') === 0) {
    return moment(start).format('DD MMM');
  } else if (unit === 'years') {
    return moment(start).format('YYYY');
  } else {
    const format = hasDifferentYear(start, end) ? 'DD MMM YYYY' : 'DD MMM';
    return `${moment(start).format(format)} - ${moment(end).format(format)}`;
  }
};

const StyledPeriod = styled(Box, { shouldForwardProp: (prop) => prop !== 'selected' })(({ theme, selected }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadiusSmall,
  border: `1px solid ${selected ? theme.palette.typography.light : 'transparent'}`,
  backgroundColor: selected ? theme.palette.typography.background : 'white',
  '&:hover': {
    backgroundColor: theme.palette.typography.background
  }
}));

const StyledSelectorButton = styled(MaterialButton)(({ theme }) => ({
  textTransform: 'none',
  lineHeight: theme.spacing(3),
}));

const PeriodsSelector = ({ defaultPeriod = periodUtils.thisMonth, defaultUnit = 'months', setDateParams, ...rest }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(defaultPeriod[0].toDate());
  const [endDate, setEndDate] = useState(defaultPeriod[1].toDate());
  const [basePeriod, setBasePeriod] = useState(defaultPeriod);
  const [unit, setUnit] = useState(defaultUnit);
  const [unitDiff, setUnitDiff] = useState(0);
  const smallView = useMediaQuery(theme => theme.breakpoints.down('sm'), { noSsr: true });

  useEffect(() => {
    if (endDate) {
      setDateParams({
        from: formatDate(startDate),
        to: formatDate(endDate)
      });
    }
  }, [startDate, endDate, setDateParams]);

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const datepickerOnChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);

    if (end) {
      setUnit(null);
      setEndDate(moment(end).endOf('day').toDate());
      setBasePeriod([moment(start), moment(end)]);
      closePopover();
    } else {
      setEndDate(null);
    }
  };

  const periodSelected = (period, unit, diff) => {
    setBasePeriod(period);
    setUnit(unit);
    setUnitDiff(diff);
    setStartDate(moment(period[0]).subtract(diff, unit).toDate());
    setEndDate(moment(period[1]).subtract(diff, unit).toDate());
    closePopover();
  };

  const updateDatesBaseOnDiff = (newDiff) => {
    const daysSelected = unit ? 1 : moment(endDate).diff(moment(startDate), 'days') + 1;
    setStartDate(basePeriod[0].clone().subtract(newDiff * daysSelected, unit || 'days').toDate());
    setEndDate(basePeriod[1].clone().subtract(newDiff * daysSelected, unit || 'days').toDate());
  };

  const changePeriodDiff = (diff) => {
    const newDiff = unitDiff + diff;
    setUnitDiff(newDiff);
    updateDatesBaseOnDiff(newDiff);
  };

  const availablePeriods = [{
    label: t('select.today'),
    selected: unit === 'days' && unitDiff === 0,
    onSelect: () => periodSelected(periodUtils.today, 'days', 0)
  }, {
    label: t('select.yesterday'),
    selected: unit === 'days' && unitDiff === 1,
    onSelect: () => periodSelected(periodUtils.today, 'days', 1)
  }, {
    label: t('select.thisWeek'),
    selected: unit === 'weeks' && unitDiff === 0,
    onSelect: () => periodSelected(periodUtils.thisWeek, 'weeks', 0)
  }, {
    label: t('select.lastWeek'),
    selected: unit === 'weeks' && unitDiff === 1,
    onSelect: () => periodSelected(periodUtils.thisWeek, 'weeks', 1)
  }, {
    label: t('select.thisMonth'),
    selected: unit === 'months' && unitDiff === 0,
    onSelect: () => periodSelected(periodUtils.thisMonth, 'months', 0)
  }, {
    label: t('select.lastMonth'),
    selected: unit === 'months' && unitDiff === 1,
    onSelect: () => periodSelected(periodUtils.thisMonth, 'months', 1)
  }, {
    label: t('select.thisYear'),
    selected: unit === 'years' && unitDiff === 0,
    onSelect: () => periodSelected(periodUtils.thisYear, 'years', 0)
  }, {
    label: t('select.lastYear'),
    selected: unit === 'years' && unitDiff === 1,
    onSelect: () => periodSelected(periodUtils.thisYear, 'years', 1)
  }];
  const selectedPeriod = availablePeriods.find(p => p.selected);

  const availablePeriodItems = availablePeriods.map(p => (
    <StyledPeriod key={p.label} selected={p.selected} onClick={p.onSelect}>{p.label}</StyledPeriod>
  ));

  return (
    <Box {...rest}>
      <ButtonGroup variant='outlined' fullWidth={mobileView}>
        <StyledSelectorButton sx={{ width: theme.spacing(5) }} onClick={() => changePeriodDiff(1)}>
          <Icon type='chevronLeft' />
        </StyledSelectorButton>
        <StyledSelectorButton
          sx={{ minWidth: `${theme.spacing(20)} !important` }}
          variant='outlined'
          onClick={openPopover}
          startIcon={<Icon type='calendar' />}
        >
          {selectedPeriod?.label || formatPeriod(startDate, endDate, unit)}
        </StyledSelectorButton>
        <StyledSelectorButton sx={{ width: theme.spacing(5) }} onClick={() => changePeriodDiff(-1)}>
          <Icon type='chevronRight' />
        </StyledSelectorButton>
      </ButtonGroup>
      <Popover
        sx={{ mt: 2 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box display={smallView ? 'block' : 'flex'} p={2} pl={4}>
          <Box mr={smallView ? 0 : 4}>
            {availablePeriodItems}
          </Box>
          <Box>
            <Typography variant='h3' sx={{ p: 1 }}>{t('orChoosePeriod')}</Typography>
            <Datepicker
              datepickerProps={{
                selectsRange: true,
                inline: true,
                startDate,
                endDate,
                onChange: datepickerOnChange,
                monthsShown: 2
              }}
              showIcon={false}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default memo(PeriodsSelector);
