import React, { memo } from 'react';
import mova from 'mova';
import * as Yup from 'yup';
import { Form, withFormik } from 'formik';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormikTextInput from '@form/FormikTextInput';
import FormikSelect from '@form/FormikSelect';
import Box from '@mui/material/Box';
import Card from '@components/Card/Card';
import Button from '@components/Button/Button';
import Icon from '@components/Icon/Icon';
import store from '@state/store';
import { useSelector } from 'react-redux';
import { placeSelectors, placeThunks } from 'state/ducks/place';
import Grid from '@mui/material/Grid';

const t = mova.ns('components.Settings.contactForm');

const schema = Yup.object().shape({
  type: Yup.string(),
  reference: Yup.string().required(() => t('errors.required')),
});

const ContactTypesEnum = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  WEB: 'WEB',
};

const Contacts = ({ place }) => {
  const contacts = useSelector(placeSelectors.getPlaceContacts());

  const typeItems = Object.keys(ContactTypesEnum).map(type => ({
    value: type,
    label: t(`types.${ContactTypesEnum[type]}`),
  }));

  const deleteContact = (contact) => store.dispatch(placeThunks.deleteContact({ placeId: place.id, id: contact.id }));

  const contactRows = contacts.map((contact, index) => (
    <Grid container key={index} spacing={2} alignItems='center'>
      <Grid item xs={4}>
        <Typography>{t(`types.${contact.type}`)}</Typography>
      </Grid>
      <Grid item xs={6} title={contact.reference}>
        <Typography noWrap>{contact.reference}</Typography>
      </Grid>
      <Grid item xs={2} sx={{ textAlign: 'right' }}>
        <IconButton onClick={() => deleteContact(contact)}>
          <Icon type='remove' />
        </IconButton>
      </Grid>
    </Grid>
  ));

  return (
    <>
      <Card>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} xssm={4}><Typography>{t('fields.type')}</Typography></Grid>
            <Grid item xs={12} xssm={8}>
              <FormikSelect name='type' fullWidth placeholder={t('fields.type')} items={typeItems} />
            </Grid>
            <Grid item xs={12} xssm={4}><Typography>{t('fields.reference')}</Typography></Grid>
            <Grid item xs={12} xssm={8}>
              <FormikTextInput name='reference' fullWidth placeholder={t('fields.referencePlaceholder')} />
            </Grid>
          </Grid>
          <Box display='flex' justifyContent='center' mt={2}>
            <Button type='submit' size='large'>{t('submit')}</Button>
          </Box>
        </Form>
      </Card>
      {contacts.length > 0 && (
        <Card sx={{ mt: 2 }}>
          {contactRows}
        </Card>
      )}
    </>
  );
};

export default memo(withFormik({
  mapPropsToValues: ({ place }) => ({
    placeId: place.id,
    type: 'EMAIL',
    reference: '',
  }),
  validationSchema: schema,
  handleSubmit: async (data, helpers) => {
    await store.dispatch(placeThunks.addContact(data));
    helpers.resetForm();
    helpers.setSubmitting(false);
  },
})(Contacts));
