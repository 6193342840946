import React from 'react';
import theme from '../../theme';

export default function logo({ color = theme.palette.text.primary, size = 16 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='0.0600586' width='16' height='16' rx='3' fill={color} />
      <path
        d='M8.7747 4.6149C9.38695 3.87163 10.29 3.5 11.4839 3.5V6.7316C11.2084 6.68851 10.9533 6.66697 10.7186 6.66697C9.53491 6.66697 8.94307 7.30251 8.94307 8.57361V12.5H5.48389V3.66158H8.7747V4.6149Z'
        fill='white'
      />
    </svg>
  );
}
