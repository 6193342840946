import React from 'react';
import theme from '../../theme';

export default function chevronlLeft({ color = theme.palette.typography.primary }) {
  return (
    <svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.80778 1.63281L6.17841 5.91424L1.80778 10.1957'
        stroke={color}
        strokeWidth='1.665'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
