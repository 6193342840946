import React from 'react';
import theme from '../../theme';

export default function comment({ color = theme.palette.primary.main }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 2C10.6868 2 9.38641 2.25866 8.17315 2.7612C6.9599 3.26375 5.85751 4.00035 4.92892 4.92893C3.05356 6.8043 1.99999 9.34784 1.99999 12C1.99125 14.3091 2.79078 16.5485 4.25999 18.33L2.25999 20.33C2.12123 20.4706 2.02723 20.6492 1.98986 20.8432C1.95249 21.0372 1.97341 21.2379 2.04999 21.42C2.13305 21.5999 2.26769 21.7511 2.43683 21.8544C2.60598 21.9577 2.80199 22.0083 2.99999 22H12C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 20H5.40999L6.33999 19.07C6.52624 18.8826 6.63078 18.6292 6.63078 18.365C6.63078 18.1008 6.52624 17.8474 6.33999 17.66C5.03057 16.352 4.21516 14.6305 4.03268 12.7888C3.8502 10.947 4.31193 9.09901 5.33922 7.55952C6.3665 6.02004 7.89578 4.88436 9.6665 4.34597C11.4372 3.80759 13.3398 3.8998 15.0502 4.60691C16.7606 5.31402 18.1728 6.59227 19.0464 8.22389C19.92 9.85551 20.2009 11.7395 19.8411 13.555C19.4814 15.3705 18.5033 17.005 17.0735 18.1802C15.6438 19.3554 13.8508 19.9985 12 20ZM17 11H6.99999C6.73477 11 6 11 6 11C6 11 5.99999 11.7348 5.99999 12C5.99999 12.2652 6 13 6 13C6 13 6.73477 13 6.99999 13H17C17.2652 13 18 13 18 13C18 13 18 12.2652 18 12C18 11.7348 18 11 18 11C18 11 17.2652 11 17 11ZM15 15H8.99999C8.73477 15 8 15 8 15C8 15 7.99999 15.7348 7.99999 16C7.99999 16.2652 8 17 8 17C8 17 8.73477 17 8.99999 17H15C15.2652 17 16 17 16 17C16 17 16 16.2652 16 16C16 15.7348 16 15 16 15C16 15 15.2652 15 15 15ZM8.99999 9H15C15.2652 9 16 9 16 9C16 9 16 8.26522 16 8C16 7.73478 16 7 16 7C16 7 15.2652 7 15 7H8.99999C8.73477 7 8 7 8 7C8 7 7.99999 7.73478 7.99999 8C7.99999 8.26522 8 9 8 9C8 9 8.73477 9 8.99999 9Z'
        fill={color}
      />
    </svg>
  );
}
