import React, { memo } from 'react';
import { useField } from 'formik';
import Select from 'react-select';
import { FormHelperText, useTheme } from '@mui/material';

const FormikMultiSelect = ({ name, options, labelPath = 'label', valuePath = 'value', disabled, ...rest }) => {
  const [field, state] = useField(name);
  const theme = useTheme();

  const commonOptions = {
    isMulti: true,
    isSearchable: true,
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: theme.shape.borderRadiusSmall
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: theme.shape.borderRadiusSmall,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: theme.palette.common.white
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };

  const updatedOptions = options.map(o => ({
    label: o[labelPath],
    value: o[valuePath]
  }));

  const patchedOnChange = (value) => {
    field.onChange({ currentTarget: { value, name } });
  };

  return (
    <>
      <Select
        isMulti
        styles={customStyles}
        {...commonOptions}
        {...field}
        {...rest}
        options={updatedOptions}
        onChange={patchedOnChange}
        isDisabled={disabled}
        menuPortalTarget={document.body}
      />
      {!!state.error && state.touched && (
        <FormHelperText sx={{ color: theme.palette.secondary.dark }}>
          {!!state.error && state.touched && state.error}
        </FormHelperText>
      )}
    </>
  );
};

export default memo(FormikMultiSelect);
