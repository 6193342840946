import React from 'react';
import theme from '../../theme';

export default function incomingPayments({ color = theme.palette.common.white }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.0004 19.7144V23.143M16.2861 18.0001V21.4287M7.7147 18.0001V21.4287M13.8244 5.80985C13.7268 5.53063 13.5445 5.28878 13.303 5.11795C13.0615 4.94712 12.7728 4.85581 12.477 4.85671H11.3713C11.0577 4.85783 10.7555 4.97443 10.5224 5.18424C10.2894 5.39406 10.1418 5.68238 10.1079 5.99412C10.0739 6.30587 10.156 6.61919 10.3385 6.87423C10.521 7.12927 10.791 7.30816 11.097 7.37671L12.7821 7.747C13.1253 7.82351 13.4282 8.02377 13.633 8.30951C13.8378 8.59525 13.9302 8.94644 13.8924 9.29597C13.8547 9.6455 13.6894 9.96886 13.4283 10.2043C13.1672 10.4397 12.8285 10.5707 12.477 10.5721H11.5238C11.228 10.573 10.9393 10.4817 10.6978 10.3109C10.4563 10.1401 10.2741 9.89822 10.1764 9.619M12.0004 4.85671V3.42871M12.0004 12.0001V10.5721M4.7147 8.143C4.60104 8.143 4.49203 8.09784 4.41166 8.01747C4.33129 7.9371 4.28613 7.82809 4.28613 7.71443C4.28613 7.60076 4.33129 7.49175 4.41166 7.41138C4.49203 7.33101 4.60104 7.28585 4.7147 7.28585C4.82837 7.28585 4.93738 7.33101 5.01775 7.41138C5.09812 7.49175 5.14328 7.60076 5.14328 7.71443C5.14328 7.82809 5.09812 7.9371 5.01775 8.01747C4.93738 8.09784 4.82837 8.143 4.7147 8.143ZM19.2861 8.143C19.1725 8.143 19.0635 8.09784 18.9831 8.01747C18.9027 7.9371 18.8576 7.82809 18.8576 7.71443C18.8576 7.60076 18.9027 7.49175 18.9831 7.41138C19.0635 7.33101 19.1725 7.28585 19.2861 7.28585C19.3998 7.28585 19.5088 7.33101 19.5892 7.41138C19.6696 7.49175 19.7147 7.60076 19.7147 7.71443C19.7147 7.82809 19.6696 7.9371 19.5892 8.01747C19.5088 8.09784 19.3998 8.143 19.2861 8.143Z'
        stroke={color} strokeWidth='1.71429' strokeLinecap='round' strokeLinejoin='round'
      />
      <path
        d='M21.4289 0.857178H2.57171C2.11705 0.857178 1.68102 1.03779 1.35952 1.35928C1.03803 1.68077 0.857422 2.11681 0.857422 2.57146V12.8572C0.857422 13.3118 1.03803 13.7479 1.35952 14.0694C1.68102 14.3909 2.11705 14.5715 2.57171 14.5715H21.4289C21.8835 14.5715 22.3195 14.3909 22.641 14.0694C22.9625 13.7479 23.1431 13.3118 23.1431 12.8572V2.57146C23.1431 2.11681 22.9625 1.68077 22.641 1.35928C22.3195 1.03779 21.8835 0.857178 21.4289 0.857178Z'
        stroke={color} strokeWidth='1.71429' strokeLinecap='round' strokeLinejoin='round'
      />
    </svg>
  );
}
