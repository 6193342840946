import React, { memo } from 'react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    height: theme.spacing(5),
    borderRadius: theme.shape.borderRadiusSmall,
    backgroundColor: 'white',
    borderColor: theme.palette.typography.light,
    whiteSpace: 'nowrap',
    '&.MuiInputBase-multiline': {
      height: 'unset'
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.typography.background,
      '.MuiInputBase-input': {
        cursor: 'not-allowed'
      }
    },
  },
}));

const TextInput = ({ ...rest }) => {
  return <StyledTextField {...rest} variant='outlined' InputLabelProps={{ sx: { top: -6 } }} />;
};

export default memo(TextInput);
