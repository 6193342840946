const pushServerPublicKey =
  'BGTmAHNyeaN9as4QlHCd7vtIYn2JbE87XJX0pAKVufcrDueNa2GTyjGaZuBFJoWE8nI8wJgL-sVJ9sGWBSMThN8';

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return 'serviceWorker' in navigator && 'PushManager' in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
  let permissions = null;
  if (window.Notification) {
    permissions = await window.Notification.requestPermission();
  }
  return permissions;
}
// /**
//  * shows a notification
//  */
// function sendNotification() {
//   const img = '/images/jason-leung-HM6TMmevbZQ-unsplash.jpg';
//   const text = 'Take a look at this brand new t-shirt!';
//   const title = 'New Product Available';
//   const options = {
//     body: text,
//     icon: '/images/jason-leung-HM6TMmevbZQ-unsplash.jpg',
//     vibrate: [200, 100, 200],
//     tag: 'new-product',
//     image: img,
//     badge: 'https://spyna.it/icons/android-icon-192x192.png',
//     actions: [
//       {
//         action: 'Detail',
//         title: 'View',
//         icon: 'https://via.placeholder.com/128/ff0000',
//       },
//     ],
//   };
//   navigator.serviceWorker.ready.then(serviceWorker => {
//     serviceWorker.showNotification(title, options);
//   });
// }

/**
 *
 */
function registerServiceWorker() {
  return navigator.serviceWorker.register('/sw.js');
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
  // wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready;
  // subscribe and return the subscription
  const subscribeData = await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: pushServerPublicKey,
  });
  return subscribeData;
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
  // wait for service worker installation to be ready, and then
  if (!navigator.serviceWorker) return Promise.resolve(null);
  return navigator.serviceWorker.ready
    .then(serviceWorker => serviceWorker.pushManager.getSubscription())
    .then(pushSubscription => pushSubscription)
    .catch(err => {
      console.log('$$$ [ERROR]', err); // eslint-disable-line no-console
      return err;
    });
}

export {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
};
