import React from 'react';
import theme from '../../theme';

export default function bell({ color = theme.palette.common.white, filled = false }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {
        filled && (
          <path
            d='M12 22C10.8954 22 10 21.1046 10 20H14C14 21.1046 13.1046 22 12 22ZM20 19H4V17L6 16V10.5C6 7.038 7.421 4.793 10 4.18V2H13C12.3479 2.86394 11.9967 3.91762 12 5C12 5.25138 12.0187 5.50241 12.056 5.751H12C10.7799 5.67197 9.60301 6.21765 8.875 7.2C8.25255 8.18456 7.94714 9.33638 8 10.5V17H16V10.5C16 10.289 15.993 10.086 15.979 9.9C16.6405 10.0366 17.3226 10.039 17.985 9.907C17.996 10.118 18 10.319 18 10.507V16L20 17V19ZM17 8C16.3958 8.00073 15.8055 7.81839 15.307 7.477C14.1288 6.67158 13.6811 5.14761 14.2365 3.8329C14.7919 2.5182 16.1966 1.77678 17.5954 2.06004C18.9942 2.34329 19.9998 3.5728 20 5C20 6.65685 18.6569 8 17 8Z'
            fill={color}
          />
        )
      }
      {
        !filled && (
          <>
            <path
              d='M12 22C10.8954 22 10 21.1046 10 20H14C14 21.1046 13.1046 22 12 22ZM20 19H4V17L6 16V10.5C6 7.038 7.421 4.793 10 4.18V2H13C12.3479 2.86394 11.9967 3.91762 12 5C12 5.25138 12.0187 5.50241 12.056 5.751H12C10.7799 5.67197 9.60301 6.21765 8.875 7.2C8.25255 8.18456 7.94714 9.33638 8 10.5V17H16V10.5C16 10.289 15.993 10.086 15.979 9.9C16.6405 10.0366 17.3226 10.039 17.985 9.907C17.996 10.118 18 10.319 18 10.507V16L20 17V19Z'
              fill={color}
            />
            <path
              d='M12 22C13.1046 22 14 21.1046 14 20H10C10 21.1046 10.8954 22 12 22ZM4 19H20V17L18 16V10.5C18 7.038 16.579 4.793 14 4.18V2H11C11.6521 2.86394 12.0033 3.91762 12 5C12 5.25138 11.9813 5.50241 11.944 5.751H12C13.2201 5.67197 14.397 6.21765 15.125 7.2C15.7475 8.18456 16.0529 9.33638 16 10.5V17H8V10.5C8 10.289 8.007 10.086 8.021 9.9C7.35955 10.0366 6.67739 10.039 6.015 9.907C6.004 10.118 6 10.319 6 10.507V16L4 17V19Z'
              fill={color}
            />
          </>
        )
      }
    </svg>
  );
}
