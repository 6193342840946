import React from 'react';
import theme from '../../theme';

export default function edit({ color = theme.palette.common.white }) {
  return (
    <svg width='22' height='13' viewBox='0 0 22 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <line y1='0.5' x2='22' y2='0.5' stroke={color} />
      <line y1='12.5' x2='22' y2='12.5' stroke={color} />
      <line y1='6.5' x2='22' y2='6.5' stroke={color} />
    </svg>
  );
}
