const LocalStorageKeysEnum = {
  TOKEN: 'token',
  LAST_PLACE: 'last_place',
  LANG: 'lang',
  ACTIVE_PLACE: 'active_place',
  ACTIVE_HALL: 'active_hall',
  RESERVATIONS_HIDDEN: 'reservations_hidden',
};

const storage = window.localStorage;

export default {
  Keys: LocalStorageKeysEnum,

  get(key) {
    if (!(key in storage)) {
      return undefined;
    }

    try {
      return JSON.parse(storage.getItem(key));
    } catch {
      return storage.getItem(key);
    }
  },

  set(key, value) {
    if (typeof value === 'undefined') {
      this.delete(key);
      return;
    }

    storage.setItem(key, JSON.stringify(value));
  },

  delete(key) {
    storage.removeItem(key);
  },

  clear() {
    storage.clear();
  },
};
