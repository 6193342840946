import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/apiService';

const fetchChannels = createAsyncThunk('channel/fetchChannels', async data => {
  return api.get(`/places/${data.placeId}/channels`);
});

const addChannel = createAsyncThunk('channel/addChannel', async data => {
  return api.post(`/places/${data.placeId}/channels`, data);
});

const updateChannel = createAsyncThunk('channel/updateChannel', async data => {
  return api.put(`/places/${data.placeId}/channels/${data.id}`, data);
});

const deleteChannel = createAsyncThunk('channel/deleteChannel', async data => {
  return api.delete(`/places/${data.placeId}/channels/${data.id}`);
});

const fetchChannelStats = createAsyncThunk('channel/fetchChannelStats', async data => {
  return api.get(`/places/${data.placeId}/channels/${data.id}/stats?from=${data.from}&to=${data.to}`);
});

export default {
  fetchChannels,
  addChannel,
  updateChannel,
  deleteChannel,
  fetchChannelStats
};
