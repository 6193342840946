import React from 'react';
import theme from '../../theme';

export default function doubleChevronLeft({ color = theme.palette.primary.main }) {
  return (
    <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path 
        fillRule='evenodd' 
        clipRule='evenodd' 
        d='M7.80033 2.4125C8.12065 2.08267 8.11295 1.55561 7.78312 1.23529C7.45329 0.914967 6.92623 0.922674 6.60591 1.2525L2.23529 5.75284C2.07848 5.9143 1.99373 6.13238 2.00036 6.35735C2.00699 6.58233 2.10443 6.79504 2.27047 6.94699L6.64109 10.9467C6.98028 11.2571 7.50687 11.2337 7.81727 10.8945C8.12767 10.5553 8.10433 10.0287 7.76515 9.71835L4.02717 6.29764L7.80033 2.4125ZM10.8003 2.41252C11.1206 2.0827 11.113 1.55565 10.7831 1.23531C10.4533 0.914976 9.92627 0.922664 9.60593 1.25248L5.23531 5.75248C5.0785 5.91394 4.99374 6.13201 5.00036 6.35699C5.00698 6.58197 5.10441 6.79468 5.27045 6.94663L9.64107 10.9466C9.98024 11.257 10.5068 11.2337 10.8172 10.8946C11.1277 10.5554 11.1043 10.0288 10.7652 9.71837L7.02717 6.29735L10.8003 2.41252Z' 
        fill={color}
      />
    </svg>
  );
}
