import React, { lazy, Suspense } from 'react';
import Page404 from '../views/pages/Page404';
import Sandbox from '../views/pages/Sandbox';
import Login from '../views/pages/auth/Login';
import Halls from '../views/pages/halls/Halls';
import Register from '../views/pages/auth/Register';
import Layout from '../views/layouts/Layout';
import AuthLayout from '../views/layouts/AuthLayout';
import ForgotPassword from '../views/pages/auth/ForgotPassword';
import Settings from '../views/pages/settings/Settings';
import SocialLogin from '../views/oauth/SocialLogin/SocialLogin';
import Payment from '../views/pages/payment/Payment';
import NewPayment from '../views/pages/payment/NewPayment';
import InvoiceDetails from '../views/pages/payment/InvoiceDetails';
import PaymentStatistics from "../views/pages/paymentStatistics/PaymentStatistics";
import Certificates from "../views/pages/certificates/Certificates";

const AllReviews = lazy(() => import('../views/pages/allReviews/AllReviews'));
const SuperAdmin = lazy(() => import('../views/pages/superAdmin/SuperAdmin'));
const Dishes = lazy(() => import('../views/pages/menu/Dishes'));
const Channels = lazy(() => import('../views/pages/channels/Channels'));
const ChannelStatistics = lazy(() => import('../views/pages/channelStats/ChannelStatistics'));
const Profile = lazy(() => import('../views/pages/profile/Profile'));
const Clients = lazy(() => import('../views/pages/clients/Clients'));
const RestaurantStatistics = lazy(() => import('../views/pages/statistics/RestaurantStatistics'));
const Timeline = lazy(() => import('../views/pages/timeline/Timeline'));
const Dashboard = lazy(() => import('../views/pages/dashboard/Dashboard'));
const Reservations = lazy(() => import('../views/pages/reservations/Reservations'));

const routes = {
  sandbox: { path: '/sandbox', children: <Sandbox />, exact: true },
  oauth: {
    path: '/oauth/token',
    children: <SocialLogin />,
    exact: true,
  },
  superadmin: {
    path: '/superadmin',
    children: (
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <SuperAdmin />
        </Suspense>
      </Layout>
    ),
    exact: true,
  },
  home: {
    path: '/',
    children: (
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Dashboard />
        </Suspense>
      </Layout>
    ),
    exact: true,
  },
  reservations: {
    path: '/reservations',
    children: (
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Reservations />
        </Suspense>
      </Layout>
    ),
    exact: true,
  },
  settings: {
    path: '/settings',
    children: (
      <Layout>
        <Settings />
      </Layout>
    ),
    exact: true,
  },
  timeline: {
    path: '/timeline',
    children: (
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Timeline />
        </Suspense>
      </Layout>
    ),
    exact: true,
  },
  clients: {
    path: '/clients',
    children: (
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Clients />
        </Suspense>
      </Layout>
    ),
    exact: true,
  },
  channels: {
    path: '/channels',
    children: (
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Channels />
        </Suspense>
      </Layout>
    ),
    exact: true,
  },
  menu: {
    path: '/menu',
    children: (
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Dishes />
        </Suspense>
      </Layout>
    ),
    exact: true,
  },
  certificates: {
    path: '/certificates',
    children: (
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Certificates />
        </Suspense>
      </Layout>
    ),
    exact: true,
  },
  channelStats: {
    path: '/channels/:placeId/:channelId',
    children: (
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <ChannelStatistics />
        </Suspense>
      </Layout>
    ),
    exact: true,
  },
  reviews: {
    path: '/reviews',
    children: (
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <AllReviews />
        </Suspense>
      </Layout>
    ),
    exact: true,
  },
  statistics: {
    path: '/statistics',
    children: (
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <RestaurantStatistics />
        </Suspense>
      </Layout>
    ),
    exact: true,
  },
  halls: {
    path: '/halls',
    children: (
      <Layout>
        <Halls />
      </Layout>
    ),
    exact: true,
  },
  profile: {
    path: '/profile',
    children: (
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Profile />
        </Suspense>
      </Layout>
    ),
    exact: true,
  },
  paymentStats: {
    path: '/payment-statistics',
    children: (
      <Layout>
        <PaymentStatistics />
      </Layout>
    ),
    exact: true,
  },
  payment: {
    path: '/payment',
    children: (
      <Layout>
        <Payment />
      </Layout>
    ),
    exact: true,
  },
  newPayment: {
    path: '/payment/new',
    children: (
      <Layout>
        <NewPayment />
      </Layout>
    ),
    exact: true,
  },
  invoiceDetails: {
    path: '/payment/invoice/:invoiceId',
    children: (
      <Layout>
        <InvoiceDetails />
      </Layout>
    ),
    exact: true,
  },
  login: {
    path: '/login',
    children: (
      <AuthLayout>
        <Login />
      </AuthLayout>
    ),
    exact: true,
  },
  register: {
    path: '/register',
    children: (
      <AuthLayout>
        <Register />
      </AuthLayout>
    ),
    exact: true,
  },
  forgot: {
    path: '/forgot',
    children: (
      <AuthLayout>
        <ForgotPassword />
      </AuthLayout>
    ),
    exact: true,
  },
  page404: {
    path: '*',
    children: (
      <AuthLayout>
        <Page404 />
      </AuthLayout>
    ),
  },
};

export default routes;
