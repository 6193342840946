import React, { memo } from 'react';
import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import BorderCollapseTableRow from '@components/BorderCollapseTableRow/BorderCollapseTableRow';
import TableCell from '@mui/material/TableCell';
import styled from '@emotion/styled';
import Box from "@mui/material/Box";
import Icon from "../../../components/Icon/Icon";
import CertificateTypeLabel from "./CertificateTypeLabel";
import CertificateStatusLabel from "./CertificateStatusLabel";

const StyledCell = styled(TableCell)(({ theme }) => ({
  height: theme.spacing(8),
  padding: theme.spacing(1),
  textAlign: 'left'
}));

const getClientName = (billing) => `${billing?.data?.user.firstName || ''} ${billing?.data?.user.lastName || ''}`

const CertificateTableRow = ({ billing, view }) => {
  const { certificate, data } = billing;

  return (
    <BorderCollapseTableRow sx={{ cursor: 'pointer' }} onClick={() => view(billing)}>
      <StyledCell>
        <Typography noWrap variant='body2'>{certificate.name}</Typography>
      </StyledCell>
      <StyledCell>{billing.data.uuid}</StyledCell>
      <StyledCell>
        <Typography noWrap variant='body2'>{getClientName(billing)}</Typography>
      </StyledCell>
      <StyledCell>{billing.data.user.phone}</StyledCell>
      <StyledCell>
        <CertificateTypeLabel certificate={certificate} />
      </StyledCell>
      <StyledCell>
        <CertificateStatusLabel billing={billing} />
      </StyledCell>
      <StyledCell>
        <Box display='flex' alignItems='center' justifyContent='flex-end' gap={2}>
          {!!data.comment && (
            <Tooltip title={data.comment} placement='top'>
              <Icon type='comment' />
            </Tooltip>
          )}
          <Icon type='chevronRight' />
        </Box>
      </StyledCell>
    </BorderCollapseTableRow>
  );
};

export default memo(CertificateTableRow);
