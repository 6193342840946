const getTags = () => state => state.dictionary.tags.data;
const getCities = () => state => state.dictionary.cities.data;
const getCusines = () => state => state.dictionary.cusines.data;
const getMoods = () => state => state.dictionary.moods.data;
const getPayments = () => state => state.dictionary.payments.data;

export default {
  getCities,
  getCusines,
  getMoods,
  getPayments,
  getTags,
};
