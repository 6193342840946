import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/apiService';

const fetchOrganizations = createAsyncThunk('superAdmin/fetchOrganizations', async () => {
  return api.get('/organisations');
});

const createOrganization = createAsyncThunk('superAdmin/createOrganization', async organization => {
  return api.post('/organisations', organization);
});

const updateOrganization = createAsyncThunk('superAdmin/updateOrganization', async organization => {
  return api.put(`/organisations/${organization.id}`, organization);
});

const deleteOrganization = createAsyncThunk('superAdmin/deleteOrganization', async organization => {
  return api.delete(`/organisations/${organization.id}`);
});

export default {
  fetchOrganizations,
  createOrganization,
  updateOrganization,
  deleteOrganization,
};
