import React from 'react';
import theme from '../../theme';

export default function plus({ color = theme.palette.common.white }) {
  return (
    <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.86364 14.2209H7.68537V8.38281H13.5234V6.57173H7.68537V0.733664H5.86364V6.57173H0.0255682V8.38281H5.86364V14.2209Z'
        fill={color}
      />
    </svg>
  );
}
