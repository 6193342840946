import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'state/utils/loadDataExtraReducer';
import loadDataInitialState from 'state/utils/loadDataInitialState';

const clientSlice = createSlice({
  name: 'client',
  initialState: {
    list: loadDataInitialState({ rows: [], count: 0 }),
    details: loadDataInitialState(null),
    topVisitors: loadDataInitialState({ rows: [] }),
    topUserBills: loadDataInitialState({ rows: [] }),
  },
  extraReducers: {
    ...loadDataExtraReducer(thunks.fetchClients, 'list'),
    ...loadDataExtraReducer(thunks.addClient, 'details'),
    ...loadDataExtraReducer(thunks.updateClient, 'details'),
    ...loadDataExtraReducer(thunks.updateClientNote, 'details'),

    ...loadDataExtraReducer(thunks.fetchTopVisiors, 'topVisitors'),
    ...loadDataExtraReducer(thunks.fetchTopUserBills, 'topUserBills'),
  },
});

export default clientSlice.reducer;
