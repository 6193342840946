import React from 'react';

export default function uk({ size = 24 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24 3.75H0C0 3.75 0 5.59688 0 7.875V12H24V7.875C24 5.59688 24 3.75 24 3.75Z'
        fill='#1B75BB'
      />
      <path
        d='M0 16.125C0 18.4031 0 20.25 0 20.25H20.25C22.7351 20.25 24 20.25 24 20.25V12H0V16.125Z'
        fill='#F9CB38'
      />
    </svg>
  );
}
