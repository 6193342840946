import React, { memo } from 'react';
import Icon from '@components/Icon/Icon';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';

const StyledIconWrapper = styled(Box)(({ top, left, right, bottom }) => ({
  position: 'absolute',
  top,
  left,
  right,
  bottom,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}));

const StyledIcon = styled(Icon)(({ rotate, small, adjustPosition }) => ({
  position: adjustPosition ? 'relative' : 'static',
  top: small ? '-6px' : '-8px',
  left: small ? '-7px' : '-9px',
  transform: rotate ? `rotate(${rotate})` : 'none',
}));

const Chair = ({ small, adjustPosition, rotate, ...props }) => {
  return (
    <StyledIconWrapper {...props}>
      <StyledIcon type='chair' adjustPosition={adjustPosition} small={small} width={small ? 10 : 14} rotate={rotate} />
    </StyledIconWrapper>
  );
};

export default memo(Chair);
