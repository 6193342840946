import React from 'react';
import theme from '../../theme';

export default function userAccept({ color = theme.palette.primary.main, size = 24 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 21C1.5 21 0 21 0 19.5C0 18 1.5 13.5 9 13.5C16.5 13.5 18 18 18 19.5C18 21 16.5 21 16.5 21H1.5ZM9 12C10.1935 12 11.3381 11.5259 12.182 10.682C13.0259 9.83807 13.5 8.69347 13.5 7.5C13.5 6.30653 13.0259 5.16193 12.182 4.31802C11.3381 3.47411 10.1935 3 9 3C7.80653 3 6.66193 3.47411 5.81802 4.31802C4.97411 5.16193 4.5 6.30653 4.5 7.5C4.5 8.69347 4.97411 9.83807 5.81802 10.682C6.66193 11.5259 7.80653 12 9 12ZM18.219 7.719C18.2887 7.64916 18.3714 7.59374 18.4626 7.55593C18.5537 7.51812 18.6513 7.49866 18.75 7.49866C18.8487 7.49866 18.9463 7.51812 19.0374 7.55593C19.1286 7.59374 19.2113 7.64916 19.281 7.719L21 9.4395L22.719 7.719C22.8598 7.57817 23.0508 7.49905 23.25 7.49905C23.4492 7.49905 23.6402 7.57817 23.781 7.719C23.9218 7.85983 24.0009 8.05084 24.0009 8.25C24.0009 8.44916 23.9218 8.64017 23.781 8.781L22.0605 10.5L23.781 12.219C23.9218 12.3598 24.0009 12.5508 24.0009 12.75C24.0009 12.9492 23.9218 13.1402 23.781 13.281C23.6402 13.4218 23.4492 13.5009 23.25 13.5009C23.0508 13.5009 22.8598 13.4218 22.719 13.281L21 11.5605L19.281 13.281C19.1402 13.4218 18.9492 13.5009 18.75 13.5009C18.5508 13.5009 18.3598 13.4218 18.219 13.281C18.0782 13.1402 17.9991 12.9492 17.9991 12.75C17.9991 12.5508 18.0782 12.3598 18.219 12.219L19.9395 10.5L18.219 8.781C18.1492 8.71133 18.0937 8.62857 18.0559 8.53745C18.0181 8.44633 17.9987 8.34865 17.9987 8.25C17.9987 8.15135 18.0181 8.05367 18.0559 7.96255C18.0937 7.87143 18.1492 7.78867 18.219 7.719Z'
        fill={color}
      />
    </svg>
  );
}
