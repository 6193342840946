import queryString from 'query-string';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/apiService';

const getProfile = createAsyncThunk('user/getProfile', async () => {
  return api.get(`/profile`);
});

const loginUser = createAsyncThunk('user/login', async ({ credentials, callback }) => {
  const response = await api.post(`/auth/admin`, credentials);
  callback && callback(response);
  return response;
});

const searchUsers = createAsyncThunk('user/searchUsers', async data => {
  return api.get(`/search/users?${queryString.stringify(data)}`, data);
});

const updatePassword = createAsyncThunk('user/updatePassword', async data => {
  return api.put(`/profile/changePassword`, data);
});
const updateRecovery = createAsyncThunk('user/updateRecovery', async data => {
  return api.put(`/recovery`, data);
});
const updateNote = createAsyncThunk('user/updateNote', async data => {
  return api.put(`/users/note/update`, data);
});
const updateLang = createAsyncThunk('user/updateLang', async data => {
  return api.put(`/profile/lang`, data);
});
const updateProfile = createAsyncThunk('user/updateProfile', async data => {
  return api.put(`/profile`, data);
});
const updateProfileAvatar = createAsyncThunk('user/updateProfileAvatar', async data => {
  return api.put(`/profile/avatar`, data.file, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
});

const getNotification = createAsyncThunk('user/getNotification', async ({ auth }) => {
  return api.get(`/notifications/${auth}`);
});
const deleteNotification = createAsyncThunk('user/deleteNotification', async ({ auth }) => {
  return api.delete(`/notifications/${auth}`);
});
const addNotification = createAsyncThunk('user/addNotification', async data => {
  return api.post(`/notifications`, data);
});

const getNotices = createAsyncThunk('user/getNotices', async () => {
  return api.get(`/profile/notices`);
});

const deleteNotices = createAsyncThunk('user/deleteNotices', async () => {
  return api.delete(`/profile/notices`);
});

export default {
  getProfile,
  loginUser,
  updateProfile,
  updateProfileAvatar,
  updatePassword,
  updateLang,
  addNotification,
  deleteNotification,
  getNotification,
  getNotices,
  deleteNotices,
  updateRecovery,
  searchUsers,
  updateNote,
};
