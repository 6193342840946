import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'state/utils/loadDataExtraReducer';
import loadDataInitialState from 'state/utils/loadDataInitialState';

const reservationSlice = createSlice({
  name: 'reservation',
  initialState: {
    list: loadDataInitialState({ rows: [], count: 0 }),
    hallList: loadDataInitialState([]),
    timeline: loadDataInitialState({ rows: [], count: 0 }),
    details: loadDataInitialState(null),
    total: loadDataInitialState({}),
    history: loadDataInitialState(null),
    newReservations: 0,
    reservationsKey: 0,
    outdated: false,
    listParams: {
      activePage: 0,
      filter: 'ACCEPTED',
      limit: 12,
    },
    drawer: {
      open: false,
      reservation: null,
      table: null,
      date: null,
    },
  },
  reducers: {
    setTotal: {
      reducer: (state, action) => {
        state.total = { data: action.payload };
      },
      prepare: payload => ({ payload }),
    },
    setListParams: {
      reducer: (state, action) => {
        state.listParams = action.payload;
      },
      prepare: payload => ({ payload }),
    },
    setNewReservations(state, action) {
      state.newReservations = action.payload;
    },
    addOrReplaceHallReservation(state, action) {
      const reservation = action.payload;
      const existingReservation = state.hallList.data.find(r => r.id === reservation.id);
      if (existingReservation) {
        const index = state.hallList.data.indexOf(existingReservation);
        if (index > -1) {
          state.hallList.data.splice(index, 1, reservation);
        }
      } else {
        state.hallList.data.push(reservation);
      }
    },
    incrementReservationsKey(state) {
      state.reservationsKey = state.reservationsKey + 1;
    },
    openReservationDrawer(state, action) {
      state.drawer.open = true;
      state.drawer.reservation = action.payload?.reservation || null;
      state.drawer.table = action.payload?.table || null;
      state.drawer.date = action.payload?.date || null;
    },
    closeReservationDrawer(state) {
      state.drawer.open = false;
      state.drawer.reservation = null;
      state.drawer.table = null;
      state.drawer.date = null;
    }
  },
  extraReducers: {
    ...loadDataExtraReducer(thunks.fetchReservations, 'list'),
    ...loadDataExtraReducer(thunks.fetchHallReservations, 'hallList'),
    ...loadDataExtraReducer(thunks.addReservation, 'details'),
    ...loadDataExtraReducer(thunks.updateReservation, 'details'),
    ...loadDataExtraReducer(thunks.fetchReservationsTotal, 'total'),
    ...loadDataExtraReducer(thunks.fetchReservationsTimeline, 'timeline'),
    ...loadDataExtraReducer(thunks.fetchReservationHistory, 'history'),
  },
});

export const {
  setTotal,
  setListParams,
  setNewReservations,
  incrementReservationsKey,
  openReservationDrawer,
  closeReservationDrawer,
  addOrReplaceHallReservation,
} = reservationSlice.actions;

export default reservationSlice.reducer;
