import React, { memo } from 'react';
import styled from '@emotion/styled';
import Table from '@mui/material/Table';

const StyledTable = styled(Table)(({ theme }) => ({
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: theme.spacing(0, 1),
  background: 'transparent',
  textAlign: 'center',
}));

const BorderCollapseTable = ({ children, ...rest }) => {
  return (
    <StyledTable {...rest}>
      {children}
    </StyledTable>
  );
};

export default memo(BorderCollapseTable);
