import React, { memo } from 'react';
import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';

const StyledTable = styled(Table)(() => ({
  position: 'absolute',
  zIndex: -1,
  width: 'unset',
  borderCollapse: 'collapse',
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:first-of-type td': {
    borderTopColor: '#777',
  },
  '&:nth-of-type(4n) td': {
    borderBottomColor: '#777',
  }
}));

const StyledTableCell = styled(TableCell)(() => ({
  width: '15px',
  height: '15px',
  padding: 0,
  border: '1px solid #ccc',
  boxSizing: 'content-box',
  '&:first-of-type': {
    borderLeftColor: '#777',
  },
  '&:nth-of-type(4n)': {
    borderRightColor: '#777',
  }
}));

const SnapGrid = ({ hallX, hallY }) => {
  const tableCells = [...Array(hallX * 4).keys()]
    .map((_, index) => <StyledTableCell key={index} />);
  const tableRows = [...Array(hallY * 4).keys()]
    .map((_, index) => <StyledTableRow key={index}>{tableCells}</StyledTableRow>);

  return (
    <StyledTable>
      <TableBody>
        {tableRows}
      </TableBody>
    </StyledTable>
  );
};

export default memo(SnapGrid);
