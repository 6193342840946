import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import baseTableStyles from "./baseTableStyles";

const getTableContentProps = (theme, tableRotation) => {
  if (tableRotation >= 300) {
    return {
      paddingTop: theme.spacing(1.5),
    };
  } else if (tableRotation >= 250) {
    return {
      height: '60%',
      transform: `rotate(90deg) translateX(8px)`,
    };
  } else if (tableRotation >= 110) {
    return {
      transform: `rotate(-180deg)`,
      paddingBottom: theme.spacing(1.5),
    };
  } else if (tableRotation >= 60) {
    return {
      height: '60%',
      transform: `rotate(-90deg) translateX(-8px)`,
    };
  } else {
    return {
      paddingTop: theme.spacing(1.5),
    };
  }
}

export default styled(Box)(({ theme, selected, inProgress, table, offsetY }) => {
  const baseStyles = baseTableStyles(theme, table, inProgress, selected, offsetY);
  baseStyles.transform += ` rotate(${table.variables?.angle || 0}deg) !important`;

  return {
    ...baseStyles,
    width: table.variables?.length * 32 || (table.variables?.seats === 2 ? 48 : 32),
    height: table.variables?.seats === 2 ? table.variables?.length * 4 / 3 * 32 : table.variables?.length * 64,
    '.table-content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minWidth: 0,
      height: '100%',
      ...getTableContentProps(theme, table.variables?.angle || 0),
    },
    '&:before': {
      display: 'block',
      position: 'absolute',
      top: 12,
      width: '100%',
      borderTop: `2px ${table.variables?.border || 'solid'} ${theme.palette.primary.main}`,
      content: '""',
    },
    '&:after': {
      display: table.variables?.seats === 2 ? 'block' : 'none',
      position: 'absolute',
      left: 'calc(50% - 1px)',
      top: 0,
      height: 12,
      borderRight: `2px ${table.variables?.border || 'solid'} ${theme.palette.primary.main}`,
      content: '""',
    },
  }
});
