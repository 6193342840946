export default {
  base: {
    cancel: 'Скасувати',
    save: 'Зберегти',
    confirm: 'Підтвердити',
    close: 'Закрити',
    search: 'Пошук',
    login: 'Увійти',
    register: 'Зареєструватися',
    submit: 'Відправити',
    edit: 'Редагувати',
    delete: 'Видалити',
    city: 'Місто',
    seeAll: 'Подивитись усі',
    length: 'Довжина',
    width: 'Ширина',
    height: 'Висота',
    unknownUser: 'Гість',
    sendAgain: 'Відправити знову',
  },
  lang: {
    ua: 'Українська',
    ru: 'Російська',
    en: 'Англійська',
    es: 'Іспанська',
  },
  currencies: {
    uah: 'грн'
  },
  types: {
    user: {
      phone: 'Телефон',
      email: 'Електронна пошта',
      password: 'Пароль',
      oldPassword: 'Старий пароль',
      newPassword: 'Новий пароль',
      confirmPassword: 'Підтвердження пароля',
      firstName: "Ім'я",
      lastName: 'Прізвище',
      confirm: '<base.confirm>',
      avgBillAmount: 'Середній чек',
      restaurant: 'Ресторан',
      city: '<base.city>',
      lang: 'Мова спілкування',
    },
    admin: {
      '@extends': 'types.user',
      lastLogin: 'Останній раз онлайн',
      role: 'Роль',
      roles: {
        admin: 'Адміністратор',
        analyst: 'Аналітик',
        owner: 'Власник',
      },
    },
    place: {
      name: 'Назва',
      description: 'Повний опис',
      address: 'Адреса',
      latitude: 'Широта',
      longitude: 'Довгота',
      active: 'Активний',
      link: 'Посилання',
      cusines: 'Кухні закладу',
      moods: 'Настрій закладу',
      payments: 'Тип платежів',
      city: '<base.city>',
      web: 'Веб-сайт',
      phone: 'Телефон',
      time: 'Час',
      contactPhone: 'Телефон для повідомлень',
    },
    placeOpenHours: {
      sun: 'Неділя',
      mon: 'Понеділок',
      tue: 'Вівторок',
      wed: 'Середа',
      thu: 'Четвер',
      fri: "П'ятниця",
      sat: 'Субота',
    },
    placeHall: {
      name: 'Назва',
      active: 'Активний',
      length: '<base.length> (м)',
      width: '<base.width> (м)',
      order: 'Порядок',
    },
    placeSpot: {
      type: 'Тип',
      label: 'Назва',
      seats: 'К-сть місць',
      minSeats: 'Мінімальна к-сть місць',
      length: '<base.length> (м)',
      width: '<base.width> (м)',
      priority: 'Пріоритет',
      angle: 'Кут',
      oneSeat: 'Одномісний',
      twoSeat: 'Двомісний',
      border: 'Межі',
      depositCategory: 'Категорія депозиту',
      borderType: {
        solid: 'Суцільні',
        dashed: 'Пунктирні',
        dotted: 'Крапки',
      },
    },
    cities: {
      kyiv: 'Київ',
      odessa: 'Одеса',
    },
    reservation: {
      accepted: 'Погоджені',
      requested: 'Нові',
      success: 'Успішні',
      rejected: 'Відхилені',
      cancelled: 'Скасовані',
      skipped: 'Пропущені',
      finished: 'Закінчені',
      waitlist: 'Список очікування',
      inProgress: 'В процесі',
      totalReserves: 'Усього резервів',
      successReservations: 'Успішні резерви',
      failedReservations: 'Неуспішні резерви',
      comment: 'Коментар',
      hall: 'Зал',
      spot: 'Стіл',
      spots: 'Столи',
      from: 'Початок',
      to: 'Кінець',
      seats: 'Кількість людей',
      event: 'Захід',
      events: {
        other: 'Інше',
        business: 'Ділова зустріч',
        date: 'Побачення',
        family: 'Сімейний відпочинок',
        friends: 'Зустріч із друзями',
        birthday: 'День народження',
        football: 'Футбол',
      },
      keepTable: 'Не пересаджувати',
      manager: 'Адміністратор',
      waitForTomorrow: 'Переносити на наступний день',
    },
    dish: {
      name: 'Назва страви',
      categories: 'Категорії',
      photo: 'Фото',
      description: 'Опис',
      weight: 'Вага',
      price: 'Ціна, грн',
      active: 'Активне',
      vegetarian: 'Вегетаріанське',
      hot: 'Гостре',
      top: 'Топ',
    },
    dishCategory: {
      name: 'Назва категорії',
      parent: 'Батьківська категорія',
      order: 'Порядок',
    },
    legal: {
      privacy: 'Політика конфіденційності',
      privacyWhat: 'Політику конфіденційності',
      terms: 'Умови використання',
      termsWhat: 'Умови використання',
    },
    channel: {
      name: 'Ім\'я',
    },
    certificate: {
      active: 'Активний',
      name: 'Назва',
      category: 'Категорія',
      categoryCertificate: 'Сертифікат',
      categorySubscription: 'Абонемент',
      price: 'Ціна',
      description: 'Опис',
      photo: 'Фото',
      type: 'Тип',
      typeVisits: 'На кількість відвідувань',
      typeAmount: 'На суму',
      visits: 'Кількість відвідувань',
      amount: 'Сума',
      expiration: 'Строк дії',
      expirationDate: 'До певної дати',
      expirationDays: 'Днів з моменту покупки',
      tillDate: 'До якої дати',
      validDays: 'Кількість днів',
      activation: 'Дата активації',
      activationPurchase: 'З моменту покупки',
      activationVisit: 'Після першого відвідування',
      activationVisitMaxDays: 'Автоматично активувати через (днів)',
      duration: 'Строк дії абонементу (днів)',
      id: 'Унікальний номер',
      clientName: 'Ім‘я гостя',
      clientLastName: 'Прізвище гостя',
      phone: 'Телефон',
      status: 'Статус',
      purchased: 'Дата покупки',
      comment: 'Коментар',
      statuses: {
        DRAFT: 'Не оплачений',
        INACTIVE: 'Не активний',
        ACTIVE: 'Активний',
        USED: 'Використаний',
        EXPIRED: 'Завершений',
      }
    },
  },
  auth: {
    Login: {
      fields: { '@extends': 'types.user' },
      forgotPassword: 'Забули Пароль?',
      title: 'Вхід',
      errors: {
        '@any': 'Сталася помилка!',
        '@extends': 'errors.validation',
        wrongAuth: 'Неправильний телефон або пароль',
        wrongRole: 'Ви не маєте прав на доступ до цієї послуги. Напишіть адміністратору',
      },
      submit: '<base.login>',
      dontHaveAccount: 'Немає облікового запису?',
      createAccount: 'Створити обліковий запис',
      rememberMe: "Запам'ятати мене",
      or: 'або',
    },
    Register: {
      fields: { '@extends': 'types.user' },
      legal: { '@extends': 'types.legal' },
      cities: { '@extends': 'types.cities' },
      title: 'Реєстрація',
      errors: {
        '@any': 'Сталася помилка!',
        '@extends': 'errors.validation',
        confirm: 'Ви маєте прийняти умови',
      },
      haveAccount: 'Вже є обліковий запис?',
      confirm: 'Я приймаю',
      and: 'та',
      submit: '<base.register>',
      login: '<base.login>',
    },
    Social: {
      signIn: 'Увійти за допомогою',
      providers: {
        google: 'Google',
        facebook: 'Facebook',
      },
    },
    Forgot: {
      fields: { '@extends': 'types.user' },
      errors: {
        '@any': 'Сталася помилка!',
        '@extends': 'errors.validation',
        userWithSpecifiedEmailNotFoundd: 'Акаунт із такою поштою не знайденний',
      },
      login: '<base.login>',
      title: 'Відновлення пароля',
      buttonSend: 'Надіслати на пошту інструкції',
      goBackLogin: 'Повернутися до авторизації',
    },
  },
  errors: {
    validation: {
      '@root': 'Невірний формат',
      '@any': '<.>',
      format: '<.>',
      required: "Це поле є обов'язковим",
      password: 'Введений пароль занадто короткий',
      email: 'Будь ласка, використовуйте правильний формат електронної пошти ',
      phone: 'Будь ласка, використовуйте правильний формат телефону: +380999999999',
      minLength: 'Мінімальна довжина <=symbols> символів',
      maxLength: 'Максимальна довжина <=symbols> символів',
      number: 'Необхідно ввести число',
      minNumber: 'Мінімальне значення <=min>',
      maxNumber: 'Максимальне значення <=max>',
      validationError: 'Вхідний набір даних не є правильним в полях: <=fields>',
      notUniq: 'Поле не є унікальним',
      url: 'Посилання має бути валідною URL адресою',
      passwordsMatch: 'Паролі не співпадають',
    },
    badRequest: 'Спробуйте ще раз або напишіть адміністратору',
    socialUserAlreadyExist: 'Користувач вже прикріплений до іншого аккаунту',
    global: {
      somethingWrong: 'Щось пішло не так',
      refresh:
        'Оновіть сторінку, і якщо проблема не зникне, зв’яжіться з нашою командою за адресою nosenkocc@gmail.com',
    },
    menuHasDishes: 'Не можна видаляти категорії, які містять страви',
    menuHasChild: 'Не можна видаляти категорії з підкатегоріями',
  },
  components: {
    Select: {
      selectAll: 'Обрати всі',
    },
    Sidebar: {
      superadmin: 'Суперадмін',
      home: 'Головна',
      reservations: 'Резерви',
      timeline: 'Таймлайн',
      clients: 'Гості',
      halls: 'Зали',
      channels: 'Канали продажів',
      menu: 'Меню',
      certificates: 'Сертифікати',
      reviews: 'Відгуки',
      statistics: 'Статистика',
      payment: 'Оплата',
      incomingPayments: 'Прийом платежів',
    },
    DashboardMenu: {
      reservation: 'Резерви',
      channels: 'Канали продажу',
      top10: 'Топ 10',
      reviews: 'Відгуки',
    },
    Reservations: {
      title: 'Резерви',
      hall: 'Зал',
      table: 'Стіл',
      accept: 'Підтвердити',
      reject: 'Відмовити',
      rejectReasonTitle: 'Вкажіть причину відмови',
      rejectReason: 'Причина відмови',
      rejectNoPlacesShort: 'Вставити текст: Немає вільних місць',
      rejectNoPlaces: 'Наразі на обраний Вами час немає вільних місць',
      customDeclineMsg: 'Вставити власне повідомлення',
      confirm: 'Відмовити',
      finish: 'Закінчити',
      finishingReservation: 'Успішне завершення резерву',
      confirmFinishReservation: 'Ви впевнені, що хочете позначити цей резерв як успішно виконаний?',
      billAmount: 'Сума чека',
      indicateBillAmount: 'Вкажіть суму чека',
      skip: 'Пропущено',
      skippingReservation: 'Бронювання було пропущено',
      confirmSkipReservation: 'Ви впевнені, що хочете позначити цей резерв як пропущений гостем?',
      updateBillAmount: 'Редагувати суму чека',
      updatingBill: 'Редагування суми чека',
      showHistory: 'Показати історію змін',
      clientCancelled: 'Гість відмінив резерв',
      cancellingReservation: 'Скасування резерву',
      confirmCancelReservation:
        'Ви впевнені, що хочете скасувати цей резерв від імені гостя? Це потрібно робити тільки в тому випадку, якщо гість сам попросив скасувати резерв.',
      movingToInProgress: 'Гість прийшов до закладу',
      backToAccepted: 'Назад до погоджених',
      confirmMoveToInProgress: 'Ви підтверджуєте, що гість прийшов до закладу?',
      additionalConfirmation: 'Підтвердити резерв',
      confirmAdditionalConfirmation: 'Ви впевнені, що хочете підтвердити цей резерв?',
      deleteFromWaitList: 'Видалити зі списку очікування',
      confirmDeleteFromWaitList: 'Ви впевнені, що хочете видалити даний запис зі списку очікування?',
      movingBackToAccepted: 'Назад до погоджених',
      confirmBackToAccepted: 'Ви впевнені, що хочете перемістити цей резерв назад до списку погоджених?',
      errors: { '@extends': 'errors.validation' },
      filter: { '@extends': 'types.reservation' },
      seeAll: '<base.seeAll>',
      acceptingReservation: 'Підтвердження резерва',
      confirmAcceptReservation: 'Ви впевнені, що хочете підтвердити даний резерв?',
      today: 'Сьогодні',
      tomorrow: 'Завтра',
      people: 'люд.',
      callClient: 'Зателефонувати гостю',
      disableDay: 'Закрити бронювання',
      enableDay: 'Відкрити бронювання',
      disablingDay: 'Закриття бронювання',
      enablingDay: 'Відкриття бронювання',
      showOnlyUnconfirmed: 'Показати лише непідтверджені',
      onlyThisHall: 'Резерви тільки в цьому залі',
      confirmDisableDay:
        'Ви впевнені, що хочете закрити можливість бронювання для поточного дня? Гості більше не зможуть забронювати столик на сьогодні, поки ви вручну не відключите цю опцію. Це налаштування буде автоматично вимкнено завтра вранці.',
      confirmEnableDay:
        'Ви впевнені, що хочете відкрити можливість бронювання для поточного дня? Гості знову зможуть забронювати столик на сьогодні.',
      dayClosedWarning:
        'Можливість бронювання була вимкнена для поточного дня. Нові гості не зможуть зробити резерви на сьогодні.',
      comment: 'Коментар: ',
      cancelReason: 'Причина скасування: ',
      cancelReasons: {
        changeOfPlans: 'Змінилися плани',
        mistake: 'Бронювання було створено помилково',
        late: 'Не встигаю на зазначений час',
        changeTime: 'Хочу обрати інший час',
        adminCancelled: 'Адміністратор скасував резерв на прохання гостя',
      },
      unknownUser: '<base.unknownUser>',
      indicateHallAndTable: 'Вкажіть зал та стіл',
      edit: '<base.edit>',
      pickSpot: 'Підібрати стіл',
      spotNotFound: 'Вільний стіл на обраний час не знайдено',
      events: { '@extends': 'types.reservation.events' },
      minutes: 'хв',
      hours: 'г',
      uah: '<currencies.uah>',
      viewMenu: 'Подивитися меню',
    },
    Channels: {
      title: 'Канали продажу',
      noChannels: 'Ще немає каналів продажу',
      copyButtonLabel: 'копіювати',
      promoLabel: 'Промо акція',
      activeLabel: 'Активовано',
      name: 'Назва',
      successReservations: 'Успішні',
      unfinishedReservations: 'Незакінчені',
      addChannel: 'Додати новий канал продажу',
      update: 'Оновити канал продажу',
      copiedSuccessfully: 'Посилання скопійовано в буфер обміну',
      seeAll: '<base.seeAll>',
      addNewChannel: 'Додавання канала продажу',
      fields: { '@extends': 'types.place' },
      errors: { '@extends': 'errors.validation' },
      deletingChannel: 'Видалення канала продажу',
      confirmDeleteChannel: 'Ви впевнені, що хочете видалити даний канал продажу?',
      system: {
        placeReservations: {
          name: 'Адміністратор',
          description: 'Резерви, створені власноруч',
        },
        guestReservations: {
          name: 'Reservble Marketplace',
          description: 'Резерви',
        },
      },
    },
    ChannelStatistics: {
      '@extends': 'types.reservation',
      backToChannels: 'Назад до списку',
      newUsers: 'Нові користувачі',
      chartTitle: 'Статистика канала продажу',
    },
    Top10: {
      attendanceTitle: 'ТОП-10 Відвідування',
      attendanceLabel: 'Відвідувань',
      checkTitle: 'ТОП-10 Чек',
      checkLabel: 'Середній чек',
      noData: 'Недостатньо даних для відображення статистики',
    },
    Settings: {
      settings: 'Налаштування',
      description: 'Опис',
      characteristics: 'Характеристика',
      reservations: 'Резерви',
      gallery: 'Галерея',
      hours: 'Години роботи',
      contacts: 'Контакти',
      administration: 'Адміністрація',
      halls: 'Зали',
      integrations: 'Інтеграції',
      deposits: 'Депозити',
      bookingWidget: 'Віджет бронювання',
      promo: 'Промоакції',
      certificates: 'Сертифікати',
      fields: { '@extends': 'types.place' },
      hourFields: { '@extends': 'types.placeOpenHours' },
      inactive: 'Неактивний',
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.settings' },
      cancel: '<base.cancel>',
      save: '<base.save>',
      contactForm: {
        fields: {
          type: 'Тип',
          reference: 'Значення',
          referencePlaceholder: 'Заповніть поле',
        },
        errors: {
          '@any': 'Сталася помилка!',
          '@extends': 'errors.validation',
        },
        types: {
          EMAIL: 'Электронная почта',
          PHONE: 'Телефон',
          FACEBOOK: 'Facebook',
          INSTAGRAM: 'Instagram',
          WEB: 'Web',
        },
        submit: 'Добавить контакт',
        update: 'Обновление контакта',
      },
      paidServices: {
        title: 'Додаткові послуги',
        active: 'Активний',
        label: 'Назва кнопки',
      },
    },
    Clients: {
      title: 'Гості',
      visits: 'Кількість відвідувань',
      cheque: 'Середній чек',
      addClient: 'Додати',
      addNewClient: 'Додавання нового гостя',
      editingClient: 'Редагування гостя',
      cancel: '<base.cancel>',
      save: '<base.save>',
      fields: { '@extends': 'types.user' },
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.clients' },
      sort: {
        date: 'За датою додавання',
        cheque: 'За середнім чеком',
        visits: 'За кількістю відвідувань',
      },
      lang: { '@extends': 'lang' },
    },
    Pagination: {
      of: 'з',
    },
    SearchInput: {
      search: '<base.search>',
    },
    Administration: {
      title: 'Адміністрація',
      fields: { '@extends': 'types.admin' },
      addAdmin: 'Додати адміністратора',
      addNewAdmin: 'Додавання адміністратора',
      editingAdmin: 'Редагування адміністратора',
      cancel: '<base.cancel>',
      save: '<base.save>',
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.administrations' },
      deletingAdmin: 'Видалення адміністратора',
      confirmDeleteAdmin: 'Ви впевнені, що хочете видалити даного адміністратора?',
    },
    Integrations: {
      telegramService: 'Telegram API',
      telegramKey: 'ID користувача або групи в Telegram',
      googleService: 'Google My Business',
      googleKey: 'ID місця',
      facebookService: 'Facebook Business Manager',
      facebookKey: 'Page Access Token',
      syrveService: 'Syrve',
      syrveKey: 'API ключ',
      syrveOrganization: 'Організація',
      syrveTerminal: 'Головний термінал',
      syrveApiFailed: 'Невірний API ключ',
      loadSyrveMenu: 'Завантажити меню',
      portmoneService: 'Portmone',
      portmoneKey: 'Portmone Payee ID',
      connect: 'Підключити',
      reset: 'Очистити',
      loadSyrveOrganisations: 'Завантажити організацію',
      loadSyrveTerminals: 'Завантажити термінал',
      connected: 'Підключено',
      telegramConnectionFailed:
        'Не вдалося надіслати повідомлення в чат із даним ID. Напишіть @reservble_bot особисте повідомлення для отримання індивідуальних сповіщень, або додайте бота до групи для отримання повідомлень у цій групі. Після цього спробуйте знову.',
      googleApiFailed: 'Неправильний ID місця',
      enableBanquetPreOrder: 'Увімкнути синхронізацію банкетів',
      enableSyncReservations: 'Увімкнути синхронізацію резервів',
      errors: { '@extends': 'errors.validation' },
    },
    ReservationSettings: {
      title: 'Резерви',
      ourManagement: 'Не перевіряти наявність вільних місць при бронюванні онлайн',
      autoSeating: 'Автоматичне підтвердження резервів',
      tgAdminNotification: 'Нагадування про пропущені резерви',
      smsConfirmation: 'Відправляти смс повідомлення з підтвердженням резерву',
      smsFeedback: 'Відправляти смс із запитом на відгук після успішного резерву',
      newReservationMessage: 'Надсилати повідомлення в Telegram після створення нового резерву адміністратором',
      reservationCancelMessage: 'Надсилати повідомлення в Telegram при відхиленні або скасуванні бронювання',
      showHalls: 'Давати можливість гостям вибрати зал під час бронювання онлайн',
      showSpots: 'Давати можливість гостям вибрати стіл під час бронювання онлайн',
      spotsMandatory: 'Зробити вибір столу обов‘язковим',
      reservationDuration: 'Стандартна тривалість резерву, хвилин',
      reservationAllDay: 'До кінця дня',
      lastTimeReservation1: 'Приймати останній резерв за',
      lastTimeReservation2: 'хвилин до закриття закладу',
      highlightReservations: 'Підсвічувати резерви перед початком',
      minutesBefore: 'За скільки хвилин',
      hideTableCapacity: 'Не показувати кількість місць за столом',
      disableSameTimeReservation: 'Не дозволяти створювати резерви на однаковий час',
      showAutomaticReservationButton: 'Показувати кнопку автоматичного підбору столу',
      syrveDelay: 'Після закриття рахунку в Syrve, позначати стіл як доступний через',
      depositsActive: 'Включити депозити',
      depositsPaymentTime: 'Час на оплату депозиту, хвилин',
      onlyDepositReservations: 'Дозволяти створювати резерви лише з депозитами',
      menuActive: 'Включити меню',
      clientMenuActive: 'Також показувати меню на клієнті',
      certificatesActive: 'Показувати сертифікати на клієнті',
      customDeclineMsg: 'Власне повідомлення при відхиленні резерву',
      minutes: 'хвилин',
      save: '<base.save>',
      success: '<actions.settings.saved>',
      title2: 'Додаткова інформація до резервів',
      customTagsTitle: 'Перевизначення тегів',
      hourFields: { '@extends': 'types.placeOpenHours' },
      apply: 'Застосувати',
      message: {
        ruPlaceholder: 'Русский',
        uaPlaceholder: 'Українська',
        enPlaceholder: 'English',
        ruDefault: 'В связи с большой загрузкой действует ограничение в 2 часа на длительность резерва',
        uaDefault: "У зв'язку з великим завантаженням діє обмеження у 2 години на тривалість резерву",
        enDefault: 'Due to busy hours, there is a 2-hour limit for the reservations',
      },
      highloadTitle: 'Додаткові опції',
      adminSelection: 'Обирати адміністратора при створенні резерву',
      showAllReservations: 'Дозволити показувати усі резерви столу одночасно',
      showReservationConfirmed: 'Додаткове підтвердження резервів',
      showWaitList: 'Показувати список очікування',
      reservationMissed: 'Автоматично скасовувати резерви, якщо гість не прийшов протягом',
      reservationMissedTime: 'хвилин',
      showHallFilter: 'Дозволяти фільтрувати резерви по залам',
      maxGuests: 'Максимальна кількість гостей під час бронювання',
      maxGuestsLabel: 'Введіть кількість',
    },
    BookingWidget: {
      styleTitle: 'Налаштування стилів',
      backgroundColor: 'Колір фону',
      borderColor: 'Колір границь',
      primaryColor: 'Основний колір',
      primaryColorHover: 'Основний колір при наведенні',
      textColor: 'Колір тексту',
      contrastTextColor: 'Контрастний колір тексту',
      errorColor: 'Колір помилки',
      configTitle: 'Інші налаштування',
      showSlots: 'Показувати доступні часові слоти',
      showHalls: 'Показувати зали для вибору',
      translations: 'Текстові елементи',
      policyTitle: 'Заголовок політики',
      policyText: 'Основний текст політики',
      policyItems: 'Елементи політики',
      finishMessage: 'Повідомлення при завершенні резерву',
      save: '<base.save>',
      lang: { '@extends': 'lang' },
      success: '<actions.settings.saved>',
    },
    Deposits: {
      addDeposit: 'Додати категорію',
      addType: 'Додати період',
      active: 'Активна',
      depositPlaceholder: 'Назва категорії',
      typePlaceholder: 'Назва періоду',
      amountInUah: 'Вкажіть суму депозиту в заданий день в',
      amountInUahPlaceholder: 'Сума в грн',
      uah: '<currencies.uah>',
      days: { '@extends': 'types.placeOpenHours' },
      save: '<base.save>',
      success: '<actions.settings.saved>',
      errors: { '@extends': 'errors.validation' },
      minOneType: 'Категорія має містити хоча б один період',
      deletingDeposit: 'Видалення категорії',
      confirmDeleteDeposit: 'Ви впевнені, що хочете видалити дану категорію? Цю дію не можна відмінити',
      deletingType: 'Видалення періоду',
      confirmDeleteType: 'Ви впевнені, що хочете видалити даний період? Цю дію не можна відмінити',
      disableQuantityLabel: 'Не показувати вибір кількості для цієї категорії',
    },
    Promo: {
      title: 'Промоакції',
      current: 'Поточна акція',
      save: '<base.save>',
      noPromo: 'Відсутня',
      percent: 'Відсоток',
      errors: { '@extends': 'errors.validation' },
      success: '<actions.settings.saved>',
    },
    Halls: {
      title: 'Зали закладу',
      addNewHall: 'Додати зал',
      editHall: '<base.edit>',
      activeHall: 'Вибрати зал',
      save: '<base.save>',
      errors: { '@extends': 'errors.validation' },
      spotsForm: {
        fields: { '@extends': 'types.placeSpot' },
        errors: {
          '@any': 'Сталася помилка!',
          '@extends': 'errors.validation',
        },
        duplicate: 'Дублювати',
        delete: 'Bидалити',
        submit: 'Додати стіл',
        update: 'Змінити стіл',
        backToHalls: 'Зали',
        types: { ELLIPSE: 'Круглий', RECT: 'Прямокутний', LOUNGE: 'Шезлонг' },
        ifZeroMinSeats: "Якщо 0 буде враховуватися число з поля 'К-сть Місць '",
        manualChairs: 'Ручне розміщення стільців',
        top: 'Зверху',
        bottom: 'Знизу',
        left: 'Зліва',
        right: 'Справа',
        couch: 'Диван',
        active: 'Активний',
      },
      hallForm: {
        addNewHall: 'Додавання нового залу',
        editHall: 'Редагування залу',
        fields: { '@extends': 'types.placeHall' },
        cancel: '<base.cancel>',
        save: '<base.save>',
        errors: { '@extends': 'errors.validation' },
        pattern: 'Фон залу',
        patterns: {
          none: 'Немає',
          asphalt: 'Асфальт',
          stone: 'Камінь',
          tiles: 'Плитка',
          wood: 'Дерево',
        },
        bgCustom: 'Своя картинка',
      },
      reservationForm: {
        addingReservation: 'Створення резерву',
        editingReservation: 'Редагування резерву',
        cancel: '<base.cancel>',
        save: '<base.save>',
        fields: {
          user: { '@extends': 'types.user' },
          reservation: { '@extends': 'types.reservation' },
        },
        errors: { '@extends': 'errors.validation' },
        pickSpot: 'Підібрати стіл',
        spotNotFound: 'Вільний стіл на обраний час не знайдено',
        addToWaitList: 'Додати в список очікування',
        sameTimeReservationDisabled: 'Створення резервів на однаковий час заборонено адміністратором',
        openMenu: 'Додати страви',
        certificateId: 'ID сертифікату/абонементу',
        findCertificate: 'Знайти сертифікат',
        messages: { '@extends': 'actions.certificates' }
      },
      deletingSpot: 'Видалення стола',
      confirmDeleteSpot: 'Ви впевнені, що хочете видалити даний стіл?',
      deletingHall: 'Видалення зала',
      confirmDeleteHall: 'Ви впевнені, що хочете видалити даний зал? Усі столи у ньому також будуть видалені',
      accept: 'Підтвердити',
      assigningReservation: 'Призначення резерву',
      assignText: 'Ви впевнені, що хочете підтвердити цей резерв і призначити його на обраний столик?',
      reassignText: 'Ви хочете перемістити цей резерв на інший столик, або приєднати ще один стіл до резерву?',
      reassign: 'Перемістити',
      link: 'Приєднати',
      cancel: '<base.cancel>',
      tableReservations: 'Резерви столу',
      close: '<base.close>',
      createReservation: 'Створити резерв',
      newWalkIn: 'Швидка бронь',
      indicateSeats: 'Вкажіть кількість гостей',
      indicateFinish: 'Час закінчення резерву',
      unknownUser: '<base.unknownUser>',
      showPast: 'Показувати завершені резерви',
      hideReservations: 'Сховати список резервів',
    },
    Profile: {
      title: 'Профіль користувача',
      fields: { '@extends': 'types.user' },
      errors: { '@extends': 'errors.validation' },
      messages: { '@extends': 'actions.profile' },
      save: '<base.save>',
      logout: 'Закінчити сесію',
    },
    PeriodsSelector: {
      select: {
        today: 'Сьогодні',
        yesterday: 'Вчора',
        thisWeek: 'Цей тиждень',
        lastWeek: 'Минулий тиждень',
        thisMonth: 'Цей місяць',
        lastMonth: 'Минулий місяць',
        thisYear: 'Цей рік',
        lastYear: 'Минулий рік',
      },
      orChoosePeriod: 'Або виберіть період',
    },
    Reviews: {
      google: 'Відгуки Google',
      facebook: 'Відгуки на Facebook',
      reservble: 'Відгуки Reservble',
      seeAll: '<base.seeAll>',
      connectGoogle: 'Вкажіть ID місця в Google My Business, щоб бачити відгуки з Google',
      connectFacebook: 'Підключіть Facebook Business Manager, щоб бачити відгуки з Facebook',
      connect: 'Підключити',
      noReviews: 'Ви ще не отримали відгуків тут',
    },
    UserModal: {
      birthday: 'Дата нарождення',
      gender: 'Стать',
      familyStatus: 'Сімейний стан',
      comment: 'Коментар',
      save: '<base.save>',
      preferences: 'Вподобання',
      favorite: 'Улюблені страви',
      dontLike: 'Не подобається',
      cuisines: 'Улюблені кухні',
      allergies: 'Алергії',
      statistics: 'Статистика',
      ourPlace: 'Наш заклад',
      allPlaces: 'Усі заклади',
      visits: 'Загальна кількість відвідувань',
      people: 'Середня кількість осіб',
      averageBill: 'Середній чек',
      reservationHistory: 'Історія резервів',
      showAll: 'Показати всі',
    },
    RestaurantStatistics: {
      '@extends': 'types.reservation',
      regularClients: 'Постійні гості',
      total: 'Всього',
      regular: 'Постійних',
      reviews: 'Відгуки <=service>',
      reviewsNumber: {
        1: 'відгук',
        2: 'відгука',
        5: 'відгуків',
      },
      details: 'Детально',
      onlineReservations: 'Онлайн резервів',
      handReservations: 'Резерви, введенні вручну',
      newUsers: 'Нові гості',
      chartTitle: 'Статистика ресторану',
    },
    Payment: {
      title: 'Оплата',
      pay: 'Поповнити баланс',
      currentBalance: 'Поточний баланс',
      balanceDetails:
        'Це ваш поточний баланс. Нижче наведено розподіл витрат за поточний розрахунковий період. Списання з балансу відбудеться наприкінці поточного платіжного періоду',
      currentBillType: 'Поточний тариф',
      billingPeriod: 'Розрахунковий период',
      chatbot: 'Чат-бот в Facebook/Instagram',
      menu: 'Меню',
      tariffPayment: 'Базова вартість тарифу',
      reservationsInTariff: 'Резервів в тарифі',
      reservationsUsed: 'Резервів використано',
      reservationsOverTariff: 'Оплата за додаткові резерви',
      smsTotal: 'Оплата за смс',
      commission: 'Комісія платіжної системи (<=commission>%)',
      total: 'Сума',
      paymentHistory: 'Історія платежів',
      invoice: 'Рахунок',
      payment: 'Оплата',
      invoiceDetails: 'Деталі рахунку',
      invoicesHistory: 'Історія рахунків-фактур',
      invoiceBillType: 'Тариф',
      makingPayment: 'Поповнення балансу',
      selectAmount: 'Вкажіть суму',
      orSelect: 'Або виберіть період для поповнення',
      INPROCESS: 'До сплати',
      DONE: 'Cплачено',
      DONE_PAYMENT: 'Поповнено',
      months: {
        1: 'Один місяць',
        2: '2 місяці',
        3: '3 місяці',
        6: '6 місяців',
        9: '9 місяців',
        12: '12 місяців',
      },
      proceed: 'Продовжити',
      errors: { '@extends': 'errors.validation' },
    },
    SuperAdmin: {
      menu: {
        organizations: 'Організації',
        places: 'Заклади',
        cities: 'Міста',
        tags: 'Теги',
        invoices: 'Рахунки',
      },
      organizations: {
        addOrganization: 'Додати організацію',
        title: 'Доступні організації',
        addingOrganization: 'Додавання організації',
        editingOrganization: 'Редагування організації',
        deletingOrganization: 'Видалення організації',
        confirmDeleteOrganization: 'Ви впевнені, що хочете видалити дану організацію із системи?',
        fields: {
          name: 'Назва',
          created: 'Дата додавання',
        },
        errors: { '@extends': 'errors.validation' },
      },
      places: {
        withTarrifs: 'Тільки з тарифами',
        addRestaurant: 'Додати ресторан',
        title: 'Доступні ресторани',
        activate: 'Активувати',
        deactivate: 'Деактивувати',
        addingRestaurant: 'Додавання ресторану',
        editingRestaurant: 'Редагування ресторану',
        deletingRestaurant: 'Видалення ресторану',
        confirmDeleteRestaurant:
          "Ви впевнені, що хочете видалити даний ресторан із системи? Це назавжди видалить усі дані, пов'язані з ним.",
        activatingRestaurant: 'Активація ресторану',
        deactivatingRestaurant: 'Деактивація ресторану',
        confirmActivateRestaurant: 'Ви впевнені, що хочете активувати даний ресторан?',
        confirmDeactivateRestaurant:
          "Ви впевнені, що хочете деактивувати даний ресторан? Він більше не будет показаний на гостьовому сайті, а всі акаунти, пов'язані з ним, будуть тимчасово заблоковані",
        fields: {
          name: 'Назва',
          ownerName: "Ім'я власника",
          ownerLastName: 'Прізвище власника',
          ownerPhone: 'Телефон власника',
          active: 'Активний',
          billType: 'Тариф',
          created: 'Дата платежу',
          charityBill: 'Сума чеків',
          organization: 'Організація',
        },
        sort: {
          billEnd: 'По даті платежу',
          date: 'По даті додавання',
          name: 'По імені',
        },
        errors: { '@extends': 'errors.validation' },
      },
      cities: {
        addCity: 'Додати місто',
        title: 'Доступні міста',
        addingCity: 'Додавання міста',
        editingCity: 'Редагування міста',
        fields: {
          id: 'ID',
          code: "Ім'я",
        },
        errors: { '@extends': 'errors.validation' },
      },
      tags: {
        addTag: 'Додати тег',
        title: 'Доступні теги',
        addingTag: 'Додавання тега',
        editingTag: 'Редагування тега',
        fields: {
          id: 'ID',
          name: "Ім'я",
          type: 'Тип',
          search: 'Пошук',
        },
        errors: { '@extends': 'errors.validation' },
      },
      invoices: {
        title: 'Нові рахунки',
        approve: 'Погодити',
        approvingInvoices: 'Погодження рахунків',
        confirmApproveInvoices: 'Ви впевнені, що хочете погодити усі вибрані рахунки?',
        editingInvoice: 'Редагування рахунку',
        fields: {
          id: 'ID',
          place: 'Ресторан',
          date: 'Дата',
          amount: 'Сума',
          adjustment: 'Корегування',
          status: 'Статус',
          comment: 'Коментар',
          confirm: '<base.confirm>'
        },
        errors: { '@extends': 'errors.validation' },
      },
    },
    NoAccess: {
      message: 'Ваш поточний тариф не включає доступ до цієї сторінки. Для зміни тарифу зверніться до ',
      admin: 'адміністратора',
    },
    ReservationHistoryModal: {
      close: '<base.close>',
      title: 'Історія змін резерву',
      date: 'Дата',
      time: 'Час',
      user: 'Користувач',
      action: 'Дія',
      guest: '<base.unknownUser>',
      new: 'Резерв створено',
      billRemoved: 'Видалено депозит',
      changed: 'Резерв змінено',
      status: {
        REQUESTED: 'Гість створив новий резерв',
        ACCEPTED: 'Резерв підтверджено',
        INPROGRESS: 'Гість прийшов до ресторану',
        REJECTED: 'Резерв відмовлений адміністратором',
        FINISHED: 'Резерв відмічений як завершений',
        SKIPPED: 'Гість не прийшов',
        CANCELED: 'Резерв відмінений гостем',
      },
      fields: { '@extends': 'types.reservation' },
    },
    Dishes: {
      title: 'Меню',
      addCategory: 'Додати категорію',
      edit: '<base.edit>',
      delete: '<base.delete>',
      addDish: 'Додати страву',
      uploadPhoto: 'Завантажити фотографію',
      addingCategory: 'Додавання категорії',
      editingCategory: 'Редагування категорії',
      addingDish: 'Додавання страви',
      editingDish: 'Редагування страви',
      deletingCategory: 'Видалення категорії',
      confirmDeleteCategory: 'Ви впевнені, що хочете видалити цю категорію з меню?',
      deletingDish: 'Видалення страви',
      confirmDeleteDish: 'Ви впевнені, що хочете видалити дану страву? Вона буде недоступна у майбутньому',
      uah: '<currencies.uah>',
      category: {
        '@extends': 'types.dishCategory',
      },
      dish: {
        '@extends': 'types.dish',
      },
      errors: {
        '@extends': 'errors.validation',
      },
    },
    ConflictingReservationsModal: {
      title: 'На цьому столі є інший резерв',
      cancel: '<base.cancel>',
      confirm: '<base.confirm>',
      description:
        'Ви впевнені, що хочете призначити резерв на цей стіл та вказані дату та час? Цей слот вже зайнятий наступними резервами:',
    },
    OverduePaymentPopup: {
      message: 'Заклад має неоплачений рахунок. Поповніть баланс на сторінці оплати',
    },
    Hours: {
      notWorkingDays: 'Неробочі дні',
      selectDate: 'Виберіть дату',
      addDate: 'Додати дату',
      dateList: 'Список неробочіх днів',
    },
    DepositManagement: {
      switch: 'Депозит',
      total: 'Загальна сума',
      quantity: 'Кіл-ст',
      price: 'Ціна',
      uah: '<currencies.uah>',
    },
    PaymentStatistics: {
      depositsTab: 'Депозити',
      certificatesTab: 'Сертифікати',
      deposits: {
        name: 'Ім‘я',
        phone: 'Номер телефону',
        hall: 'Зал',
        spot: 'Стіл',
        billId: 'ID платежу',
        amount: 'Сума',
        total: 'Загальна сума',
      },
      certificates: {
        firstName: 'Ім‘я',
        phone: 'Номер телефону',
        price: '<types.certificate.price>',
        name: '<types.certificate.name>',
        id: 'Унікальний номер',
        date: 'Дата покупки',
        total: 'Загальна сума',
      },
      uah: '<currencies.uah>',
    },
    MenuModal: {
      title: 'Меню',
      search: 'Введіть назву страви',
      uah: '<currencies.uah>',
      cancel: '<base.cancel>',
      save: 'Додати до бронювання',
      preview: 'Перегляд',
      total: 'Загальна сума',
      prepayment: 'Сума передоплати',
    },
    PreviewMenuModal: {
      title: 'Страви додані до замовлення',
      total: 'Загальна сума',
      uah: '<currencies.uah>',
      edit: '<base.edit>',
    },
    DishCard: {
      uah: '<currencies.uah>',
      qty: 'Кіл-ст',
    },
    DishCategoriesMenu: {
      edit: '<base.edit>',
      delete: '<base.delete>'
    },
    CertificateSettings: {
      addCertificate: 'Створити сертифікат',
      addingCertificate: 'Додавання сертифікату',
      editingCertificate: 'Редагування сертифікату',
      deletingCertificate: 'Видалення сертифікату',
      confirmDeleteCertificate: 'Ви впевнені, що хочете видалити цей сертифікат? Він буде недоступний у майбутньому',
      uploadPhoto: 'Завантажити фотографію',
      fields: { '@extends': 'types.certificate', },
      errors: { '@extends': 'errors.validation' },
    },
    CertificateSettingsCard: {
      '@extends': 'types.certificate',
      active: 'Активний',
      inactive: 'Неактивний',
      days: 'днів',
      uah: '<currencies.uah>',
      edit: '<base.edit>',
      delete: '<base.delete>',
    },
    Certificates: {
      title: 'Сертифікати',
      addCertificate: 'Додати сертифікат',
      addNewCertificate: 'Додавання нового сертифікату',
      certificateDetails: 'Інформація про сертифікат',
      cancel: '<base.cancel>',
      save: '<base.save>',
      fields: { '@extends': 'types.certificate' },
      uah: '<currencies.uah>',
      days: 'днів',
      visits: 'відвідувань',
      activate: 'Активувати',
      sendAgain: '<base.sendAgain>',
      sms: 'СМС повідомлення',
      smsSent: 'Відправлено',
      smsNotSent: 'Не відправлено',
      messages: { '@extends': 'actions.certificates' },
      errors: { '@extends': 'errors.validation' },
      filter: {
        certificates: 'Сертифікати',
        subscriptions: 'Абонементи',
      },
    },
  },
  actions: {
    place: {},
    hall: {
      saved: 'Зміни збережено',
      hallAdded: 'Зал успішно додано',
      spotDeleted: 'Стіл видалено',
    },
    settings: {
      saved: 'Налаштування збережено',
      menuConnected: 'Меню успішно завантажено',
    },
    clients: {
      added: 'Гість доданий',
      exist: "Користувач доданий під ім'ям <=displayedName>",
    },
    administrations: {
      added: 'Користувач доданий',
      exist: "Користувач доданий під ім'ям <=displayedName>",
      edited: 'Права користувача відредаговано',
      deleted: 'Права користувача видалені',
    },
    profile: {
      saved: 'Зміни збережено',
      passwordChanged: 'Пароль було успішно змінено',
      oldPasswordIsInvalid: 'Старий пароль неправильний',
    },
    reservation: {
      success: 'Резерв оновлений успішно',
      alreadyBookedTable: 'У нас вже є замовлення на цей час за цим столом',
      busySlotForReservation: 'Створення резервів на однаковий час заборонено адміністратором',
    },
    certificates: {
      added: 'Сертифікат успішно створено',
      saved: 'Зміни збережено',
      smsResent: 'СМС повторно відправлено',
      notFound: 'Сертифікат з таким номером не знайдено',
      expired: 'Термін дії сертифікату закінчиться на момент резерву',
    },
  },
  service: {
    jwt: {
      invalidToken: 'Неприпустимий токен доступу',
      accessExpired: 'Токен доступу минув',
      signinInProcess: 'Авторизація в процесі...',
      welcomeMessage: 'Ласкаво просимо в RESERVBLE Resto UI',
      loginWithTokenFailed: 'Увійти з токеном не вдалося',
    },
  },
};
