export default (theme, table, inProgress, selected, offsetY) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  touchAction: 'none',
  userSelect: 'none',
  border: `2px ${table.variables?.border || 'solid'} ${theme.palette.primary.main}`,
  backgroundColor: selected ?
    inProgress ? theme.palette.secondary.light : theme.palette.success.light :
    table.active ? theme.palette.common.white : theme.palette.typography.info,
  cursor: table.active ? 'pointer' : 'default',
  transform: `translate(${table.x}px, ${table.y - offsetY}px)`
});
