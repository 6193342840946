import React, { memo, useState } from 'react';
import Box from "@mui/material/Box";
import mova from 'mova';
import TextInput from "../../../form/TextInput";
import Button from "../../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { certificateThunks } from "state/ducks/certificate";
import Typography from "@mui/material/Typography";
import { toastrActions } from "state/ducks/toastr";
import Icon from "../../Icon/Icon";
import { getCertificateIcon } from "utils/certificate";
import IconButton from "@mui/material/IconButton";
import { placeSelectors } from "state/ducks/place";

const t = mova.ns('components.Halls.reservationForm');

const CertificateManagement = ({ values, setFieldValue }) => {
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const activePlace = useSelector(placeSelectors.getActivePlace());

  const findCertificate = async () => {
    const response = await dispatch(certificateThunks.findCertificate({ placeId: activePlace.id, uuid: search }));
    if (response?.payload?.id) {
      // toDO: Check certificate status, expiration date, number of visited
      // toDO: If will be expired - allow adding anyway? Show this in certificate card. Discuss with the team
      setFieldValue('certificate', response.payload);
      setFieldValue('certificateId', response.payload.id);
      setSearch('');
    } else {
      dispatch(toastrActions.showToastr({ variant: 'error', message: t('messages.notFound') }));
    }
  }

  const removeCertificate = () => {
    setFieldValue('certificate', null);
    setFieldValue('certificateId', null);
  }

  if (values.certificate) {
    return (
      <Box
        p={2}
        display='flex'
        alignItems='flex-start'
        gap={2}
        sx={{ borderRadius: '8px', border: '1px solid', borderColor: 'typography.light' }}
      >
        <Box
          flexShrink={0}
          display='flex'
          alignItems='center'
          justifyContent='center'
          width={48}
          height={48}
          backgroundColor='primary.main'
          sx={{ borderRadius: '50%' }}
        >
          <Icon type={getCertificateIcon(values.certificate.certificate)} color='white' />
        </Box>
        <Box flexGrow={1}>
          <Typography variant='caption' color='typography.info' noWrap>
            {values.certificate.certificate.name}
          </Typography>
          <Typography sx={{ mt: 0.5 }}>{values.certificate.data.uuid}</Typography>
        </Box>
        <IconButton onClick={removeCertificate} sx={{ flexShrink: 0 }}>
          <Icon type='close' size={18} />
        </IconButton>
      </Box>
    )
  } else {
    return (
      <Box>
        <TextInput
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t('certificateId')}
          label={t('certificateId')}
          fullWidth
        />
        <Button sx={{ mt: 1 }} onClick={findCertificate} disabled={!search} fullWidth color='primary'>
          {t('findCertificate')}
        </Button>
      </Box>
    );
  }
};

export default memo(CertificateManagement);
