import React, { memo } from 'react';
import mova from 'mova';
import { userThunks } from 'state/ducks/user';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from '@form/FormikTextInput';
import store from '@state/store';
import Button from '@components/Button/Button';
import SocialLoginButton from './components/SocialLoginButton';
import Divider from './components/Divider';
import FormikSelect from '@form/FormikSelect';
import { Typography } from '@mui/material';
import Icon from '@components/Icon/Icon';
import FormikCheckbox from '@form/FormikCheckbox';
import RouterLink from '@components/RouterLink/RouterLink';
import { useSelector } from 'react-redux';
import { userSelectors } from '@state/ducks/user';
import { Redirect } from 'react-router-dom';

const t = mova.ns('auth.Register');

const schema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, () => t('errors.minLength', 2))
    .required(() => t('errors.required')),
  lastName: Yup.string()
    .min(2, () => t('errors.minLength', 2))
    .required(() => t('errors.required')),
  phone: Yup.string().required(() => t('errors.required')),
  password: Yup.string()
    .min(4, () => t('errors.password'))
    .required(() => t('errors.required')),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], () => t('errors.passwordsMatch'))
    .required(() => t('errors.required')),
  restaurant: Yup.string().required(() => t('errors.required')),
  city: Yup.string().required(() => t('errors.required')),
  acceptTerms: Yup.boolean().oneOf([true], () => t('errors.confirm')),
});

const Register = () => {
  const acceptCheckboxLabel = (
    <>
      {t('confirm')}&nbsp;<RouterLink to='/policies/privacy'>{t('legal.privacyWhat')}</RouterLink>&nbsp;
      {t('and')}&nbsp;<RouterLink to='/policies/terms'>{t('legal.termsWhat')}</RouterLink>
    </>
  );

  const cityOptions = ['odessa'].map(city => ({ label: t(`cities.${city}`), id: city }));

  const user = useSelector(userSelectors.getCurrentUser());
  if (user) {
    return <Redirect to='/' />
  }

  return (
    <Form>
      <Typography mb={3} variant='h0' component='h1' align='center'>
        {t('title')}
      </Typography>
      <SocialLoginButton sx={{ my: 1 }} fullWidth provider='google' onClick={() => {}} />
      <SocialLoginButton sx={{ my: 1 }} fullWidth provider='facebook' onClick={() => {}} />
      <Divider />
      <FormikTextInput sx={{ my: 1 }} fullWidth name='firstName' placeholder={t('fields.firstName')} />
      <FormikTextInput sx={{ my: 1 }} fullWidth name='lastName' placeholder={t('fields.lastName')} />
      <FormikTextInput sx={{ my: 1 }} fullWidth name='phone' placeholder={t('fields.phone')} />
      <FormikTextInput sx={{ my: 1 }} fullWidth name='password' placeholder={t('fields.password')} type='password' />
      <FormikTextInput
        sx={{ my: 1 }}
        fullWidth
        name='confirmPassword'
        placeholder={t('fields.confirmPassword')}
        type='password'
      />
      <FormikTextInput sx={{ my: 1 }} fullWidth name='restaurant' placeholder={t('fields.restaurant')} />
      <FormikSelect sx={{ my: 1 }} type='white' fullWidth name='city' items={cityOptions} valuePath='id' labelPath='label' />
      <FormikCheckbox sx={{ my: 1 }} name='acceptTerms' label={acceptCheckboxLabel} />
      <Button
        sx={{ mt: 3 }}
        fullWidth
        type='submit'
        variant='contained'
        color='primary'
        endIcon={<Icon type='arrow' />}
      >
        {t('submit')}
      </Button>
      <Typography mt={2} variant='body2' align='center' color='typography.info'>
        {t('haveAccount')}&nbsp;<RouterLink to='/login'>{t('login')}</RouterLink>
      </Typography>
    </Form>
  );
};

export default memo(withFormik({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
    confirmPassword: '',
    restaurant: '',
    city: 'odessa',
    acceptTerms: false,
  }),
  validationSchema: schema,
  handleSubmit: (credentials, helpers) => {
    store.dispatch(userThunks.loginUser(credentials, () => helpers.setSubmitting(false)));
  },
})(Register));
