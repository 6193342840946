import React, { memo } from 'react';
import {Avatar as MuiAvatar} from '@mui/material';
import PropTypes from 'prop-types';

const Avatar = ({size = 56, src, sx = {}, ...rest}) => {
  return (
    <MuiAvatar sx={{width: size, height: size, ...sx}} src={src} {...rest} />
  );
};

Avatar.propTypes = {
  size: PropTypes.number
};

export default memo(Avatar);
