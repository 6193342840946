import React, { memo, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { billingSelectors, billingThunks } from "state/ducks/billing";
import { placeSelectors } from "../../../../state/ducks/place";
import Card from "../../../components/Card/Card";
import Typography from "@mui/material/Typography";
import { Table, TableBody } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "@emotion/styled";
import TableCell from "@mui/material/TableCell";
import mova from 'mova';
import { useLocation } from "react-router-dom";

const t = mova.ns('components.PaymentStatistics');

const StyledTableCell = styled(TableCell)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

const getUserName = (user) => {
  return `${user?.firstName || ''} ${user?.lastName || ''}`;
}

const PaymentStatisticsCertificates = () => {
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const placeCertificates = useSelector(billingSelectors.getPlaceCertificates());
  const location = useLocation();
  const { from, to } = queryString.parse(location.search);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activePlace.id && from && to) {
      dispatch(billingThunks.fetchPlaceCertificates({ from, to, placeId: activePlace.id }));
    }
  }, [activePlace.id, from, to, dispatch]);

  return Object.entries(placeCertificates).map(([day, bills]) => {
    let total = 0;
    return (
      <Card sx={{ p: 2 }} key={day}>
        <Typography sx={{ mb: 2 }} variant='h3'>{day}</Typography>
        <Table sx={{ mt: 2 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('certificates.firstName')}</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('certificates.phone')}</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('certificates.price')}</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('certificates.name')}</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('certificates.id')}</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }} component='th'>{t('certificates.date')}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bills.map(({ id, certificate, data, amount }) => {
              total = total + amount;
              return (
                <TableRow key={id}>
                  <StyledTableCell>{getUserName(data?.user)}</StyledTableCell>
                  <StyledTableCell>{data?.user?.phone}</StyledTableCell>
                  <StyledTableCell>{certificate?.price} {t('uah')}</StyledTableCell>
                  <StyledTableCell>{certificate?.name}</StyledTableCell>
                  <StyledTableCell>{data?.uuid}</StyledTableCell>
                  <StyledTableCell>{moment(certificate?.createdAt).format('DD-MM-YYYY')}</StyledTableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <StyledTableCell colSpan={5} sx={{ fontWeight: 'bold' }}>{t('certificates.total')}</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }}>{total} {t('uah')}</StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    )
  });
};

export default memo(PaymentStatisticsCertificates);
