import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/apiService';
import queryString from 'query-string';
import { toastrActions } from '../toastr';
import { incrementReservationsKey, setNewReservations, addOrReplaceHallReservation } from './slice';
import reservationSelectors from './selectors';
import { placeSelectors } from 'state/ducks/place';
import mova from 'mova';
import moment from 'moment';
import { isDefined } from 'utils/lo/lo';
import { formatDate } from 'utils/date';
import createDebouncedAsyncThunk from 'state/utils/createDebouncedAsyncThunk';

const t = mova.ns('actions.reservation');

const fetchReservations = createAsyncThunk(
  'reservation/fetchReservations',
  async ({ placeId, date, unconfirmed, ...query }, { getState }) => {
    const activePlaceId = placeSelectors.getActivePlace()(getState()).id;
    const listParams = reservationSelectors.getListParams()(getState());

    if (date && query.status !== 'REQUESTED') {
      query.date = formatDate(date);
    }

    if (unconfirmed && query.status === 'ACCEPTED') {
      query.confirmed = false;
    }

    return api.get(
      `/places/${placeId || activePlaceId}/reservations?${queryString.stringify({
        limit: date ? undefined : listParams.limit,
        page: date ? undefined : listParams.activePage + 1,
        status: listParams.filter,
        ...query,
      })}`,
    );
  },
);

const fetchHallReservations = createAsyncThunk('reservation/fetchHallReservations', async ({ placeId, date, ...body }) => {
    if (date) {
      body.date = formatDate(date);
    }

    return api.get(`/places/${placeId}/reservations/halls?${queryString.stringify(body)}`);
  },
);

const fetchReservationsTimeline = createAsyncThunk(
  'reservation/fetchReservationsTimeline',
  async ({ placeId, ...query }, { getState }) => {
    query.date = formatDate(query.date);
    const activePlaceId = placeSelectors.getActivePlace()(getState()).id;
    return api.get(`/places/${placeId || activePlaceId}/reservations?${queryString.stringify(query)}`);
  },
);

const fetchReservationsTotal = createDebouncedAsyncThunk(
  'reservation/fetchReservationsTotal',
  ({ placeId, date, q }, { dispatch }) => {
    dispatch(fetchNewReservationsCount({ placeId }));
    return api
      .get(
        `/places/${placeId}/reservations/total?${queryString.stringify({
          date: isDefined(date) ? formatDate(date) : undefined,
          q: isDefined(q) ? q : undefined,
        })}`,
      )
      .then(res => {
        dispatch(setNewReservations(res.REQUESTED));
        return res;
      });
  },
);

const fetchNewReservationsCount = createDebouncedAsyncThunk(
  'reservation/fetchNewReservationsCount',
  ({ placeId }, { dispatch }) => {
    return api.get(`/places/${placeId}/reservations/total`).then(res => {
      dispatch(setNewReservations(res.REQUESTED));
      return res;
    });
  },
  600,
);

const addReservation = createAsyncThunk('reservation/addReservation', async (data, { dispatch, getState }) => {
  const placeId = data.placeId || placeSelectors.getActivePlace()(getState()).id;
  return api
    .post(`/places/${placeId}/reservations`, data)
    .then(newReservation => {
      dispatch(incrementReservationsKey());
      dispatch(addOrReplaceHallReservation(newReservation));
      dispatch(toastrActions.showToastr({ message: t('success') }));
      return newReservation;
    })
    .catch(err => {
      dispatch(toastrActions.showToastr({ variant: 'error', message: t(err) }));
    });
});

const updateReservation = createAsyncThunk(
  'reservation/updateReservation',
  async ({ statusTo, callback, ...data }, { dispatch, getState }) => {
    const placeId = placeSelectors.getActivePlace()(getState()).id;
    return api
      .put(`/places/${placeId}/reservations/${data.id}`, { ...data, status: statusTo })
      .then((updatedReservation) => {
        dispatch(incrementReservationsKey());
        dispatch(addOrReplaceHallReservation(updatedReservation));
        callback && callback();
        dispatch(toastrActions.showToastr({ message: t('success') }));
      })
      .catch(err => {
        dispatch(toastrActions.showToastr({ variant: 'error', message: t(err) }));
      });
  },
);

const fetchFreeSpot = createAsyncThunk('reservation/freeSpot', async ({ from, to, seats }, { getState }) => {
  const placeId = placeSelectors.getActivePlace()(getState()).id;
  return api.get(
    `/places/${placeId}/reservations/free?from=${moment(from).format('YYYY-MM-DD HH:mm')}&to=${moment(to).format(
      'YYYY-MM-DD HH:mm',
    )}&seats=${seats}`,
  );
});

const fetchReservationHistory = createAsyncThunk('reservation/history', async (reservation, { getState }) => {
  const placeId = placeSelectors.getActivePlace()(getState()).id;
  return api.get(`/places/${placeId}/reservations/${reservation.id}/history`);
});

export default {
  fetchReservations,
  fetchHallReservations,
  addReservation,
  updateReservation,
  fetchReservationsTotal,
  fetchNewReservationsCount,
  fetchReservationsTimeline,
  fetchFreeSpot,
  fetchReservationHistory,
};
