export const CATEGORY_CERTIFICATE = "CERTIFICATE";
export const CATEGORY_SUBSCRIPTION = "SUBSCRIPTION";
export const TYPE_VISITS = "VISITS";
export const TYPE_AMOUNT = "AMOUNT";
export const EXPIRATION_DATE = "DATE";
export const EXPIRATION_DAYS = "DAYS";
export const ACTIVATION_PURCHASE = "PURCHASE";
export const ACTIVATION_VISIT = "VISIT";

export const CERTIFICATE_STATUS_DRAFT = 'DRAFT';
export const CERTIFICATE_STATUS_INACTIVE = 'INACTIVE';
export const CERTIFICATE_STATUS_ACTIVE = 'ACTIVE';
export const CERTIFICATE_STATUS_USED = 'USED';
export const CERTIFICATE_STATUS_EXPIRED = 'EXPIRED';

export const getCertificateIcon = (certificate) => {
  if (certificate.category === CATEGORY_SUBSCRIPTION) {
    return 'calendar';
  } else if (certificate.category === CATEGORY_CERTIFICATE) {
    if (certificate.data.type === TYPE_VISITS) {
      return 'pedestrian';
    } else if (certificate.data.type === TYPE_AMOUNT) {
      return 'dollar';
    }
  }
}

export const getCertificateType = (certificate, t) => {
  if (certificate.category === CATEGORY_SUBSCRIPTION) {
    return `${certificate.data.duration} ${t('days')}`;
  } else if (certificate.category === CATEGORY_CERTIFICATE && certificate.data.type === TYPE_VISITS) {
    return `${certificate.data.visits} ${t('visits')}`
  } else if (certificate.category === CATEGORY_CERTIFICATE && certificate.data.type === TYPE_AMOUNT) {
    return `${certificate.data.amount} ${t('uah')}`;
  }
}
