import React from 'react';
import theme from '../../theme';

export default function dollar({ color = theme.palette.primary.main, size = 24 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.999 8.5H17.999C17.999 5.663 15.244 4.369 12.999 4.071V2H10.999V4.071C8.75402 4.369 5.99902 5.663 5.99902 8.5C5.99902 11.206 8.66502 12.613 10.999 12.93V17.9C9.55102 17.649 7.99902 16.876 7.99902 15.5H5.99902C5.99902 18.089 8.42402 19.619 10.999 19.936V22H12.999V19.93C15.244 19.632 17.999 18.337 17.999 15.5C17.999 12.663 15.244 11.369 12.999 11.071V6.1C14.329 6.339 15.999 7.041 15.999 8.5ZM7.99902 8.5C7.99902 7.041 9.66902 6.339 10.999 6.1V8.4995V10.899C9.62802 10.646 7.99902 9.897 7.99902 8.5ZM15.999 15.5C15.999 16.959 14.329 17.661 12.999 17.9V13.1C14.329 13.339 15.999 14.041 15.999 15.5Z'
        fill={color}
      />
    </svg>
  );
}


