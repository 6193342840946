const getReservations = () => state => state.reservation.list.data?.rows;
const getHallReservations = () => state => state.reservation.hallList.data;
const getReservationsCount = () => state => state.reservation.list.data?.count;
const getReservationsTotal = () => state => state.reservation.total.data;
const getNewReservations = () => state => state.reservation.newReservations;
const getReservationsKey = () => state => state.reservation.reservationsKey;
const getReservationsLoading = () => state => state.reservation.list.isLoading;
const getReservationsError = () => state => state.reservation.list.error;

const getReservationsTimeline = () => state => state.reservation.timeline.data?.rows;
const getReservationsTimelineCount = () => state => state.reservation.timeline.data?.count;

const getListParams = () => state => state.reservation.listParams;

const getReservationDrawer = () => state => state.reservation.drawer;
const getReservationHistory = () => state => state.reservation.history.data;
const getReservationHistoryLoading = () => state => state.reservation.history.isLoading;

export default {
  getReservations,
  getHallReservations,
  getReservationsLoading,
  getReservationsError,
  getReservationsCount,
  getReservationsTotal,
  getNewReservations,
  getReservationsKey,

  getListParams,

  getReservationsTimeline,
  getReservationsTimelineCount,
  getReservationDrawer,
  getReservationHistory,
  getReservationHistoryLoading,
};
