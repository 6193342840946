import React, { memo, useEffect } from 'react';
import Modal from '@components/Modal/Modal';
import mova from 'mova';
import { Table, TableBody, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button/Button';
import { reservationSelectors, reservationThunks } from '@state/ducks/reservation';
import Loader from '../../Loader/Loader';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import date from 'filters/date';
import Icon from "../../Icon/Icon";
import IconButton from "@mui/material/IconButton";
import { clone } from "utils/utils";
import { placeSelectors } from "state/ducks/place";

const t = mova.ns('components.ReservationHistoryModal');

const allowedProperties = ['from', 'to', 'event', 'seats', 'comment', 'spots'];

const prettifyChanges = (changes, personnel, customTags) => {
  const result = clone(changes);

  for (const prop in result) {
    if (!allowedProperties.includes(prop)) {
      delete result[prop];
    }
  }

  if (result.from) {
    result.from = date(result.from, 'DD.MM.YY HH:mm');
  }

  if (result.to) {
    result.to = date(result.to, 'DD.MM.YY HH:mm');
  }

  if (result.spots) {
    result.spots = result.spots.map(s => s.label).join(", ");
  }

  if (result.event) {
    result.event = customTags[result.event] || t(`fields.events.${result.event}`)
  }

  if (changes.managerId) {
    const manager = personnel.find(u => u.id === changes.managerId);
    if (manager) {
      result.manager = manager.displayedName;
    }
  }

  return Object.keys(result).map(key => (
    <TableRow key={key}>
      <TableCell padding='none' sx={{ color: 'white', border: 'none' }}>{t(`fields.${key}`)}</TableCell>
      <TableCell padding='none' sx={{ pl: 2, color: 'white', border: 'none' }}>{result[key]}</TableCell>
    </TableRow>
  ));
}

const ReservationHistoryModal = ({ onClose, reservation }) => {
  const dispatch = useDispatch();
  const history = useSelector(reservationSelectors.getReservationHistory());
  const loading = useSelector(reservationSelectors.getReservationHistoryLoading());
  const personnel = useSelector(placeSelectors.getPlaceManagers()).map(u => u.user);
  const customTags = useSelector(placeSelectors.getCustomTags());

  useEffect(() => {
    dispatch(reservationThunks.fetchReservationHistory(reservation));
  }, [dispatch, reservation]);

  const dataLoaded = !loading && history?.length > 0 && history[0].reservationId === reservation.id;
  const getHistoryAction = (item) => {
    if (item.data.status === 'new') {
      return t('new');
    } else if (item.data.status === 'billRemoved') {
      return t('billRemoved');
    } else if (item.data.changes?.status) {
      return t(`status.${item.data.changes.status}`);
    } else {
      return t('changed');
    }
  };


  const historyItems = (history || []).map(h => (
    <TableRow key={h.id}>
      <TableCell>{date(h.data.createdAt, 'DD.MM.YY')}</TableCell>
      <TableCell>{date(h.data.createdAt, 'HH:mm')}</TableCell>
      <TableCell>
        {h.user.id === reservation.userId && t('guest')}
        {h.user.id !== reservation.userId && h.user.displayedName}
      </TableCell>
      <TableCell>{getHistoryAction(h)}</TableCell>
      <TableCell>
        {h.data?.changes && Object.keys(h.data.changes).length > 0 && (
          <Tooltip
            title={
              <Table>
                <TableBody>
                  {prettifyChanges(h.data.changes, personnel, customTags)}
                </TableBody>
              </Table>
            }
            enterTouchDelay={0}
          >
            <IconButton>
              <Icon type='comment' />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  ));

  return (
    <Modal
      open
      onClose={onClose}
      title={t('title')}
      actions={<Button onClick={onClose}>{t('close')}</Button>}
      maxWidth='sm'
    >
      {loading && <Loader />}
      {dataLoaded && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component='th' sx={{ fontWeight: 'bold' }}>{t('date')}</TableCell>
              <TableCell component='th' sx={{ fontWeight: 'bold' }}>{t('time')}</TableCell>
              <TableCell component='th' sx={{ fontWeight: 'bold' }}>{t('user')}</TableCell>
              <TableCell component='th' sx={{ fontWeight: 'bold' }}>{t('action')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {historyItems}
          </TableBody>
        </Table>
      )}
    </Modal>
  );
};

export default memo(ReservationHistoryModal);
