import React, { memo } from 'react';
import Typography from '@mui/material/Typography';
import mova from 'mova';
import BorderCollapseTable from '@components/BorderCollapseTable/BorderCollapseTable';

const t = mova.ns('components.Administration');

const AdminTable = ({ children }) => {
  return (
    <BorderCollapseTable sx={{ tableLayout: 'fixed' }}>
      <thead>
        <tr>
          <th width='40' />
          <th />
          <th><Typography color='typography.info' variant='caption'>{t('fields.phone')}</Typography></th>
          <th><Typography color='typography.info' variant='caption'>{t('fields.email')}</Typography></th>
          <th><Typography color='typography.info' variant='caption'>{t('fields.role')}</Typography></th>
          <th><Typography color='typography.info' variant='caption'>{t('fields.lastLogin')}</Typography></th>
          <th width='80' />
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </BorderCollapseTable>
  );
};

export default memo(AdminTable);
