import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'state/utils/loadDataExtraReducer';
import loadDataInitialState from 'state/utils/loadDataInitialState';

const channelSlice = createSlice({
  name: 'channel',
  initialState: {
    list: loadDataInitialState([]),
    details: loadDataInitialState(null),
    channelStats: loadDataInitialState([]),
  },
  extraReducers: {
    ...loadDataExtraReducer(thunks.fetchChannels, 'list'),
    ...loadDataExtraReducer(thunks.addChannel, 'list'),
    ...loadDataExtraReducer(thunks.deleteChannel, 'list'),
    ...loadDataExtraReducer(thunks.updateChannel, 'list'),
    ...loadDataExtraReducer(thunks.fetchChannelStats, 'channelStats'),
  },
});

export default channelSlice.reducer;
