import React, { PureComponent } from 'react';
import queryString from 'query-string';
import localStorage from 'utils/localStorage';
import Loader from '@components/Loader/Loader';

class SocialLogin extends PureComponent {
  componentDidMount() {
    const { accessToken } = queryString.parse(window.location.search);
    localStorage.set(localStorage.Keys.TOKEN, accessToken);
    let redirectionPath = localStorage.get(localStorage.Keys.OAUTH_REDIRECTION) || '/';
    setTimeout(() => {
      window.location = redirectionPath || '/';
    }, 300);
  }

  render() {
    return <Loader />;
  }
}

export default SocialLogin;
