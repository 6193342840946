import classMethodDecorator from './_classMethodDecorator';

function debounce(fn, delayMs) {
  const delay = delayMs && delayMs > 0 ? delayMs : 0;
  let timeoutKey;
  return function wrapper(...args) {
    clearTimeout(timeoutKey);
    timeoutKey = setTimeout(() => fn.apply(this, args), delay);
  };
}

export default function debounceDecorator(delay) {
  return classMethodDecorator((target, key, descriptor) => ({
    ...descriptor,
    value: debounce(descriptor.value, delay),
  }));
}
