import React, { memo } from 'react';
import Card from "../../Card/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { lineClamp } from "../../../../utils/styles";
import Icon from "../../Icon/Icon";
import SelectQuantity from "../../SelectQuantity/SelectQuantity";
import { useTheme } from "@mui/material";
import mova from 'mova';

const t = mova.ns('components.DishCard');

const DishCard = ({ dish, getSelected, setSelected, editMode, ...rest }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        flexShrink: 0,
        p: 2,
        display: 'flex',
        flexDirection: { xs: 'column', smmd: 'row' },
        gap: 2,
        borderRadius: '8px',
        border: `1px solid ${theme.palette.typography.light}`,
        boxShadow: 'none',
      }}
      {...rest}
    >
      {dish.photoUrl && (
        <Box
          component='img'
          src={dish.photoUrl}
          width={{ xs: '100%', smmd: 78 }}
          height={{ xs: 150, smmd: 78 }}
          flexBasis={{ xs: 150, smmd: 78 }}
          flexShrink={0}
          alt={dish.name}
          sx={{ objectFit: 'cover' }}
        />
      )}
      {!dish.photoUrl && (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          backgroundColor='typography.light'
          width={{ xs: '100%', smmd: 78 }}
          height={{ xs: 150, smmd: 78 }}
          flexBasis={{ xs: 150, smmd: 78 }}
          flexShrink={0}
          sx={{ borderRadius: '8px' }}
        >
          <Box component='img' src='/images/bowl-food.svg' alt='No dish photo' />
        </Box>
      )}
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        flexGrow={1}
        flexShrink={1}
        gap={0.5}
        minWidth={0}
      >
        <Box width='100%'>
          <Typography variant='h3' noWrap>{dish.name}</Typography>
          <Typography style={lineClamp(3)} color='primary.light' variant='caption'>{dish.description}</Typography>
        </Box>
        <Box display='flex' alignItems='center' gap={1}>
          <Typography variant='caption' color='secondary.main'>{dish.weight}</Typography>
          {dish.variables?.top && <Icon type='top' size={16} cursor='default' />}
          {dish.variables?.hot && <Icon type='hot' size={16} cursor='default' />}
          {dish.variables?.vegetarian && <Icon type='vegetarian' cursor='default' size={16} />}
        </Box>
      </Box>
      <Box
        flexBasis={{ xs: '', smmd: 190 }}
        display='flex'
        alignItems='center'
        justifyContent='flex-end'
        flexGrow={0}
        flexShrink={0}
        gap={1}
      >
        <Box flexBasis={80} flexGrow={0} flexShrink={0} display='flex' alignItems='center'>
          <Typography variant='h3'>{dish.price} {t('uah')}</Typography>
        </Box>
        <Box display='flex' alignItems='center' flexBasis={110} flexGrow={0} flexShrink={0} justifyContent='flex-end'>
          {editMode && <SelectQuantity selected={getSelected(dish)} setSelected={(val) => setSelected(dish, val)} />}
          {!editMode && <Typography variant='h3'>{t('qty')}: {getSelected(dish)}</Typography>}
        </Box>
      </Box>
    </Card>
  );
};

export default memo(DishCard);
