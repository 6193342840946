import React, { memo, useEffect, useState } from 'react';
import mova from 'mova';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from '@form/FormikTextInput';
import FormikSelect from '@form/FormikSelect';
import FormikDatepicker from '@form/FormikDatepicker';
import FormikTimepicker from '@form/FormikTimepicker';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { hallSelectors } from 'state/ducks/hall';
import { userSelectors } from 'state/ducks/user';
import { reservationActions, reservationSelectors, reservationThunks } from 'state/ducks/reservation';
import { getReservationColor, PHONE_REGEX, sortSpots } from 'utils/utils';
import { Chip, Drawer, MenuItem, styled, useTheme } from '@mui/material';
import Button from '../Button/Button';
import { toastrActions } from 'state/ducks/toastr';
import IconButton from '@mui/material/IconButton';
import Icon from '../Icon/Icon';
import { nearest15Minutes } from 'utils/date';
import useModal from '../../../utils/hooks/useModal/useModal';
import api from 'services/apiService';
import ConflictingReservationsModal from './components/ConflictingReservationsModal';
import FormikCheckbox from "../../form/FormikCheckbox";
import { placeSelectors } from "../../../state/ducks/place";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DepositManagement from "../ReservationCard/components/DepositManagement";
import MenuModal from "../ReservationCard/components/MenuModal";
import { dishSelectors } from "../../../state/ducks/dish";
import { accessSelectors } from "../../../state/ducks/access";
import FormikClientPhoneInput from "../../form/FormikClientPhoneInput";
import CertificateManagement from "../ReservationCard/components/CertificateManagement";

const t = mova.ns('components.Halls.reservationForm');

const StyledDrawer = styled(Drawer)(() => ({
  '.MuiBackdrop-root': {
    left: 'calc(100vw - 380px)',
  },
  '.MuiPaper-root': {
    width: 380,
  },
}));

const schema = (userRequired, adminRequired = false, isWaitList = false) =>
  Yup.object().shape({
    phone: Yup.string().matches(PHONE_REGEX, () => t('errors.phone')),
    firstName: userRequired ? Yup.string().required(() => t('errors.required')) : Yup.string(),
    lastName: Yup.string().nullable(),
    from: Yup.date().required(() => t('errors.required')),
    comment: Yup.string(),
    event: Yup.string(),
    hallId: isWaitList ? Yup.number().nullable(true) : Yup.number().nullable(true).required(() => t('errors.required')),
    spots: isWaitList ? Yup.array() : Yup.array().min(1, () => t('errors.required')),
    seats: Yup.number()
      .required(() => t('errors.required'))
      .min(1, () => t('errors.minNumber', { min: 1 })),
    managerId: adminRequired
      ? Yup.number().nullable(true).required(() => t('errors.required'))
      : Yup.number().nullable(true),
  });

const getFirstHallId = (halls) => {
  if (!Array.isArray(halls) || halls.length === 0) {
    return '';
  }

  return halls.reduce((lowest, hall) => {
    if (lowest === null || hall.order < lowest.order) {
      return hall;
    }
    return lowest;
  }, null)?.id || '';
}

const AddReservationDrawer = ({ table, reservation, place, date }) => {
  const [conflictsModalOpen, showConflicts, closeConflictsModal, conflictData] = useModal(false);
  const [menuModalOpen, openMenuModal, closeMenuModal] = useModal(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [existingUser, setExistingUser] = useState(false);
  const [isWaitList, setIsWaitList] = useState(reservation?.status === 'WAITLIST');
  const halls = useSelector(hallSelectors.getHalls());
  const allSpots = halls.map(h => h.spots).reduce((arr, val) => [...arr, ...val], []);
  const searchUsers = useSelector(userSelectors.getSearchUsers());
  const activePlace = useSelector(placeSelectors.getActivePlace());
  const selectAdmin = activePlace.billTypeExtra?.includes('highload') && place.adminSelection;
  const showWaitList = activePlace.billTypeExtra?.includes('highload') && activePlace.showWaitList;
  const admins = useSelector(placeSelectors.getPlaceManagers())
    .filter(u => u.permissions.includes('admin'))
    .map(u => u.user);
  const currentUser = useSelector(userSelectors.getCurrentUser());
  const userIsAdmin = admins.some(u => u.id === currentUser.id);
  const defaultSeats = table?.type === 'LOUNGE' && table?.variables?.seats ? table?.variables?.seats : '';
  const customTags = useSelector(placeSelectors.getCustomTags());
  const deposits = useSelector(placeSelectors.getDeposits());
  const dishes = useSelector(dishSelectors.getDishes());
  const showMenu = useSelector(accessSelectors.showMenu());
  const showCertificates = useSelector(accessSelectors.showCertificates());

  const formikContext = useFormik({
    initialValues: {
      phone: reservation?.user?.phone || '',
      firstName: reservation?.user?.firstName || reservation?.variables?.user?.firstName || '',
      lastName: reservation?.user?.lastName || reservation?.variables?.user?.lastName || '',
      seats: reservation?.seats || defaultSeats,
      from: reservation?.from ? new Date(reservation.from) : nearest15Minutes(date).toDate(),
      to: reservation?.to
        ? new Date(reservation.to)
        : place?.reservationAllDay
          ? moment(date || moment())
            .set('hour', place.openHours[moment(date || moment()).isoWeekday() - 1].closedH)
            .set('minute', place.openHours[moment(date || moment()).isoWeekday() - 1].closedM)
            .toDate()
          : nearest15Minutes(date)
            .add(place?.reservationDuration || 120, 'minutes')
            .toDate(),
      comment: reservation?.comment || '',
      event: reservation?.event || 'other',
      hallId: reservation?.hallId || table?.hallId || getFirstHallId(halls) || halls?.[0]?.id || '',
      spots: (table?.id && [table.id]) || (reservation?.spots?.length && reservation?.spots.map(s => s.id)) || [],
      managerId: selectAdmin
        ? reservation
          ? reservation?.managerId || ''
          : userIsAdmin ? currentUser.id : ''
        : currentUser.id,
      variables: {
        keepTable: reservation?.variables?.keepTable || false,
        waitForTomorrow: reservation?.variables?.waitForTomorrow || false,
      },
      depositOrder: reservation?.bill?.data?.order?.fullAmount ? [] : (reservation?.bill?.data?.order || []),
      depositUpdated: false,
      banquetOrder: reservation?.bill?.data?.order?.fullAmount ? reservation?.bill?.data?.order : {},
      banquetUpdated: false,
      certificate: reservation?.certificate,
      certificateId: reservation?.certificateId,
    },
    validationSchema: schema(!reservation || reservation.user, selectAdmin, isWaitList),
    onSubmit: (data, helpers) => {
      const conflictCheckBody = {
        from: data.from.toISOString(),
        to: data.to.toISOString(),
        spots: data.spots.map(s => ({ id: s, label: allSpots.find(sp => sp.id === s)?.label })),
      };

      if (isWaitList) {
        data.hallId = null;
      }

      const confirmReservation = async () => {
        helpers.setSubmitting(true);
        if (reservation) {
          await dispatch(
            reservationThunks.updateReservation({
              ...reservation,
              ...data,
              depositOrder: data.depositUpdated ? data.depositOrder : undefined,
              banquetOrder: data.banquetUpdated ? data.banquetOrder : undefined,
              spots: data.spots.map(s => ({ id: s, label: allSpots.find(sp => sp.id === s)?.label })),
            }),
          );
          helpers.setSubmitting(false);
          dispatch(reservationActions.closeReservationDrawer());
        } else {
          const result = await dispatch(
            reservationThunks.addReservation({
              ...data,
              spots: data.spots.map(s => ({ id: s, label: allSpots.find(sp => sp.id === s)?.label })),
              status: isWaitList ? 'WAITLIST' : 'ACCEPTED',
              depositOrder: data.depositUpdated ? data.depositOrder : undefined,
              banquetOrder: data.banquetUpdated ? data.banquetOrder : undefined,
            }),
          );
          helpers.setSubmitting(false);
          if (result?.payload) {
            dispatch(reservationActions.closeReservationDrawer());
          }
        }
      };

      api.post(`/places/${place.id}/reservations/checkBusySpots`, conflictCheckBody)
        .then(res => {
          const conflicts = res.filter(r => r.id !== reservation?.id);
          if (conflicts.length > 0) {
            showConflicts({
              conflicts,
              confirmReservation: place.disableSameTimeReservation
                ? () => dispatch(toastrActions.showToastr({ variant: 'error', message: t('sameTimeReservationDisabled') }))
                : confirmReservation
            });
          } else {
            helpers.setSubmitting(true);
            confirmReservation();
          }
        })
        .finally(() => {
          helpers.setSubmitting(false);
        });
    },
  });

  const spots = (halls.find(h => h.id === formikContext.values.hallId)?.spots || [])
    .filter(s => s.active)
    .slice()
    .sort(sortSpots);
  const drawer = useSelector(reservationSelectors.getReservationDrawer());
  const open = drawer.open;

  const onClose = () => dispatch(reservationActions.closeReservationDrawer());

  const eventOptions = ['other', 'business', 'date', 'family', 'friends', 'birthday', 'football'].map(
    (event, index) => (
      <MenuItem key={event} value={event} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box sx={{ backgroundColor: theme.palette.reservations[index] + ' !important', height: 40, width: 40 }} />
        {customTags[event] || t(`fields.reservation.events.${event}`)}
      </MenuItem>
    ),
  );

  useEffect(() => {
    formikContext.setFieldValue('hallId', '');
    formikContext.setFieldValue('spots', []);
  }, [place?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      formikContext.resetForm();
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const selectedOption = searchUsers.find(u => u.phone === formikContext.values.phone);

    if (selectedOption && !existingUser) {
      formikContext.setFieldValue('firstName', selectedOption.firstName);
      formikContext.setFieldValue('lastName', selectedOption.lastName);
      setExistingUser(true);
    } else if (!selectedOption) {
      setExistingUser(false);
    }
  }, [formikContext.values.phone, searchUsers, formikContext.setFieldValue]); // eslint-disable-line

  const clearSpots = () => {
    formikContext.setFieldValue('spots', []);
  };

  const removeSpot = id => {
    formikContext.setFieldValue(
      'spots',
      formikContext.values.spots.filter(s => s !== id),
    );
  };

  const currentDuration = moment.duration(moment(formikContext.values.to).diff(moment(formikContext.values.from)))
    .asMinutes();
  const fromDateChanged = val => {
    if (place?.reservationAllDay) {
      const to = moment(val)
        .set('hour', place.openHours[moment(val).isoWeekday() - 1].closedH)
        .set('minute', place.openHours[moment(val).isoWeekday() - 1].closedM);
      if (moment() > to) {
        to.add(1, 'days');
      }
      formikContext.setFieldValue('to', to.toDate());
    } else {
      formikContext.setFieldValue(
        'to',
        moment(val)
          .add(currentDuration || place?.reservationDuration || 120, 'minutes')
          .toDate(),
      );
    }
  };

  const pickFreeSpot = () => {
    dispatch(
      reservationThunks.fetchFreeSpot({
        from: formikContext.values.from,
        to: formikContext.values.to,
        seats: formikContext.values.seats,
      }),
    ).then(res => {
      const spot = res.payload;
      if (spot?.id) {
        formikContext.setFieldValue('hallId', spot.hallId);
        formikContext.setFieldValue('spots', [spot.id]);
      } else {
        dispatch(toastrActions.showToastr({ variant: 'error', message: t('spotNotFound') }));
      }
    });
  };

  const toggleOpenMenu = () => {
    if (formikContext.values.banquetOrder.fullAmount) {
      formikContext.setFieldValue('banquetOrder', {});
      formikContext.setFieldValue('banquetUpdated', true);
    } else {
      openMenuModal();
    }
  }

  const updateMenuOrder = (newOrder, total, prepayment) => {
    formikContext.setFieldValue('banquetOrder', {
      fullAmount: total,
      amount: prepayment,
      dishes: newOrder
    });
    formikContext.setFieldValue('banquetUpdated', true);
    closeMenuModal();
  }

  return (
    <StyledDrawer anchor='right' open={open} onClose={onClose} ModalProps={{ sx: { left: 'calc(100vw - 380px)' } }}>
      <FormikProvider value={formikContext}>
        <Box p={2} component={Form} display='flex' flexDirection='column' gap={2} noValidate>
          <Box display='flex' justifyContent='flex-end' position='absolute' right={12} top={8}>
            <IconButton onClick={onClose}>
              <Icon type='close' size={24} />
            </IconButton>
          </Box>
          <Typography variant='h3'>
            {reservation ? t('editingReservation') : t('addingReservation')}
          </Typography>
          {showWaitList && (
            <FormControlLabel
              control={<Switch checked={isWaitList} onChange={() => setIsWaitList(!isWaitList)} color='secondary' />}
              label={t('addToWaitList')}
            />
          )}
          <FormikClientPhoneInput />
          <FormikTextInput
            disabled={existingUser}
            fullWidth
            name='firstName'
            placeholder={t('fields.user.firstName')}
          />
          <FormikTextInput disabled={existingUser} fullWidth name='lastName' placeholder={t('fields.user.lastName')} />
          {!isWaitList && (
            <Box display='flex' gap={1}>
              <FormikDatepicker
                fullWidth
                name='from'
                datepickerProps={{ withPortal: true, portalId: 'custom-portal-root' }}
                handleChange={fromDateChanged}
              />
              <FormikTimepicker
                fullWidth
                name='from'
                datepickerProps={{ popperPlacement: 'top-start' }}
                handleChange={fromDateChanged}
              />
            </Box>
          )}
          {!isWaitList && (
            <Box display='flex' gap={1}>
              <FormikDatepicker fullWidth name='to' datepickerProps={{ withPortal: true, portalId: 'custom-portal-root' }} />
              <FormikTimepicker
                fullWidth name='to'
                datepickerProps={{ popperPlacement: 'top-start' }}
              />
            </Box>
          )}
          <FormikTextInput
            type='number'
            fullWidth
            name='seats'
            inputProps={{ min: 1 }}
            placeholder={t('fields.reservation.seats')}
          />
          <FormikTextInput multiline fullWidth name='comment' placeholder={t('fields.reservation.comment')} />
          {isWaitList && (
            <FormikCheckbox name='variables.waitForTomorrow' label={t('fields.reservation.waitForTomorrow')} />
          )}
          {!isWaitList && (
            <>
              <FormikSelect
                fullWidth
                name='event'
                label={t('fields.reservation.event')}
                renderValue={selected => (
                  <Box display='flex' alignItems='center' gap={1}>
                    <Box backgroundColor={getReservationColor(selected)} width={24} height={24} />
                    <Typography>{customTags[selected] || t(`fields.reservation.events.${selected}`)}</Typography>
                  </Box>
                )}
              >
                {eventOptions}
              </FormikSelect>
              <FormikCheckbox name='variables.keepTable' label={t('fields.reservation.keepTable')} />
              {activePlace?.depositsActive && deposits.length > 0 && (
                <DepositManagement
                  values={formikContext.values}
                  setFieldValue={formikContext.setFieldValue}
                  editDisabled={!formikContext.values.from || !formikContext.values.phone || reservation?.bill?.status === 'DONE'}
                />
              )}
              {showMenu && dishes.length > 0 && (
                <Box display='flex'>
                  <FormControlLabel
                    control={
                      <Switch
                        color='secondary'
                        checked={!!formikContext.values.banquetOrder.fullAmount}
                        onChange={toggleOpenMenu}
                      />
                    }
                    label={<Typography variant='body1'>{t('openMenu')}</Typography>}
                    disabled={!formikContext.values.phone}
                  />
                  {!!formikContext.values.banquetOrder.fullAmount && (
                    <IconButton onClick={openMenuModal}>
                      <Icon type='edit' />
                    </IconButton>
                  )}
                </Box>
              )}
              {activePlace?.showAutomaticReservationButton && (
                <Button onClick={pickFreeSpot} disabled={!formikContext.values.seats} color='primary'>
                  {t('pickSpot')}
                </Button>
              )}
              <FormikSelect
                fullWidth
                name='hallId'
                items={halls}
                label={t('fields.reservation.hall')}
                labelPath='name'
                valuePath='id'
                onChange={clearSpots}
              />
              <FormikSelect
                multiple
                selectAll
                items={spots}
                name='spots'
                fullWidth
                label={t('fields.reservation.spot')}
                labelPath='label'
                valuePath='id'
                disabled={!formikContext.values.hallId}
                renderValue={selected => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map(val => (
                      <Chip
                        size='small'
                        onDelete={() => removeSpot(val)}
                        key={val}
                        label={allSpots.find(s => s.id === val)?.label}
                        onMouseDown={e => {
                          e.stopPropagation();
                        }}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              />
            </>
          )}
          {selectAdmin && (
            <FormikSelect
              items={admins}
              name='managerId'
              fullWidth
              label={t('fields.reservation.manager')}
              labelPath='displayedName'
              valuePath='id'
            />
          )}
          {showCertificates && (
            <CertificateManagement values={formikContext.values} setFieldValue={formikContext.setFieldValue} />
          )}
          <Button type='submit' disabled={formikContext.isSubmitting}>{t('save')}</Button>
        </Box>
      </FormikProvider>
      {conflictsModalOpen && (
        <ConflictingReservationsModal
          conflicts={conflictData?.conflicts}
          confirmReservation={conflictData?.confirmReservation}
          onClose={closeConflictsModal}
          isSubmitting={formikContext.isSubmitting}
        />
      )}
      {menuModalOpen && (
        <MenuModal
          onClose={closeMenuModal}
          order={formikContext.values.banquetOrder}
          setOrder={updateMenuOrder}
          initialPrepayment={formikContext.values.banquetOrder?.amount}
        />
      )}
    </StyledDrawer>
  );
};

export default memo(AddReservationDrawer);
