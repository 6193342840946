import React, { memo } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import mova from 'mova';
import Icon from '../Icon/Icon';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';

const t = mova.ns('components.Pagination');

const Pagination = ({ page = 0, perPage = 10, items, onChangePage, ...rest }) => {
  const theme = useTheme();

  if (items < perPage) {
    return null;
  }

  const lastPage = parseInt((items - 1) / perPage);
  const toItems = page === lastPage ? items : (page + 1) * perPage;

  return (
    <Box {...rest} alignItems='center'>
      <Typography variant='h3' align='center'>
        <IconButton disabled={page === 0} onClick={() => onChangePage(0)}>
          <Icon
            type='doubleChevronLeft'
            clickable={page !== 0}
            color={page === 0 ? theme.palette.typography.light : theme.palette.primary.main}
          />
        </IconButton>
        <IconButton disabled={page === 0} onClick={() => onChangePage(page - 1)}>
          <Icon
            type='chevronLeft'
            clickable={page !== 0}
            color={page === 0 ? theme.palette.typography.light : theme.palette.primary.main}
          />
        </IconButton>
        &nbsp;&nbsp;{page * perPage + 1}-{toItems} {t('of')} {items}&nbsp;&nbsp;
        <IconButton disabled={page >= lastPage} onClick={() => onChangePage(page + 1)}>
          <Icon
            type='chevronRight'
            clickable={page < lastPage}
            color={page >= lastPage ? theme.palette.typography.light : theme.palette.primary.main}
          />
        </IconButton>
        <IconButton disabled={page >= lastPage} onClick={() => onChangePage(lastPage)}>
          <Icon
            type='doubleChevronRight'
            clickable={page < lastPage}
            color={page >= lastPage ? theme.palette.typography.light : theme.palette.primary.main}
          />
        </IconButton>
      </Typography>
    </Box>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number,
  items: PropTypes.number.isRequired, // number of items
  onChangePage: PropTypes.func.isRequired,
};

export default memo(Pagination);
