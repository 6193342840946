import React from 'react';
import Modal from '../Modal/Modal';
import ModalActions from '../Modal/ModalActions';
import mova from 'mova';

const t = mova.ns('base');

const ConfirmModal = ({ open, onClose, confirm, title, content, confirmTitle = t('delete') }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      actions={<ModalActions onClose={onClose} submitForm={confirm} submitTitle={confirmTitle} />}
    >
      {content}
    </Modal>
  );
};

export default ConfirmModal;
