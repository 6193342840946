import React, { memo, useState } from 'react';
import Box from '@mui/material/Box';
import { Card, MenuItem } from '@mui/material';
import mova from 'mova';
import Typography from '@mui/material/Typography';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import { lineClamp } from "utils/styles";
import Icon from "../../../../../components/Icon/Icon";
import { CATEGORY_CERTIFICATE, CATEGORY_SUBSCRIPTION, TYPE_VISITS } from "utils/certificate";

const t = mova.ns('components.CertificateSettingsCard');

const CertificateSettingsCard = ({ certificate, editCertificate, deleteCertificate }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const closeMenu = () => {
    setMenuAnchorEl(null);
  }

  const handleEdit = () => {
    editCertificate(certificate);
    closeMenu();
  }

  const handleDelete = () => {
    deleteCertificate(certificate);
    closeMenu();
  }

  return (
    <Card sx={{ p: 2 }}>
      <Box display='flex' gap={2}>
        {certificate.image && (
          <Box flex='0 0 80px' height={80}>
            <Box
              component='img'
              width={80}
              height={80}
              src={certificate.imageUrl}
              alt={certificate.name}
              sx={{ objectFit: 'cover', borderRadius: '8px' }}
            />
          </Box>
        )}
        <Box width='100%'>
          <Box display='flex' alignItems='center' justifyContent='space-between' gap={0.5} width='100%'>
            <Typography variant='h3' sx={{ mb: 0.5 }}>
              {certificate.name}
            </Typography>
            <IconButton
              sx={{ flexGrow: 0, flexShrink: 0 }}
              size='small'
              onClick={(e) => setMenuAnchorEl(e.currentTarget)}
            >
              <Icon type='dots' size={16} />
            </IconButton>
          </Box>
          <Typography variant='body2' color='secondary.main'>
            {certificate.category === CATEGORY_CERTIFICATE ? t('categoryCertificate') : t('categorySubscription')}
            {certificate.category === CATEGORY_CERTIFICATE && (
              <Typography component='span' variant='body2'>
                : {certificate.data.type === TYPE_VISITS ? t('typeVisits') : t('typeAmount')}
              </Typography>
            )}
            {certificate.category === CATEGORY_SUBSCRIPTION && (
              <Typography component='span' variant='body2'>: {certificate.data.duration} {t('days')}</Typography>
            )}
          </Typography>
          <Typography variant='body2' style={lineClamp(3)} sx={{ height: 60 }}>{certificate.description}</Typography>
        </Box>
      </Box>
      <Box mt={2} display='flex' alignItems='center' justifyContent='space-between' gap={1}>
        <Typography variant='h3'>{certificate.price} {t('uah')}</Typography>
        <Typography variant='body2' color={certificate.active ? 'success.main' : 'error.main'}>
          {certificate.active ? t('active') : t('inactive')}
        </Typography>
      </Box>
      <Menu anchorEl={menuAnchorEl} open={!!menuAnchorEl} onClose={closeMenu}>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon><Icon type='edit' /></ListItemIcon>
          <ListItemText>{t('edit')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon><Icon type='remove' /></ListItemIcon>
          <ListItemText>{t('delete')}</ListItemText>
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default memo(CertificateSettingsCard);
