import React, { memo } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled, Tab, Tabs, useTheme } from "@mui/material";
import ScrollableMenu from "../../../components/ScrollableMenu/ScrollableMenu";

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
  minHeight: 40,
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
  }
}));

const DishScrollableMenu = ({ categories }) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const currentPath = queryString.parse(location.search).path;

  const goToPath = (path) => {
    const urlParams = queryString.parse(location.search);
    urlParams.path = path;
    history.push(`${location.pathname}?${queryString.stringify(urlParams)}`);
  };

  const menuItems = [...categories]
    .sort((c1, c2) => c1.order - c2.order)
    .map(c => ({
      label: c.name,
      path: c.slug,
      active: c.slug === currentPath || c.subcategories?.some(sc => sc.slug === currentPath),
      onClick: () => {
        if (c.subcategories?.length > 0) {
          goToPath(c.subcategories[0].slug);
        } else {
          goToPath(c.slug);
        }
      }
    }));

  const selectedCategory = categories.find(c => c.slug === currentPath || c.subcategories?.some(sc => sc.slug === currentPath));

  return (
    <Box>
      <ScrollableMenu items={menuItems} />
      {selectedCategory?.subcategories?.length > 0 && (
        <Tabs
          variant='scrollable'
          scrollButtons='auto'
          sx={{ mt: 1, minHeight: 40 }}
          value={currentPath}
          onChange={(e, newValue) => goToPath(newValue)}
          TabIndicatorProps={{ style: { backgroundColor: theme.palette.secondary.main } }}
        >
          {selectedCategory?.subcategories.map(sc => (
            <StyledTab key={sc.id} label={sc.name} value={sc.slug} />
          ))}
        </Tabs>
      )}
    </Box>
  );
};

export default memo(DishScrollableMenu);
