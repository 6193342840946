import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/apiService';

const fetchMenus = createAsyncThunk('dish/fetchMenus', async data => {
  return api.get(`/places/${data.placeId}/menus`);
});

const addMenu = createAsyncThunk('dish/addMenu', async data => {
  return api.post(`/places/${data.placeId}/menus`, data);
});

const updateMenu = createAsyncThunk('dish/updateMenu', async data => {
  return api.put(`/places/${data.placeId}/menus/${data.id}`, data);
});

const deleteMenu = createAsyncThunk('dish/deleteMenu', async data => {
  return api.delete(`/places/${data.placeId}/menus/${data.id}`);
});

const fetchDishes = createAsyncThunk('dish/fetchDishes', async data => {
  return api.get(`/places/${data.placeId}/dishes`);
});

const addDish = createAsyncThunk('dish/addDish', async ({ placeId, data }) => {
  return api.post(`/places/${placeId}/dishes`, data, {
    'Content-Type': 'multipart/form-data',
  });
});

const updateDish = createAsyncThunk('dish/updateDish', async ({ placeId, id, data }) => {
  return api.put(`/places/${placeId}/dishes/${id}`, data, {
    'Content-Type': 'multipart/form-data',
  });
});

const deleteDish = createAsyncThunk('dish/deleteDish', async ({ placeId, id }) => {
  return api.delete(`/places/${placeId}/dishes/${id}`);
});

export default {
  fetchMenus,
  addMenu,
  updateMenu,
  deleteMenu,

  fetchDishes,
  addDish,
  updateDish,
  deleteDish,
};
