import React from 'react';
import theme from '../../theme';

export default function calendar({ color = theme.palette.typography.primary, size = 24 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.001 22H5.00098C3.89641 22 3.00098 21.1046 3.00098 20V6C3.00098 4.89543 3.89641 4 5.00098 4H7.00098V2H9.00098V4H15.001V2H17.001V4H19.001C20.1055 4 21.001 4.89543 21.001 6V20C21.001 21.1046 20.1055 22 19.001 22ZM5.00098 10V20H19.001V10H5.00098ZM5.00098 6V8H19.001V6H5.00098ZM17.001 18H15.001V16H17.001V18ZM13.001 18H11.001V16H13.001V18ZM9.00098 18H7.00098V16H9.00098V18ZM17.001 14H15.001V12H17.001V14ZM13.001 14H11.001V12H13.001V14ZM9.00098 14H7.00098V12H9.00098V14Z'
        fill={color} />
    </svg>
  );
}


