import React from 'react';
import theme from '../../theme';

export default function chevronUp({ color = theme.palette.typography.primary }) {
  return (
    <svg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.4268 6.74828L6.30017 1.51489L1.17358 6.74828'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
