import React, { memo, useCallback, useState } from 'react';
import Box from "@mui/material/Box";
import PeriodsSelector from "../../components/PeriodsSelector/PeriodsSelector";
import queryString from 'query-string';
import { useHistory, useLocation } from "react-router-dom";
import periodUtils from "../../../utils/periodUtils";
import { Tab, Tabs } from "@mui/material";
import mova from "mova";
import PaymentStatisticsDeposits from "./components/PaymentStatisticsDeposits";
import PaymentStatisticsCertificates from "./components/PaymentStatisticsCertificates";

const t = mova.ns('components.PaymentStatistics');

const PaymentStatistics = () => {
  const [tab, setTab] = useState('deposits');
  const history = useHistory();
  const location = useLocation()

  const setDateParams = useCallback((params) => {
    const urlParams = queryString.parse(location.search);
    history.push(`${location.pathname}?${queryString.stringify({ ...urlParams, ...params })}`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps



  return (
    <Box display='flex' flexDirection='column' gap={2}>
      <PeriodsSelector setDateParams={setDateParams} defaultPeriod={periodUtils.today} defaultUnit='days' />
      <Tabs variant='scrollable' scrollButtons='auto' value={tab} onChange={(e, newValue) => setTab(newValue)}>
        <Tab label={t('depositsTab')} value='deposits' />
        <Tab label={t('certificatesTab')} value='certificates' />
      </Tabs>
      {tab === 'deposits' && <PaymentStatisticsDeposits />}
      {tab === 'certificates' && <PaymentStatisticsCertificates />}
    </Box>
  );
};

export default memo(PaymentStatistics);
