import React from 'react';
import theme from '../../theme';

export default function dishes({ color = theme.palette.common.white, size = 24 }) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M3 2H5V22H3V2ZM10 6H17V8H10V6ZM10 10H17V12H10V10Z' fill={color} />
      <path d='M19 2H6V22H19C20.103 22 21 21.103 21 20V4C21 2.897 20.103 2 19 2ZM19 20H8V4H19V20Z' fill={color} />
    </svg>
  );
}
